import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { AppState } from '../types';
import { accountingActions } from './accounting.actions';
import { accountingSelectors } from './accounting.selector';
import {
  AccountMoveModel,
  AccountMoveInstanceParams,
  IssuingAccountMovePayingAccountMoveModel,
  AccountMovePeriodModel,
  SlipModel,
  AccountMoveStatus,
  SlipResponseModel,
  JournalAssignRecord,
  BindType,
  AccountModel,
  CashJournalEntry,
  CashJournalModel,
} from './types';

@Injectable()
export class AccountingService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<AccountMoveModel, string> = this.genericApiCall(
    accountingActions.getById,
    accountingSelectors.getById,
    true,
  );
  getByContactId: GenericApiCall<AccountMoveModel[], string> =
    this.genericApiCall(
      accountingActions.getByContactId,
      accountingSelectors.getByContactId,
      true,
    );
  getByJournalId: GenericApiCall<AccountMoveModel[], string> =
    this.genericApiCall(
      accountingActions.getByJournalId,
      accountingSelectors.getByJournalId,
      true,
    );
  getUnbindedByContactId: GenericApiCall<
    AccountMoveModel[],
    { id: string; bindType: BindType }
  > = this.genericApiCall(
    accountingActions.getUnbindedByContactId,
    accountingSelectors.getUnbindedByContactId,
    true,
  );
  getUnbindedByContractId: GenericApiCall<
    AccountMoveModel[],
    { id: string; bindType: BindType }
  > = this.genericApiCall(
    accountingActions.getUnbindedByContractId,
    accountingSelectors.getUnbindedByContractId,
    true,
  );
  getUnbindedRepayment: GenericApiCall<AccountMoveModel[], string> =
    this.genericApiCall(
      accountingActions.getUnbindedRepayment,
      accountingSelectors.getUnbindedRepayment,
      true,
    );
  getUnbindedSlip: GenericApiCall<JournalAssignRecord[], string> =
    this.genericApiCall(
      accountingActions.getUnbindedSlip,
      accountingSelectors.getUnbindedSlip,
      true,
    );
  createInstance: GenericApiCall<AccountMoveModel, AccountMoveInstanceParams> =
    this.genericApiCall(
      accountingActions.createInstance,
      accountingSelectors.createInstance,
      true,
    );
  add: GenericApiCall<AccountMoveModel, AccountMoveModel> = this.genericApiCall(
    accountingActions.add,
    accountingSelectors.add,
    true,
  );
  bind: GenericApiCall<boolean, IssuingAccountMovePayingAccountMoveModel> =
    this.genericApiCall(accountingActions.bind, accountingSelectors.bind, true);
  getPeriods: GenericApiCall<AccountMovePeriodModel[], number> =
    this.genericApiCall(
      accountingActions.getPeriods,
      accountingSelectors.getPeriods,
      true,
    );

  importSlip: GenericApiCall<SlipResponseModel[], SlipModel> =
    this.genericApiCall(
      accountingActions.importSlip,
      accountingSelectors.importSlip,
      true,
    );
  changeAccountMoveStatus: GenericApiCall<
    AccountMoveModel,
    { id: string; status: AccountMoveStatus }
  > = this.genericApiCall(
    accountingActions.changeAccountMoveStatus,
    accountingSelectors.changeAccountMoveStatus,
    true,
  );
  uploadProof: GenericApiCall<boolean, { id: number; file: File }> =
    this.genericApiCall(
      accountingActions.uploadProof,
      accountingSelectors.uploadProof,
      true,
    );
  getAccountMovePdf: GenericApiCall<any, number> = this.genericApiCall(
    accountingActions.getAccountMovePdf,
    accountingSelectors.getAccountMovePdf,
    true,
  );
  delete: GenericApiCall<boolean, number> = this.genericApiCall(
    accountingActions.delete,
    accountingSelectors.delete,
    true,
  );
  unbind: GenericApiCall<
    boolean,
    { accountMoveId: number; bindType: BindType }
  > = this.genericApiCall(
    accountingActions.unbind,
    accountingSelectors.unbind,
    true,
  );
  getBindedAccountMoveByAccountMoveId: GenericApiCall<
    any,
    { accountMoveId: number; bindType: BindType }
  > = this.genericApiCall(
    accountingActions.getBindedAccountMoveByAccountMoveId,
    accountingSelectors.getBindedAccountMoveByAccountMoveId,
    true,
  );
  getAccountByContactId: GenericApiCall<AccountModel, string> =
    this.genericApiCall(
      accountingActions.getAccountByContactId,
      accountingSelectors.getAccountByContactId,
      true,
    );
  getAccountById: GenericApiCall<AccountModel, string> = this.genericApiCall(
    accountingActions.getAccountById,
    accountingSelectors.getAccountById,
    true,
  );
  getCashJournal: GenericApiCall<CashJournalModel, string> =
    this.genericApiCall(
      accountingActions.getCashJournal,
      accountingSelectors.getCashJournal,
      true,
    );
}
