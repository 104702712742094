<div
  class="tab-pane fade flex-even active show h-100"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
  *ngIf="this.contactDetail$ | async as contactDetail"
>
  <div class="d-flex flex-column h-100">
    <div class="row mt-2">
      <div class="col-12 col-md">
        <button
          type="button"
          [class]="ButtonType.Primary | buttontypepipe"
          class="btn-sm btn"
          translate
          [routerLink]="'/Crm/Leads/Create'" [info]="{contactId: contactDetail.id}"
        >
          Ajouter
        </button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 col-md">
        <app-generic-grid
          style="height: 300px"
          [data]="contactDetail.leads"
          [tabViewName]="'leads'" />
      </div>
    </div>
  </div>
</div>
