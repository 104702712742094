<form [formGroup]="documentForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="documentForm">
  <button type="submit" hidden="hidden"></button>

  <div class="row">
    <div class="col-12 mr-auto">
      <div class="form-group">
        <label class="form-title-label" for="name" translate
        >DOCUMENT_LBL_NAME</label
        >
        <input
          id="name"
          class="form-control form-title"
          type="text"
          formControlName="name"
          checkFormFieldValidity
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-12">
      <div class="form-group row">
        <label
          class="col-4 col-form-label-sm"
          for="documentType"
          translate
        >PRODUCT_CATEGORY_LBL_TYPE</label
        >
        <div class="col">
          <app-typeahead
            id="documentType"
            [routeEndpoint]="'DocumentTypes'"
            [entity]="Entity.DocumentType"
            [controlForm]="this.documentForm.controls['documentTypeId']"
            [dropdownStyle]="DropdownStyle.DropDown"
            [limitToList]="true"
            [domain]="'recordType!=1'"
            [readonly]="!!((this.documentModel$ | async)?.requestDate)"
          ></app-typeahead>
        </div>
      </div>
    </div>
  </div>
</form>
