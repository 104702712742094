import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { AccountMovesRoutingModule } from './account-move-routing.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, AccountMovesRoutingModule, SharedModule],
})
export class AccountMovesModule {}
