import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product-line-details-commissions',
  templateUrl: './product-line-details-commissions.component.html',
  styleUrls: ['./product-line-details-commissions.component.scss'],
})
export class ProductLineDetailsCommissionsComponent
  implements OnInit, OnDestroy
{
  productLineId: number;

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit(): void {
    this.productLineId = this.route.snapshot.params['id'];
  }

  ngOnDestroy(): void {}
}
