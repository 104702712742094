<app-company-commission-subheader />
<div *ngIf="companyCommissions$ | async as companyCommissions">
  <table class="table table-striped border-bottom">
    <thead>
      <tr>
        <th translate>FINANCE_COMPANY_COMMISSION_COMPANYNAME</th>
        <th translate>FINANCE_COMPANY_COMMISSION_HEALTH_INSURANCE</th>
        <th translate>FINANCE_COMPANY_COMMISSION_CAR_INSURANCE</th>
        <th translate>FINANCE_COMPANY_COMMISSION_HOUSE_INSURANCE</th>
        <th translate>FINANCE_COMPANY_COMMISSION_TOTAL_AMOUNT</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let companyCommission of companyCommissions">
        <td>{{ companyCommission.companyName }}</td>
        <td>{{ companyCommission.healthAmount }} €</td>
        <td>{{ companyCommission.carAmount }} €</td>
        <td>{{ companyCommission.houseAmount }} €</td>
        <td>{{ companyCommission.totalAmount }} €</td>
      </tr>
    </tbody>
  </table>
</div>
