<app-document-subheader/>
<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div class="page-container">
        <div class="card card-light w-100">

          <div class="card-body">
            <div class="container-fluid">
              @if (cashJournalModel$ |async; as cashJournalModel) {
                <div class="row mb-2">
                  <div class="col">
                    <app-badge
                      [color]="'primary'"
                      [message]="'CASH_JOURNAL_LBL_BALANCE'  | translate : {balance: cashJournalModel.balance |showSignValueType: 'Monetary'}"
                      [needTranslation]="false"
                    />
                  </div>
                </div>

                <app-generic-grid
                  [data]="cashJournalModel.cashJournalEntries"
                  [tabViewName]="'cashJournalEntries'"
                  (onActionClickedSetRowData)="onSetRowData($event)"
                />

                <!--                <div class="row mt-2">-->
                  <!--                  <table class="table table-striped border-bottom">-->
                  <!--                    <thead>-->
                  <!--                    <tr>-->
                  <!--                      <th>-->
                  <!--                        <a translate>CASH_JOURNAL_LBL_TYPE</a>-->
                  <!--                      </th>-->
                  <!--                      <th>-->
                  <!--                        <a translate>CASH_JOURNAL_LBL_AMOUNT</a>-->
                  <!--                      </th>-->
                  <!--                      <th>-->
                  <!--                        <a translate>CASH_JOURNAL_LBL_ACCOUNT</a>-->
                  <!--                      </th>-->
                  <!--                      <th>-->
                  <!--                        <a translate>CASH_JOURNAL_LBL_SALES_PERSON</a>-->
                  <!--                      </th>-->
                  <!--                      <th>-->
                  <!--                        <a translate>CASH_JOURNAL_LBL_PROOF</a>-->
                  <!--                      </th>-->
                  <!--                      <th>-->
                  <!--                        <a translate>CASH_JOURNAL_LBL_DATE</a>-->
                  <!--                      </th>-->
                  <!--                      <th>-->
                  <!--                        <a translate>CASH_JOURNAL_LBL_DESCRIPTION</a>-->
                  <!--                      </th>-->
                  <!--                    </tr>-->
                  <!--                    </thead>-->

                  <!--                    <tbody>-->
                  <!--                      @if (!!cashJournalModel.cashJournalEntries) {-->
                  <!--                        @for (cashJournalEntry of cashJournalModel.cashJournalEntries; track cashJournalEntry.id) {-->
                  <!--                          <tr>-->
                  <!--                            <td>{{ cashJournalEntry.accountMoveTypeFlowLoc }}</td>-->
                  <!--                            <td [class.negativeAmount]="cashJournalEntry.amount < 0"-->
                  <!--                                [class.positiveAmount]="cashJournalEntry.amount >= 0">{{ cashJournalEntry.amount  | showSignValueType: 'Monetary' }}-->
                  <!--                            </td>-->
                  <!--                            <td>-->
                  <!--                              @if (cashJournalEntry.debitAccountId != this.salesTeamAccountId) {-->
                  <!--                                {{ cashJournalEntry.debitAccountName }}-->
                  <!--                              } @else {-->
                  <!--                                {{ cashJournalEntry.creditAccountName }}-->
                  <!--                              }-->
                  <!--                            </td>-->
                  <!--                            <td>{{ cashJournalEntry.salesPersonName }}</td>-->
                  <!--                            <td>-->
                  <!--                              @if (cashJournalEntry.documentId) {-->
                  <!--                                <a (click)="onClickShowDocument(cashJournalEntry.documentId)" role="button" translate>CASH_JOURNAL_LBL_VIEW_DOCUMENT</a>-->
                  <!--                              }-->
                  <!--                            </td>-->
                  <!--                            <td>{{ cashJournalEntry.creationDate | date : 'dd/MM/yyyy' }}</td>-->
                  <!--                            <td>-->
                  <!--                              @if (!!cashJournalEntry.description && !!cashJournalEntry.reference) {-->
                  <!--                                {{ cashJournalEntry.description }} {{ '(référence ' + cashJournalEntry.reference + ')' }}-->
                  <!--                              }-->
                  <!--                              @if (!!cashJournalEntry.description && !cashJournalEntry.reference) {-->
                  <!--                                {{ cashJournalEntry.description }}-->
                  <!--                              }-->
                  <!--                              @if (!cashJournalEntry.description && !!cashJournalEntry.reference) {-->
                  <!--                                {{ 'référence ' + cashJournalEntry.reference }}-->
                  <!--                              }-->

                  <!--                            </td>-->
                  <!--                          </tr>-->
                  <!--                        }-->
                  <!--                      }-->
                  <!--                    </tbody>-->
                  <!--                  </table>-->
                  <!--                </div>-->
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
