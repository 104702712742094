import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-commission-plan-rule-shared-sales-person-edit',
  templateUrl: './commission-plan-rule-shared-sales-person-edit.component.html',
  styleUrl: './commission-plan-rule-shared-sales-person-edit.component.scss',
})
export class CommissionPlanRuleSharedSalesPersonEditComponent
  implements OnInit
{
  commissionPlanRuleId: string;

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit(): void {
    this.commissionPlanRuleId = this.route.snapshot.params['id'];
  }
}
