import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DataListingProviderComponent } from 'src/app/shared/components/data-listing-provider/data-listing-provider.component';
import { AuthenticationGuard } from 'src/app/shared/guard/authentication-guard';
import { Entity } from 'src/app/shared/store/view/types';
import { HasUnsavedChangesGuard } from '../../../shared/guard/has-form-changed.guard';
import { ModuleId } from '../../../shared/store/navigation/types';
import { HeaderMenuType } from '../../../shared/store/view/types';
import { ContractCreateComponent } from './contract-create/contract-create.component';
import { ContractDetailsComponent } from './contract-details/contract-details.component';
import { ContractEditComponent } from './contract-edit/contract-edit.component';
import { AccountMoveAssignContentComponent } from '../../../shared/components/account-moves/account-move-assign-content/account-move-assign-content.component';

const routes: Routes = [
  {
    path: 'Crm/ContractRequests',
    data: {
      breadcrumb: { label: ' ', alias: 'list' },
    },
    children: [
      {
        path: '',
        redirectTo: 'List',
        pathMatch: 'full',
      },
      {
        path: 'List',
        component: DataListingProviderComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          moduleId: ModuleId.crm,
          entity: Entity.ContractRequest,
          breadcrumb: { skip: true },
        },
      },
      {
        path: 'Details/:id',
        component: ContractDetailsComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          moduleId: ModuleId.crm,
          entity: Entity.ContractRequest,
          breadcrumb: { label: ' ', alias: 'contractDetails' },
        },
      },
      {
        path: 'Edit/:id',
        component: ContractEditComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          moduleId: ModuleId.crm,
          entity: Entity.ContractRequest,
          breadcrumb: { label: ' ', alias: 'contractEdit' },
        },
      },
      {
        path: 'Create',
        component: ContractCreateComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          moduleId: ModuleId.crm,
          entity: Entity.ContractRequest,
          breadcrumb: { label: ' ', alias: 'contractCreate' },
        },
      },
      {
        path: 'Create/:productCategoryId/:leadId',
        component: ContractCreateComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          moduleId: ModuleId.crm,
          entity: Entity.ContractRequest,
          breadcrumb: { label: ' ', alias: 'contractCreate' },
        },
      },
      {
        path: 'Create/:productCategoryId/:leadId/:productId',
        component: ContractCreateComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          entity: Entity.ContractRequest,
          breadcrumb: { label: ' ', alias: 'contractCreate' },
        },
      },
    ],
  },
  {
    path: 'Insurance/Contracts',
    data: {
      breadcrumb: { label: ' ', alias: 'list' },
    },
    children: [
      {
        path: '',
        redirectTo: 'List',
        pathMatch: 'full',
      },
      {
        path: 'List',
        component: DataListingProviderComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          moduleId: ModuleId.insurance,
          entity: Entity.Contract,
          breadcrumb: { skip: true },
        },
      },
      {
        path: 'Details/:id',
        component: ContractDetailsComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          moduleId: ModuleId.insurance,
          entity: Entity.Contract,
          breadcrumb: { label: ' ', alias: 'contractDetails' },
        },
      },
      {
        path: 'Assign/:id',
        component: AccountMoveAssignContentComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          moduleId: ModuleId.insurance,
          entity: Entity.Contract,
          breadcrumb: { label: ' ', alias: 'contractDetails' },
        },
      },
      // {
      //   path: 'Edit/:id',
      //   component: ContractEditComponent,
      //   canActivate: [AuthentificationGuard],
      //   canDeactivate: [HasUnsavedChangesGuard],
      //   data: {
      //     headerMenu: HeaderMenuType.Sales,
      //     entity: Entity.Contract,
      //     breadcrumb: { label: ' ', alias: 'contractEdit' },
      //   },
      // },
      // {
      //   path: 'Create/:category',
      //   component: ContractCreateComponent,
      //   canActivate: [AuthentificationGuard],canDeactivate: [HasUnsavedChangesGuard],
      //   data: {
      //     headerMenu: HeaderMenuType.Sales,
      //     entity: Entity.Contract,
      //     subHeaderOption: SubHeaderType.ContractCreate,
      //     breadcrumb: { label: ' ', alias: 'contractCreate' },
      //   },
      // },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContractRoutingModule {}
