import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onApiCall } from '../../../common/store/generic.reducer';
import { GenericState, IState } from '../../../common/store/types';
import { accountActions } from './account.actions';
import { AccountModel } from '../accounting/types';

export interface IAccountState extends IState<AccountModel> {
  getById: GenericState<AccountModel>;
  add: GenericState<AccountModel>;
  update: GenericState<AccountModel>;
  delete: GenericState<boolean>;
  createInstance: GenericState<AccountModel>;
}

export const accountInitialState: IAccountState = {
  getById: new GenericState<AccountModel>(),
  add: new GenericState<AccountModel>(),
  update: new GenericState<AccountModel>(),
  delete: new GenericState<boolean>(),
  createInstance: new GenericState<AccountModel>(),
};

const accountReducers = [
  ...onApiCall<AccountModel>(accountActions.getById, 'getById'),
  ...onApiCall<AccountModel>(accountActions.add, 'add'),
  ...onApiCall<AccountModel>(accountActions.update, 'update'),
  ...onApiCall<boolean>(accountActions.delete, 'delete'),
  ...onApiCall<AccountModel>(accountActions.createInstance, 'createInstance'),
];

export const accountReducer = createReducer(
  accountInitialState,
  ...accountReducers,
) as ActionReducer<IAccountState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return accountReducer(state, action);
}
