import { ContractModel } from '../contract/types';
import { LeadDetailModel, LeadModel } from '../lead/types';
import { BankAccountModel } from '../bank-account/types';
import { DocumentModel } from '../document/types';
import { AccountMoveModel } from '../accounting/types';

export const contactFeatureKey = 'contact';

export type ContactModel = {
  id: number;
  title: string;
  titleId: number;
  titleName: string;
  name: string;
  lastName: string;
  firstName: string;
  profession: string;
  phoneNumber: string;
  mobilePhoneNumber: string;
  email: string;
  address: string;
  address2: string;
  zipCode: string;
  city: string;
  countryId?: number;
  countryName: string;
  isCompany: boolean;
  salesPersonId?: number;
  salesPersonName: string;
  campaignId?: number;
  campaignName: string;
  medium: string;
  source: string;
  addressType: number;
  addressTypeLoc?: string;
  gender?: number;
  genderLoc?: string;
  parentId?: number;
  parentName: string;
  notes: string;
  salesTeamId?: number;
  salesTeamName: string;
  creationTime: Date;
  bankAccount?: BankAccountModel;
  uniqueId: string;
  pictureId?: number;
  picturePath?: string;
  pictureFile?: File;
  contracts: ContractModel[];
  leads: LeadDetailModel[];
  bankAccounts: BankAccountModel[];
  documents: DocumentModel[];
  accountMoves: AccountMoveModel[];
  clientAccountMoves: AccountMoveModel[];
  contractsCount: number;
  leadsCount: number;
  isEmployee: boolean;
  isInsuranceCompany: boolean;
  isUser: boolean;
};
