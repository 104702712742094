<ng-container *ngIf="this.activeFilters$ | async as filters">
  <span
    class="search-tag"
    *ngFor="let filter of filters; trackBy: trackByIndex"
  >
    <span class="search-tag-prepend" *ngIf="filter.tag != 'TextFilter'">
      <i class="mdi mdi-filter"></i>
    </span>
    <span class="search-tag-prepend" *ngIf="filter.tag == 'TextFilter'">
      {{ filter.name }}
    </span>

    <span
      class="search-tag-value"
      *ngIf="filter.tag == 'BuiltInFilter' || 'SmartActionFilter' || 'PermanentFilter'"
    >
      {{ filter.text }}
    </span>
    <ng-container *ngIf="filter.tag == 'CustomFilter'">
      <span
        class="search-tag-value"
        *ngIf="filter.domains | formattedDomain as domains"
      >
        <span *ngFor="let domain of domains">
          <span
            *ngIf="filter.domains.length != 1 && domains.indexOf(domain) != 0"
          >
            <i> ou</i>
          </span>
          {{ domain.fieldLabel }}
          {{ domain.operator.label }}
          <span [innerHTML]="domain.text"> </span>
        </span>
      </span>
    </ng-container>
    <span class="search-tag-value" *ngIf="filter.tag == 'TextFilter'">
      <span *ngFor="let domain of filter.domains">
        <span
          *ngIf="
            filter.domains.length != 1 && filter.domains.indexOf(domain) != 0
          "
        >
          <i>ou</i>
        </span>
        {{ domain.value }}
      </span>
    </span>

    <span
      class="search-tag-append"
      (click)="removeFilter(filters.indexOf(filter))"
      *ngIf="filter.tag !== 'PermanentFilter'"
    >
      <i class="mdi mdi-close"></i>
    </span>
  </span>
</ng-container>
