import { Pipe, PipeTransform } from '@angular/core';
import { AmountType } from '../store/commissions/commission-plan-rule/types';

@Pipe({
  name: 'showSignValueType',
})
export class ShowSignValueTypePipe implements PipeTransform {
  transform(value: any, valueType: AmountType): string {
    const valueNumber = Number(value);
    if (Number.isNaN(valueNumber)) return value;

    let sign = '';
    if (valueType === 'Monetary') sign = '€';
    if (valueType === 'Percentage') sign = '%';

    return valueNumber.toFixed(2).toString() + ' ' + sign;
  }
}
