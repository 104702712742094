import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { CompanyCommissionRoutingModule } from './company-commission-routing.module';
import { CompanyCommissionComponent } from './company-commission/company-commission.component';
import { CompanyCommissionSubHeaderComponent } from './company-commission-subheader/company-commission-subheader.component';

@NgModule({
  declarations: [
    CompanyCommissionComponent,
    CompanyCommissionSubHeaderComponent,
  ],
  imports: [CommonModule, CompanyCommissionRoutingModule, SharedModule],
})
export class CompanyCommissionModule {}
