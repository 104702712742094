import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { DocumentModel } from '../../../store/document/types';
import { filter, take } from 'rxjs/operators';
import { DocumentService } from '../../../store/document/document.service';
import { FileSaverService } from 'ngx-filesaver';
import { ModalContentComponent } from '../../modal-content/modal-content.component';
import { toFirstLetterLower } from '../../../helper/stringhelper';
import { SourceEnum } from '../../../store/typeahead/types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '../../../service/modal.service';
import { ActionButton } from '../../modal-content/action-button';
import { ButtonType } from '../../../store/subheader/type';
import { BehaviorSubject } from 'rxjs';
import { AccountingService } from '../../../store/accounting/accounting.service';
import { ConfigurationService } from '../../../service/configuration.service';

@Component({
  selector: 'app-document-card',
  templateUrl: './document-card.component.html',
  styleUrls: ['./document-card.component.scss'],
})
export class DocumentCardComponent implements OnInit {
  @Input({ required: true }) document!: DocumentModel;
  @Input() isHorizontalUse: boolean = false;

  downloadFinished: boolean = false;
  downloadClicked: boolean = false;

  constructor(
    private readonly documentService: DocumentService,
    private readonly fileSaverService: FileSaverService,
    private readonly modalService: NgbModal,
    private readonly modalExchangeService: ModalService,
    private readonly configurationService: ConfigurationService,
  ) {}

  ngOnInit(): void {}

  onClickDownload(document: DocumentModel) {
    const getFileSubscription = this.documentService.getFile.value$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result: Blob) => {
        if (
          this.configurationService
            .getOpenFileExtensions()
            .some((x) => x.includes(document.fileExtension))
        ) {
          var fileURL = window.URL.createObjectURL(result);
          let tab = window.open();
          if (tab) {
            tab.location.href = fileURL;
          }
        } else {
          this.fileSaverService.save(
            result,
            document.name,
            document.contentType,
          );
        }

        this.downloadFinished = true;
        this.documentService.getFile.reset();
      });

    this.documentService.getFile.call(document.id.toString());
  }

  onClickOpenModalEdit() {
    if (this.downloadClicked) {
      this.downloadClicked = false;
      return;
    }

    const modalRef = this.modalService.open(ModalContentComponent, {
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.component =
      this.modalExchangeService.getComponentClass(
        'DocumentEditContentComponent',
      );
    modalRef.componentInstance.title = 'Document';
    modalRef.componentInstance.cancelButton = true;
    modalRef.componentInstance.buttons = [
      <ActionButton>{
        actionName: toFirstLetterLower('document') + 'Update',
      },
    ];
    modalRef.componentInstance.inputParams = {
      modalId: this.document.id,
      source: SourceEnum.Modal,
    };

    modalRef.result
      .then((id) => {
        if (id >= 0) {
          modalRef.close();
        }
      })
      .finally(() => {
        //avoid error on cancel
      });
  }
}
