@if (!documentModel().fileExtension) {
  <!--//request-->
  <app-document-add #newDocument [documentId]="documentModel().id" [multiple]="true"
                    (newDocument)="onNewDocument($event)"/>
  <div class="row file-upload-wrapper mb-2 w-100"
       [class.pe-none]="!isEditMode()"
       id="tile-container-{{documentModel().documentTypeId}}-{{documentModel().id}}"
       (click)="isEditMode() && newDocument.fileupload.nativeElement.click()"
       [class.file-over]="isEditMode() && (this.fileOver$ | async)"
       (drop)="onFileDrop($event)"
       (dragover)="onDragOver($event)"
       (dragleave)="onDragLeave($event)"
       (dragend)="onDragEnd($event)"
  >
    <div class="col">
      {{ documentModel().documentTypeName }}
    </div>
    <div class="col-auto">

      <app-badge
        *ngIf="documentModel().productCategoryDocumentTypeMandatory"
        [color]="'light-warning'"
        [message]="'DOCUMENT_LBL_STATUS_MANDATORY'"
      />
      <app-badge
        *ngIf="!documentModel().productCategoryDocumentTypeMandatory"
        [color]="'light-info'"
        [message]="'DOCUMENT_LBL_STATUS_OPTIONAL'"
      />

    </div>
  </div>
} @else {
  <!--  //received-->
  <div class="row file-received mb-2 w-100">
    <div class="col">
      {{ documentModel().documentTypeName }}
    </div>
    <div class="col text-nowrap text-truncate">
      {{ documentModel().name }}
    </div>
    <div class="col">
      {{ documentModel().size | fileSize }}
    </div>
    <div class="col">
      {{ documentModel().dateReceived | date: 'dd/MM/YYYY' }}
    </div>
    <div class="col">
      @for (action of documentModel().actions; track action.name; let last = $last) {
        <a role='button'
           (click)="this.triggerAction(action.name)"
           translate>{{ action.actionName }}{{ last ? '' : ' | ' }}</a>
      }
    </div>
    <div class="col-auto">
      <app-badge
        [color]="'light-primary'"
        [message]="'DOCUMENT_LBL_STATUS_RECEIVED'"
      />
    </div>
  </div>
}
