import { Component, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { filter, Observable } from 'rxjs';
import { EmployeeModel } from '../../../../../../shared/store/employee/types';
import { EmployeeService } from '../../../../../../shared/store/employee/employee.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-employee-form-parameters',
  templateUrl: './employee-form-parameters.component.html',
  styleUrls: ['./employee-form-parameters.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class EmployeeFormParametersComponent implements OnInit {
  form: FormGroup;
  employeeDetail$: Observable<EmployeeModel>;
  employeeModel$: Observable<EmployeeModel>;

  constructor(
    private readonly employeeService: EmployeeService,
    private parent: FormGroupDirective,
    private formBuilder: FormBuilder,
  ) {
    this.employeeDetail$ = this.employeeService.getById.value$;
    this.employeeModel$ = this.employeeService.getEmployeeModel.value$;
  }

  ngOnInit(): void {
    this.form = this.parent.form;
    this.form.addControl(
      'parametersFormGroup',
      this.formBuilder.group({
        parametersVehicle: [''],
        parameterHomeWorkDistance: [0],
      }),
    );

    this.employeeModel$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.form.patchValue({
          parametersFormGroup: {
            parametersVehicle: x.vehicle,
            parameterHomeWorkDistance: x.homeWorkDistance,
          },
        });
      });

    this.employeeDetail$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.form.patchValue({
          parametersFormGroup: {
            parametersVehicle: x.vehicle,
            parameterHomeWorkDistance: x.homeWorkDistance,
          },
        });
      });
  }
}
