import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ErrorService } from '../../error';
import { CashControlModel } from './types';
import { catchError } from 'rxjs/operators';
import {
  AccountMoveInstanceParams,
  AccountMoveModel,
} from '../accounting/types';
import { isNumber } from 'chart.js/helpers';
import { CrudType } from '../../service/message/types';
import { BankAccountModel } from '../bank-account/types';
import { MessageService } from '../../service/message/message.service';

@Injectable({
  providedIn: 'root',
})
export class CashControlApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService,
    private readonly messageService: MessageService,
  ) {}

  getById = (id: number): Observable<CashControlModel> => {
    let parameters: string[];
    parameters = [id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<CashControlModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  getBySalesTeamAccountId = (id: number): Observable<CashControlModel[]> => {
    let parameters: string[];
    parameters = ['account', id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<CashControlModel[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  add = (cashControlModel: CashControlModel): Observable<CashControlModel> => {
    let parameters: string[];
    parameters = [];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .post<CashControlModel>(url, cashControlModel, { observe: 'response' })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  update = (cashControlModel: CashControlModel): any => {
    let parameters: string[];
    parameters = [cashControlModel.id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .put<CashControlModel>(url, cashControlModel, { observe: 'response' })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  delete = (cashControlId: number): Observable<boolean> => {
    let parameters: string[];
    parameters = [cashControlId.toString()];
    let url = this.formatUrl(parameters);

    return this.httpClient.delete<boolean>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  createInstance = (
    salesTeamAccountId: number,
  ): Observable<CashControlModel> => {
    let parameters: string[];
    parameters = ['create'];

    let queryParams = new HttpParams();
    queryParams = queryParams.append(
      'salesTeamAccountId',
      salesTeamAccountId.toString()!,
    );

    let url = this.formatUrl(parameters);
    return this.httpClient
      .get<CashControlModel>(url, { params: queryParams })
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  formatUrl = (parameters: string[]): string => {
    var url = environment.comparanooapiurl;
    url += environment.features.cashControl.url;
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}
