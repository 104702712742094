import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable } from 'rxjs';
import { CommissionService } from 'src/app/shared/store/commissions/commission/commission.service';
import { ReportingService } from 'src/app/shared/store/reporting/reporting.service';
import { Period } from 'src/app/shared/store/reporting/types';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { View } from 'src/app/shared/store/view/types';
import { ViewService } from 'src/app/shared/store/view/views.service';
import { BreadcrumbService } from 'xng-breadcrumb';

@UntilDestroy()
@Component({
  selector: 'app-company-commission-subheader',
  templateUrl: './company-commission-subheader.component.html',
  styleUrl: './company-commission-subheader.component.scss',
})
export class CompanyCommissionSubHeaderComponent implements OnInit, OnDestroy {
  view$: Observable<View>;
  periodLabel: string;
  periods$: Observable<Period[]>;
  periods: Period[];
  defaultPeriod: string;
  startDate!: Date;
  endDate!: Date;

  constructor(
    private readonly subHeaderService: SubHeaderService,
    private readonly viewService: ViewService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly reportingService: ReportingService,
    private readonly commissionService: CommissionService,
  ) {}

  ngOnInit(): void {
    this.view$ = this.viewService.getViews.value$;
    this.periods$ = this.reportingService.getPeriods.value$;

    this.startDate = new Date();
    this.startDate.setDate(this.startDate.getDate() - 30);

    this.endDate = new Date();
    this.endDate.setDate(this.endDate.getDate());

    this.reportingService.getPeriods.call(new Date());

    this.periodLabel = formatPeriodLabel(this.startDate, this.endDate);
    this.defaultPeriod = 'Last30Days';

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });
    this.periods$
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((periods) => {
        this.periods = periods;
      });
    this.commissionService.getCompanyCommissions.call({
      startDate: this.startDate,
      endDate: this.endDate,
    });
  }
  ngOnDestroy(): void {}
  onPeriodChange(newValue) {
    this.periods.forEach((period) => {
      if (period.value == newValue.target.value) {
        this.startDate = period.startDate;
        this.endDate = period.endDate;
      }
    });
    this.periodLabel = formatPeriodLabel(
      new Date(this.startDate),
      new Date(this.endDate),
    );
    this.commissionService.getCompanyCommissions.call({
      startDate: new Date(this.startDate),
      endDate: new Date(this.endDate),
    });
  }
}

function formatPeriodLabel(startDate: Date, endDate: Date) {
  const fullOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const monthOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  };
  const dayOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    day: 'numeric',
  };
  let periodLabel: string = '';
  if (startDate.getFullYear() == endDate.getFullYear()) {
    periodLabel =
      'Du ' +
      startDate.toLocaleDateString('fr-FR', monthOptions) +
      ' au ' +
      endDate.toLocaleDateString('fr-FR', fullOptions);

    if (startDate.getMonth() == endDate.getMonth()) {
      periodLabel =
        'Du ' +
        startDate.toLocaleDateString('fr-FR', dayOptions) +
        ' au ' +
        endDate.toLocaleDateString('fr-FR', fullOptions);

      if (startDate.getDate() == endDate.getDate()) {
        periodLabel =
          'Le ' + startDate.toLocaleDateString('fr-FR', fullOptions);
      }
    }
  }

  if (startDate.getFullYear() != endDate.getFullYear()) {
    periodLabel =
      'Du ' +
      startDate.toLocaleDateString('fr-FR', fullOptions) +
      ' au ' +
      endDate.toLocaleDateString('fr-FR', fullOptions);
  }
  return periodLabel;
}
