import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  ColDef,
  GridOptions,
  RowSelectionOptions,
  SelectionColumnDef,
} from 'ag-grid-community';
import { Column } from '../store/view/types';
import { BadgeListComponent } from '../components/badge/badge-list/badge-list.component';
import { BadgeComponent } from '../components/badge/badge/badge.component';
import { BadgeModel } from '../models/badge-model';
import { PhoneNumberComponent } from '../components/phone-number/phone-number.component';
import { FileSizePipe } from '../pipe/file-size.pipe';
import { ActionLinkComponent } from '../components/generic-grid/action-link/action-link.component';
import { ColoredAmountComponent } from '../components/generic-grid/colored-amount/colored-amount.component';

@Injectable({
  providedIn: 'root',
})
export class GridService {
  constructor(
    private readonly router: Router,
    private readonly translate: TranslateService,
  ) {}

  getConfig(): GridOptions<any> {
    let gridOptions: GridOptions = {};

    gridOptions.suppressCellFocus = true;
    gridOptions.headerHeight = 37.2;
    gridOptions.animateRows = true;
    gridOptions.suppressMiddleClickScrolls = true;

    gridOptions.selectionColumnDef = {
      sortable: false,
      width: 60,
      maxWidth: 60,
      suppressHeaderMenuButton: false,
    };

    return gridOptions;
  }

  getRowSelectionConfig() {
    let rowSelectionOptions: RowSelectionOptions | undefined;

    rowSelectionOptions = undefined;

    return rowSelectionOptions;
  }

  renderCustomColumn(
    column: Column,
    colDef: ColDef<any, any>,
  ): ColDef<any, any> {
    if (!column || !colDef) return colDef;

    switch (column.type) {
      case 'date':
        colDef.cellRenderer = (data) => {
          return data.value
            ? new Date(data.value).toLocaleString('fr-FR', {
                dateStyle: 'short',
                timeStyle: 'short',
              })
            : '-';
        };
        return colDef;
      case 'dateOnly':
        colDef.cellRenderer = (data) => {
          return data.value
            ? new Date(data.value).toLocaleString('fr-FR', {
                dateStyle: 'short',
              })
            : '-';
        };
        return colDef;
      case 'badges':
        colDef.cellRenderer = BadgeListComponent;
        return colDef;
      case 'badge':
        colDef.cellRenderer = BadgeComponent;
        colDef.cellRendererParams = <BadgeModel>{
          name: column.name,
          color: column.color,
        };
        return colDef;
      case 'checkbox':
        colDef.cellRenderer = (params) => {
          return `<input type='checkbox' ${
            params.value ? 'checked' : ''
          } disabled />`;
        };
        return colDef;
      case 'activeBadge':
        colDef.cellRenderer = BadgeComponent;
        colDef.cellRendererParams = <BadgeModel>{
          name: column.name,
          color: column.color,
        };
        return colDef;
      case 'phoneNumber':
        colDef.cellRenderer = PhoneNumberComponent;
        return colDef;
      case 'number':
        colDef.cellClass = 'ag-right-aligned-cell';
        return colDef;
      case 'currency':
        colDef.cellClass = 'ag-right-aligned-cell';
        colDef.cellRenderer = (data) => {
          if (Number.isNaN(data.value)) {
            return data.value;
          }

          return data.value.toFixed(2).toString() + ' €';
        };
        return colDef;
      case 'percent':
        colDef.cellClass = 'ag-right-aligned-cell';
        colDef.cellRenderer = (data) => {
          if (Number.isNaN(data.value)) {
            return data.value;
          }

          return data.value.toFixed(2).toString() + ' %';
        };
        return colDef;
      case 'fileSize':
        colDef.cellRenderer = (data) => {
          return data.value
            ? new FileSizePipe().transform(data.value)
            : data.value;
        };
        return colDef;
      case 'array':
        colDef.cellRenderer = (data) => {
          if (!Array.isArray(data.value)) return null;

          return data.value.join(', ');
        };
        return colDef;
      case 'actions':
        colDef.cellRenderer = ActionLinkComponent;
        return colDef;
      case 'coloredAmount':
        colDef.cellClass = 'ag-right-aligned-cell';
        colDef.cellRenderer = ColoredAmountComponent;
        return colDef;
      default:
        colDef.cellRenderer = null;
        return colDef;
    }
  }

  getEmptyColumnDef(): ColDef<any> {
    const colDef: ColDef = {
      field: '',
      headerName: '',
      sortable: false,
      suppressMovable: true, //column not movable for all
      sort: null,
      sortingOrder: [null],
      resizable: false,
      unSortIcon: false, //Icon to show sort is possible
      width: 20,
    };

    return colDef;
  }
}
