<div
  class="tab-pane fade flex-even active show"
  id="employees"
  role="tabpanel"
  aria-labelledby="employees-tab"
  formGroupName="privateInformationsFormGroup"
>
  <div class="d-flex flex-column h-100">
    <div class="row mt-2">
      <div class="col-12 col-md">

        <div class="form-group row">
          <label class="col-4 col-form-label-sm" for="privateInformationsPrivatePhoneNumber" translate
          >EMPLOYEE_LBL_PRIVATE_PHONE_NUMBER</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="privateInformationsPrivatePhoneNumber"
              type="text"
              [placeholder]="'EMPLOYEE_PLACEHOLDER_PHONE_NUMBER' |translate"
              formControlName="privateInformationsPrivatePhoneNumber"
              mask="00 00 00 00 00"
              checkFormFieldValidity
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" for="privateInformationsPrivateEmail" translate
          >EMPLOYEE_LBL_PRIVATE_MAIL</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="privateInformationsPrivateEmail"
              type="text"
              formControlName="privateInformationsPrivateEmail"
              [placeholder]="'EMPLOYEE_PLACEHOLDER_EMAIL' | translate"
              checkFormFieldValidity
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-4 col-form-label-sm" for="privateInformationsHomeAddress" translate>EMPLOYEE_LBL_HOME_ADDRESS</label>
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="privateInformationsHomeAddressId"
              type="text"
              formControlName="privateInformationsHomeAddressId"
              [hidden]="true"
            />
            <input
              class="form-control form-control-sm"
              id="privateInformationsHomeAddress"
              type="text"
              formControlName="privateInformationsHomeAddress"
              [placeholder]="'EMPLOYEE_PLACEHOLDER_PRIVATE_INFORMATIONS_ADDRESS' | translate"
              checkFormFieldValidity
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" for="privateInformationsHomeZipCode" translate></label>
          <div class="col">
            <div class="row">
              <div class="col-3">
                <input
                  class="form-control form-control-sm"
                  id="privateInformationsHomeZipCode"
                  type="text"
                  formControlName="privateInformationsHomeZipCode"
                  [placeholder]="'EMPLOYEE_PLACEHOLDER_PRIVATE_INFORMATIONS_ZIPCODE' | translate"
                  checkFormFieldValidity
                  mask="00000"
                  maxlength="5"
                />
              </div>
              <div class="col">
                <app-typeahead
                  [entity]="Entity.ZipCode"
                  [referenceEndpoint]="'ZipCodes/Cities/'"
                  [dropdownStyle]="DropdownStyle.DropDown"
                  [controlForm]="this.form.controls['privateInformationsFormGroup'].get('privateInformationsHomeCity')"
                  [limitToList]="true"
                  [referenceFilterParentName]="'Code'"
                  [referenceFilterParentValue]="this.form.controls['privateInformationsFormGroup'].get('privateInformationsHomeZipCode')?.value"
                  [valueMember]="'name'"
                  [displayMember]="'name'"
                  [dbNameOfId]="'City'"
                  [dbNameOfName]="'City'"
                  [referenceField]="'City'"
                  [showFirstElement]="true"
                ></app-typeahead>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-4 col-form-label-sm" for="privateInformationsHomeAddress" translate></label>
          <div class="col">
            <app-typeahead
              [entity]="Entity.Country"
              [routeEndpoint]="'Countries'"
              [dropdownStyle]="DropdownStyle.DropDown"
              [controlForm]="this.form.controls['privateInformationsFormGroup'].get('privateInformationsHomeCountryId')"
              [limitToList]="true"
              [domain]="'active=true'"
              [showFirstElement]="true"
            ></app-typeahead>

          </div>
        </div>

        <div class="form-group row">
          <label class="col-4 col-form-label-sm" for="privateInformationsIBAN"
                 translate>EMPLOYEE_LBL_BANK_ACCOUNT</label>
          <div class="col">
            <div class="form-group row">
              <div class="col">
                <input
                  type="text"
                  [hidden]="true"
                  formControlName="privateInformationsId"
                />
                <input
                  id="privateInformationsIBAN"
                  class="form-control form-control-sm"
                  type="text"
                  [placeholder]="'EMPLOYEE_PLACEHOLDER_PRIVATE_INFORMATIONS_IBAN' | translate"
                  formControlName="privateInformationsIBAN"
                  checkFormFieldValidity
                  ibanValidators
                  oninput="this.value = this.value.toUpperCase()"
                  [patterns]="customPatterns"
                  mask="SS00 CCCC CCCC CCCC CCCC CCCC CCCC CCCC CC" [validation]="false"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <input
                  id="privateInformationsBIC"
                  class="form-control form-control-sm"
                  type="text"
                  [placeholder]="'EMPLOYEE_PLACEHOLDER_PRIVATE_INFORMATIONS_BIC' | translate"
                  formControlName="privateInformationsBIC"
                  oninput="this.value = this.value.toUpperCase()"
                  checkFormFieldValidity
                  [minlength]="8"
                  [maxLength]="11"
                />
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="col">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" for="privateInformationsBirthDate"
                 translate>EMPLOYEE_LBL_DATE_OF_BIRTH</label>
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="privateInformationsBirthDate"
              type="date"
              formControlName="privateInformationsBirthDate"
              checkFormFieldValidity
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" for="privateInformationsPlaceOfBirth" translate>EMPLOYEE_LBL_PLACE_OF_BIRTH</label>
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="privateInformationsPlaceOfBirth"
              type="text"
              formControlName="privateInformationsPlaceOfBirth"
              checkFormFieldValidity
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate>EMPLOYEE_LBL_NATIONALITY</label>
          <div class="col">
            <app-typeahead
              [entity]="Entity.Country"
              [routeEndpoint]="'Countries/Nationalities'"
              [controlForm]="this.form.controls['privateInformationsFormGroup'].get('privateInformationsCountryIdNationality')"
              [limitToList]="true"
              [dropdownStyle]="DropdownStyle.DropDownList"
              [dbNameOfName]="'Nationality'"
              [dbNameOfId]="'Id'"
              [domain]="'Nationality!=null'"
            ></app-typeahead>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-4 col-form-label-sm" for="privateInformationsPassportNumber" translate>EMPLOYEE_LBL_REGISTRATION_NUMBER</label>
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="privateInformationsPassportNumber"
              type="text"
              formControlName="privateInformationsPassportNumber"
              [maxLength]="50"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-4 col-form-label-sm" for="privateInformationsSsnNumber" translate>EMPLOYEE_LBL_NATIONAL_NUMBER</label>
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="privateInformationsSsnNumber"
              type="text"
              formControlName="privateInformationsSsnNumber"
              [maxLength]="50"
            />
          </div>
        </div>

        <div class="form-group-title" translate>EMPLOYEE_LBL_STATUS</div>

        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >EMPLOYEE_LBL_GENDER</label>
            <div class="col">
              <app-typeahead
                [limitToList]="true"
                [dataType]="DataType.Enum"
                [referenceEndpoint]="'Views/enum/values'"
                [referenceField]="'Comparanoo.Core.Enums.Gender'"
                [dropdownStyle]="DropdownStyle.DropDownList"
                [controlForm]="
                          this.form.controls['privateInformationsFormGroup'].get(
                            'privateInformationsGender'
                          )
                        "
                [valueMember]="'value'"
                [displayMember]="'name'"
              ></app-typeahead>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >EMPLOYEE_LBL_MARITAL_STATUS</label>
          <div class="col">
            <app-typeahead
              [limitToList]="true"
              [dataType]="DataType.Enum"
              [referenceEndpoint]="'Views/enum/values'"
              [referenceField]="'Comparanoo.Core.Enums.MaritalStatus'"
              [dropdownStyle]="DropdownStyle.DropDownList"
              [controlForm]="
                          this.form.controls['privateInformationsFormGroup'].get(
                            'privateInformationsMaritalStatus'
                          )
                        "
              [valueMember]="'value'"
              [displayMember]="'name'"
       ></app-typeahead>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-4 col-form-label-sm" for="privateInformationsNumberOfChildren" translate
          >EMPLOYEE_LBL_NUMBER_OF_CHILDREN</label>
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="privateInformationsNumberOfChildren"
              type="number"
              formControlName="privateInformationsNumberOfChildren"
              checkFormFieldValidity
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
