import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PageResult, PaginationParameters } from '../../types';
import { LockoutModel, User, UserConnectionHistory, UserModel } from './type';
import { ErrorService } from '../../error';
import { catchError, map } from 'rxjs/operators';
import { MessageService } from '../../service/message/message.service';
import { CrudType } from '../../service/message/types';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService,
    private readonly messageService: MessageService,
  ) {}

  //think of a way to add start date, end date, lead type as a parameters to the get methods to get the correct list
  get = (): Observable<User[]> => {
    let parameters: string[];
    let url = this.formatUrl('');
    return this.httpClient.get<User[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  getById = (id: string): Observable<User> => {
    let parameters: string[];
    parameters = [id];
    let url = this.formatUrl('details', parameters);
    return this.httpClient.get<User>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  add = (userModel: UserModel): Observable<User> => {
    let parameters: string[] = [];
    let url = this.formatUrl('', parameters);

    // url += '/api/accounts';
    if (typeof parameters !== 'undefined') {
      parameters.forEach((parameters) => {
        url += '/';
        url += parameters;
      });
    }
    //Usefull for file upload
    let formData = new FormData();
    for (const property in userModel) {
      if (!!userModel[property]) {
        if (Array.isArray(userModel[property])) {
          for (let i = 0; i < userModel[property].length; i++) {
            formData.append(property + '[]', userModel[property][i]);
          }
        } else {
          formData.append(property, userModel[property]);
        }
      }
    }

    return this.httpClient
      .post<User>(url, formData, { observe: 'response' })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  update = (user: User): Observable<User> => {
    let parameters: string[];
    parameters = [user.id.toString()];
    let url = this.formatUrl('', parameters);

    //Usefull for file upload
    let formData = new FormData();
    for (const property in user) {
      if (!!user[property]) {
        if (Array.isArray(user[property])) {
          for (let i = 0; i < user[property].length; i++) {
            formData.append(property + '[]', user[property][i]);
          }
        } else {
          formData.append(property, user[property]);
        }
      }
    }

    return this.httpClient
      .post<User>(url, formData, { observe: 'response' })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  lockout = (lockoutModel: LockoutModel): Observable<boolean> => {
    let url = environment.comparanooapiurl;
    url += environment.features.users.lockout.url;
    return this.httpClient.post<boolean>(url, lockoutModel).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  getPaged = (
    paginationParam: PaginationParameters,
  ): Observable<PageResult<User>> => {
    let parameters: string[];
    parameters = [];
    let url = this.formatUrl('', parameters);

    let queryParams = new HttpParams();
    queryParams = queryParams.append('PageIndex', paginationParam.pageIndex);
    queryParams = queryParams.append('PageSize', paginationParam.pageSize);

    return this.httpClient
      .get<PageResult<User>>(url, { params: queryParams })
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  getCurrentUser = (): Observable<User> => {
    let parameters: string[] = [];
    let url = this.formatUrl('currentUser', parameters);
    return this.httpClient.get<User>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  getConnectionHistoriesById = (
    userId: string,
  ): Observable<UserConnectionHistory[]> => {
    let parameters: string[];
    parameters = [userId, 'connectionHistory'];
    let url = this.formatUrl('details', parameters);
    return this.httpClient.get<UserConnectionHistory[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  formatUrl = (urlType: string, parameters?: string[]): string => {
    var url = environment.comparanooapiurl;
    url += environment.features.users.url;

    switch (urlType) {
      case 'details':
        url += environment.features.users.details;
        break;
      case 'currentUser':
        url += environment.features.users.currentUser;
        break;
      default:
        break;
    }

    if (typeof parameters !== 'undefined') {
      parameters.forEach((parameters) => {
        url += '/';
        url += parameters;
      });
    }
    return url;
  };
}
