import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-application-fee-rule-edit',
  templateUrl: './application-fee-rule-edit.component.html',
  styleUrls: ['./application-fee-rule-edit.component.scss'],
})
export class ApplicationFeeRuleEditComponent implements OnInit {
  applicationFeeRuleId: string;

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit(): void {
    this.applicationFeeRuleId = this.route.snapshot.params['id'];
  }
}
