<!---->
<div class="card data-card w-100 border-0 p-0 p-1" [class]="this.isHorizontalUse | horizontalClass">
  <div class="card-body p-0" (click)="onClickOpenModalEdit();" role="button">
    <div class="card-left border-end-0 me-3">
      <i *ngIf="document.fileExtension" class="icon icon-file-{{document.fileExtension}} icon-img"></i>
    </div>
    <div class="card-content" >
<!--      <h3 class="card-title" *ngIf="document.name">{{ document.name }}</h3>-->
      <span class="card-title font-weight-bolder mb-1 text-break">{{ document.name }}</span>
      <span class="card-subtitle text-muted mb-0" *ngIf="document.documentTypeName">
        {{ document.documentTypeName }}
      </span>
      <span class="card-subtitle text-muted mb-0" *ngIf="document.userContactName">
        {{ document.userContactName }}
      </span>
      <span class="card-subtitle text-muted mb-0">
         {{ document.size | fileSize }} /
         {{ {localeDate: document.localeCreationDate, utcDate: document.creationDate} | friendlyDate }}
      </span>
    </div>
    <div class="card-right ps-2 pe-2">
      <div>
        <button *ngIf="document.fileExtension"
                class="btn btn-outline-primary"
                type="button"
                [title]="'DOCUMENT_PLACEHOLDER_DOWNLOAD' | translate"
                (click)="this.downloadClicked = true; onClickDownload(document)"
        >
          <i class="mdi mdi-cloud-download" *ngIf="!downloadFinished"></i>
          <i class="mdi mdi-cloud-check" *ngIf="downloadFinished"></i>
        </button>
      </div>
    </div>
  </div>
</div>
<app-document-add #newDocument [documentId]="document.id" />
