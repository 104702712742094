<app-loader
  [apiCall]="this.apiCall"
  [loader]="true"
  [loaderId]="'cashJournalModal'"
  style="min-height: 150px"
>

<form
  [formGroup]="cashJournalForm"
  (ngSubmit)="onSubmit()"
  [hasUnsavedData]="cashJournalForm"
>
  <button type="submit" hidden="hidden"></button>

  <div class="row mb-2">
    <div class="col-12 mr-auto"></div>
  </div>
  <div class="row">
    <div class="col-12 col-md">

      @if (this.type === 'Out') {
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >CASH_JOURNAL_LBL_FROM</label
          >
          <div class="col">
            <app-typeahead
              [routeEndpoint]="'Accounts'"
              [controlForm]="this.cashJournalForm.get('fromAccountId')"
              [domain]="'contactId='+this.contactId"
              [orderBy]="'name asc'"
              [limitToList]="true"
              [readonly]="true"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >CASH_JOURNAL_LBL_TO</label
          >
          <div class="col">
            <app-typeahead
              [entity]="Entity.Account"
              [routeEndpoint]="'Accounts'"
              [controlForm]="this.cashJournalForm.get('toAccountId')"
              [domain]="'canHandleCash=1,active=1,contactId'+this.excludeSignHack+this.contactId"
              [orderBy]="'name asc'"
              [limitToList]="true"
              [readonly]="this.isInitialBalance"
              [allowCustomActions]="true"
              [customAddButton]="true"
              [customAddComponent]="'AccountFormContentComponent'"
              [customDetailsButton]="false"
            />
          </div>
        </div>
      } @else if (this.type === 'In') {
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >CASH_JOURNAL_LBL_FROM</label
          >
          <div class="col">
            <app-typeahead
              [entity]="Entity.Account"
              [routeEndpoint]="'Accounts'"
              [controlForm]="this.cashJournalForm.get('fromAccountId')"
              [domain]="'canHandleCash=1,active=1,contactId'+this.excludeSignHack+this.contactId"
              [orderBy]="'name asc'"
              [limitToList]="true"
              [readonly]="this.isInitialBalance"
              [allowCustomActions]="true"
              [customAddButton]="true"
              [customAddComponent]="'AccountFormContentComponent'"
              [customDetailsButton]="false"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >CASH_JOURNAL_LBL_TO</label
          >
          <div class="col">
            <app-typeahead
              [routeEndpoint]="'Accounts'"
              [controlForm]="this.cashJournalForm.get('toAccountId')"
              [domain]="'contactId='+this.contactId"
              [orderBy]="'name asc'"
              [limitToList]="true"
              [readonly]="true"
            />
          </div>
        </div>
      }

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
        >CASH_JOURNAL_LBL_AMOUNT</label
        >
        <div class="col">
          <input
            class="form-control form-control-sm"
            type="text"
            formControlName="amount"
            checkFormFieldValidity
            [placeholder]="'€'"
            mask="separator.2"
            [suffix]="'PAYMENT_PLACEHOLDER_MONETARY' | translate"
            [showMaskTyped]="false"
            [decimalMarker]="','"
            [allowNegativeNumbers]="true"
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
        >CASH_JOURNAL_LBL_MODE</label
        >
        <div class="col">
          <app-typeahead
            [limitToList]="true"
            [dataType]="DataType.Enum"
            [referenceEndpoint]="'Views/enum/values'"
            [referenceField]="'Comparanoo.Core.Enums.PaymentMode'"
            [valueMember]="'value'"
            [displayMember]="'name'"
            [dropdownStyle]="DropdownStyle.DropDownList"
            [controlForm]="this.cashJournalForm.get('paymentMode')"
            [readonly]="true"
          ></app-typeahead>
        </div>
      </div>
    </div>
    <div class="col">


      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
        >CASH_JOURNAL_LBL_DATE</label
        >
        <div class="col">
          <input
            class="form-control form-control-sm"
            type="date"
            formControlName="date"
            checkFormFieldValidity
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
        >CASH_JOURNAL_LBL_PROOF</label
        >
        <div class="col">
          <app-generic-file-input (fileEmitter)="onFileEmitterProof($event)"/>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
        >CASH_JOURNAL_LBL_REFERENCE</label
        >
        <div class="col">
          <input
            class="form-control form-control-sm"
            type="text"
            [placeholder]="''"
            formControlName="reference"
            checkFormFieldValidity
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
        >CASH_JOURNAL_LBL_COMMENT</label
        >
        <div class="col">
          <input
            class="form-control form-control-sm"
            type="text"
            [placeholder]="''"
            formControlName="description"
            checkFormFieldValidity
          />
        </div>
      </div>
    </div>
  </div>
</form>
@if (this.accountMovePaymentCreateInstance$  | async) {
  <div></div>
}
</app-loader>
