import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { documentFeatureKey, DocumentModel } from './types';
import { IDocumentState } from './document.reducer';
import { InputByEntity } from '../comment/types';

const documentFeatureState =
  createFeatureSelector<IDocumentState>(documentFeatureKey);

export const documentSelectors = {
  getById: createApiCallSelectors<number, IDocumentState>(
    documentFeatureState,
    'getById',
  ),
  getByEntityId: createApiCallSelectors<InputByEntity, IDocumentState>(
    documentFeatureState,
    'getByEntityId',
  ),
  getContactDocumentByEntityId: createApiCallSelectors<
    InputByEntity,
    IDocumentState
  >(documentFeatureState, 'getContactDocumentByEntityId'),
  getContractDocumentByEntityId: createApiCallSelectors<
    InputByEntity,
    IDocumentState
  >(documentFeatureState, 'getContractDocumentByEntityId'),
  getFile: createApiCallSelectors<number, IDocumentState>(
    documentFeatureState,
    'getFile',
  ),
  add: createApiCallSelectors<DocumentModel, IDocumentState>(
    documentFeatureState,
    'add',
  ),
  update: createApiCallSelectors<DocumentModel, IDocumentState>(
    documentFeatureState,
    'update',
  ),
  delete: createApiCallSelectors<boolean, IDocumentState>(
    documentFeatureState,
    'delete',
  ),
  deleteFile: createApiCallSelectors<boolean, IDocumentState>(
    documentFeatureState,
    'deleteFile',
  ),
  copyToDocumentRequest: createApiCallSelectors<DocumentModel, IDocumentState>(
    documentFeatureState,
    'copyToDocumentRequest',
  ),
};
