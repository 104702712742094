<div class="form-group-title my-1" translate>
  APPLICATION_FEE_RULE_FIELDSET_APPLICATION_FEES
</div>
<div class="row mt-2">
  <div class="col-12">
    <table class="table table-striped border-bottom">
      <thead>
      <tr>
        <th>
          <a translate>APPLICATION_FEE_TABLE_TYPE</a>
        </th>
        <th>
          <a translate>APPLICATION_FEE_TABLE_MINIMUM_VALUE</a>
        </th>
        <th>
          <a translate>APPLICATION_FEE_TABLE_MAXIMUM_VALUE</a>
        </th>
        <th>
          <a translate>APPLICATION_FEE_TABLE_DEFAULT_VALUE</a>
        </th>
        <th>
          <a translate>APPLICATION_FEE_TABLE_START_DATE</a>
        </th>
        <th>
          <a translate>APPLICATION_FEE_TABLE_END_DATE</a>
        </th>
      </tr>
      </thead>

      <tbody>
      <ng-container *ngIf="this.applicationFeeRuleModels$ | async as applicationFeeRuleModels">
        <ng-container
          *ngFor="let applicationFeeRuleModel of applicationFeeRuleModels; trackBy: trackByApplicationFeeRuleId">
          <tr (click)="onClickLinkTo(applicationFeeRuleModel.id, 'Finance/ApplicationFeeRules/Details/')"
              class="row-action">
            <td>{{ applicationFeeRuleModel.applicationFeeTypeLoc }}</td>
            <td>{{ applicationFeeRuleModel.minimumValue | showSignValueType: applicationFeeRuleModel.amountType }}</td>
            <td>{{ applicationFeeRuleModel.maximumValue | showSignValueType: applicationFeeRuleModel.amountType }}</td>
            <td>{{ applicationFeeRuleModel.defaultValue | showSignValueType: applicationFeeRuleModel.amountType }}</td>
            <td>
              {{
                !!applicationFeeRuleModel.startDate
                  ? (applicationFeeRuleModel.startDate | date : 'dd/MM/yyyy')
                  : '-'
              }}
            </td>
            <td>
              {{
                !!applicationFeeRuleModel.endDate
                  ? (applicationFeeRuleModel.endDate | date : 'dd/MM/yyyy')
                  : '-'
              }}
            </td>
          </tr>
        </ng-container>
      </ng-container>
      </tbody>
    </table>


  </div>
  <div class="col"></div>
</div>
