import { Component, Input, OnInit } from '@angular/core';
import { DocumentModel } from '../../../store/document/types';
import { Observable, withLatestFrom } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UploadFileService } from '../../../store/upload-file/upload-file.service.facade';
import { DocumentService } from '../../../store/document/document.service';

@UntilDestroy()
@Component({
  selector: 'app-document-request',
  templateUrl: './document-request.component.html',
  styleUrls: ['./document-request.component.scss'],
})
export class DocumentRequestComponent implements OnInit {
  @Input() document!: DocumentModel;
  uploadCompleted$: Observable<boolean>;

  constructor(
    private readonly uploadFileService: UploadFileService,
    private readonly documentService: DocumentService,
  ) {}

  ngOnInit(): void {
    this.uploadCompleted$ = this.uploadFileService.completed$.pipe(
      withLatestFrom(this.uploadFileService.currentFileId$),
      filter(([_isInProgress, id]) => this.document.id == id),
      map(([isInProgress, _id]) => isInProgress),
      untilDestroyed(this),
    );
  }
}
