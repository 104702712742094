import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../../../shared/shared.module';
import { ContactCreateComponent } from './contact-create/contact-create.component';
import { ContactDetailsContentComponent } from './contact-details/contact-details-content/contact-details-content.component';
import { ContactDetailsMarketingComponent } from './contact-details/contact-details-tab/contact-details-marketing/contact-details-marketing.component';
import { ContactDetailsNotesComponent } from './contact-details/contact-details-tab/contact-details-notes/contact-details-notes.component';
import { ContactDetailsComponent } from './contact-details/contact-details.component';
import { ContactTabsDetailsComponent } from './contact-details/contact-tabs-details/contact-tabs-details.component';
import { ContactEditComponent } from './contact-edit/contact-edit.component';
import { ContactFormMarketingComponent } from './contact-form/contact-form-tabs/contact-form-marketing/contact-form-marketing.component';
import { ContactFormNotesComponent } from './contact-form/contact-form-tabs/contact-form-notes/contact-form-notes.component';
import { ContactTabsAddFormComponent } from './contact-form/contact-tabs-add-form/contact-tabs-add-form.component';
import { ContactTabsEditFormComponent } from './contact-form/contact-tabs-edit-form/contact-tabs-edit-form.component';
import { ContactRoutingModule } from './contact-routing.module';
import { ContactDetailsChildrenComponent } from './shared/contact-details-children/contact-details-children.component';
import { ContactFormContentComponent } from './shared/contact-form-content/contact-form-content.component';
import { ContactDetailsPaymentComponent } from './shared/contact-details-payment/contact-details-payment.component';
import { ContactDetailsContractsComponent } from './contact-details/contact-details-tab/contact-details-contracts/contact-details-contracts.component';
import { ContactDetailsLeadsComponent } from './contact-details/contact-details-tab/contact-details-leads/contact-details-leads.component';
import { ContactDetailsAccountMovesComponent } from './contact-details/contact-details-tab/contact-details-account-moves/contact-details-account-moves.component';
import { ContactDetailsAccountMovesTableComponent } from './contact-details/contact-details-tab/contact-details-account-moves-table/contact-details-account-moves-table.component';

@NgModule({
  declarations: [
    ContactDetailsComponent,
    ContactDetailsContentComponent,
    ContactCreateComponent,
    ContactFormContentComponent,
    ContactTabsEditFormComponent,
    ContactTabsAddFormComponent,
    ContactFormNotesComponent,
    ContactFormMarketingComponent,
    ContactTabsDetailsComponent,
    ContactDetailsMarketingComponent,
    ContactDetailsNotesComponent,
    ContactEditComponent,
    ContactDetailsChildrenComponent,
    ContactDetailsPaymentComponent,
    ContactDetailsContractsComponent,
    ContactDetailsLeadsComponent,
    ContactDetailsAccountMovesComponent,
    ContactDetailsAccountMovesTableComponent,
  ],
  imports: [CommonModule, ContactRoutingModule, SharedModule],
  exports: [],
})
export class ContactModule {}
