import { AccountMoveLineModel } from '../../accounting/types';
import { CommissionPlanType } from '../commission-plan-rule/types';

export const commissionFeatureKey = 'commission';

export class CommissionModel {
  id: number;
  userId: number;
  contractId: number;
  amount: number;
  commissionPlanRuleId: number;
}

export type CommissionPreviewModel = {
  id: number;
  contractContactName: string;
  name: string;
  accountMoveType: string;
  storesubcriptionDate: string;
  accountMoveStatus: string;
  companyCommissionAmount: number;
  salesPersonCommissionRate: number;
  shareCommissionRate: number;
  salesPersonCommissionAmount: number;
  salesPersonName: string;
};

export type CompanyCommissionModel = {
  insuranceCompanyId: number;
  companyName: string;
  healthAmount: number;
  carAmount: number;
  houseAmount: number;
  totalAmount: number;
};

export type SalesPersonCommissionModel = {
  id: number;
  accountMoveTypeId: number;
  contractName: string;
  debitAccountId: number;
  debitAccountName: string;
  creditAccountContactId: number;
  creditAccountContactName: string;
  creationDate: Date;
  accountMoveLines: AccountMoveLineModel[];
};
