import { SalesTeamModel } from '../sales-team/types';

export const userFeatureKey = 'users';

export class RegisterModel {
  constructor(email: string, password: string, username: string) {
    this.email = email;
    this.password = password;
    this.username = username;
  }
  email: string;
  password: string;
  username: string;
}

export type UserModel = {
  firstName: string;
  lastName: string;
  email: string;
  roles: string[];
  contactPictureId?: number;
  contactPictureFile?: File;
  salesPersonCommissionPlanId?: number;
  sharedCommissionPlanId?: number;
  salesTeamIds?: number[];
  mainSalesTeamId?: number;
};

export type LockoutModel = {
  email: string;
  activate: boolean;
};

// export class User {
//   id: string;
//   email: string;
//   userName: string;
// }

export class User {
  id: string;
  contactId: number;
  contactName: string;
  contactFirstName: string;
  contactLastName: string;
  contactPicturePath: string;
  contactPictureId?: number;
  contactPictureFile?: File;
  aspNetUserEmail: string;
  aspNetUserUserName: string;
  aspNetUserAspNetRoles: Roles[];
  rolesLoc: string[];
  roles: string[];
  rolesModel: RoleModel[];
  active: boolean;
  aspNetUserEmailConfirmed: boolean;
  firstName: string;
  lastName: string;
  email: string;
  salesPersonCommissionPlanId?: number;
  salesPersonCommissionPlanName?: string;
  sharedCommissionPlanId?: number;
  sharedCommissionPlanName?: string;
  userSalesTeams: SalesTeamModel[];
  salesTeamIds?: number[];
  mainSalesTeamId?: number;
  mainSalesTeamName?: string;
}

export type Roles = {
  name: string;
};

export type RoleModel = {
  role: string;
  roleLoc: string;
};

export class Avatar {
  constructor(contactName: string, url: string, bgColor: string) {
    this.contactName = contactName;
    this.url = url;
    this.bgColor = bgColor;
  }
  contactName: string;
  url: string;
  bgColor: string = 'cccccc';
}

export type UserConnectionHistory = {
  id: number;
  userId: string;
  connectionDate: Date;
};
