import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '../../shared/guard/authentication-guard';
import { HasUnsavedChangesGuard } from '../../shared/guard/has-form-changed.guard';
import { Entity, HeaderMenuType } from '../../shared/store/view/types';
import { ModuleId } from '../../shared/store/navigation/types';
import { InsuranceReportingComponent } from './insurance-reporting/insurance-reporting.component';

const routes: Routes = [
  {
    path: 'Insurance',
    redirectTo: 'Insurance/Contracts',
    pathMatch: 'full',
  },
  {
    path: 'Insurance/Overview',
    component: InsuranceReportingComponent,
    canActivate: [AuthenticationGuard],
    canDeactivate: [HasUnsavedChangesGuard],
    data: {
      headerMenu: HeaderMenuType.Insurance,
      moduleId: ModuleId.insurance,
      entity: Entity.InsuranceOverview,
      breadcrumb: { label: ' ', alias: 'insuranceReportingList' },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InsuranceRoutingModule {}
