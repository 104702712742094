import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { AppState } from '../types';
import { cashControlActions } from './cash-control.actions';
import { cashControlSelectors } from './cash-control.selector';
import { CashControlModel } from './types';

@Injectable()
export class CashControlService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<CashControlModel, string> = this.genericApiCall(
    cashControlActions.getById,
    cashControlSelectors.getById,
    true,
  );
  getBySalesTeamAccountId: GenericApiCall<CashControlModel[], string> =
    this.genericApiCall(
      cashControlActions.getBySalesTeamAccountId,
      cashControlSelectors.getBySalesTeamAccountId,
      true,
    );
  add: GenericApiCall<CashControlModel, CashControlModel> = this.genericApiCall(
    cashControlActions.add,
    cashControlSelectors.add,
    true,
  );
  update: GenericApiCall<CashControlModel, CashControlModel> =
    this.genericApiCall(
      cashControlActions.update,
      cashControlSelectors.update,
      true,
    );
  delete: GenericApiCall<boolean, string> = this.genericApiCall(
    cashControlActions.delete,
    cashControlSelectors.delete,
    true,
  );
  createInstance: GenericApiCall<CashControlModel, string> =
    this.genericApiCall(
      cashControlActions.createInstance,
      cashControlSelectors.createInstance,
      true,
    );
}
