import { AmountType } from '../commissions/commission-plan-rule/types';

export const applicationFeeRuleFeatureKey = 'applicationFeeRule';

export class ApplicationFeeRuleModel {
  id: number;
  name: string;
  insuranceCompanyId?: number;
  productCategoryId?: number;
  productLineId?: number;
  productLineName?: string;
  productId?: number;
  minimumValue: number;
  maximumValue: number;
  defaultValue: number;
  startDate: Date;
  endDate?: Date;
  amountType: AmountType;
  applicationFeeType: ApplicationFeeType;
  applicationFeeTypeLoc?: string;
}

export type ApplicationFeeType = 'Customer' | 'Vendor';
