import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  BaseServiceFacade,
  GenericApiCall,
} from '../../../../common/store/types';
import { AppState } from '../../types';
import { commissionPlanRuleActions } from './commission-plan-rule.actions';
import { commissionPlanRuleSelectors } from './commission-plan-rule.selector';
import { CommissionPlanRuleModel, CommissionType } from './types';

@Injectable()
export class CommissionPlanRuleService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  getById: GenericApiCall<CommissionPlanRuleModel, string> =
    this.genericApiCall(
      commissionPlanRuleActions.getById,
      commissionPlanRuleSelectors.getById,
      true,
    );
  getByProductLineId: GenericApiCall<CommissionPlanRuleModel[], string> =
    this.genericApiCall(
      commissionPlanRuleActions.getByProductLineId,
      commissionPlanRuleSelectors.getByProductLineId,
      true,
    );
  getSharedBySalesPersonIdAndCommissionType: GenericApiCall<
    CommissionPlanRuleModel,
    string
  > = this.genericApiCall(
    commissionPlanRuleActions.getSharedBySalesPersonIdAndCommissionType,
    commissionPlanRuleSelectors.getSharedBySalesPersonIdAndCommissionType,
    true,
  );
  createInstance: GenericApiCall<CommissionPlanRuleModel, CommissionType> =
    this.genericApiCall(
      commissionPlanRuleActions.createInstance,
      commissionPlanRuleSelectors.createInstance,
      true,
    );
  add: GenericApiCall<CommissionPlanRuleModel, CommissionPlanRuleModel> =
    this.genericApiCall(
      commissionPlanRuleActions.add,
      commissionPlanRuleSelectors.add,
      true,
    );
  update: GenericApiCall<CommissionPlanRuleModel, CommissionPlanRuleModel> =
    this.genericApiCall(
      commissionPlanRuleActions.update,
      commissionPlanRuleSelectors.update,
      true,
    );
  delete: GenericApiCall<boolean, string> = this.genericApiCall(
    commissionPlanRuleActions.delete,
    commissionPlanRuleSelectors.delete,
    true,
  );
}
