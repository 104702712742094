import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TabsContentGenericDetails } from '../../../../shared/models/tabs-content-generic-details';
import { ApplicationFeeRuleDetailsApplicationFeeComponent } from '../../../../shared/components/application-fee-rule-details-application-fee/application-fee-rule-details-application-fee.component';

@Component({
  selector: 'app-application-fee-rule-tabs-details',
  templateUrl: './application-fee-rule-tabs-details.component.html',
  styleUrls: ['./application-fee-rule-tabs-details.component.scss'],
})
export class ApplicationFeeRuleTabsDetailsComponent
  implements OnInit, OnChanges, OnDestroy
{
  active = 1;
  currentActive: number = 1;

  tabsContent$: BehaviorSubject<TabsContentGenericDetails[]> =
    new BehaviorSubject<TabsContentGenericDetails[]>([]);
  tabsInit: TabsContentGenericDetails[] = [
    {
      title: 'APPLICATION_FEE_RULE_TABS_APPLICATION_FEES',
      index: 1,
      name: 'applicationFeeRules',
      component: ApplicationFeeRuleDetailsApplicationFeeComponent,
    },
  ];

  constructor() {}

  ngOnInit(): void {
    this.tabsContent$.next(this.tabsInit);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.currentActive) {
      this.active = this.currentActive;
    } else {
      this.active = 1;
    }
    this.tabsContent$.next(this.tabsInit);
  }

  trackByTabsContent(index: any, item: TabsContentGenericDetails) {
    return item.index;
  }

  onActiveIdChangeSaveCurrent(active: number) {
    this.currentActive = active;
  }

  ngOnDestroy(): void {
    this.currentActive = 0;
  }
}
