import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onApiCall } from '../../../../common/store/generic.reducer';
import { GenericState, IState } from '../../../../common/store/types';
import { CommissionPlanRuleModel } from './types';
import { commissionPlanRuleActions } from './commission-plan-rule.actions';

export interface ICommissionPlanRuleState
  extends IState<CommissionPlanRuleModel> {
  getById: GenericState<CommissionPlanRuleModel>;
  getByProductLineId: GenericState<CommissionPlanRuleModel[]>;
  getSharedBySalesPersonIdAndCommissionType: GenericState<CommissionPlanRuleModel>;
  createInstance: GenericState<CommissionPlanRuleModel>;
  add: GenericState<CommissionPlanRuleModel>;
  update: GenericState<CommissionPlanRuleModel>;
  delete: GenericState<boolean>;
}

export const commissionPlanRuleInitialState: ICommissionPlanRuleState = {
  getById: new GenericState<CommissionPlanRuleModel>(),
  getByProductLineId: new GenericState<CommissionPlanRuleModel[]>(),
  getSharedBySalesPersonIdAndCommissionType:
    new GenericState<CommissionPlanRuleModel>(),
  createInstance: new GenericState<CommissionPlanRuleModel>(),
  add: new GenericState<CommissionPlanRuleModel>(),
  update: new GenericState<CommissionPlanRuleModel>(),
  delete: new GenericState<boolean>(),
};

const commissionPlanRuleReducers = [
  ...onApiCall<CommissionPlanRuleModel>(
    commissionPlanRuleActions.getById,
    'getById',
  ),
  ...onApiCall<CommissionPlanRuleModel[]>(
    commissionPlanRuleActions.getByProductLineId,
    'getByProductLineId',
  ),
  ...onApiCall<CommissionPlanRuleModel>(
    commissionPlanRuleActions.getSharedBySalesPersonIdAndCommissionType,
    'getSharedBySalesPersonIdAndCommissionType',
  ),
  ...onApiCall<CommissionPlanRuleModel>(
    commissionPlanRuleActions.createInstance,
    'createInstance',
  ),
  ...onApiCall<CommissionPlanRuleModel>(commissionPlanRuleActions.add, 'add'),
  ...onApiCall<CommissionPlanRuleModel>(
    commissionPlanRuleActions.update,
    'update',
  ),
  ...onApiCall<boolean>(commissionPlanRuleActions.delete, 'delete'),
];

export const commissionPlanRuleReducer = createReducer(
  commissionPlanRuleInitialState,
  ...commissionPlanRuleReducers,
) as ActionReducer<ICommissionPlanRuleState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return commissionPlanRuleReducer(state, action);
}
