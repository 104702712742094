import { CommonModule } from '@angular/common';
import { ConfigService } from './core.config.service';
import { CoreService } from './core.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonLoaderModule } from '../common/loader';
import { CommonTranslateModule } from '../common/translate/translate.module';
import { AuthConfigModule } from '../common/authentification/auth-config.module';
import { NgBootstrapModule } from '../common/ngbootstrap/ngbootstrap.module';
import { GridModule } from '../common/ag-grid/ag-grid.module';
import { HtmlEditorModule } from '../common/html-editor/html-editor.module';
import { ChartJsModule } from '../common/chartsjs/chartjs.module';
import { MultiselectDropdownModule } from '../common/multiselect-dropdown/multiselect-dropdown.module';
import { XngBreadcrumbModule } from '../common/xng-breadcrumb/xng-breadcrumb.module';
import { TypeaheadModule } from '../common/ng-select/ng-select.module';
import { MaskModule } from '../common/ngx-mask/ngx-mask.module';
import { FilesaveModule } from '../common/ngx-filesave/ngx-filesave.module';

@NgModule({ exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CommonLoaderModule,
        CommonTranslateModule,
        AuthConfigModule,
        NgBootstrapModule,
        GridModule,
        HtmlEditorModule,
        ChartJsModule,
        MultiselectDropdownModule,
        XngBreadcrumbModule,
        TypeaheadModule,
        MaskModule,
        FilesaveModule,
    ], imports: [CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CommonLoaderModule,
        CommonTranslateModule.forRoot({ locales: ['fr', 'en'] }),
        AuthConfigModule,
        NgBootstrapModule,
        GridModule,
        HtmlEditorModule,
        ChartJsModule,
        MultiselectDropdownModule,
        XngBreadcrumbModule,
        TypeaheadModule,
        MaskModule,
        FilesaveModule], providers: [CoreService, ConfigService, provideHttpClient(withInterceptorsFromDi())] })
export class CoreModule {}
