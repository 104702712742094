import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DocumentService } from '../../../store/document/document.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DataType,
  DropdownStyle,
  SourceEnum,
} from '../../../store/typeahead/types';
import { Entity } from '../../../store/view/types';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { filter, Observable } from 'rxjs';
import { DocumentModel } from '../../../store/document/types';
import { FormService } from '../../../store/form/form.service';
import { take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SubHeaderService } from '../../../store/subheader/subheader.service';
import { ModalService } from '../../../service/modal.service';

@UntilDestroy()
@Component({
  selector: 'app-document-form-content',
  templateUrl: './document-form-content.component.html',
  styleUrls: ['./document-form-content.component.scss'],
})
export class DocumentFormContentComponent implements OnInit, OnDestroy {
  @Input() modalId: number;
  @Input() modalRandomIdentifier: number;
  @Input() source: SourceEnum = SourceEnum.Component;

  documentModel$: Observable<DocumentModel>;

  documentForm: FormGroup;
  documentId: number;

  protected readonly DataType = DataType;
  protected readonly DropdownStyle = DropdownStyle;
  protected readonly Entity = Entity;

  constructor(
    private readonly documentService: DocumentService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly formService: FormService,
    private readonly subHeaderService: SubHeaderService,
    private readonly dynamicService: ModalService,
  ) {
    this.documentModel$ = this.documentService.getById.value$;
  }

  ngOnInit(): void {
    //Déclaration du formulaire
    this.documentForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      documentTypeId: [undefined],
    });

    //Récupération de l'id via la route
    this.documentId = this.route.snapshot.params['id'];
    if (this.modalId) this.documentId = this.modalId;

    //On appelle les API
    this.documentService.getById.call(this.documentId.toString());

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'documentUpdate') this.onSubmit();
        this.subHeaderService.setTriggerAction('');
      });

    this.documentModel$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.documentForm.patchValue({
          name: x.name,
          documentTypeId: x.documentTypeId,
        });
      });
  }

  onSubmit() {
    if (this.documentForm.invalid) {
      this.documentForm.markAllAsTouched();
      this.formService.setEntityErrors('DOCUMENT');
      this.formService.countErrors(this.documentForm, true);
      return;
    }

    const documentModel: DocumentModel = {
      id: !!this.documentId ? this.documentId : 0,
      name: this.documentForm.value.name!,
      documentTypeId: this.documentForm.value.documentTypeId!,

      //Others
      description: '',
      fileExtension: '',
      contentType: '',
      userContactName: '',
      contactName: '',
      creationDate: new Date(),
      localeCreationDate: new Date(),
      downloadFinished: false,
      documentTypeName: '',
      entityId: 0,
      entityType: '',
      actions: [],
    };

    const result$ = this.documentService.update.call(documentModel);

    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result) => {
        this.formService.clear();
        switch (this.source) {
          case SourceEnum.Component:
            this.router.navigate(['/Documents/Documents']);
            break;
          case SourceEnum.Modal:
            this.dynamicService.outputFromDynamicComponent(
              this.modalRandomIdentifier,
              result.id.toString(),
            );
            break;
          default:
            this.router.navigate(['/Documents/Documents']);
            break;
        }
      });
  }

  ngOnDestroy(): void {
    this.documentService.update.reset();
    this.documentService.getById.reset();
    this.formService.clear();
  }
}
