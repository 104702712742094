import { Component, Input, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable, take } from 'rxjs';
import { filterTrue } from 'src/app/shared/pipe/rxjs/operators';
import { ModalService } from 'src/app/shared/service/modal.service';
import { AccountingService } from 'src/app/shared/store/accounting/accounting.service';
import {
  AccountMoveLineModel,
  AccountMoveModel,
  IssuingAccountMovePayingAccountMoveModel,
} from 'src/app/shared/store/accounting/types';
import { FormService } from 'src/app/shared/store/form/form.service';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { SourceEnum } from 'src/app/shared/store/typeahead/types';

@UntilDestroy()
@Component({
  selector: 'app-account-move-assign-repayment-content',
  templateUrl: './account-move-assign-repayment-content.component.html',
  styleUrls: ['./account-move-assign-repayment-content.component.scss'],
})
export class AccountMoveAssignRepaymentContentComponent implements OnInit {
  @Input() modalRandomIdentifier: number;
  @Input() source: SourceEnum = SourceEnum.Component;
  @Input() contractId: number;
  @Input() paymentId: number;
  @Input() invoiceId: number;

  accountMoves$: Observable<AccountMoveModel[]>;
  assignForm: FormGroup;

  constructor(
    private readonly accountingService: AccountingService,
    private readonly subHeaderService: SubHeaderService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder,
    private readonly dynamicService: ModalService,
  ) {}

  ngOnInit(): void {
    this.accountMoves$ = this.accountingService.getUnbindedRepayment.value$;

    this.assignForm = this.formBuilder.group({
      clientAccountMoves: this.formBuilder.array([]),
      repayments: this.formBuilder.array([]),
    });

    this.accountingService.getUnbindedRepayment.call(
      this.contractId.toString(),
    );

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'accountMoveRepaymentAssignCreate') this.onSubmit();
        this.subHeaderService.setTriggerAction('');
      });

    this.accountMoves$
      .pipe(filterTrue(), take(1))
      .subscribe((accountMoves: AccountMoveModel[]) => {
        accountMoves.forEach((accountMove) => {
          if (accountMove.accountMoveLines) {
            accountMove.accountMoveLines.forEach((accountMoveLine) => {
              const accountMoveForm = this.formBuilder.group({
                id: [accountMove.id],
                name: [accountMoveLine.name],
                amount: [accountMoveLine.unitPrice],
                checked: [false, [Validators.required]],
              });
              if (accountMove.moveType == 'CustomerInvoice') {
                this.clientAccountMoves.push(accountMoveForm);
                this.repayments.push(this.formBuilder.group({}));
              } else {
                this.repayments.push(accountMoveForm);
                this.clientAccountMoves.push(this.formBuilder.group({}));
              }
            });
          } else {
            this.repayments.push(this.formBuilder.group({}));
            this.clientAccountMoves.push(this.formBuilder.group({}));
          }
        });
        // debugger;
        // if (this.clientAccountMoves.controls && this.paymentId) {
        //   this.addClientAccountMove(this.paymentId);
        // }
        // if (this.repayments.controls && this.invoiceId) {
        //   this.addRepayment(this.invoiceId);
        // }
      });
  }

  onSubmit() {
    if (this.assignForm.invalid) {
      this.assignForm.markAllAsTouched();
      this.formService.setEntityErrors('ASSIGN');
      this.formService.countErrors(this.assignForm, true);
      return;
    }
    // if (this.paymentAmount < this.invoiceAmount) return;
    let clientAccountMoveControls = this.clientAccountMoves
      .controls as FormControl[];
    const payments: number[] = clientAccountMoveControls
      .filter((x: FormControl) => x.value.checked == true)
      .map((x: FormControl) => x.value.id);
    let invoiceControls = this.repayments.controls as FormControl[];
    const repayments: number[] = invoiceControls
      .filter((x: FormControl) => x.value.checked == true)
      .map((x: FormControl) => x.value.id);
    const issuingAccountMovePayingAccountMoveModel: IssuingAccountMovePayingAccountMoveModel =
      {
        issuingAccountMoves: payments,
        payingAccountMoves: repayments,
        bindType: 'Repayment',
      };
    let result$ = this.accountingService.bind.call(
      issuingAccountMovePayingAccountMoveModel,
    );
    result$
      .pipe(
        filter((x) => x !== undefined && x !== null),
        take(1),
      )
      .subscribe((result) => {
        this.formService.clear();
        switch (this.source) {
          case SourceEnum.Component:
            //   this.userService.getCurrentUser.call();
            // this.router.navigate(['/Contacts/Contacts/Details', result.id]);
            break;
          case SourceEnum.Modal:
            this.dynamicService.outputFromDynamicComponent(
              this.modalRandomIdentifier,
              result ? '1' : '-1',
            ); //1 to refresh the data, -1 to cancel
            break;
          default:
            // this.router.navigate(['/Contacts/Contacts/Details', result.id]);
            break;
        }
      });
  }

  get clientAccountMoves() {
    return this.assignForm.controls['clientAccountMoves'] as FormArray;
  }

  get repayments() {
    return this.assignForm.controls['repayments'] as FormArray;
  }

  addClientAccountMove(accountMoveId: number) {
    let controls = this.clientAccountMoves.controls as FormControl[];
    const index = controls.findIndex(
      (x: FormControl) => x.value.id == accountMoveId,
    );
    const value = (this.clientAccountMoves.controls[index] as FormGroup)
      .controls['checked'].value;

    (this.clientAccountMoves.controls[index] as FormGroup).controls[
      'checked'
    ].patchValue(!value);
  }
  addRepayment(accountMoveId: number) {
    let controls = this.repayments.controls as FormControl[];
    const index = controls.findIndex(
      (x: FormControl) => x.value.id == accountMoveId,
    );
    const value = (this.repayments.controls[index] as FormGroup).controls[
      'checked'
    ].value;

    (this.repayments.controls[index] as FormGroup).controls[
      'checked'
    ].patchValue(!value);
  }

  trackByAccountMoveId(index: any, item: AccountMoveModel) {
    return item.id;
  }
  trackByAccountMoveLineId(index: any, item: AccountMoveLineModel) {
    return item.id;
  }
}
