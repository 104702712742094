import { Component } from '@angular/core';

@Component({
  selector: 'app-commission-plan-rule-shared-sales-person-create',
  templateUrl: './commission-plan-rule-shared-sales-person-create.component.html',
  styleUrl: './commission-plan-rule-shared-sales-person-create.component.scss'
})
export class CommissionPlanRuleSharedSalesPersonCreateComponent {

}
