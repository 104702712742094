import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { View } from '../../../../shared/store/view/types';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { FormService } from '../../../../shared/store/form/form.service';
import { EmployeeService } from '../../../../shared/store/employee/employee.service';
import {
  AddressModel,
  BankAccountModel,
  EmployeeModel,
} from '../../../../shared/store/employee/types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-employee-edit',
  templateUrl: './employee-edit.component.html',
  styleUrls: ['./employee-edit.component.scss'],
})
export class EmployeeEditComponent implements OnInit, OnDestroy {
  employee$: Observable<EmployeeModel>;
  employeeForm: FormGroup;
  view$: Observable<View>;
  employeeId: number;

  constructor(
    private readonly subHeaderService: SubHeaderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
    private readonly employeeService: EmployeeService,
    private readonly formBuilder: FormBuilder
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.employee$ = this.employeeService.getById.value$;
  }

  ngOnInit(): void {
    this.employeeId = this.route.snapshot.params['id'];

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'employeeUpdate') this.onSubmit();
        if (x == 'employeeDetails') {
          this.formService.clear();
          this.router.navigate([
            'HumanResources/Employees/Details/',
            this.route.snapshot.params['id'],
          ]);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.employeeService.getById.call(this.route.snapshot.params['id']);

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.employeeForm = this.formBuilder.group({
      firstname: ['', [Validators.required]], //userContactName
      lastname: ['', [Validators.required]], //userContactName
      workEmail: [''], //workEmail
      workPhone: [''], //workPhone
      resourceId: [],
    });

    this.employee$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@employeeEdit', x.name);
        this.employeeForm.patchValue({
          firstname: x.firstName,
          lastname: x.lastName,
          workEmail: x.workEmail,
          workPhone: x.workPhone,
          resourceId: x.resourceId,
        });
      });
  }

  onSubmit() {
    if (this.employeeForm.invalid) {
      this.employeeForm.markAllAsTouched();
      this.formService.countErrors(this.employeeForm, true);
      return;
    }

    let bankAccount: BankAccountModel | null = null;
    if (
      this.employeeForm.value.privateInformationsFormGroup
        .privateInformationsIBAN &&
      this.employeeForm.value.privateInformationsFormGroup
        .privateInformationsIBAN.length > 0
    ) {
      bankAccount = {
        id: +this.employeeForm.value.privateInformationsFormGroup
          .privateInformationsId!,
        holderName: (
          this.employeeForm.value.lastname! +
          ' ' +
          this.employeeForm.value.firstname!
        ).trim(),
        active: true,
        iban: this.employeeForm.value.privateInformationsFormGroup
          .privateInformationsIBAN!,
        bic: this.employeeForm.value.privateInformationsFormGroup
          .privateInformationsBIC!,
      };
    }

    let homeAddress: AddressModel = {
      id: this.employeeForm.value.privateInformationsFormGroup
        .privateInformationsHomeAddressId!
        ? this.employeeForm.value.privateInformationsFormGroup
            .privateInformationsHomeAddressId!
        : 0,
      zipCode:
        this.employeeForm.value.privateInformationsFormGroup
          .privateInformationsHomeZipCode!,
      city: this.employeeForm.value.privateInformationsFormGroup
        .privateInformationsHomeCity!,
      address:
        this.employeeForm.value.privateInformationsFormGroup
          .privateInformationsHomeAddress!,
      address2: '',
      countryId: undefined,
      countryName: '',
    };
    let workingAddress: AddressModel | null = null;
    if (
      this.employeeForm.value.publicInformationsFormGroup
        .publicInformationsWorkingAddressId
    ) {
      workingAddress = {
        id:
          this.employeeForm.value.publicInformationsFormGroup
            .publicInformationsWorkingAddressId! ?? 0,
        address: '',
        address2: '',
        zipCode: '',
        city: '',
        countryName: '',
      };
    }

    let employeeModel: EmployeeModel = {
      id: this.route.snapshot.params['id'],
      firstName: this.employeeForm.value.firstname!,
      lastName: this.employeeForm.value.lastname!,

      workEmail: this.employeeForm.value.workEmail!,
      workPhone: this.employeeForm.value.workPhone!,
      notes: this.employeeForm.value.notesFormGroup.notes!,
      vehicle: this.employeeForm.value.parametersFormGroup.parametersVehicle!,
      homeWorkDistance:
        this.employeeForm.value.parametersFormGroup.parameterHomeWorkDistance!,
      jobId:
        this.employeeForm.value.publicInformationsFormGroup
          .publicInformationsJobId!,
      managerId:
        this.employeeForm.value.publicInformationsFormGroup
          .publicInformationsManagerId!,
      isManager:
        this.employeeForm.value.publicInformationsFormGroup
          .publicInformationsIsManager!,
      passportNumber:
        this.employeeForm.value.privateInformationsFormGroup
          .privateInformationsPassportNumber!,
      ssnNumber:
        this.employeeForm.value.privateInformationsFormGroup
          .privateInformationsSsnNumber!,
      gender:
        this.employeeForm.value.privateInformationsFormGroup
          .privateInformationsGender!,
      maritalStatus:
        this.employeeForm.value.privateInformationsFormGroup
          .privateInformationsMaritalStatus!,
      children:
        this.employeeForm.value.privateInformationsFormGroup
          .privateInformationsNumberOfChildren!,
      birthDate:
        this.employeeForm.value.privateInformationsFormGroup
          .privateInformationsBirthDate === ''
          ? undefined
          : this.employeeForm.value.privateInformationsFormGroup
              .privateInformationsBirthDate!,
      placeOfBirth:
        this.employeeForm.value.privateInformationsFormGroup
          .privateInformationsPlaceOfBirth!,
      nationalityId:
        this.employeeForm.value.privateInformationsFormGroup
          .privateInformationsCountryIdNationality!,

      privatePhoneNumber:
        this.employeeForm.value.privateInformationsFormGroup
          .privateInformationsPrivatePhoneNumber!,
      privateEmail:
        this.employeeForm.value.privateInformationsFormGroup
          .privateInformationsPrivateEmail!,

      bankAccount: bankAccount,
      homeAddress: homeAddress,
      workingAddress: workingAddress,

      name: '',

      registrationNumber: '',
      resourceId: this.employeeForm.value.resourceId,
      userId:
        this.employeeForm.value.publicInformationsFormGroup
          .publicInformationsUserId!,
      nationalityName: '',
      genderLoc: '',
      maritalStatusLoc: '',
      mobilePhone: '',
      managerName: '',
      jobName: '',
      userName: '',
      active: true,
    };

    // console.log(this.employeeForm);
    //

    console.log(employeeModel);

    let result$ = this.employeeService.updateEmployee.call(employeeModel);

    result$
      .pipe(
        filter((x) => !!x),
        take(1)
      )
      .subscribe((result) => {
        this.formService.clear();
        this.router.navigate(['/HumanResources/Employees/Details', result.id]);
      });
  }

  ngOnDestroy(): void {
    this.employeeService.updateEmployee.reset();
    this.breadcrumbService.set('@employeeEdit', ' ');
  }
}
