import { Component, output } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Action } from '../../../store/view/types';
import { SubHeaderService } from '../../../store/subheader/subheader.service';

@Component({
  selector: 'app-action-link',
  templateUrl: './action-link.component.html',
  styleUrl: './action-link.component.scss',
})
export class ActionLinkComponent implements ICellRendererAngularComp {
  data: any;
  actions: Action[];
  params!: ICellRendererParams;
  componentParent: any;

  constructor(private readonly subHeaderService: SubHeaderService) {}

  agInit(params: ICellRendererParams): void {
    this.data = params.data;
    this.actions = params.value;

    this.componentParent = params.context.componentParent;
  }

  refresh(params: ICellRendererParams) {
    this.params = params;
    return true;
  }

  triggerAction(actionName: string) {
    try {
      this.componentParent.setRowData(this.data);
    } catch (err) {
      console.error(err);
    }
    this.subHeaderService.setTriggerAction(actionName);
  }
}
