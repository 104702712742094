import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from 'src/app/shared/guard/authentication-guard';
import { Entity } from 'src/app/shared/store/view/types';
import { HasUnsavedChangesGuard } from '../../../shared/guard/has-form-changed.guard';
import { ModuleId } from '../../../shared/store/navigation/types';
import { HeaderMenuType } from '../../../shared/store/view/types';
import { BankAccountCreateComponent } from './bank-account-create/bank-account-create.component';
import { BankAccountDetailsComponent } from './bank-account-details/bank-account-details.component';
import { BankAccountEditComponent } from './bank-account-edit/bank-account-edit.component';
import { DataListingProviderComponent } from '../../../shared/components/data-listing-provider/data-listing-provider.component';

const routes: Routes = [
  {
    path: 'Contacts/BankAccounts',
    data: {
      breadcrumb: { label: ' ', alias: 'list' },
    },
    children: [
      {
        path: '',
        redirectTo: 'List',
        pathMatch: 'full',
      },
      {
        path: 'List',
        component: DataListingProviderComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Contacts,
          moduleId: ModuleId.contacts,
          entity: Entity.BankAccount,
          breadcrumb: { skip: true },
        },
      },
      {
        path: 'Details/:id',
        component: BankAccountDetailsComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Contacts,
          moduleId: ModuleId.contacts,
          entity: Entity.BankAccount,
          breadcrumb: { label: ' ', alias: 'bankAccountDetails' },
        },
      },
      {
        path: 'Create',
        component: BankAccountCreateComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Contacts,
          moduleId: ModuleId.contacts,
          entity: Entity.BankAccount,
          breadcrumb: { label: ' ', alias: 'bankAccountCreate' },
        },
      },
      {
        path: 'Edit/:id',
        component: BankAccountEditComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Contacts,
          moduleId: ModuleId.contacts,
          entity: Entity.BankAccount,
          breadcrumb: { label: ' ', alias: 'bankAccountEdit' },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BankAccountRoutingModule {}
