import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ContractService } from 'src/app/shared/store/contract/contract.service';
import { ContractModel } from 'src/app/shared/store/contract/types';

@Component({
  selector: 'app-contract-details-commission',
  templateUrl: './contract-details-commission.component.html',
  styleUrls: ['./contract-details-commission.component.scss'],
})
export class ContractDetailsCommissionComponent implements OnInit {
  contract$: Observable<ContractModel>;
  constructor(private readonly contractService: ContractService) {}

  ngOnInit(): void {
    this.contract$ = this.contractService.getByUniqueId.value$;
  }
}
