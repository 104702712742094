import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { CommissionRoutingModule } from './commission-routing.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, CommissionRoutingModule, SharedModule],
})
export class CommissionModule {}
