import { Component, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { OAuthenticationService } from 'src/app/shared/authentication/oauthentication.service';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NavigationService } from '../../../shared/store/navigation/navigation.service';

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  host: { class: 'connection-form p-4 col-12 col-sm-8' },
})
export class LoginComponent implements OnDestroy {
  isAuthenticated = false;
  checkSessionChanged$: Observable<boolean>;
  checkSessionChanged: any;
  result: Promise<Object>;
  returnUrl: string;
  showErrorLogin: boolean;
  loginForm: FormGroup;

  private firstPath: string = '/page-not-found';
  private sidebar: Subscription;

  constructor(
    private readonly authService: OAuthenticationService,
    private readonly router: Router,
    private route: ActivatedRoute,
    private readonly navigationService: NavigationService,
    protected readonly formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
    this.navigationService.getModules.reset();

    this.loginForm = this.formBuilder.group({
      rememberMe: [false],
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }

    this.authService
      .loginwithcredentials({
        mail: this.loginForm.value.email!,
        password: this.loginForm.value.password!,
      })
      .then((x) => {
        //remember me ?

        //Ajouter le path vers le currentUser
        this.sidebar = this.navigationService.getModules
          .call()
          .pipe(
            untilDestroyed(this),
            filter((x) => !!x),
          )
          .subscribe((sidebar) => {
            this.firstPath = sidebar[0]?.route;
            this.router.navigateByUrl(this.returnUrl ?? this.firstPath);
          });
      })
      .catch((x) => {
        this.showErrorLogin = true;
        // console.log(x);
      });
  }

  get email() {
    return this.loginForm.get('email')!;
  }

  get password() {
    return this.loginForm.get('password')!;
  }

  ngOnDestroy(): void {
    // this.sidebar.unsubscribe();
  }
}
