import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { ErrorsNestedFormGroup } from '../../../../../shared/store/form/types';
import { FormService } from '../../../../../shared/store/form/form.service';
import { TabsContentEmployee } from '../../../../../shared/models/tabs-content-employee';
import { EmployeeFormPublicInformationsComponent } from '../employee-form-tabs/employee-form-public-informations/employee-form-public-informations.component';
import { EmployeeFormPrivateInformationsComponent } from '../employee-form-tabs/employee-form-private-informations/employee-form-private-informations.component';
import { EmployeeFormParametersComponent } from '../employee-form-tabs/employee-form-parameters/employee-form-parameters.component';
import { EmployeeFormNotesComponent } from '../employee-form-tabs/employee-form-notes/employee-form-notes.component';

@Component({
  selector: 'app-employee-tabs-form',
  templateUrl: './employee-tabs-form.component.html',
  styleUrls: ['./employee-tabs-form.component.scss'],
})
export class EmployeeTabsFormComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;
  errorsDescription$: Observable<ErrorsNestedFormGroup>;

  active = 1;

  tabsContent$: Observable<TabsContentEmployee[]> = of([]);

  tabsInit: TabsContentEmployee[] = [
    {
      title: 'EMPLOYEE_TABS_PUBLIC_INFORMATIONS',
      index: 1,
      name: 'public-informations',
      component: EmployeeFormPublicInformationsComponent,
      formGroupName: 'publicInformationsFormGroup',
    },
    {
      title: 'EMPLOYEE_TABS_PRIVATE_INFORMATIONS',
      index: 2,
      name: 'private-informations',
      component: EmployeeFormPrivateInformationsComponent,
      formGroupName: 'privateInformationsFormGroup',
    },
    {
      title: 'EMPLOYEE_TABS_PARAMETERS',
      index: 3,
      name: 'parameters',
      component: EmployeeFormParametersComponent,
      formGroupName: 'parametersFormGroup',
    },
    {
      title: 'EMPLOYEE_TABS_NOTES',
      index: 4,
      name: 'notes',
      component: EmployeeFormNotesComponent,
      formGroupName: 'notesFormGroup',
    },
  ];
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private readonly formService: FormService,
  ) {}

  ngOnInit(): void {
    this.errorsDescription$ = this.formService.getErrors();
    this.tabsContent$ = of(this.tabsInit);
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.tabsContent$ = of(this.tabsInit);
  }

  trackByTabsContent(index: any, item: TabsContentEmployee) {
    return item.index;
  }
}
