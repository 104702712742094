import { AccountType } from '../accounting/types';

export const accountFeatureKey = 'account';

// export class AccountModel {
//   id: number;
//   contactId?: number;
//   contactName: string;
//   accountType: AccountType;
//   name: string;
//   code: string;
//   active: boolean;
//   canHandleCash: boolean;
// }
