import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SubHeaderService } from '../../../store/subheader/subheader.service';
import { ViewService } from '../../../store/view/views.service';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FormService } from '../../../store/form/form.service';
import { AccountingService } from '../../../store/accounting/accounting.service';
import {
  AccountMoveLineModel,
  AccountMoveModel,
  IssuingAccountMovePayingAccountMoveModel,
  JournalAssignRecord,
} from '../../../store/accounting/types';
import { filter, Observable } from 'rxjs';
import { SourceEnum } from '../../../store/typeahead/types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filterTrue } from '../../../pipe/rxjs/operators';
import { take } from 'rxjs/operators';
import { ModalService } from '../../../service/modal.service';

@UntilDestroy()
@Component({
  selector: 'app-account-move-assign-slip-content',
  templateUrl: './account-move-assign-slip-content.component.html',
  styleUrls: ['./account-move-assign-slip-content.component.scss'],
})
export class AccountMoveAssignSlipContentComponent
  implements OnInit, OnDestroy
{
  @Input() modalRandomIdentifier: number;
  @Input() source: SourceEnum = SourceEnum.Component;
  @Input() contactId: number;
  @Input() paymentId: number;
  @Input() slipId: number;

  assignForm: FormGroup;
  accountMovesJournal$: Observable<JournalAssignRecord[]>;
  accountMovesPayment$: Observable<AccountMoveModel[]>;

  paymentAmount: number = 0;
  slipAmount: number = 0;

  constructor(
    private readonly subHeaderService: SubHeaderService,
    private readonly viewService: ViewService,
    private readonly formBuilder: FormBuilder,
    private readonly formService: FormService,
    private readonly accountingService: AccountingService,
    private readonly dynamicService: ModalService,
  ) {
    this.accountMovesJournal$ = this.accountingService.getUnbindedSlip.value$;
    this.accountMovesPayment$ =
      this.accountingService.getUnbindedByContactId.value$;
  }

  ngOnInit(): void {
    this.accountingService.getUnbindedSlip.call(this.contactId.toString());
    this.accountingService.getUnbindedByContactId.call({
      id: this.contactId.toString(),
      bindType: 'Slip',
    });

    this.assignForm = this.formBuilder.group({
      payments: this.formBuilder.array([]),
      slips: this.formBuilder.array([]),
    });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'accountMoveAssignCreate') this.onSubmit();
        this.subHeaderService.setTriggerAction('');
      });

    this.accountMovesPayment$
      .pipe(filterTrue(), take(1))
      .subscribe((accountMoves: AccountMoveModel[]) => {
        accountMoves.forEach((accountMove) => {
          if (
            accountMove.accountMoveLines &&
            accountMove.accountMoveLines.length > 0
          ) {
            accountMove.accountMoveLines.forEach((accountMoveLine) => {
              switch (accountMove.moveType) {
                case 'Entry':
                  const accountMoveFormEntry = this.formBuilder.group({
                    id: [accountMove.id],
                    name: [accountMoveLine.name],
                    amount: [accountMoveLine.unitPrice],
                    checked: [false, [Validators.required]],
                  });

                  this.payments.push(accountMoveFormEntry);
                  break;
                case 'CustomerInvoice':
                case 'VendorInvoice':
                default:
                  break;
              }
            });
          } else {
            this.payments.push(this.formBuilder.group({}));
          }
        });
        if (this.payments.controls && this.paymentId) {
          this.addPayment(this.paymentId);
        }
      });

    this.accountMovesJournal$
      .pipe(filterTrue(), take(1))
      .subscribe((journals: JournalAssignRecord[]) => {
        journals.forEach((journal) => {
          const journalForm = this.formBuilder.group({
            id: [journal.id],
            name: [journal.name],
            amount: [journal.amount],
            checked: [false, [Validators.required]],
          });

          this.slips.push(journalForm);
        });
        if (this.slips.controls && this.slipId) {
          this.addSlip(this.slipId);
        }
      });
  }

  onSubmit() {
    if (this.assignForm.invalid) {
      this.assignForm.markAllAsTouched();
      this.formService.setEntityErrors('ASSIGN');
      this.formService.countErrors(this.assignForm, true);
      return;
    }

    if (this.paymentAmount < this.slipAmount) return;

    let paymentControls = this.payments.controls as FormControl[];

    const payments: number[] = paymentControls
      .filter((x: FormControl) => x.value.checked == true)
      .map((x: FormControl) => x.value.id);

    let slipControls = this.slips.controls as FormControl[];

    const slips: number[] = slipControls
      .filter((x: FormControl) => x.value.checked == true)
      .map((x: FormControl) => x.value.id);

    const issuingAccountMovePayingAccountMoveModel: IssuingAccountMovePayingAccountMoveModel =
      {
        issuingAccountMoves: slips,
        payingAccountMoves: payments,
        bindType: 'Slip',
      };

    let result$ = this.accountingService.bind.call(
      issuingAccountMovePayingAccountMoveModel,
    );

    result$
      .pipe(
        filter((x) => x !== undefined && x !== null),
        take(1),
      )
      .subscribe((result) => {
        this.formService.clear();
        switch (this.source) {
          case SourceEnum.Component:
            //   this.userService.getCurrentUser.call();
            // this.router.navigate(['/Contacts/Contacts/Details', result.id]);
            break;
          case SourceEnum.Modal:
            this.dynamicService.outputFromDynamicComponent(
              this.modalRandomIdentifier,
              result ? '1' : '-1',
            ); //1 to refresh the data, -1 to cancel
            break;
          default:
            // this.router.navigate(['/Contacts/Contacts/Details', result.id]);
            break;
        }
      });
  }

  ngOnDestroy(): void {
    this.accountingService.getUnbindedSlip.reset();
    this.accountingService.bind.reset();
  }

  get payments() {
    return this.assignForm.controls['payments'] as FormArray;
  }

  get slips() {
    return this.assignForm.controls['slips'] as FormArray;
  }

  addPayment(accountMoveId: number) {
    let controls = this.payments.controls as FormControl[];
    const index = controls.findIndex(
      (x: FormControl) => x.value.id == accountMoveId,
    );
    const value = (this.payments.controls[index] as FormGroup).controls[
      'checked'
    ].value;

    (this.payments.controls[index] as FormGroup).controls['checked'].patchValue(
      !value,
    );

    if (value) {
      this.paymentAmount -= (
        this.payments.controls[index] as FormGroup
      ).controls['amount'].value;
    } else {
      this.paymentAmount += (
        this.payments.controls[index] as FormGroup
      ).controls['amount'].value;
    }
  }

  addSlip(journalId: number) {
    let controls = this.slips.controls as FormControl[];
    const index = controls.findIndex(
      (x: FormControl) => x.value.id == journalId,
    );
    const value = (this.slips.controls[index] as FormGroup).controls['checked']
      .value;

    (this.slips.controls[index] as FormGroup).controls['checked'].patchValue(
      !value,
    );

    if (value) {
      this.slipAmount -= (this.slips.controls[index] as FormGroup).controls[
        'amount'
      ].value;
    } else {
      this.slipAmount += (this.slips.controls[index] as FormGroup).controls[
        'amount'
      ].value;
    }
  }

  trackByAccountMoveId(index: any, item: AccountMoveModel) {
    return item.id;
  }
  trackByAccountMoveLineId(index: any, item: AccountMoveLineModel) {
    return item.id;
  }
  trackByAccountMoveJournalId(index: any, item: JournalAssignRecord) {
    return item.id;
  }
}
