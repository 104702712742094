import { createApiCallActions } from '../../../common/store/generic.actions';
import { AccountModel } from '../accounting/types';

export const accountActions = {
  getById: createApiCallActions<AccountModel>('account', 'getById'),
  add: createApiCallActions<AccountModel>('account', 'add'),
  update: createApiCallActions<AccountModel>('account', 'update'),
  delete: createApiCallActions<boolean>('account', 'delete'),
  createInstance: createApiCallActions<AccountModel>(
    'account',
    'createInstance',
  ),
};

export type AccountAction = typeof accountActions;
