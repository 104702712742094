import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DataListingProviderComponent } from '../../../shared/components/data-listing-provider/data-listing-provider.component';
import { AuthenticationGuard } from '../../../shared/guard/authentication-guard';
import { HasUnsavedChangesGuard } from '../../../shared/guard/has-form-changed.guard';
import { Entity, HeaderMenuType } from '../../../shared/store/view/types';
import { ModuleId } from '../../../shared/store/navigation/types';
import { AccountCreateComponent } from './account-create/account-create.component';

const routes: Routes = [
  {
    path: 'Finance/Accounts',
    data: {
      breadcrumb: { label: ' ', alias: 'list' },
    },
    children: [
      {
        path: '',
        redirectTo: 'List',
        pathMatch: 'full',
      },
      {
        path: 'List',
        component: DataListingProviderComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Finance,
          moduleId: ModuleId.finance,
          entity: Entity.Account,
          breadcrumb: { skip: true },
        },
      },
      // {
      //   path: 'Details/:id',
      //   component: ApplicationFeeRuleDetailsComponent,
      //   canActivate: [AuthenticationGuard],
      //   canDeactivate: [HasUnsavedChangesGuard],
      //   data: {
      //     headerMenu: HeaderMenuType.Finance,
      //     moduleId: ModuleId.finance,
      //     entity: Entity.Account,
      //     breadcrumb: { label: ' ', alias: 'accountDetails' },
      //   },
      // },
      {
        path: 'Create',
        component: AccountCreateComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Finance,
          moduleId: ModuleId.finance,
          entity: Entity.Account,
          breadcrumb: { label: ' ', alias: 'accountCreate' },
        },
      },
      // {
      //   path: 'Edit/:id',
      //   component: ApplicationFeeRuleEditComponent,
      //   canActivate: [AuthenticationGuard],
      //   canDeactivate: [HasUnsavedChangesGuard],
      //   data: {
      //     headerMenu: HeaderMenuType.Finance,
      //     moduleId: ModuleId.finance,
      //     entity: Entity.Account,
      //     breadcrumb: { label: ' ', alias: 'accountEdit' },
      //   },
      // },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
