import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { AccountApiService } from "./account.api.service";
import { AccountEffects } from "./account.effects";
import { IAccountState, accountInitialState, accountReducer } from "./account.reducer";
import { AccountService } from "./account.service";
import { accountFeatureKey } from "./types";

@NgModule({
    imports:[
        StoreModule.forFeature<IAccountState>(accountFeatureKey, accountReducer,{
            initialState: accountInitialState,
        }),
        EffectsModule.forFeature([AccountEffects])
    ],
    providers:[AccountApiService, AccountService]
})
export class StoreAccountModule{}
