export function setDate(date?: Date) {
  if (!date) {
    date = new Date();
  } else {
    date = new Date(date);
  }
  let hours = ('0' + date.getHours()).slice(-2);
  let minutes = ('0' + date.getMinutes()).slice(-2);
  let str = hours + ':' + minutes;
  return str;
}
