import { Component, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { Entity } from 'src/app/shared/store/view/types';
import { Observable, filter, debounceTime } from 'rxjs';
import {
  ContractModel,
  CreateContractFormModel,
} from 'src/app/shared/store/contract/types';
import { ContractService } from 'src/app/shared/store/contract/contract.service';
import { take } from 'rxjs/operators';
import { formatDate } from '../../../../../../shared/helper/datehelper';
import {
  DataType,
  DropdownStyle,
} from '../../../../../../shared/store/typeahead/types';

@Component({
  selector: 'app-contract-form-general-informations',
  templateUrl: './contract-form-general-informations.component.html',
  styleUrls: ['./contract-form-general-informations.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class ContractFormGeneralInformationsComponent implements OnInit {
  form: FormGroup;
  createContractFormModel$: Observable<ContractModel>;
  contractDetail$: Observable<ContractModel>;

  protected readonly Entity = Entity;
  protected readonly DataType = DataType;
  protected readonly DropdownStyle = DropdownStyle;

  constructor(
    private parent: FormGroupDirective,
    private formBuilder: FormBuilder,
    private readonly contractService: ContractService
  ) {
    this.createContractFormModel$ =
      this.contractService.getContractModel.value$;
    this.contractDetail$ = this.contractService.getByUniqueId.value$;
  }

  ngOnInit(): void {
    this.form = this.parent.form;
    this.form.addControl(
      'generalInformationFormGroup',
      this.formBuilder.group({
        validationDate: [],
        approverId: [undefined],
        documentsReceivedDate: [],
        documentsSentDate: [],
        regularizationDate: [],
        monthlyFee: [
          0,
          [Validators.required, Validators.pattern(/^[1-9]\d*$/)],
        ],
        monthlyCost: [],
        taxRate: [],
        commissionRate: [],
        applicationFees: [0],
        terminationDate: [],
        terminationId: [undefined], //FK new table "TerminationReason" => Typeahead
        expiryMonth: [],
        expiryDay: [],
        annualFee: [0],
      })
    );

    this.createContractFormModel$
      .pipe(
        filter((x) => !!x),
        take(1)
      )
      .subscribe((x) => {
        this.form.patchValue({
          generalInformationFormGroup: {
            approverId: x.approverId,
            monthlyFee: x.monthlyFee,
            monthlyCost: x.monthlyCost,
            taxRate: x.taxRate,
            applicationFees: x.applicationFees,
            commissionRate: x.commissionRate,
          },
        });
      });

    this.contractDetail$
      .pipe(
        filter((x) => !!x),
        take(1)
      )
      .subscribe((x) => {
        this.form.patchValue({
          generalInformationFormGroup: {
            validationDate: x.validationDate
              ? formatDate(x.validationDate, 'ToInverseSqlDate')
              : undefined,
            approverId: x.approverId,
            documentsReceivedDate: x.documentsReceivedDate
              ? formatDate(x.documentsReceivedDate, 'ToInverseSqlDate')
              : undefined,
            documentsSentDate: x.documentsSentDate
              ? formatDate(x.documentsSentDate, 'ToInverseSqlDate')
              : undefined,
            regularizationDate: x.regularizationDate
              ? formatDate(x.regularizationDate, 'ToInverseSqlDate')
              : undefined,
            monthlyFee: x.monthlyFee,
            monthlyCost: x.monthlyCost,
            taxRate: x.taxRate,
            commissionRate: x.commissionRate,
            applicationFees: x.applicationFees,
            terminationDate: x.terminationDate
              ? formatDate(x.terminationDate, 'ToInverseSqlDate')
              : undefined,
            terminationId: x.terminationReasonId,
            expiryMonth: x.expiryMonth,
            expiryDay: x.expiryDay,
            annualFee: x.annualFee,
          },
        });
      });

    this.form.controls['generalInformationFormGroup']
      .get('monthlyCost')
      ?.valueChanges.pipe(debounceTime(150))
      .subscribe((x) => {
        this.setAnnualFee();
      });

    this.form.controls['generalInformationFormGroup']
      .get('monthlyFee')
      ?.valueChanges.pipe(debounceTime(150))
      .subscribe((x) => {
        this.setAnnualFee();
      });
  }

  setAnnualFee(): void {
    let annualFee: number = 0;
    let monthlyFee: number;
    let monthlyCost: number;

    try {
      monthlyFee =
        +this.form.controls['generalInformationFormGroup'].get('monthlyFee')
          ?.value;
      monthlyCost =
        +this.form.controls['generalInformationFormGroup'].get('monthlyCost')
          ?.value;
    } catch (e) {
      monthlyFee = 0;
      monthlyCost = 0;
    }

    if (monthlyFee !== 0 || monthlyCost !== 0) {
      annualFee = (monthlyFee + monthlyCost) * 12;
    }

    this.form.controls['generalInformationFormGroup']
      .get('annualFee')
      ?.setValue(annualFee);
  }
}
