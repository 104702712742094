import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, filter, Observable, of } from 'rxjs';
import { TabsContentContact } from '../../../../../shared/models/tabs-content-contact';
import { ContactService } from '../../../../../shared/store/contact/contact.service';
import { ContactModel } from '../../../../../shared/store/contact/types';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filterTrue } from '../../../../../shared/pipe/rxjs/operators';
import { ContactDetailsContractsComponent } from '../contact-details-tab/contact-details-contracts/contact-details-contracts.component';
import { ContactDetailsLeadsComponent } from '../contact-details-tab/contact-details-leads/contact-details-leads.component';
import { TabDocumentsComponent } from '../../../../../shared/components/document/tab-documents/tab-documents.component';

@UntilDestroy()
@Component({
  selector: 'app-contact-tabs-details',
  templateUrl: './contact-tabs-details.component.html',
  styleUrls: ['./contact-tabs-details.component.scss'],
})
export class ContactTabsDetailsComponent
  implements OnInit, OnChanges, OnDestroy
{
  active = 0;
  currentActive: number = 0;
  contactDetails$: Observable<ContactModel>;

  tabsContent$: BehaviorSubject<TabsContentContact[]> = new BehaviorSubject<
    TabsContentContact[]
  >([]);
  tabsInit: TabsContentContact[] = [
    {
      title: 'Leads',
      component: ContactDetailsLeadsComponent,
      index: 0,
      name: 'lead',
    },
    {
      title: 'Contrats',
      component: ContactDetailsContractsComponent,
      index: 1,
      name: 'contract',
    },
    // {
    //   title: 'Documents',
    //   component: TabDocumentsComponent,
    //   index: 2,
    //   name: 'document',
    // },
  ];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private readonly contactService: ContactService,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    this.contactDetails$ = this.contactService.getById.value$;
  }

  ngOnInit(): void {
    this.tabsContent$.next(this.tabsInit);
    this.changeDetectorRef.detectChanges();

    this.contactDetails$
      .pipe(filterTrue(), untilDestroyed(this))
      .subscribe((contactModel) => {
        if (contactModel.isCompany) {
          if (this.currentActive) {
            this.active = this.currentActive;
          } else {
            this.active = 0;
          }
        } else {
          if (this.currentActive) {
            this.active = this.currentActive;
          } else {
            this.active = 1;
          }
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.currentActive) {
      this.active = this.currentActive;
    }

    // this.tabsContent$.next(this.tabsInit);
  }

  trackByTabsContent(index: any, item: TabsContentContact) {
    return item.index;
  }

  onActiveIdChangeSaveCurrent(active: number) {
    this.currentActive = active;
  }

  ngOnDestroy(): void {
    this.currentActive = 0;
  }
}
