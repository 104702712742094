<app-loader
  [apiCall]="this.apiCall"
  [loader]="true"
  [loaderId]="'cashJournalModal'"
  style="min-height: 150px"
>

<form
  [formGroup]="cashControlForm"
  (ngSubmit)="onSubmit()"
  [hasUnsavedData]="cashControlForm"
>
  <button type="submit" hidden="hidden"></button>

  <div class="row mb-2">
    <div class="col-12 mr-auto"></div>
  </div>
  <div class="row">
    <div class="col-12 col-md">

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
        >CASH_CONTROL_LBL_REAL_AMOUNT</label
        >
        <div class="col">
          <input
            class="form-control form-control-sm"
            type="text"
            formControlName="realAmount"
            checkFormFieldValidity
            [placeholder]="'€'"
            mask="separator.2"
            [suffix]="'PAYMENT_PLACEHOLDER_MONETARY' | translate"
            [showMaskTyped]="false"
            [decimalMarker]="','"
            [allowNegativeNumbers]="true"
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
        >CASH_CONTROL_LBL_CALCULATE_AMOUNT</label
        >
        <div class="col">
          <input
            class="form-control form-control-sm"
            type="text"
            formControlName="calculatedAmount"
            checkFormFieldValidity
            [placeholder]="'€'"
            mask="separator.2"
            [suffix]="'PAYMENT_PLACEHOLDER_MONETARY' | translate"
            [showMaskTyped]="false"
            [decimalMarker]="','"
            [allowNegativeNumbers]="true"
            [readOnly]="true"
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
        >CASH_CONTROL_LBL_DIFFERENCE_AMOUNT</label
        >
        <div class="col">
          <input
            class="form-control form-control-sm"
            type="text"
            formControlName="differenceAmount"
            checkFormFieldValidity
            [placeholder]="'€'"
            mask="separator.2"
            [suffix]="'PAYMENT_PLACEHOLDER_MONETARY' | translate"
            [showMaskTyped]="false"
            [decimalMarker]="','"
            [allowNegativeNumbers]="true"
            [readOnly]="true"
          />
        </div>
      </div>

    </div>
    <div class="col">

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
        >CASH_CONTROL_LBL_CONTROL_DATE</label
        >
        <div class="col">
          <input
            class="form-control form-control-sm"
            type="date"
            formControlName="controlDate"
            checkFormFieldValidity
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
        >CASH_CONTROL_LBL_CONTROL_TIME</label
        >
        <div class="col">
          <input
            class="form-control form-control-sm"
            type="time"
            formControlName="controlTime"
            checkFormFieldValidity
          />
        </div>
      </div>
    </div>
  </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group row">
          <label class="col-2 col-form-label-sm" translate
          >CASH_CONTROL_LBL_DESCRIPTION</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              type="text"
              [placeholder]="''"
              formControlName="description"
              checkFormFieldValidity
            />
          </div>
        </div>
      </div>
    </div>
</form>
@if (createCashControlInstance$ | async) {
  <div></div>
}
</app-loader>
