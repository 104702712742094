import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AccountMoveLineModel,
  AccountMoveModel,
  BindType,
} from '../../../store/accounting/types';
import { SourceEnum } from '../../../store/typeahead/types';
import { AccountingService } from '../../../store/accounting/accounting.service';
import { Observable, take } from 'rxjs';
import { filterTrue } from '../../../pipe/rxjs/operators';
import { ModalService } from '../../../service/modal.service';
import { SubHeaderService } from '../../../store/subheader/subheader.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-account-move-assign-slip-details-content',
  templateUrl: './account-move-assign-slip-details-content.component.html',
  styleUrls: ['./account-move-assign-slip-details-content.component.scss'],
})
export class AccountMoveAssignSlipDetailsContentComponent
  implements OnInit, OnDestroy
{
  @Input() modalRandomIdentifier: number;
  @Input() source: SourceEnum = SourceEnum.Component;
  @Input({ required: true }) invoice: AccountMoveModel;
  @Input({ required: true }) journalId: number;
  @Input({ required: true }) journalName: string;

  accountMoves$: Observable<AccountMoveModel[]>;

  constructor(
    private readonly subHeaderService: SubHeaderService,
    private readonly accountingService: AccountingService,
    private readonly dynamicService: ModalService,
  ) {
    this.accountMoves$ = this.accountingService.getByJournalId.value$;
  }

  ngOnInit(): void {
    this.accountingService.getByJournalId.call(this.journalId.toString());

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'accountMovesUnbind') this.unbind();
        this.subHeaderService.setTriggerAction('');
      });
  }

  ngOnDestroy(): void {
    this.accountingService.getByJournalId.reset();
    this.accountingService.unbind.reset();
  }

  private unbind() {
    let bindType: BindType = 'Slip';

    let result$ = this.accountingService.unbind.call({
      accountMoveId: this.invoice.id,
      bindType: bindType,
    });

    result$.pipe(filterTrue(), take(1)).subscribe((result) => {
      switch (this.source) {
        case SourceEnum.Component:
          break;
        case SourceEnum.Modal:
          this.dynamicService.outputFromDynamicComponent(
            this.modalRandomIdentifier,
            '1',
          ); //close the modal
          break;
        default:
          break;
      }
    });
  }

  trackByAccountMoveId(index: any, item: AccountMoveModel) {
    return item.id;
  }
  trackByAccountMoveLineId(index: any, item: AccountMoveLineModel) {
    return item.id;
  }
}
