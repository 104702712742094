<div
  class="tab-pane fade flex-even active show"
  id="employees"
  role="tabpanel"
  aria-labelledby="employees-tab"
  *ngIf="this.employeeDetail$ | async as employeeDetail"
>
  <div class="row mt-2">
    <div class="col-12 col-md">
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >EMPLOYEE_LBL_PRIVATE_PHONE_NUMBER</label
        >
        <div class="col">
          <app-phone-number
            [phoneNumber]="employeeDetail.privatePhoneNumber"
            [isLinkEnabled]="false"
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >EMPLOYEE_LBL_PRIVATE_MAIL</label
        >
        <div class="col">
          <span class="form-control-plaintext form-control-sm">
            {{ employeeDetail.privateEmail }}
          </span>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >EMPLOYEE_LBL_HOME_ADDRESS</label
        >
        <div class="col">
          <div class="form-control-plaintext form-control-sm mb-2 h-100 p-0">
            <app-address
              [address]="employeeDetail.homeAddress.address"
              [address2]="employeeDetail.homeAddress.address2"
              [zipCode]="employeeDetail.homeAddress.zipCode"
              [city]="employeeDetail.homeAddress.city"
              [country]="employeeDetail.homeAddress.countryName"
            />
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >EMPLOYEE_LBL_BANK_ACCOUNT</label
        >
        <div class="col">
          <span class="form-control-plaintext form-control-sm">
            <app-iban [iban]="employeeDetail.bankAccount?.iban"></app-iban>
          </span>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >EMPLOYEE_LBL_DATE_OF_BIRTH</label
        >
        <div class="col">
          <span class="form-control-plaintext form-control-sm">
            {{ employeeDetail.birthDate | date : "dd/MM/yyyy" }}
          </span>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >EMPLOYEE_LBL_PLACE_OF_BIRTH</label
        >
        <div class="col">
          <span class="form-control-plaintext form-control-sm">
            {{ employeeDetail.placeOfBirth }}
          </span>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >EMPLOYEE_LBL_NATIONALITY</label
        >
        <div class="col">
          <span class="form-control-plaintext form-control-sm">
            {{ employeeDetail.nationalityName }}
          </span>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >EMPLOYEE_LBL_REGISTRATION_NUMBER</label
        >
        <div class="col">
          <span class="form-control-plaintext form-control-sm">
            {{ employeeDetail.passportNumber }}
          </span>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >EMPLOYEE_LBL_NATIONAL_NUMBER</label
        >
        <div class="col">
          <span class="form-control-plaintext form-control-sm">
            {{ employeeDetail.ssnNumber }}
          </span>
        </div>
      </div>
      <div class="form-group-title" translate>EMPLOYEE_LBL_STATUS</div>
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >EMPLOYEE_LBL_GENDER</label
        >
        <div class="col">
          <span class="form-control-plaintext form-control-sm">
            {{ employeeDetail.genderLoc }}
          </span>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >EMPLOYEE_LBL_MARITAL_STATUS</label
        >
        <div class="col">
          <span class="form-control-plaintext form-control-sm">
            {{ employeeDetail.maritalStatusLoc }}
          </span>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >EMPLOYEE_LBL_NUMBER_OF_CHILDREN</label
        >
        <div class="col">
          <span class="form-control-plaintext form-control-sm">
            {{ employeeDetail.children }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
