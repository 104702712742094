import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { IAccountingState } from './accounting.reducer';
import {
  accountingFeatureKey,
  AccountModel,
  AccountMoveModel,
  AccountMovePeriodModel,
  CashJournalEntry,
  CashJournalModel,
  JournalAssignRecord,
} from './types';

const accountingFeatureState =
  createFeatureSelector<IAccountingState>(accountingFeatureKey);

export const accountingSelectors = {
  getById: createApiCallSelectors<AccountMoveModel, IAccountingState>(
    accountingFeatureState,
    'getById',
  ),
  getByContactId: createApiCallSelectors<AccountMoveModel[], IAccountingState>(
    accountingFeatureState,
    'getByContactId',
  ),
  getByJournalId: createApiCallSelectors<AccountMoveModel[], IAccountingState>(
    accountingFeatureState,
    'getByJournalId',
  ),
  getUnbindedByContactId: createApiCallSelectors<
    AccountMoveModel[],
    IAccountingState
  >(accountingFeatureState, 'getUnbindedByContactId'),
  getUnbindedByContractId: createApiCallSelectors<
    AccountMoveModel[],
    IAccountingState
  >(accountingFeatureState, 'getUnbindedByContractId'),
  getUnbindedRepayment: createApiCallSelectors<
    AccountMoveModel[],
    IAccountingState
  >(accountingFeatureState, 'getUnbindedRepayment'),
  getUnbindedSlip: createApiCallSelectors<
    JournalAssignRecord[],
    IAccountingState
  >(accountingFeatureState, 'getUnbindedSlip'),

  createInstance: createApiCallSelectors<AccountMoveModel, IAccountingState>(
    accountingFeatureState,
    'createInstance',
  ),

  add: createApiCallSelectors<AccountMoveModel, IAccountingState>(
    accountingFeatureState,
    'add',
  ),
  bind: createApiCallSelectors<boolean, IAccountingState>(
    accountingFeatureState,
    'bind',
  ),
  getPeriods: createApiCallSelectors<
    AccountMovePeriodModel[],
    IAccountingState
  >(accountingFeatureState, 'getPeriods'),
  importSlip: createApiCallSelectors<AccountMoveModel, IAccountingState>(
    accountingFeatureState,
    'importSlip',
  ),
  changeAccountMoveStatus: createApiCallSelectors<
    AccountMoveModel,
    IAccountingState
  >(accountingFeatureState, 'changeAccountMoveStatus'),
  uploadProof: createApiCallSelectors<boolean, IAccountingState>(
    accountingFeatureState,
    'uploadProof',
  ),
  getAccountMovePdf: createApiCallSelectors<any, IAccountingState>(
    accountingFeatureState,
    'getAccountMovePdf',
  ),
  delete: createApiCallSelectors<boolean, IAccountingState>(
    accountingFeatureState,
    'delete',
  ),
  unbind: createApiCallSelectors<boolean, IAccountingState>(
    accountingFeatureState,
    'unbind',
  ),
  getBindedAccountMoveByAccountMoveId: createApiCallSelectors<
    any,
    IAccountingState
  >(accountingFeatureState, 'getBindedAccountMoveByAccountMoveId'),
  getAccountByContactId: createApiCallSelectors<AccountModel, IAccountingState>(
    accountingFeatureState,
    'getAccountByContactId',
  ),
  getAccountById: createApiCallSelectors<AccountModel, IAccountingState>(
    accountingFeatureState,
    'getAccountById',
  ),
  getCashJournal: createApiCallSelectors<CashJournalModel, IAccountingState>(
    accountingFeatureState,
    'getCashJournal',
  ),
};
