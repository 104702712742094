import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ErrorService } from '../../error';
import { ApplicationFeeRuleModel, ApplicationFeeType } from './types';
import { catchError } from 'rxjs/operators';
import { CrudType } from '../../service/message/types';
import { MessageService } from '../../service/message/message.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationFeeRuleApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService,
    private readonly messageService: MessageService,
  ) {}

  getById = (id: number): Observable<ApplicationFeeRuleModel> => {
    let parameters: string[];
    parameters = [id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<ApplicationFeeRuleModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  getByProductLineId = (id: number): Observable<ApplicationFeeRuleModel[]> => {
    let parameters: string[];
    parameters = ['productline', id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<ApplicationFeeRuleModel[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  getByContractId = ({
    id,
    type,
  }: {
    id: number;
    type: ApplicationFeeType;
  }): Observable<ApplicationFeeRuleModel> => {
    let parameters: string[];
    parameters = [type.toString(), 'contract', id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<ApplicationFeeRuleModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  createInstance = (): Observable<ApplicationFeeRuleModel> => {
    let parameters: string[];
    parameters = ['create'];
    let url = this.formatUrl(parameters);

    return this.httpClient.get<ApplicationFeeRuleModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  add = (
    applicationFeeRuleModel: ApplicationFeeRuleModel,
  ): Observable<ApplicationFeeRuleModel> => {
    let parameters: string[];
    parameters = [];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .post<ApplicationFeeRuleModel>(url, applicationFeeRuleModel, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  update = (
    applicationFeeRuleModel: ApplicationFeeRuleModel,
  ): Observable<ApplicationFeeRuleModel> => {
    let parameters: string[];
    parameters = [applicationFeeRuleModel.id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .put<ApplicationFeeRuleModel>(url, applicationFeeRuleModel, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  delete = (id: number): Observable<boolean> => {
    let parameters: string[];
    parameters = [id.toString()];
    let url = this.formatUrl(parameters);

    return this.httpClient
      .delete<boolean>(url, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.delete),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  formatUrl = (parameters: string[]): string => {
    var url = environment.comparanooapiurl;
    url += environment.features.applicationFeeRule.url;
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}
