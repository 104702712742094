import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ErrorService } from '../../../error';
import {
  CommissionModel,
  CommissionPreviewModel,
  CompanyCommissionModel,
  SalesPersonCommissionModel,
} from './types';
import { catchError } from 'rxjs/operators';
import { CrudType } from '../../../service/message/types';
import { MessageService } from '../../../service/message/message.service';
import { AccountMoveModel } from '../../accounting/types';

@Injectable({
  providedIn: 'root',
})
export class CommissionApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService,
    private readonly messageService: MessageService
  ) {}

  getById = (id: number): Observable<CommissionModel> => {
    let parameters: string[];
    parameters = [id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<CommissionModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getCommissionsPreview = (): Observable<CommissionPreviewModel[]> => {
    let parameters: string[];
    parameters = ['preview'];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<CommissionPreviewModel[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  add = ({
    commissionPreviewModels,
    isAllSelected,
  }: {
    commissionPreviewModels: CommissionPreviewModel[];
    isAllSelected: boolean;
  }): Observable<CommissionPreviewModel[]> => {
    let parameters: string[];
    parameters = ['commissions'];
    let url = this.formatUrl(parameters);

    return this.httpClient
      .put<CommissionPreviewModel[]>(url, commissionPreviewModels, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  getCompanyCommissions = ({
    startDate,
    endDate,
  }: {
    startDate: Date;
    endDate: Date;
  }): Observable<CompanyCommissionModel[]> => {
    let parameters: string[];
    let stringStartDate =
      startDate.getMonth() +
      1 +
      '-' +
      startDate.getDate() +
      '-' +
      startDate.getFullYear();
    let stringEndDate =
      endDate.getMonth() +
      1 +
      '-' +
      endDate.getDate() +
      '-' +
      endDate.getFullYear();
    parameters = ['companies', stringStartDate, stringEndDate];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<CompanyCommissionModel[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  addSalesPersonCommissionPayment = ({
    salesPersonCommissionModels,
    isAllSelected,
  }: {
    salesPersonCommissionModels: SalesPersonCommissionModel[];
    isAllSelected: boolean;
  }): Observable<AccountMoveModel> => {
    let parameters: string[];
    parameters = ['commissions', 'salesperson', 'payment'];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .post<AccountMoveModel>(url, salesPersonCommissionModels, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  formatUrl = (parameters: string[]): string => {
    var url = environment.comparanooapiurl;
    url += environment.features.commission.url;
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}
