import { Field, Filter } from '../search/types';
import { ButtonType } from '../subheader/type';

export const viewsFeatureKey = 'views';

export enum Entity {
  Lead = 'Lead',
  ContractRequest = 'ContractRequest',
  Contract = 'Contract',
  Article = 'Article',
  Glossary = 'Glossary',
  ZipCode = 'ZipCode',
  County = 'County',
  User = 'User',
  Country = 'Country',
  Currency = 'Currency',
  Job = 'Job',
  Home = 'Home',
  SalesTeam = 'SalesTeam',
  Campaign = 'Campaign',
  SalesDashboard = 'SalesDashboard',
  HumanResourcesDashboard = 'HumanResourcesDashboard',
  Employee = 'Employee',
  Account = 'Account',
  Contact = 'Contact',
  Product = 'Product',
  ProductLine = 'ProductLine',
  InsuranceCompany = 'InsuranceCompany',
  CommissionPlan = 'CommissionPlan',
  Document = 'Document',
  InsuranceDocument = 'InsuranceDocument',
  ProductCategory = 'ProductCategory',
  Title = 'Title',
  Bank = 'Bank',
  BankAccount = 'BankAccount',
  LeadLostReason = 'LeadLostReason',
  TerminationReason = 'TerminationReason',
  ProductComparator = 'ProductComparator',
  Activity = 'Activity',
  ActivityType = 'ActivityType',
  LeadPick = 'LeadPick',
  SalesTeamMembers = 'SalesTeamMembers',
  InsuranceClaim = 'InsuranceClaim',
  InsuranceOverview = 'InsuranceOverview',
  PaymentClient = 'PaymentClient',
  PaymentInternal = 'PaymentInternal',
  PaymentMode = 'PaymentMode',
  Commission = 'Commission',
  SalesPersonCommission = 'SalesPersonCommission',
  CommissionPreview = 'CommissionPreview',
  SalesPersonCommissionPreview = 'SalesPersonCommissionPreview',
  SalesPersonCommissionPayment = 'SalesPersonCommissionPayment',
  Invoice = 'Invoice',
  DocumentType = 'DocumentType',
  CarReferenceBrand = 'CarReferenceBrand',
  CarReferenceModel = 'CarReferenceModel',
  CarReferenceExactModel = 'CarReferenceExactModel',
  CommissionPlanRuleCompany = 'CommissionPlanRuleCompany',
  CommissionPlanRuleSalesPerson = 'CommissionPlanRuleSalesPerson',
  ApplicationFeeRule = 'ApplicationFeeRule',
  CashJournal = 'CashJournal',
  ClientAccountMove = 'ClientAccountMove',
  InternalAccountMove = 'InternalAccountMove',
  CommissionPlanRuleSharedSalesPerson = 'CommissionPlanRuleSharedSalesPerson',
  CompanyCommission = 'CompanyCommission',
}

export type View = {
  name: string;
  entity: string;
  title: string;
  views: string;
  breadcrumbCreate: string;
  searchView: SearchView;
  treeView: TreeView;
  kanbanView: KanbanView;
  formView: FormView;
  fields: Field[];
  actions: ActionsView[];
  tabViews: TabView[];
};
export type SearchView = {
  name: string;
  url: string;
  inputPlaceholder: string;
  autoCompleteFields: Field[];
  builtInFilters: Filter[];
  permanentFilters: Filter[];
  views: string[];
};
export type ActionsView = {
  name: string;
  listOfActions: Action[];
};
export type Action = {
  name: string;
  title: string;
  cssClass: string;
  mode: ActionMode;
  route: string;
  buttonType: ButtonType;
  actionName: string;
  statusTarget: number;
  idProperty: string;
};

export type TreeView = {
  columns: Column[];
  defaultSort: string;
  orderBy: string;
  actions: Action[];
};

export type KanbanView = {
  columns: Column[];
  states: string[];
  cardTemplate: string;
};

export type FormView = {
  customActions: Action[];
};

export type GridView = {
  columns: Column[];
};

export type TabView = {
  name: string;
  model: string;
  treeView: TreeView;
};

export type Column = {
  name: string;
  title: string;
  type: string;
  sort: string;
  sortable: boolean;
  color: string;
  width: number;
};

export type CardsView = {};
export type ChartsView = {};
export type TimeLineView = {};

export enum ActionMode {
  Redirect = 'Redirect',
  ApiCall = 'ApiCall',
  Export = 'Export',
  Navigate = 'Navigate',
  Action = 'Action',
}

export class Path {
  module: HeaderMenuType;
  entity: Entity;
}

export enum ColumnType {
  LeadCategoryIcon = 'leadTypeCellRenderer',
  Date = 'date',
}

export type EnumModel = {
  id: number;
  name: string;
  nameLoc: string;
};

export type SmartAction = {
  id: string;
  filter: Filter;
  url: string;
  label: string;
  count: number;
  icon: string;
  actif: boolean;
  singleId: string;
};

export type ViewType = 'TreeView' | 'KanbanView';

export enum HeaderMenuType {
  Home = 1,
  Users = 2,
  Content = 3,
  Configuration = 4,
  Sales = 5,
  HumanResources = 6,
  Account = 7,
  Contacts = 8,
  Products = 9,
  Documents = 10,
  Insurance,
  Finance,
}

export type ActionType =
  | ''
  | 'openDetail'
  | 'openBlankDetail'
  | 'openExternal'
  | 'openExternalBlank';

export type NavigationObject = {
  left: DataAction;
  middle: DataAction;
};

export type DataAction =
  | {
      url: string;
      idProperty: string;
      type: ActionType;
    }
  | null
  | undefined;
