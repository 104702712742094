import { formActions } from './form.actions';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { ErrorsNestedFormGroup } from './types';
import { notificationActions } from '../notification/notification.action';
import { INotificationState } from '../notification/notification.reducer';

export interface IFormState {
  countErrors: ErrorsNestedFormGroup;
  name: string;
  isDirty: boolean;
  entityType: string;
}

export const formInitialState: IFormState = {
  countErrors: {
    root: { count: 0, countTotal: 0, fieldName: [], groupName: '' },
    children: [],
  },
  name: '',
  isDirty: false,
  entityType: '',
};

const formReducers = [
  on(formActions.setIsDirty, (state: IFormState, { formName, isDirty }) => ({
    ...state,
    isDirty: isDirty,
  })),
  on(formActions.setErrorsEntity, (state: IFormState, { entityType }) => ({
    ...state,
    entityType: entityType,
  })),
  on(formActions.clear, (state: IFormState) => ({
    ...state,
    name: '',
    isDirty: false,
    entityType: '',
  })),
  on(
    formActions.countErrors,
    (state: IFormState, countErrors: ErrorsNestedFormGroup) => ({
      ...state,
      countErrors: countErrors,
    }),
  ),
  on(formActions.createErrors, (state: IFormState, { message }) => ({
    ...state,
    countErrors: <ErrorsNestedFormGroup>{
      root: { count: 1, countTotal: 1, groupName: '', fieldName: [message] },
      children: [],
    },
  })),
];

export const formReducer = createReducer(
  formInitialState,
  ...formReducers,
) as ActionReducer<IFormState>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return formReducer(state, action);
}
