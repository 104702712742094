import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../../common/store/generic.selectors';
import {
  CommissionPlanRuleModel,
  commissionPlanRuleFeatureKey,
  CommissionType,
} from './types';
import { ICommissionPlanRuleState } from './commission-plan-rule.reducer';

const commissionPlanRuleFeatureState =
  createFeatureSelector<ICommissionPlanRuleState>(commissionPlanRuleFeatureKey);

export const commissionPlanRuleSelectors = {
  getById: createApiCallSelectors<number, ICommissionPlanRuleState>(
    commissionPlanRuleFeatureState,
    'getById',
  ),
  getByProductLineId: createApiCallSelectors<number, ICommissionPlanRuleState>(
    commissionPlanRuleFeatureState,
    'getByProductLineId',
  ),
  getSharedBySalesPersonIdAndCommissionType: createApiCallSelectors<
    number,
    ICommissionPlanRuleState
  >(
    commissionPlanRuleFeatureState,
    'getSharedBySalesPersonIdAndCommissionType',
  ),
  createInstance: createApiCallSelectors<
    CommissionType,
    ICommissionPlanRuleState
  >(commissionPlanRuleFeatureState, 'createInstance'),
  add: createApiCallSelectors<
    CommissionPlanRuleModel,
    ICommissionPlanRuleState
  >(commissionPlanRuleFeatureState, 'add'),
  update: createApiCallSelectors<
    CommissionPlanRuleModel,
    ICommissionPlanRuleState
  >(commissionPlanRuleFeatureState, 'update'),
  delete: createApiCallSelectors<boolean, ICommissionPlanRuleState>(
    commissionPlanRuleFeatureState,
    'delete',
  ),
};
