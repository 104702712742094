import { Component, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { filter, Observable } from 'rxjs';
import { EmployeeModel } from '../../../../../../shared/store/employee/types';
import { EmployeeService } from '../../../../../../shared/store/employee/employee.service';
import { take } from 'rxjs/operators';
import { Entity } from '../../../../../../shared/store/view/types';
import {
  DataType,
  DropdownStyle,
} from '../../../../../../shared/store/typeahead/types';
import { formatDate } from '../../../../../../shared/helper/datehelper';
import { ZipCodeService } from '../../../../../../shared/store/zipcode/zipcode.service';
import {
  InputCodeAndCityZipCode,
  ZipCodeModel,
} from '../../../../../../shared/store/zipcode/types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-employee-form-private-informations',
  templateUrl: './employee-form-private-informations.component.html',
  styleUrls: ['./employee-form-private-informations.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class EmployeeFormPrivateInformationsComponent implements OnInit {
  form: FormGroup;
  employeeDetail$: Observable<EmployeeModel>;
  employeeModel$: Observable<EmployeeModel>;
  zipCodeModel$: Observable<ZipCodeModel>;

  protected readonly Entity = Entity;
  protected readonly DropdownStyle = DropdownStyle;
  protected readonly DataType = DataType;

  customPatterns = {
    0: {
      pattern: new RegExp(/[0-9]/),
    },
    C: {
      pattern: new RegExp('[a-zA-Z0-9]'),
    },
    S: {
      pattern: new RegExp('[a-zA-Z]'),
    },
  };

  constructor(
    private readonly employeeService: EmployeeService,
    private parent: FormGroupDirective,
    private formBuilder: FormBuilder,
    private readonly zipCodeService: ZipCodeService,
  ) {
    this.employeeDetail$ = this.employeeService.getById.value$;
    this.employeeModel$ = this.employeeService.getEmployeeModel.value$;
    this.zipCodeModel$ = this.zipCodeService.getByCodeAndCity.value$;
  }

  ngOnInit(): void {
    this.form = this.parent.form;
    this.form.addControl(
      'privateInformationsFormGroup',
      this.formBuilder.group({
        privateInformationsHomeAddressId: [undefined],
        privateInformationsPassportNumber: [''],
        privateInformationsSsnNumber: [''],
        privateInformationsPrivatePhoneNumber: [''],
        privateInformationsPrivateEmail: [''],
        privateInformationsHomeAddress: [''],
        privateInformationsHomeAddress2: [''],
        privateInformationsHomeZipCode: [''],
        privateInformationsHomeCity: [undefined],
        privateInformationsHomeCountryId: [undefined],
        privateInformationsGender: [undefined],
        privateInformationsMaritalStatus: [undefined],
        privateInformationsNumberOfChildren: [0],
        privateInformationsBirthDate: [],
        privateInformationsPlaceOfBirth: [''],
        privateInformationsId: [''],
        privateInformationsIBAN: [''],
        privateInformationsBIC: [''],
        privateInformationsCountryIdNationality: [undefined],
      }),
    );

    this.employeeModel$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.form.patchValue({
          privateInformationsFormGroup: {
            privateInformationsPassportNumber: x.passportNumber,
            privateInformationsSsnNumber: x.ssnNumber,
            privateInformationsPrivatePhoneNumber: x.privatePhoneNumber,
            privateInformationsPrivateEmail: x.privateEmail,
            privateInformationsHomeAddressId: x.homeAddress.id,
            privateInformationsHomeAddress: x.homeAddress.address,
            privateInformationsHomeAddress2: x.homeAddress.address2,
            privateInformationsHomeZipCode: x.homeAddress.zipCode,
            privateInformationsHomeCity: x.homeAddress.city,
            privateInformationsHomeCountryId: x.homeAddress.countryId,
            privateInformationsGender: x.gender,
            privateInformationsMaritalStatus: x.maritalStatus,
            privateInformationsNumberOfChildren: x.children,
            privateInformationsBirthDate: x.birthDate
              ? formatDate(x.birthDate, 'ToInverseSqlDate')
              : undefined,
            privateInformationsPlaceOfBirth: x.placeOfBirth,
            privateInformationsId: x.bankAccount?.id,
            privateInformationsIBAN: x.bankAccount?.iban,
            privateInformationsBIC: x.bankAccount?.bic,
            privateInformationsCountryIdNationality: x.nationalityId,
          },
        });
      });

    this.employeeDetail$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.form.patchValue({
          privateInformationsFormGroup: {
            privateInformationsPassportNumber: x.passportNumber,
            privateInformationsSsnNumber: x.ssnNumber,
            privateInformationsPrivatePhoneNumber: x.privatePhoneNumber,
            privateInformationsPrivateEmail: x.privateEmail,
            privateInformationsHomeAddressId: x.homeAddress.id,
            privateInformationsHomeAddress: x.homeAddress.address,
            privateInformationsHomeAddress2: x.homeAddress.address2,
            privateInformationsHomeZipCode: x.homeAddress.zipCode,
            privateInformationsHomeCity: x.homeAddress.city,
            privateInformationsHomeCountryId: x.homeAddress.countryId,
            privateInformationsGender: x.gender,
            privateInformationsMaritalStatus: x.maritalStatus,
            privateInformationsNumberOfChildren: x.children,
            privateInformationsBirthDate: x.birthDate
              ? formatDate(x.birthDate, 'ToInverseSqlDate')
              : undefined,
            privateInformationsPlaceOfBirth: x.placeOfBirth,
            privateInformationsId: x.bankAccount?.id,
            privateInformationsIBAN: x.bankAccount?.iban,
            privateInformationsBIC: x.bankAccount?.bic,
            privateInformationsCountryIdNationality: x.nationalityId,
          },
        });
      });
    this.form.controls['privateInformationsFormGroup']
      .get('privateInformationsHomeCity')
      ?.valueChanges.subscribe((city) => {
        if (city) {
          this.zipCodeService.getByCodeAndCity.call(<InputCodeAndCityZipCode>{
            code: this.form.controls['privateInformationsFormGroup'].get(
              'privateInformationsHomeZipCode',
            )?.value,
            city: city,
          });
        }
        this.form.patchValue({
          privateInformationsFormGroup: {
            privateInformationsHomeCountryId: undefined,
          },
        });
      });

    this.zipCodeModel$
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((x) => {
        this.form.patchValue({
          privateInformationsFormGroup: {
            privateInformationsHomeCountryId: x.countryId,
          },
        });
      });
  }
}
