import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommentService } from '../../../store/comment/comment.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../store/user/user.service';
import { Avatar, User } from '../../../store/user/type';
import { environment } from '../../../../../environments/environment';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { filterTrue } from '../../../pipe/rxjs/operators';

@Component({
  selector: 'app-comment-add',
  templateUrl: './comment-add.component.html',
  styleUrls: ['./comment-add.component.scss'],
})
export class CommentAddComponent implements OnInit {
  @Input() entityId: string;
  @Input() entityType: string;
  @Output() newComment = new EventEmitter<boolean>();

  isVisible: boolean = true;
  user: User;
  currentAvatar$: Observable<Avatar>;

  constructor(
    private readonly commentService: CommentService,
    private readonly userService: UserService,
  ) {}

  commentForm = new FormGroup({
    message: new FormControl('', [Validators.required]),
  });

  ngOnInit() {
    const bgColor = window
      .getComputedStyle(document.body)
      .getPropertyValue('--comment-avatar-bg')
      .replace('#', '');

    this.currentAvatar$ = this.userService.getCurrentUser.value$.pipe(
      filterTrue(),
      map((user) => {
        this.user = user;

        let url: string = '';
        if (!user.contactPicturePath) {
          url = `${
            environment.comparanooapiurl +
            environment.features.thumbnail.url +
            environment.features.thumbnail.avatar
          }?name=${user.contactName}&bgColor=${bgColor}&color=FFFFFF`;
        } else {
          url = environment.comparanooapiurl + user.contactPicturePath;
        }

        return new Avatar(user.contactName, url, '');
      }),
    );
  }

  onSubmit() {
    if (this.commentForm.invalid) return;

    let result$ = this.commentService.postComment.call({
      message: this.commentForm.value.message!,
      parentId: null,
      entityType: this.entityType,
      entityId: +this.entityId,
      userId: +this.user.id,

      //Others
      id: 0,
      creationDate: null,
      localeCreationDate: null,
      userContactName: '',
      userContactPicturePath: '',
    });

    result$.pipe(filterTrue(), take(1)).subscribe((result) => {
      this.newComment.emit(true);
      this.commentForm.reset();
      // this.isVisible = false;
    });
  }

  cancelNewComment(): void {
    // this.invertIsVisible();
    this.commentForm.reset();
  }

  invertIsVisible(): void {
    this.isVisible = !this.isVisible;
  }
}
