import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onApiCall } from '../../../common/store/generic.reducer';
import { GenericState, IState } from '../../../common/store/types';
import { accountingActions } from './accounting.actions';
import {
  AccountModel,
  AccountMoveModel,
  AccountMovePeriodModel,
  CashJournalEntry,
  CashJournalModel,
  JournalAssignRecord,
  SlipResponseModel,
} from './types';

export interface IAccountingState extends IState<AccountMoveModel> {
  getById: GenericState<AccountMoveModel>;
  getByContactId: GenericState<AccountMoveModel[]>;
  getByJournalId: GenericState<AccountMoveModel[]>;
  getUnbindedByContactId: GenericState<AccountMoveModel[]>;
  getUnbindedByContractId: GenericState<AccountMoveModel[]>;
  getUnbindedRepayment: GenericState<AccountMoveModel[]>;
  getUnbindedSlip: GenericState<AccountMoveModel[]>;
  createInstance: GenericState<AccountMoveModel>;
  add: GenericState<AccountMoveModel>;
  bind: GenericState<boolean>;
  getPeriods: GenericState<AccountMovePeriodModel[]>;
  importSlip: GenericState<AccountMoveModel>;
  changeAccountMoveStatus: GenericState<AccountMoveModel>;
  uploadProof: GenericState<boolean>;
  getAccountMovePdf: GenericState<any>;
  getBindedAccountMoveByAccountMoveId: GenericState<AccountMoveModel>;
  delete: GenericState<boolean>;
  unbind: GenericState<boolean>;
  getAccountByContactId: GenericState<AccountModel>;
  getAccountById: GenericState<AccountModel>;
  getCashJournal: GenericState<CashJournalModel>;
}

export const accountingInitialState: IAccountingState = {
  getById: new GenericState<AccountMoveModel>(),
  getByContactId: new GenericState<AccountMoveModel[]>(),
  getByJournalId: new GenericState<AccountMoveModel[]>(),
  getUnbindedByContactId: new GenericState<AccountMoveModel[]>(),
  getUnbindedByContractId: new GenericState<AccountMoveModel[]>(),
  getUnbindedRepayment: new GenericState<AccountMoveModel[]>(),
  getUnbindedSlip: new GenericState<AccountMoveModel[]>(),
  createInstance: new GenericState<AccountMoveModel>(),
  add: new GenericState<AccountMoveModel>(),
  bind: new GenericState<boolean>(),
  getPeriods: new GenericState<AccountMovePeriodModel[]>(),
  importSlip: new GenericState<AccountMoveModel>(),
  changeAccountMoveStatus: new GenericState<AccountMoveModel>(),
  uploadProof: new GenericState<boolean>(),
  getAccountMovePdf: new GenericState<any>(),
  getBindedAccountMoveByAccountMoveId: new GenericState<AccountMoveModel>(),
  delete: new GenericState<boolean>(),
  unbind: new GenericState<boolean>(),
  getAccountByContactId: new GenericState<AccountModel>(),
  getAccountById: new GenericState<AccountModel>(),
  getCashJournal: new GenericState<CashJournalModel>(),
};

const accountingReducers = [
  ...onApiCall<AccountMoveModel>(accountingActions.getById, 'getById'),
  ...onApiCall<AccountMoveModel[]>(
    accountingActions.getByContactId,
    'getByContactId',
  ),
  ...onApiCall<AccountMoveModel[]>(
    accountingActions.getByJournalId,
    'getByJournalId',
  ),
  ...onApiCall<AccountMoveModel[]>(
    accountingActions.getUnbindedByContactId,
    'getUnbindedByContactId',
  ),
  ...onApiCall<AccountMoveModel[]>(
    accountingActions.getUnbindedByContractId,
    'getUnbindedByContractId',
  ),
  ...onApiCall<AccountMoveModel[]>(
    accountingActions.getUnbindedRepayment,
    'getUnbindedRepayment',
  ),
  ...onApiCall<JournalAssignRecord[]>(
    accountingActions.getUnbindedSlip,
    'getUnbindedSlip',
  ),
  ...onApiCall<AccountMoveModel>(
    accountingActions.createInstance,
    'createInstance',
  ),
  ...onApiCall<AccountMoveModel>(accountingActions.add, 'add'),
  ...onApiCall<boolean>(accountingActions.bind, 'bind'),
  ...onApiCall<AccountMovePeriodModel[]>(
    accountingActions.getPeriods,
    'getPeriods',
  ),
  ...onApiCall<SlipResponseModel[]>(accountingActions.importSlip, 'importSlip'),
  ...onApiCall<AccountMoveModel>(
    accountingActions.changeAccountMoveStatus,
    'changeAccountMoveStatus',
  ),
  ...onApiCall<boolean>(accountingActions.uploadProof, 'uploadProof'),
  ...onApiCall<any>(accountingActions.getAccountMovePdf, 'getAccountMovePdf'),
  ...onApiCall<any>(
    accountingActions.getBindedAccountMoveByAccountMoveId,
    'getBindedAccountMoveByAccountMoveId',
  ),
  ...onApiCall<boolean>(accountingActions.delete, 'delete'),
  ...onApiCall<boolean>(accountingActions.unbind, 'unbind'),
  ...onApiCall<AccountModel>(
    accountingActions.getAccountByContactId,
    'getAccountByContactId',
  ),
  ...onApiCall<AccountModel>(
    accountingActions.getAccountById,
    'getAccountById',
  ),
  ...onApiCall<CashJournalModel>(
    accountingActions.getCashJournal,
    'getCashJournal',
  ),
];

export const accountingReducer = createReducer(
  accountingInitialState,
  ...accountingReducers,
) as ActionReducer<IAccountingState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return accountingReducer(state, action);
}
