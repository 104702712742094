import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { distinctUntilChanged, filter, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'xng-breadcrumb';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { AccountModel } from '../../store/accounting/types';
import { View } from '../../store/view/types';
import { AccountService } from '../../store/account/account.service';
import {
  DataType,
  DropdownStyle,
  SourceEnum,
} from '../../store/typeahead/types';
import { SubHeaderService } from '../../store/subheader/subheader.service';
import { ViewService } from '../../store/view/views.service';
import { FormService } from '../../store/form/form.service';
import { ModalService } from '../../service/modal.service';

@UntilDestroy()
@Component({
  selector: 'app-account-form-content',
  templateUrl: './account-form-content.component.html',
  styleUrl: './account-form-content.component.scss',
})
export class AccountFormContentComponent implements OnInit, OnDestroy {
  @Input() modalRandomIdentifier: number;
  @Input() source: SourceEnum = SourceEnum.Component;
  @Input() modalName: string = '';

  view$: Observable<View>;
  accountForm: FormGroup;

  accountModel$: Observable<AccountModel>;
  createAccountInstance$: Observable<AccountModel>;
  saveAccountInstance: AccountModel;

  isEditMode: boolean = false;
  accountId: string;

  protected readonly DataType = DataType;
  protected readonly DropdownStyle = DropdownStyle;
  protected readonly SourceEnum = SourceEnum;

  constructor(
    private readonly accountService: AccountService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder,
    private readonly dynamicService: ModalService,
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.accountModel$ = this.accountService.getById.value$;
    this.createAccountInstance$ = this.accountService.createInstance.value$;
  }

  ngOnInit(): void {
    //Déclaration du formulaire
    this.accountForm = this.formBuilder.group({
      accountId: [undefined],
      name: ['', [Validators.required]],
      contactId: [undefined],
      accountType: [undefined, [Validators.required]],
      canHandleCash: [true, [Validators.required]],
      active: [true, [Validators.required]],
      code: [undefined],
    });

    //Récupération de l'id via la route pour déterminer si mode Edit (true) ou Create (false)
    this.accountId = this.route.snapshot.params['id'];
    this.isEditMode = !!this.accountId;

    //On appelle les API en fonction du mode
    if (this.isEditMode) {
      this.accountForm.patchValue({
        accountId: this.accountId,
      });
      // this.accountService.getById.call(this.accountId);
    } else {
      this.accountService.createInstance.call('Bank');
    }

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'accountCreate' || x == 'accountUpdate') this.onSubmit();
        if (x == 'accountList') {
          this.formService.clear();
          this.router.navigate(['Finance/Accounts/']);
        }
        // if (x == 'accountDetails') {
        //   this.formService.clear();
        //   this.router.navigate([
        //     '/Finance/Accounts/Details',
        //     this.accountId,
        //   ]);
        // }
        this.subHeaderService.setTriggerAction('');
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);

        if (!this.isEditMode)
          this.breadcrumbService.set('@accountCreate', x.breadcrumbCreate);
      });

    this.accountModel$
      .pipe(
        // filter((x) => this.isEditMode),
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@applicationFeeRuleEdit', x.name);
        this.patchForm(x);
      });

    this.createAccountInstance$
      .pipe(
        filter((x) => !this.isEditMode),
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((x) => {
        this.saveAccountInstance = x;
        this.accountForm.patchValue({
          name: this.modalName ? this.modalName : x.name,
          contactId: x.contactId,
          accountType: x.accountType,
          canHandleCash: x.canHandleCash,
          code: x.code,
        });
      });

    //When the productLineId change, we call the existing application fee rules
    this.accountForm
      .get('accountId')
      ?.valueChanges?.pipe(distinctUntilChanged(), untilDestroyed(this))
      ?.subscribe((x) => {
        if (!!x) {
          this.accountService.getById.call(x.toString());
          this.isEditMode = true;
          // this.accountService.getByProductLineId.call(x.toString());
        } else {
          this.accountForm.reset();
          this.patchForm(this.saveAccountInstance);
          this.isEditMode = false;
          // this.accountService.getByProductLineId.reset();
        }
      });
  }

  onSubmit() {
    if (this.accountForm.invalid) {
      this.accountForm.markAllAsTouched();
      this.formService.setEntityErrors('ACCOUNT');
      this.formService.countErrors(this.accountForm, true);
      return;
    }

    const accountModel: AccountModel = {
      id: this.accountForm.value.accountId
        ? +this.accountForm.value.accountId
        : 0,
      name: this.accountForm.value.name!,
      accountType: this.accountForm.value.accountType!,
      active: this.accountForm.value.active!,
      canHandleCash: this.accountForm.value.canHandleCash!,
      code: this.accountForm.value.code!,
      contactId: this.accountForm.value.contactId!,

      //Others
      contactName: '',
      accountTypeLoc: '',
    };

    let result$: Observable<AccountModel>;
    if (this.isEditMode) {
      result$ = this.accountService.update.call(accountModel);
    } else {
      result$ = this.accountService.add.call(accountModel);
    }

    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result) => {
        this.formService.clear();
        switch (this.source) {
          case SourceEnum.Component:
            this.router.navigate(['/Finance/Accounts']);
            break;
          case SourceEnum.Modal:
            console.log(result.id.toString());
            this.dynamicService.outputFromDynamicComponent(
              this.modalRandomIdentifier,
              result.id.toString(),
            );
            break;
          default:
            this.router.navigate(['/Finance/Accounts']);
            break;
        }
      });
  }

  ngOnDestroy(): void {
    this.accountService.add.reset();
    this.accountService.update.reset();

    if (this.isEditMode) {
      this.accountService.getById.reset();
    } else {
      this.accountService.createInstance.reset();
    }

    this.breadcrumbService.set('@accountEdit', ' ');
    this.breadcrumbService.set('@accountCreate', ' ');
  }

  patchForm = (accountModel: AccountModel) => {
    this.accountForm.patchValue({
      name: accountModel.name,
      contactId: accountModel.contactId,
      accountType: accountModel.accountType,
      canHandleCash: accountModel.canHandleCash,
      code: accountModel.code,
    });
  };
}
