<app-document-subheader></app-document-subheader>
<div class="page-container">
  <div class="card card-light w-100">
    <div class="card-body">
      <div class="container-fluid">
        <app-commission-plan-rule-shared-sales-person-form-content />
      </div>
    </div>
  </div>
</div>
