import { Component, Input, OnInit, Type } from '@angular/core';
import { ButtonType } from '../../store/subheader/type';
import { SubHeaderService } from '../../store/subheader/subheader.service';
import { SourceEnum } from '../../store/typeahead/types';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '../../service/modal.service';
import { take, tap } from 'rxjs/operators';
import { debugLog, filterTrue } from '../../pipe/rxjs/operators';
import { ActionButton } from './action-button';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';
import { getRandomInt } from '../../helper/random.helper';

@UntilDestroy()
@Component({
  selector: 'app-modal-content',
  templateUrl: './modal-content.component.html',
  styleUrls: ['./modal-content.component.scss'],
})
export class ModalContentComponent implements OnInit {
  protected readonly ButtonType = ButtonType;

  @Input() component: Type<any>;
  // @Input() modalParam: string | number;
  isCreate: boolean = false;
  sourceEnum: SourceEnum = SourceEnum.Modal;
  inputs: Record<string, unknown> | undefined = undefined;
  @Input() inputParams: Record<string, unknown> | undefined = undefined;

  @Input() title: string;

  @Input() closeCrossButton: boolean = true;
  @Input() cancelButton: boolean = false;

  // @Input() saveMainActionName: string;
  // @Input() labelMainButton: string = 'GLOBAL_BTN_SUBMIT';

  @Input() buttons: ActionButton[] = [];

  modalIds: number[] = [];

  // @Input() showMainButtons: boolean = false;
  // @Input() secondaryButtons: ActionButton[] = [];
  // @Input() saveSecondaryActionName: string;
  // @Input() labelSecondaryButton: string = '';
  // @Input() showSecondaryButton: boolean = true;

  constructor(
    private readonly subHeaderService: SubHeaderService,
    protected readonly activeModalService: NgbActiveModal,
    private readonly dynamicService: ModalService,
  ) {}

  ngOnInit(): void {
    // this.isCreate =
    //   this.component.name.toLowerCase().indexOf('create') >= 0 ||
    //   this.component.name.toLowerCase().indexOf('manage') >= 0;

    // if (!this.isCreate && !this.inputParams) {
    //   this.inputs = { modalId: +this.modalParam };
    // }
    // if (this.isCreate) {
    //   this.inputs = {
    //     modalName: this.modalParam.toString(),
    //     source: this.sourceEnum,
    //   };
    // }
    if (this.inputParams) {
      const randomInt = getRandomInt(1, 1500);
      this.modalIds.push(randomInt);
      this.inputParams['source'] = SourceEnum.Modal;
      this.inputParams['modalRandomIdentifier'] = randomInt;
      this.inputs = this.inputParams;
    }

    this.dynamicService
      .getObservable()
      .pipe(
        filterTrue(),
        untilDestroyed(this),
        filter((data) => this.modalIds.some((x) => x == data.id)),
      )
      .subscribe((data) => {
        if (Number(data.data) >= 0) this.activeModalService.close(+data.data);

        //remove from array
        const index = this.modalIds.indexOf(data.id);
        this.modalIds.splice(index, 1);
      });
  }

  triggerAction(actionName: string) {
    this.subHeaderService.setTriggerAction(actionName);
  }
}
