import { Injectable, OnInit } from '@angular/core';
import { SubHeaderService } from '../../store/subheader/subheader.service';
import { CommissionService } from '../../store/commissions/commission/commission.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SearchService } from '../../store/search/search.service';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class RowSelectionService {
  constructor(
    private readonly subHeaderService: SubHeaderService,
    private readonly commissionService: CommissionService,
    private readonly searchService: SearchService,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {
    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'createSalesPersonCommissionAccountMoves')
          this.createSalesPersonCommissionAccountMoves();
        if (x == 'createPaymentSalesPersonCommission')
          this.createPaymentSalesPersonCommission();
        this.subHeaderService.setTriggerAction('');
      });
  }

  private createSalesPersonCommissionAccountMoves() {
    this.searchService
      .getSelectedRows()
      .pipe(take(1))
      .subscribe((selectedRows) => {
        if (selectedRows.data.length == 0) return;
        this.commissionService.add.call({
          commissionPreviewModels: selectedRows.data,
          isAllSelected: selectedRows.isAllSelected,
        });
        this.router.navigate([
          '/Finance/SalesPersonCommissions/AccountMoves/List',
        ]);
      });
  }

  private createPaymentSalesPersonCommission() {
    this.searchService
      .getSelectedRows()
      .pipe(take(1))
      .subscribe((selectedRows) => {
        if (selectedRows.data.length == 0) return;
        this.commissionService.addSalesPersonCommissionPayment.call({
          salesPersonCommissionModels: selectedRows.data,
          isAllSelected: selectedRows.isAllSelected,
        });
        this.router.navigate(['/Finance/SalesPersonCommissions/Payments/List']);
      });
  }
}
