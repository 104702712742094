import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  InsuranceCategory,
  InsuredType,
} from '../../../../shared/store/lead/types';
import { filter, Observable } from 'rxjs';
import { Entity, View } from '../../../../shared/store/view/types';
import {
  CarInsuranceContractDisasterDetailFormModel,
  CarInsuranceContractUpdateModel,
  ContractCarUpdateModel,
  ContractHealthUpdateModel,
  ContractHouseUpdateModel,
  ContractModel,
  ContractStatus,
  ContractUpdateBaseModel,
  HealthInsuranceContractDetailFormModel,
  HouseInsuranceContractFormModel,
} from '../../../../shared/store/contract/types';
import { ViewService } from '../../../../shared/store/view/views.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractService } from '../../../../shared/store/contract/contract.service';
import { DropdownStyle } from 'src/app/shared/store/typeahead/types';
import { ContactModel } from '../../../../shared/store/contact/types';
import { ContactService } from '../../../../shared/store/contact/contact.service';
import { take } from 'rxjs/operators';
import { formatDate } from '../../../../shared/helper/datehelper';
import { FormService } from '../../../../shared/store/form/form.service';
import { BankAccountModel } from '../../../../shared/store/bank-account/types';

@UntilDestroy()
@Component({
  selector: 'app-contract-edit',
  templateUrl: './contract-edit.component.html',
  styleUrls: ['./contract-edit.component.scss'],
})
export class ContractEditComponent implements OnInit, OnDestroy {
  contractForm: FormGroup;
  contractId: string;
  contractIdNumber: number;
  contractType: InsuranceCategory;
  view$: Observable<View>;
  contract$: Observable<ContractModel>;
  companyDomain: string;
  productLineDomain: string;
  productDomain: string;

  protected readonly Entity = Entity;
  protected readonly DropdownStyle = DropdownStyle;
  active = 1;
  category: number;
  fragment: string;

  constructor(
    private readonly viewService: ViewService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly subHeaderService: SubHeaderService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly contractService: ContractService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder,
    private readonly contactService: ContactService,
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.contract$ = this.contractService.getByUniqueId.value$;
  }

  ngOnInit(): void {
    this.contractService.getByUniqueId.reset();
    this.contractService.getHealthDetail.reset();
    this.contractService.getCarDetail.reset();
    this.contractService.getHouseDetail.reset();

    this.contractId = this.route.snapshot.params['id'];
    this.contractService.getByUniqueId.call(this.contractId);
    this.fragment = this.route.snapshot.fragment!;

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'contractUpdate') this.onSubmit();
        if (x == 'contractDetails') {
          this.formService.clear();
          this.router.navigate([
            '/Crm/ContractRequests/Details',
            this.contractId,
          ]);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.contractForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      clientId: [undefined, [Validators.required]],
      salesTeam: [undefined],
      salesTeamMember: [undefined],
      category: [''],
      categoryLoc: [''],
      categoryColor: [''],
      productCategoryId: [undefined, [Validators.required]],
      companyKey: [undefined],
      companyId: [undefined, [Validators.required]],
      productLineKey: [undefined],
      productLineId: [undefined],
      productId: [undefined, [Validators.required]],
      effectiveDate: ['', [Validators.required]],
      subscriptionDate: ['', [Validators.required]],
      statusId: [0, [Validators.required]],
      contractManagerId: [undefined],
      creationDate: [],
      uniqueId: ['00000000-0000-0000-0000-000000000000'],
    });

    this.contractForm.controls['clientId']?.valueChanges.subscribe((x) => {
      let contact$: Observable<ContactModel>;
      contact$ = this.contactService.getById.value$;
      //this.contactService.getById.call(x);

      // contact$
      //   .pipe(
      //     filter((x) => !!x),
      //     untilDestroyed(this),
      //   )
      //   .subscribe((x: Contact) => {
      //     this.contractForm.controls['healthFormGroup']?.patchValue({
      //       subscriberFirstName: x.firstName,
      //       subscriberLastName: x.lastName,
      //     });
      //     this.contractForm.controls['paymentSplittingFormGroup']?.patchValue({
      //       paymentHolderName: x.name,
      //     });
      //   });
    });

    this.contractForm.controls['companyId'].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value == null) {
          this.productLineDomain = '';
          this.productDomain = '';
          this.contractForm.value.productLineId = null;
        } else {
          this.productLineDomain = `insuranceCompanyId=${value}`;
        }
      });
    this.contractForm.controls['productLineId'].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value == null) {
          this.productDomain = '';
          this.contractForm.value.productId = null;
        } else {
          this.productDomain = `productLineId=${value}`;
        }
      });
    this.contractForm.controls['productId'].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {});

    this.contract$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@contractEdit', x.contactName);
        this.category = x.productCategoryProductCategoryEnumKey;
        this.contractIdNumber = x.id;
        this.contractForm.patchValue({
          name: x.name,
          clientId: x.contactId,
          salesTeam: x.salesTeamId,
          salesTeamMember: x.salesPersonId,
          category: x.category,
          categoryLoc: x.categoryLoc,
          categoryColor: x.categoryColor,
          productCategoryId: x.productCategoryId,
          companyKey: x.companyKey,
          productLineKey: x.productLineKey,
          productId: x.productId,
          statusId: x.statusId,
          effectiveDate: formatDate(x.startDate, 'ToInverseSqlDate'),
          subscriptionDate: x.subscriptionDate
            ? formatDate(x.subscriptionDate, 'ToInverseSqlDate')
            : undefined,
          contractManagerId: x.contractManagerId,
          creationDate: x.creationDate,
          uniqueId: x.uniqueId,
          sharedSalesPersonId: x.sharedSalesPersonId,
          sharedCommissionRate: x.sharedCommissionRate,
          companyId: x.productProductLineInsuranceCompanyId,
          productLineId: x.productProductLineId,
        });
        switch (x.productCategoryProductCategoryEnumKey) {
          case InsuranceCategory.Health:
            this.contractService.getHealthDetail.call(x.id);
            break;
          case InsuranceCategory.Car:
            this.contractService.getCarDetail.call(x.id);
            break;
          case InsuranceCategory.House:
            this.contractService.getHouseDetail.call(x.id);
            break;
          default:
            break;
        }
      });
  }

  onSubmit(): void {
    if (this.contractForm.invalid) {
      this.contractForm.markAllAsTouched();
      this.formService.countErrors(this.contractForm, true);

      return;
    }

    let contractModel: ContractModel = {
      id: this.contractIdNumber,
      category: InsuranceCategory.Undefined,
      salesTeamId: this.contractForm.value.salesTeam,
      salesPersonId: this.contractForm.value.salesTeamMember,
      campaignId:
        this.contractForm.value.marketingFormGroup.marketingCampaignId,
      medium: this.contractForm.value.marketingFormGroup.marketingMedium,
      source: this.contractForm.value.marketingFormGroup.marketingSource,
      monthlyFee:
        this.contractForm.value.generalInformationFormGroup.monthlyFee,
      // creationDate: new Date(),
      companyKey: this.contractForm.value.companyKey,
      productLineKey: this.contractForm.value.productLineKey,
      productKey: this.contractForm.value.productKey,
      productId: this.contractForm.value.productId,
      statusId: this.contractForm.value.statusId,
      productLineId: -1,
      paymentSplittingId:
        this.contractForm.value.paymentSplittingFormGroup.paymentSplittingId,
      startDate: this.contractForm.value.effectiveDate,
      endDate: this.contractForm.value.effectiveDate,
      name: this.contractForm.value.name,
      subscriptionDate: this.contractForm.value.subscriptionDate,
      documentsReceivedDate:
        this.contractForm.value.generalInformationFormGroup
          .documentsReceivedDate,
      regularizationDate:
        this.contractForm.value.generalInformationFormGroup.regularizationDate,
      documentsSentDate:
        this.contractForm.value.generalInformationFormGroup.documentsSentDate,
      // expiryMonth:
      //   this.contractForm.value.generalInformationFormGroup.expiryMonth,
      // expiryDay: this.contractForm.value.generalInformationFormGroup.expiryDay,
      expiryMonth: 1,
      expiryDay: 1,
      terminationDate:
        this.contractForm.value.generalInformationFormGroup.terminationDate,
      terminationReasonId:
        this.contractForm.value.generalInformationFormGroup.terminationId,
      applicationFees:
        this.contractForm.value.generalInformationFormGroup.applicationFees,
      monthlyCost:
        this.contractForm.value.generalInformationFormGroup.monthlyCost,
      commissionRate:
        this.contractForm.value.generalInformationFormGroup.commissionRate == ''
          ? null
          : this.contractForm.value.generalInformationFormGroup.commissionRate,
      taxRate: this.contractForm.value.generalInformationFormGroup.taxRate,
      validationDate:
        this.contractForm.value.generalInformationFormGroup.validationDate,
      approverId:
        this.contractForm.value.generalInformationFormGroup.approverId,
      contractManagerId: this.contractForm.value.contractManagerId,
      contactId: this.contractForm.value.clientId,
      productCategoryId: this.contractForm.value.productCategoryId!,

      creationDate: this.contractForm.value.creationDate!,
      uniqueId: this.contractForm.value.uniqueId!,
      sharedCommissionRate:
        this.contractForm.value.commissionFormGroup.sharedCommissionRate,
      sharedSalesPersonId:
        this.contractForm.value.commissionFormGroup.sharedSalesPersonId,
      sharedSalesPersonContactName: '',

      //Others
      productCategoryName: '',
      leadId: 0,
      categoryLoc: '',
      categoryColor: '',
      productCategoryProductCategoryEnumKey: 0,
      contactGender: '',
      contactFirstName: '',
      contactLastName: '',
      contactPhoneNumber: '',
      contactEmail: '',
      contactZipCode: '',
      contactCity: '',
      contactAddress: '',
      contactAddress2: '',
      contactCountryName: '',
      gender: '',
      statusInt: 0,
      statusLoc: '',
      statusColor: '',
      annualFee: undefined,
      paymentBankAccountId: 0,
      paymentBankAccount: undefined,
      refundingBankAccountId: 0,
      refundingBankAccount: undefined,
      paymentSplittingMode: '',
      paymentSplittingDay: '',
      campaignName: '',
      salesTeamName: '',
      salesPersonContactName: '',
      contactName: '',
      terminationReasonName: '',
      approverContactName: '',
      contractManagerContactName: '',
      documents: [],
      clientAccountMoves: [],
      companyAccountMoves: [],
      productProductLineInsuranceCompanyContactName: '',
      productName: '',
      productProductLineName: '',
      productProductLineInsuranceCompanyId: 0,
      productProductLineId: 0,
      productProductLineInsuranceCompanyContactId: 0,
      leadUniqueId: '',
    };

    let paymentBankAccount: BankAccountModel = {
      id: this.contractForm.value.paymentSplittingFormGroup.paymentBankAccount
        .id!,
      bankId:
        this.contractForm.value.paymentSplittingFormGroup.paymentBankAccount
          .bankId!,
      contactId: this.contractForm.value.clientId!,
      holderName:
        this.contractForm.value.paymentSplittingFormGroup.paymentBankAccount
          .holderName!,
      iban: this.contractForm.value.paymentSplittingFormGroup.paymentBankAccount
        .iban!,
      bic: this.contractForm.value.paymentSplittingFormGroup.paymentBankAccount
        .bic!,
      active: true,

      //Others
      countryId: undefined,
      bankName: '',
      bankBIC: '',
      address: '',
      address2: '',
      zipCode: '',
      city: '',
      fullCity: '',
      countryName: '',
    };
    let refundingBankAccount: BankAccountModel;
    if (this.contractForm.value.paymentSplittingFormGroup.sameBankAccount) {
      refundingBankAccount = {
        id: this.contractForm.value.paymentSplittingFormGroup.paymentBankAccount
          .id!,
        bankId:
          this.contractForm.value.paymentSplittingFormGroup.paymentBankAccount
            .bankId!,
        contactId: this.contractForm.value.clientId!,
        holderName:
          this.contractForm.value.paymentSplittingFormGroup.paymentBankAccount
            .holderName!,
        iban: this.contractForm.value.paymentSplittingFormGroup
          .paymentBankAccount.iban!,
        bic: this.contractForm.value.paymentSplittingFormGroup
          .paymentBankAccount.bic!,
        active: true,

        //Others
        countryId: undefined,
        bankName: '',
        bankBIC: '',
        address: '',
        address2: '',
        zipCode: '',
        city: '',
        fullCity: '',
        countryName: '',
      };
    } else {
      refundingBankAccount = {
        id: this.contractForm.value.paymentSplittingFormGroup
          .refundingBankAccount.id!,
        bankId:
          this.contractForm.value.paymentSplittingFormGroup.refundingBankAccount
            .bankId!,
        contactId: this.contractForm.value.clientId!,
        holderName:
          this.contractForm.value.paymentSplittingFormGroup.refundingBankAccount
            .holderName!,
        iban: this.contractForm.value.paymentSplittingFormGroup
          .refundingBankAccount.iban!,
        bic: this.contractForm.value.paymentSplittingFormGroup
          .refundingBankAccount.bic!,
        active: true,

        //Others
        countryId: undefined,
        bankName: '',
        bankBIC: '',
        address: '',
        address2: '',
        zipCode: '',
        city: '',
        fullCity: '',
        countryName: '',
      };
    }
    switch (this.category) {
      case InsuranceCategory.Health: {
        contractModel.category = InsuranceCategory.Health;
        const subscriber: HealthInsuranceContractDetailFormModel = {
          socialSecurityKey:
            this.contractForm.value.healthFormGroup.subscriberRegime,
          birthDate:
            this.contractForm.value.healthFormGroup.subscriberBirthDate,
          insuredType: InsuredType.Holder,
          firstName:
            this.contractForm.value.healthFormGroup.subscriberFirstName,
          lastName: this.contractForm.value.healthFormGroup.subscriberLastName,
          birthName: '',
          socialSecurityNumber:
            this.contractForm.value.healthFormGroup
              .subscriberSocialSecurityNumber,
          codeCpam: this.contractForm.value.healthFormGroup.subcriberCodeCpam,
          gender: this.contractForm.value.healthFormGroup.subscriberGender,
          // maritalStatus: this.contractForm.value.maritalStatus,
          maritalStatus: 1,
          affiliation: 'None',
        };

        let partners: HealthInsuranceContractDetailFormModel[] = [];

        this.contractForm.value.healthFormGroup.partners.forEach((x) => {
          const partner: HealthInsuranceContractDetailFormModel = {
            socialSecurityKey: x.partnerRegime,
            birthDate: x.partnerBirthDate,
            insuredType: InsuredType.Partner,
            firstName: x.partnerFirstName,
            lastName: x.partnerLastName,
            maritalStatus: this.contractForm.value.maritalStatus,
            birthName: '',
            gender: x.partnerGender,
            socialSecurityNumber: x.partnerSocialSecurityNumber,
            codeCpam: x.partnerCodeCpam,
            affiliation: 'None',
          };
          partners.push(partner);
        });

        let children: HealthInsuranceContractDetailFormModel[] = [];

        this.contractForm.value.healthFormGroup.children.forEach((x) => {
          let child: HealthInsuranceContractDetailFormModel = {
            socialSecurityKey: x.childRegime,
            birthDate: x.childBirthDate,
            insuredType: InsuredType.Child,
            firstName: x.childFirstName,
            lastName: x.childLastName,
            birthName: '',
            gender: x.childGender,
            maritalStatus: 0,
            socialSecurityNumber: x.childSocialSecurityNumber,
            codeCpam: x.childCodeCpam,
            affiliation: 'Subscriber',
          };

          const index = x.index;

          if (x['childAffiliation' + index] == 'Subscriber') {
            child.socialSecurityNumber =
              this.contractForm.value.healthFormGroup.subscriberSocialSecurityNumber;
            child.codeCpam =
              this.contractForm.value.healthFormGroup.subcriberCodeCpam;
          }
          if (x['childAffiliation' + index] == 'Partner') {
            child.socialSecurityNumber = partners[0].socialSecurityNumber;
            child.codeCpam = partners[0].codeCpam;
          }
          children.push(child);
        });

        let healthInsuranceContractDetailFormModel: HealthInsuranceContractDetailFormModel[] =
          [subscriber, ...partners, ...children];

        let contractHealthUpdateModel: ContractHealthUpdateModel = {
          contractModel: contractModel,
          healthInsuranceContractDetailFormModels:
            healthInsuranceContractDetailFormModel,
          paymentBankAccountModel: paymentBankAccount,
          refundingBankAccountModel: refundingBankAccount,
        };
        let result$ = this.contractService.updateHealthContract.call(
          contractHealthUpdateModel,
        );
        result$
          .pipe(
            filter((x) => !!x),
            take(1),
          )
          .subscribe((x) => {
            this.formService.clear();
            this.router.navigate([
              'Crm/ContractRequests/Details',
              x.uniqueId.toString(),
            ]);
          });
        break;
      }
      case InsuranceCategory.Car: {
        contractModel.category = InsuranceCategory.Car;

        let carInsuranceContractUpdateModel: CarInsuranceContractUpdateModel = {
          driverGender: this.contractForm.value.driverFormGroup.driverGender,
          driverFirstName:
            this.contractForm.value.driverFormGroup.driverFirstname,
          driverLastName:
            this.contractForm.value.driverFormGroup.driverLastname,
          driverBirthdate:
            this.contractForm.value.driverFormGroup.driverBirthdate,
          driverZipCode: this.contractForm.value.driverFormGroup.driverZipCode,
          driverCity: this.contractForm.value.driverFormGroup.driverCity,
          driverAddress: this.contractForm.value.driverFormGroup.driverAddress,
          driverMaritalStatus:
            this.contractForm.value.driverFormGroup.driverMaritalStatus,
          licenseDate:
            this.contractForm.value.driverFormGroup.driverLicenseDate === ''
              ? undefined
              : this.contractForm.value.driverFormGroup.driverLicenseDate,
          isDriverVehiculeRegistrationHolder:
            this.contractForm.value.driverFormGroup
              .driverVehicleRegistrationHolder,
          vehiculeRegistrationHolder:
            this.contractForm.value.vehicleFormGroup.vehicleRegistrationHolder,
          vehiculeRegistrationHolderNameLastName:
            this.contractForm.value.vehicleFormGroup
              .vehicleRegistrationHolderCompanyName,
          vehiculeRegistrationHolderNameFirstName:
            this.contractForm.value.vehicleFormGroup
              .vehicleRegistrationHolderNameLastName,
          vehiculeRegistrationHolderCompanyName:
            this.contractForm.value.vehicleFormGroup
              .vehicleRegistrationHolderNameFirstName,
          vehiculeRegistrationHolderBirthdate:
            this.contractForm.value.vehicleFormGroup
              .vehicleRegistrationHolderBirthdate === ''
              ? undefined
              : this.contractForm.value.vehicleFormGroup
                  .vehicleRegistrationHolderBirthdate,
          yearsInsured:
            this.contractForm.value.driverFormGroup.driverYearsInsured,
          bonusMalus: this.contractForm.value.driverFormGroup.driverBonusMalus,
          monthInsuranceInterruption:
            this.contractForm.value.driverFormGroup
              .driverMonthInsuranceInterruption,
          insuranceTermination:
            this.contractForm.value.driverFormGroup.driverInsuranceTermination,
          licenseAnnulation:
            this.contractForm.value.driverFormGroup.driverLicenseAnnulation.toString(),
          noLicenseConviction:
            this.contractForm.value.driverFormGroup.driverNoLicenseConviction,
          licenseCancel:
            this.contractForm.value.driverFormGroup.driverLicenseCancel,
          licenseCancelMotive:
            this.contractForm.value.driverFormGroup.driverLicenseCancelMotive,
          licenseCancelDate:
            this.contractForm.value.driverFormGroup.driverLicenseCancelDate ===
            ''
              ? undefined
              : this.contractForm.value.driverFormGroup.driverLicenseCancelDate,
          licenseSuspension:
            this.contractForm.value.driverFormGroup.driverLicenseSuspension,
          licenseSuspensionMotive:
            this.contractForm.value.driverFormGroup
              .driverLicenseSuspensionMotive,
          licenseSuspensionDate:
            this.contractForm.value.driverFormGroup
              .driverLicenseSuspensionDate === ''
              ? undefined
              : this.contractForm.value.driverFormGroup
                  .driverLicenseSuspensionDate,
          longestSuspension:
            this.contractForm.value.driverFormGroup.driverLongestSuspension,
          alcoholDrugControl:
            this.contractForm.value.driverFormGroup.driverAlcoholDrugControl,
          nbCarDisaster: '',
          licensePlateNumber:
            this.contractForm.value.vehicleFormGroup.vehicleLicensePlateNumber,
          brand: this.contractForm.value.vehicleFormGroup.vehicleBrand,
          model: this.contractForm.value.vehicleFormGroup.vehicleModel,
          exactModel:
            this.contractForm.value.vehicleFormGroup.vehicleExactModel,
          carBody: this.contractForm.value.vehicleFormGroup.vehicleCarBody,
          carEnergie: this.contractForm.value.vehicleFormGroup.vehicleCarEnergy,
          carPower: this.contractForm.value.vehicleFormGroup.vehicleCarPower,
          vehiculeUsage: this.contractForm.value.vehicleFormGroup.vehicleUsage,
          vehiculeUsageProSupp:
            this.contractForm.value.vehicleFormGroup.vehicleUsageProSupp,
          parkingType:
            this.contractForm.value.vehicleFormGroup.vehicleParkingType,
          dateOfCirculation:
            this.contractForm.value.vehicleFormGroup
              .vehicleDateOfCirculation === ''
              ? undefined
              : this.contractForm.value.vehicleFormGroup
                  .vehicleDateOfCirculation,
          contractStartDate:
            this.contractForm.value.effectiveDate === ''
              ? undefined
              : this.contractForm.value.effectiveDate,
          insuranceCoverage:
            this.contractForm.value.carInsuranceFormGroup
              .carInsuranceInsuranceCoverage,
          currentInsuranceCompany:
            this.contractForm.value.carInsuranceFormGroup
              .carInsuranceCurrentInsuranceCompany,
          driverBodyCoverage:
            this.contractForm.value.carInsuranceFormGroup
              .carInsuranceDriverBodyCoverage,
          replacementVehicleCoverage:
            this.contractForm.value.carInsuranceFormGroup
              .carInsuranceReplacementVehicleCoverage,
          driverPropertyCoverage:
            this.contractForm.value.carInsuranceFormGroup
              .carInsuranceDriverPropertyCoverage,
          brandNewCoverage:
            this.contractForm.value.carInsuranceFormGroup
              .carInsuranceBrandNewCoverage,
          moneyLossCoverage:
            this.contractForm.value.carInsuranceFormGroup
              .carInsuranceMoneyLossCoverage,
        };

        let disasters: CarInsuranceContractDisasterDetailFormModel[] = [];

        this.contractForm.value.driverFormGroup.driverDisasters.forEach((x) => {
          const disaster: CarInsuranceContractDisasterDetailFormModel = {
            carDisasterDate: x.carDisasterDate,
            carDisasterReason: x.carDisasterReason,
            carDisasterResponsiblePerson: x.carDisasterResponsiblePerson,
          };
          disasters.push(disaster);
        });

        const contractCarUpdateModel: ContractCarUpdateModel = {
          contractModel: contractModel,
          paymentBankAccountModel: paymentBankAccount,
          refundingBankAccountModel: refundingBankAccount,
          carInsuranceContractUpdateModel: carInsuranceContractUpdateModel,
          carInsuranceContractDisasterDetailFormModels: disasters,
        };

        let result$ = this.contractService.updateCarContract.call(
          contractCarUpdateModel,
        );
        result$
          .pipe(
            filter((x) => !!x),
            take(1),
          )
          .subscribe((x) => {
            this.formService.clear();
            this.router.navigate([
              'Crm/ContractRequests/Details',
              x.uniqueId.toString(),
            ]);
          });

        break;
      }
      case InsuranceCategory.House: {
        contractModel.category = InsuranceCategory.House;
        const houseInsuranceContractModel: HouseInsuranceContractFormModel = {
          address: this.contractForm.value.houseFormGroup.houseAddress,
          zipCode: this.contractForm.value.houseFormGroup.houseZipCode,
          city: this.contractForm.value.houseFormGroup.houseCity,
          maritalStatus: this.contractForm.value.houseFormGroup.houseAddress,
          profession: this.contractForm.value.houseFormGroup.houseAddress,
          housingType: this.contractForm.value.houseFormGroup.houseHousingType,
          occupancyRole:
            this.contractForm.value.houseFormGroup.houseOccupancyRole,
          nbOfRooms: this.contractForm.value.houseFormGroup.houseNbOfRooms,
          area: this.contractForm.value.houseFormGroup.houseArea,
          constructionType:
            this.contractForm.value.houseFormGroup.houseConstructionType,
          roofType: this.contractForm.value.houseFormGroup.houseRoofType,
          amountToInsure:
            this.contractForm.value.houseFormGroup.houseAmountToInsure,
          flatLevel: this.contractForm.value.houseFormGroup.houseFlatLevel,
          residencyType:
            this.contractForm.value.houseFormGroup.houseResidencyType,
          fireCoverage:
            this.contractForm.value.houseFormGroup.houseFireCoverage,
          waterCoverage:
            this.contractForm.value.houseFormGroup.houseWaterCoverage,
          theftCoverage:
            this.contractForm.value.houseFormGroup.houseTheftCoverage,
          glassBreakCoverage:
            this.contractForm.value.houseFormGroup.houseGlassBreakCoverage,
          publicLiabilityCoverage:
            this.contractForm.value.houseFormGroup.housePublicLiabilityCoverage,
          feeCancellationCoverage:
            this.contractForm.value.houseFormGroup.houseFeeCancellationCoverage,
          electricalDamageCoverage:
            this.contractForm.value.houseFormGroup
              .houseElectricalDamageCoverage,
          poolCoverage:
            this.contractForm.value.houseFormGroup.housePoolCoverage,
          dogCoverage: this.contractForm.value.houseFormGroup.houseDogCoverage,
          currentInsuranceCompany:
            this.contractForm.value.houseFormGroup.houseCurrentInsuranceCompany,
        };

        const contractHouseUpdateModel: ContractHouseUpdateModel = {
          contractModel: contractModel,
          paymentBankAccountModel: paymentBankAccount,
          refundingBankAccountModel: refundingBankAccount,
          houseInsuranceContractFormModel: houseInsuranceContractModel,
        };

        let result$ = this.contractService.updateHouseContract.call(
          contractHouseUpdateModel,
        );
        result$
          .pipe(
            filter((x) => !!x),
            take(1),
          )
          .subscribe((x) => {
            this.formService.clear();
            this.router.navigate([
              'Crm/ContractRequests/Details',
              x.uniqueId.toString(),
            ]);
          });
        break;
      }
      case InsuranceCategory.Undefined: {
        contractModel.category = InsuranceCategory.Undefined;

        const contractUpdateBaseModel: ContractUpdateBaseModel = {
          contractModel: contractModel,
          paymentBankAccountModel: paymentBankAccount,
          refundingBankAccountModel: refundingBankAccount,
        };

        let result$ = this.contractService.updateContract.call(
          contractUpdateBaseModel,
        );
        result$
          .pipe(
            filter((x) => !!x),
            take(1),
          )
          .subscribe((x) => {
            this.formService.clear();
            this.router.navigate([
              'Crm/ContractRequests/Details',
              x.uniqueId.toString(),
            ]);
          });
        break;
      }
      default: {
        break;
      }
    }
  }

  ngOnDestroy(): void {
    this.contractService.updateHealthContract.reset();
    this.contractService.updateCarContract.reset();
    this.contractService.updateHouseContract.reset();
    this.contractService.getByUniqueId.reset();
    this.breadcrumbService.set('@contractEdit', ' ');
  }

  onCategoryChange(category: number) {
    if (this.category !== category) {
      this.category = category;
    }
  }
}
