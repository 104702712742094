import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ConfigurationService } from '../../service/configuration.service';
import {
  NotificationStatus,
  NotificationType,
} from '../../store/notification/types';
import { NotificationService } from '../../store/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-generic-file-input',
  templateUrl: './generic-file-input.component.html',
  styleUrls: ['./generic-file-input.component.scss'],
})
export class GenericFileInputComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;

  @Input() acceptFiles: string;
  @Input() format: 'button' | 'link' = 'button';
  @Input() isValidationButton: boolean = false;
  @Output() fileEmitter: EventEmitter<File | undefined> = new EventEmitter();
  @Output() validationEmitter: EventEmitter<File | undefined> =
    new EventEmitter();

  file: File | undefined = undefined;

  constructor(
    private readonly configurationService: ConfigurationService,
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    if (!this.acceptFiles) {
      this.acceptFiles = this.configurationService
        .getGenericAutorizedFileExtensions()
        .join(', ');
    }
  }

  onFileSelected(event) {
    const files = event.target.files as File[];
    const file = files[0];

    if (file) {
      if (
        file.size >
        parseInt(this.configurationService.getGenericMaxSize()) * 1024 * 1024
      ) {
        this.notificationService.add({
          timestamp: new Date().getTime(),
          content: this.translateService.instant(
            'DOCUMENT_MSG_ERROR_MAX_SIZE',
            {
              fileName: file.name,
              max: this.configurationService.getGenericMaxSize(),
            },
          ),
          notificationStatus: NotificationStatus.Error,
          userCancelled: false,
          type: NotificationType.Toast,
          customNotification: false,
        });
      } else {
        this.fileEmitter.emit(file);
        this.file = file;
      }
    }
  }

  onClickClearSelectedFile() {
    this.fileEmitter.emit(undefined);
    this.fileInput.nativeElement.value = null;
    this.file = undefined;
  }

  onClickValidateFile() {
    this.validationEmitter.emit(this.file);
    this.fileInput.nativeElement.value = null;
    this.file = undefined;
  }
}
