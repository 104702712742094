import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-commission-plan-rule-company-edit',
  templateUrl: './commission-plan-rule-company-edit.component.html',
  styleUrls: ['./commission-plan-rule-company-edit.component.scss'],
})
export class CommissionPlanRuleCompanyEditComponent implements OnInit {
  commissionPlanRuleId: string;

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit(): void {
    this.commissionPlanRuleId = this.route.snapshot.params['id'];
  }
}
