<div
  class="tab-pane fade flex-even active show"
  id="employees"
  role="tabpanel"
  aria-labelledby="employees-tab"
  *ngIf="this.employeeDetail$ | async as employeeDetail"
>
  <div class="row mt-2">
    <div class="col-12 col-md">
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >EMPLOYEE_LBL_WORKING_ADDRESS</label
        >
        <div class="col">
          <div class="form-control-plaintext form-control-sm mb-2 h-100">
            <app-address
              [address]="employeeDetail.workingAddress?.address"
              [address2]="employeeDetail.workingAddress?.address2"
              [zipCode]="employeeDetail.workingAddress?.zipCode"
              [city]="employeeDetail.workingAddress?.city"
              [country]="employeeDetail.workingAddress?.countryName"
            />
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >EMPLOYEE_LBL_USER</label
        >
        <div class="col">
          <span class="form-control-plaintext form-control-sm">
            {{ employeeDetail.userName }}
          </span>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >EMPLOYEE_LBL_JOB</label
        >
        <div class="col">
          <span class="form-control-plaintext form-control-sm">
            {{ employeeDetail.jobName }}
          </span>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >EMPLOYEE_LBL_MANAGER</label
        >
        <div class="col">
          <span class="form-control-plaintext form-control-sm">
            {{ employeeDetail.managerName }}
          </span>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >EMPLOYEE_LBL_ISMANAGER</label
        >
        <div class="col">
          <span class="form-control-plaintext form-control-sm">
            <input
              type="checkbox"
              name="active"
              [(ngModel)]="employeeDetail.isManager"
              onclick="return false;"
              disabled
            />
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
