import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CommissionPlanRuleModel } from '../../store/commissions/commission-plan-rule/types';
import { ActivatedRoute, Router } from '@angular/router';
import { CommissionPlanRuleService } from '../../store/commissions/commission-plan-rule/commission-plan-rule.service';

@Component({
  selector: 'app-commission-plan-rule-details-commission-rate',
  templateUrl: './commission-plan-rule-details-commission-rate.component.html',
  styleUrls: ['./commission-plan-rule-details-commission-rate.component.scss'],
})
export class CommissionPlanRuleDetailsCommissionRateComponent
  implements OnInit, OnDestroy
{
  commissionPlanRuleModels$: Observable<CommissionPlanRuleModel[]>;
  productLineId: number;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly commissionPlanRuleService: CommissionPlanRuleService,
  ) {
    this.commissionPlanRuleModels$ =
      this.commissionPlanRuleService.getByProductLineId.value$;
  }

  ngOnInit(): void {
    this.productLineId = this.route.snapshot.params['id'];
  }

  ngOnDestroy(): void {}

  trackByCommissionPlanRuleId(index: any, item: CommissionPlanRuleModel) {
    return item.id;
  }

  onClickLinkTo(commissionPlanRuleid: number, url: string) {
    this.router.navigate([
      'Finance/CommissionPlanRuleCompanies/Details/',
      commissionPlanRuleid,
    ]);
  }
}
