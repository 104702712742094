import { Pipe, PipeTransform } from '@angular/core';
import { CommissionPlanModel } from 'src/app/shared/store/commissions/commission-plan-rule/types';

@Pipe({
  name: 'commissionPlanExtractName',
})
export class CommissionPlanExtractNamePipe implements PipeTransform {
  transform(values: CommissionPlanModel[] | null | undefined): string[] {
    if (!values || values.length == 0) return [''];

    return values.map((value) => value.name).sort();
  }
}
