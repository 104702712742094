<div
  class="tab-pane fade flex-even active show"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
>
  <div class="d-flex flex-column h-100">
    <app-commission-plan-rule-details-commission-rate />
    <app-application-fee-rule-details-application-fee />
    <div class="mt-2">
      <div class="form-group-title my-1" translate>
        PRODUCTLINE_FIELDSET_COMMISSION_PROTOCOLS
      </div>
      <div class="row mt-2">
        <div class="col ">
          <app-document-panel-horizontal [entityId]="this.productLineId.toString()" [entityType]="'ProductLine'"/>
        </div>
      </div>
    </div>
  </div>
</div>
