<div
  class="tab-pane fade flex-even active show h-100"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
  *ngIf="this.documentBase$ | async as documentModels"
>
  <div class="d-flex flex-column h-100">
    <div class="row mt-2">
      <div class="col-12 col-md">
        <app-document-upload-drag-and-drop
          [entityId]="this.entityId"
          [entityType]="this.entityType"
          [multiple]="true"
        />
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 col-md">
        <app-generic-grid
          style="height: 300px"
          [data]="documentModels"
          [tabViewName]="'documents'"
          (onActionClickedSetRowData)="onSetRowData($event)"
        />
      </div>
    </div>
  </div>
</div>
<app-document-add #newDocument [entityId]="this.entityId" [entityType]="this.entityType"
                  (newDocument)="refresh($event)"/>
