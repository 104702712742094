import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from 'src/app/shared/guard/authentication-guard';
import { HasUnsavedChangesGuard } from 'src/app/shared/guard/has-form-changed.guard';
import { Entity } from 'src/app/shared/store/view/types';
import { ProductComparatorComponent } from './product-comparator.component/product-comparator.component';
import { ModuleId } from '../../../shared/store/navigation/types';
import { HeaderMenuType } from '../../../shared/store/view/types';

const routes: Routes = [
  {
    path: 'Products',
    data: {
      breadcrumb: { label: ' ', alias: 'productComparator' },
    },
    children: [
      {
        path: '',
        redirectTo: 'Comparator',
        pathMatch: 'full',
      },
      {
        path: 'Comparator',
        component: ProductComparatorComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Products,
          moduleId: ModuleId.products,
          entity: Entity.ProductComparator,
          breadcrumb: { skip: true },
        },
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ComparatorRoutingModule {}
