import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  DataType,
  DropdownStyle,
  SourceEnum,
} from '../../../../../../../../shared/store/typeahead/types';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ScraperService } from '../../../../../../../../shared/store/scraper/scraper.service';
import { FormService } from '../../../../../../../../shared/store/form/form.service';
import { ModalService } from '../../../../../../../../shared/service/modal.service';
import { SubHeaderService } from '../../../../../../../../shared/store/subheader/subheader.service';
import { UserService } from '../../../../../../../../shared/store/user/user.service';
import { Observable } from 'rxjs';
import { User } from '../../../../../../../../shared/store/user/type';
import { filterTrue } from '../../../../../../../../shared/pipe/rxjs/operators';
import { take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Entity } from '../../../../../../../../shared/store/view/types';
import { CounselorModel } from '../../../../../../../../shared/store/scraper/types';

@UntilDestroy()
@Component({
  selector: 'app-user-external-account-create',
  templateUrl: './user-external-account-create-content.component.html',
  styleUrls: ['./user-external-account-create-content.component.scss'],
})
export class UserExternalAccountCreateContentComponent
  implements OnInit, OnDestroy
{
  @Input() modalRandomIdentifier: number;
  @Input() source: SourceEnum = SourceEnum.Component;

  userId: string;
  user$: Observable<User>;

  counselorForm: FormGroup;

  protected readonly DataType = DataType;
  protected readonly DropdownStyle = DropdownStyle;
  protected readonly Entity = Entity;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly scraperService: ScraperService,
    private readonly userService: UserService,
    private readonly formBuilder: FormBuilder,
    private readonly formService: FormService,
    private readonly dynamicService: ModalService,
    private readonly subHeaderService: SubHeaderService,
  ) {
    this.user$ = this.userService.getById.value$;
  }

  ngOnInit(): void {
    this.counselorForm = this.formBuilder.group({
      insuranceId: [undefined, [Validators.required]],
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
      isPrimary: [true, [Validators.required]],
    });

    this.user$.pipe(filterTrue(), take(1)).subscribe((result) => {
      this.userId = result.id;
    });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'counselorCreate') this.onSubmit();
        this.subHeaderService.setTriggerAction('');
      });
  }

  onSubmit() {
    if (this.counselorForm.invalid) {
      this.counselorForm.markAllAsTouched();
      this.formService.setEntityErrors('COUNSELOR');
      this.formService.countErrors(this.counselorForm, true);
      return;
    }

    const counselorModel: CounselorModel = {
      id: 0,
      username: this.counselorForm.value.username!,
      password: this.counselorForm.value.password!,
      userId: +this.userId,
      ocealizInsuranceId: this.counselorForm.value.insuranceId,
      isPrimary: this.counselorForm.value.isPrimary,

      ocealizInsuranceLabel: '',
      ocealizInsuranceExternalAccountType: 'ocealiz',
      ocealizInsuranceExternalAccountTypeLoc: '',
      actions: [],
    };

    let result$ = this.scraperService.addCounselor.call(counselorModel);

    result$.pipe(filterTrue(), take(1)).subscribe((result) => {
      this.formService.clear();
      switch (this.source) {
        case SourceEnum.Component:
          break;
        case SourceEnum.Modal:
          this.dynamicService.outputFromDynamicComponent(
            this.modalRandomIdentifier,
            result.id.toString(),
          );
          break;
        default:
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this.scraperService.addCounselor.reset();
  }
}
