import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TabsContentGenericDetails } from '../../../../../shared/models/tabs-content-generic-details';
import { CommissionPlanRuleDetailsCommissionRateComponent } from '../../../../../shared/components/commission-plan-rule-details-commission-rate/commission-plan-rule-details-commission-rate.component';

@Component({
  selector: 'app-commission-plan-rule-company-tabs-details',
  templateUrl: './commission-plan-rule-company-tabs-details.component.html',
  styleUrls: ['./commission-plan-rule-company-tabs-details.component.scss'],
})
export class CommissionPlanRuleCompanyTabsDetailsComponent
  implements OnInit, OnChanges, OnDestroy
{
  active = 1;
  currentActive: number = 1;

  tabsContent$: BehaviorSubject<TabsContentGenericDetails[]> =
    new BehaviorSubject<TabsContentGenericDetails[]>([]);
  tabsInit: TabsContentGenericDetails[] = [
    {
      title: 'COMMISSION_PLAN_RULE_TABS_COMMISSIONS',
      index: 1,
      name: 'commissions',
      component: CommissionPlanRuleDetailsCommissionRateComponent,
    },
  ];

  constructor() {}

  ngOnInit(): void {
    this.tabsContent$.next(this.tabsInit);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.currentActive) {
      this.active = this.currentActive;
    } else {
      this.active = 1;
    }
    this.tabsContent$.next(this.tabsInit);
  }

  trackByTabsContent(index: any, item: TabsContentGenericDetails) {
    return item.index;
  }

  onActiveIdChangeSaveCurrent(active: number) {
    this.currentActive = active;
  }

  ngOnDestroy(): void {
    this.currentActive = 0;
  }
}
