import { Component, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { filter, Observable } from 'rxjs';
import { EmployeeModel } from '../../../../../../shared/store/employee/types';
import { EmployeeService } from '../../../../../../shared/store/employee/employee.service';
import { take } from 'rxjs/operators';
import { Entity } from '../../../../../../shared/store/view/types';
import { DropdownStyle } from '../../../../../../shared/store/typeahead/types';

@Component({
  selector: 'app-employee-form-public-informations',
  templateUrl: './employee-form-public-informations.component.html',
  styleUrls: ['./employee-form-public-informations.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class EmployeeFormPublicInformationsComponent implements OnInit {
  form: FormGroup;
  employeeDetail$: Observable<EmployeeModel>;
  employeeModel$: Observable<EmployeeModel>;

  protected readonly Entity = Entity;
  protected readonly DropdownStyle = DropdownStyle;

  constructor(
    private readonly employeeService: EmployeeService,
    private parent: FormGroupDirective,
    private formBuilder: FormBuilder,
  ) {
    this.employeeDetail$ = this.employeeService.getById.value$;
    this.employeeModel$ = this.employeeService.getEmployeeModel.value$;
  }

  ngOnInit(): void {
    this.form = this.parent.form;
    this.form.addControl(
      'publicInformationsFormGroup',
      this.formBuilder.group({
        publicInformationsWorkingAddressId: [undefined],
        publicInformationsWorkingAddress: [''],
        publicInformationsWorkingAddress2: [''],
        publicInformationsWorkingZipCode: [''],
        publicInformationsWorkingCity: [undefined],
        publicInformationsUserId: [undefined],
        publicInformationsJobId: [undefined],
        publicInformationsManagerId: [undefined],
        publicInformationsIsManager: [false],
      }),
    );

    this.employeeModel$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.form.patchValue({
          publicInformationsFormGroup: {
            publicInformationsWorkingAddressId: x.workingAddress?.id,
            publicInformationsWorkingAddress: x.workingAddress?.address,
            publicInformationsWorkingAddress2: x.workingAddress?.address2,
            publicInformationsWorkingZipCode: x.workingAddress?.zipCode,
            publicInformationsWorkingCity: x.workingAddress?.city,
            publicInformationsUserId: x.userId,
            publicInformationsJobId: x.jobId,
            publicInformationsManagerId: x.managerId,
            publicInformationsIsManager: x.isManager,
          },
        });
      });

    this.employeeDetail$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.form.patchValue({
          publicInformationsFormGroup: {
            publicInformationsWorkingAddressId: x.workingAddress?.id,
            publicInformationsWorkingAddress: x.workingAddress?.address,
            publicInformationsWorkingAddress2: x.workingAddress?.address2,
            publicInformationsWorkingZipCode: x.workingAddress?.zipCode,
            publicInformationsWorkingCity: x.workingAddress?.city,
            publicInformationsUserId: x.userId,
            publicInformationsJobId: x.jobId,
            publicInformationsManagerId: x.managerId,
            publicInformationsIsManager: x.isManager,
          },
        });
      });
  }
}
