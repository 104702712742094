import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { View } from '../../../../shared/store/view/types';
import { ActivatedRoute, Router } from '@angular/router';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { filter, take } from 'rxjs/operators';
import { filterTrue } from '../../../../shared/pipe/rxjs/operators';
import { ApplicationFeeRuleModel } from '../../../../shared/store/application-fee-rule/types';
import { ApplicationFeeRuleService } from '../../../../shared/store/application-fee-rule/application-fee-rule.service';

@UntilDestroy()
@Component({
  selector: 'app-application-fee-rule-details',
  templateUrl: './application-fee-rule-details.component.html',
  styleUrls: ['./application-fee-rule-details.component.scss'],
})
export class ApplicationFeeRuleDetailsComponent implements OnInit, OnDestroy {
  applicationFeeRuleId: number;
  applicationFeeRuleModel$: Observable<ApplicationFeeRuleModel>;
  view$: Observable<View>;

  constructor(
    private route: ActivatedRoute,
    private readonly router: Router,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly applicationFeeRuleService: ApplicationFeeRuleService,
  ) {
    this.applicationFeeRuleModel$ =
      this.applicationFeeRuleService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.applicationFeeRuleId = this.route.snapshot.params['id'];

    this.applicationFeeRuleService.getById.call(
      this.applicationFeeRuleId.toString(),
    );

    this.applicationFeeRuleModel$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@applicationFeeRuleDetails', x.name);
      });
    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'applicationFeeRuleEdit') {
          this.router.navigate([
            'Finance/ApplicationFeeRules/Edit',
            this.applicationFeeRuleId,
          ]);
        }
        if (x == 'applicationFeeRuleDelete') {
          this.onDelete();
        }
        this.subHeaderService.setTriggerAction('');
      });
  }

  onDelete() {
    let result$ = this.applicationFeeRuleService.delete.call(
      this.applicationFeeRuleId.toString(),
    );

    result$.pipe(filterTrue(), take(1)).subscribe((result) => {
      this.applicationFeeRuleService.delete.reset();
      this.router.navigate(['Finance/ApplicationFeeRules/List']);
    });
  }

  ngOnDestroy(): void {
    this.applicationFeeRuleService.getById.reset();
    this.applicationFeeRuleService.delete.reset();
    this.breadcrumbService.set('@applicationFeeRuleDetails', ' ');
  }
}
