<app-document-subheader></app-document-subheader>

<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div class="page-container partner-details">
        <div class="card card-light w-100">
          <div class="card-body">
            <div class="container-fluid">


              <form [formGroup]="userForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="userForm">
                <button type="submit" hidden="hidden"></button>

                <div class="row mb-2">
                  <div class="col-6 mr-auto">

                    <div class="row">
                      <div class="col-6">
                        <div class="form-group">
                          <label
                            class="form-title-label"
                            translate
                          >USER_LBL_LASTNAME</label
                          >
                          <input
                            class="form-control form-title"
                            type="text"
                            formControlName="lastName"
                            oninput="this.value = this.value.toUpperCase()"
                            checkFormFieldValidity
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label
                            class="form-title-label"
                            translate
                          >USER_LBL_FIRSTNAME</label
                          >
                          <input
                            class="form-control form-title"
                            type="text"
                            formControlName="firstName"
                            checkFormFieldValidity
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto ms-auto">
                    <app-avatar-edit
                      [picturePath]="this.imageUrl"
                      [isCompany]="false"
                      (fileEmitter)="onAvatarChange($event)"/>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6">
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                      >USER_LBL_EMAIL</label
                      >
                      <div class="col">
                        <input
                          class="form-control form-control-sm"
                          type="text"
                          formControlName="email"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                      >USER_LBL_ROLES</label
                      >
                      <div class="col">
                        <app-multi-select-dropdown
                          [routeEndpoint]="'Roles'"
                          [valueMember]="'value'"
                          [displayMember]="'name'"
                          [dbNameOfId]="'value'"
                          [dbNameOfName]="'name'"
                          [controlForm]="this.userForm.controls['roles']"
                        ></app-multi-select-dropdown>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" for="salesTeamIds"
                             translate>USER_LBL_SALES_TEAM_NAME</label>
                      <div class="col">
                        <app-multi-select-dropdown
                          id="salesTeamIds"
                          [routeEndpoint]="'SalesTeams'"
                          [controlForm]="this.userForm.get('salesTeamIds')"
                          [displayMember]="'name'"
                          [valueMember]="'id'"
                          [orderBy]="'name asc'"
                          [limitToList]="false"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" for="mainSalesTeamId"
                             translate>USER_LBL_SALES_TEAM_MAIN</label>
                      <div class="col">
                        <app-typeahead
                          id="mainSalesTeamId"
                          [routeEndpoint]="'SalesTeams'"
                          [controlForm]="this.userForm.get('mainSalesTeamId')"
                          [displayMember]="'name'"
                          [valueMember]="'id'"
                          [orderBy]="'name asc'"
                          [limitToList]="true"
                          [domain]="this.mainSalesTeamDomain"
                          [showOneResultElement]="false"
                          [showFirstElement]="false"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" for="salesPersonCommissionPlanId" translate>USER_LBL_COMMISSION_PLAN_NAME_SALESPERSON</label>
                      <div class="col">
                        <app-typeahead
                          id="salesPersonCommissionPlanId"
                          [routeEndpoint]="'CommissionPlans'"
                          [controlForm]="this.userForm.get('salesPersonCommissionPlanId')"
                          [displayMember]="'name'"
                          [valueMember]="'id'"
                          [orderBy]="'name asc'"
                          [limitToList]="false"
                          [domain]="'CommissionPlanType=0'"
                          [showOneResultElement]="false"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" for="sharedCommissionPlanId" translate>USER_LBL_COMMISSION_PLAN_NAME_SHARED</label>
                      <div class="col">
                        <app-typeahead
                          id="sharedCommissionPlanId"
                          [routeEndpoint]="'CommissionPlans'"
                          [controlForm]="this.userForm.get('sharedCommissionPlanId')"
                          [displayMember]="'name'"
                          [valueMember]="'id'"
                          [orderBy]="'name asc'"
                          [limitToList]="false"
                          [domain]="'CommissionPlanType=1'"
                          [showOneResultElement]="false"
                        />
                      </div>
                    </div>

                    <!--                    <div class="form-group row">-->
                    <!--                      <label class="col-4 col-form-label-sm" for="active" translate>USER_LBL_ACTIVE</label>-->
                    <!--                      <div class="col">-->
                    <!--                        <input-->
                    <!--                          class="form-control-sm"-->
                    <!--                          id="active"-->
                    <!--                          name="active"-->
                    <!--                          type="checkbox"-->
                    <!--                          value=""-->
                    <!--                          formControlName="active" [defaultChecked]="false"-->
                    <!--                          checkFormFieldValidity-->
                    <!--                        />-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
