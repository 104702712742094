<app-list-subheader></app-list-subheader>
<ng-container *ngIf="this.viewType$ | async as viewType">
  <ag-grid-angular
    class="ag-theme-alpine data-grid"
    [gridOptions]="gridOptions"
    [rowData]="(this.data$ | async)?.data"
    (gridReady)="onGridReady($event)"
    (sortChanged)="onSortChanged($event)"
    (gridSizeChanged)="onGridSizeChanged($event)"
    (selectionChanged)="onSelectionChanged($event)"
    [overlayNoRowsTemplate]="this.noRowsTemplate"
    [rowSelection]="this.rowSelectionOptions"
    *ngIf="viewType == 'TreeView'"
  ></ag-grid-angular>
  <app-kanban-view
    [data]="(this.data$ | async)?.data"
    [entity]="(this.view$ | async)?.entity"
    *ngIf="viewType == 'KanbanView'"
  ></app-kanban-view>
</ng-container>
