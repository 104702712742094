import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { DocumentService } from '../../store/document/document.service';

@Injectable({
  providedIn: 'root',
})
export class DragAndDropService {
  constructor(private readonly documentService: DocumentService) {}

  copyDocumentToDocumentRequest(documentId: number, documentRequestId: number) {
    this.documentService.copyToDocumentRequest.call({
      documentId: documentId,
      documentRequestId: documentRequestId,
    });
  }
}
