import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { translateInitializerFactory, TRANSLATE_CONFIG_TOKEN, TranslateConfig } from './types';
import { CommonTranslateService } from './translate.service';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from './translate.config.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({ exports: [TranslateModule], imports: [TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        })], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class CommonTranslateModule {
  static forRoot(config: TranslateConfig): ModuleWithProviders<CommonTranslateModule> {
    return {
      ngModule: CommonTranslateModule,
      providers: [
        TranslateConfigService,
        { provide: TRANSLATE_CONFIG_TOKEN, useValue: config },
        {
          provide: APP_INITIALIZER,
          useFactory: translateInitializerFactory,
          deps: [TranslateConfigService, TRANSLATE_CONFIG_TOKEN],
          multi: true,
        },
        TranslateService,
        CommonTranslateService,
      ],
    };
  }
}
