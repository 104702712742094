import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ContactModel } from '../../../../../../shared/store/contact/types';
import { ContactService } from '../../../../../../shared/store/contact/contact.service';
import { ButtonType } from '../../../../../../shared/store/subheader/type';

@Component({
  selector: 'app-contact-details-contracts',
  templateUrl: './contact-details-contracts.component.html',
  styleUrl: './contact-details-contracts.component.scss',
})
export class ContactDetailsContractsComponent {
  contactDetail$: Observable<ContactModel>;

  protected readonly ButtonType = ButtonType;

  constructor(private readonly contactService: ContactService) {
    this.contactDetail$ = this.contactService.getById.value$;
  }
}
