<div
  class="tab-pane fade flex-even active show"
  id="employees"
  role="tabpanel"
  aria-labelledby="employees-tab"
  *ngIf="this.employeeDetail$ | async as employeeDetail"
>
  <div class="d-flex flex-column h-100">
    <div class="row">
      <div class="col">
        <div class="form-group row">
          <div class="col-auto">
            {{ employeeDetail.notes }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
