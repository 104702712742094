import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountRoutingModule } from './account-routing.module';
import { AccountCreateComponent } from './account-create/account-create.component';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  declarations: [AccountCreateComponent],
  imports: [CommonModule, AccountRoutingModule, SharedModule],
})
export class AccountModule {}
