import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-iban',
  templateUrl: './iban.component.html',
  styleUrls: ['./iban.component.scss'],
})
export class IbanComponent {
  @Input() iban!: string | undefined | null;
}
