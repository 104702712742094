<ng-container *ngIf="tabsContent$ | async as tabsContent">
  <ul
    ngbNav
    #nav="ngbNav"
    [(activeId)]="active"
    class="nav-tabs"
    [destroyOnHide]="false"
    (activeIdChange)="onActiveIdChangeSaveCurrent($event)"
  >
    <ng-container *ngIf="contactDetails$ | async as contactDetail">
      <li [ngbNavItem]="0" *ngIf="contactDetail.isCompany">
        <button ngbNavLink class="nav-link" translate>
          CONTACT_TABS_CONTACTS
        </button>
        <ng-template ngbNavContent>
          <app-contact-details-children/>
        </ng-template>
      </li>
    </ng-container>

    <li [ngbNavItem]="tabContent.index +1"
        *ngFor="let tabContent of tabsContent; count as count; last as isLast; trackBy: trackByTabsContent">
      <button ngbNavLink class="nav-link" translate>
        {{ tabContent.title }}
      </button>
      <ng-template ngbNavContent>
        <ng-container *ngComponentOutlet="tabContent.component"/>
      </ng-template>
    </li>

    <ng-container *ngIf="contactDetails$ | async as contactDetail">
      <li [ngbNavItem]="tabsContent.length + 2">
        <button ngbNavLink class="nav-link" translate>
          CONTACT_TABS_DOCUMENTS
        </button>
        <ng-template ngbNavContent>
          <app-tab-documents [entityId]="contactDetail.id.toString()" [entityType]="'Contact'"/>
        </ng-template>
      </li>


      <li [ngbNavItem]="tabsContent.length + 3">
        <button ngbNavLink class="nav-link" translate>
          CONTACT_TABS_INVOICES
        </button>
        <ng-template ngbNavContent>
          <app-contact-details-account-moves-table
            [accountMoves]="contactDetail.clientAccountMoves"
            [isClient]="true"
          />
        </ng-template>
      </li>
    </ng-container>
    <!--    <li [ngbNavItem]="tabsContent.length + 3">-->
    <!--      <button ngbNavLink class="nav-link" translate>-->
    <!--        CONTACT_TABS_INVOICES-->
    <!--      </button>-->
    <!--      <ng-template ngbNavContent>-->
    <!--        <app-details-account-moves />-->
    <!--      </ng-template>-->
    <!--    </li>-->

    <li [ngbNavItem]="tabsContent.length + 4">
      <button ngbNavLink class="nav-link" translate>
        CONTACT_TABS_PAYMENTS
      </button>
      <ng-template ngbNavContent>
        <app-contact-details-payment/>
      </ng-template>
    </li>

    <li [ngbNavItem]="tabsContent.length + 5">
      <button ngbNavLink class="nav-link" translate>
        CONTACT_TABS_MARKETING
      </button>
      <ng-template ngbNavContent>
        <app-contact-details-marketing/>
      </ng-template>
    </li>


    <ng-container *ngIf="contactDetails$ | async as contactDetail">
      <li [ngbNavItem]="18" *ngIf="contactDetail.notes && contactDetail.notes.length > 0">
        <button ngbNavLink class="nav-link" translate>
          CONTACT_TABS_NOTES
        </button>
        <ng-template ngbNavContent>
          <app-contact-details-notes/>
        </ng-template>
      </li>
    </ng-container>


  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</ng-container>
