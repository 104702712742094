import { createFeatureSelector, createSelector } from '@ngrx/store';
import { createApiCallSelectors } from 'src/app/common/store/generic.selectors';
import { PageResult, SearchResult } from '../../types';
import { ISearchState } from './search.reducer';
import { OperationModel, searchFeatureKey } from './types';

const searchFeatureState =
  createFeatureSelector<ISearchState>(searchFeatureKey);
export const searchSelectors = {
  search: createApiCallSelectors<SearchResult<any>, ISearchState>(
    searchFeatureState,
    'search',
  ),
  operations: createApiCallSelectors<OperationModel[], ISearchState>(
    searchFeatureState,
    'operations',
  ),
  getActiveFilters: createSelector(
    searchFeatureState,
    (state: ISearchState) => state.activeFilters,
  ),
  getCustomFilters: createSelector(
    searchFeatureState,
    (state: ISearchState) => state.customFilters,
  ),
  getContextFilters: createSelector(
    searchFeatureState,
    (state: ISearchState) => state.contextFilters,
  ),
  getContextFilterFlag: createSelector(
    searchFeatureState,
    (state: ISearchState) => state.isContextFilterFlag,
  ),
  getSelectedRows: createSelector(
    searchFeatureState,
    (state: ISearchState) => state.selectedRows,
  ),
};
