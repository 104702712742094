import { createApiCallActions } from '../../../../common/store/generic.actions';
import { AccountMoveModel } from '../../accounting/types';
import {
  CommissionModel,
  CommissionPreviewModel,
  CompanyCommissionModel,
} from './types';

export const commissionActions = {
  getById: createApiCallActions<CommissionModel>('commission', 'getById'),
  getCommissionsPreview: createApiCallActions<CommissionPreviewModel[]>(
    'commission',
    'getCommissionsPreview'
  ),
  add: createApiCallActions<CommissionPreviewModel[]>('commission', 'add'),
  addSalesPersonCommissionPayment: createApiCallActions<AccountMoveModel>(
    'commission',
    'addSalesPersonCommissionPayment'
  ),
  getCompanyCommissions: createApiCallActions<CompanyCommissionModel[]>(
    'commission',
    'getCompanyCommissions'
  ),
};

export type CommissionAction = typeof commissionActions;
