import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from 'src/app/common/store/types';
import { AppState } from '../types';
import {
  LockoutModel,
  RegisterModel,
  User,
  UserConnectionHistory,
  UserModel,
} from './type';
import { userActions } from './user.actions';
import { userSelectors } from './user.selectors';

@Injectable()
export class UserService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  get: GenericApiCall<User[], void> = this.genericApiCall(
    userActions.get,
    userSelectors.get,
    true,
  );

  getById: GenericApiCall<User, string> = this.genericApiCall(
    userActions.getById,
    userSelectors.getById,
    true,
  );

  add: GenericApiCall<User, UserModel> = this.genericApiCall(
    userActions.add,
    userSelectors.add,
    true,
  );

  update: GenericApiCall<User, User> = this.genericApiCall(
    userActions.update,
    userSelectors.update,
    true,
  );

  lockout: GenericApiCall<boolean, LockoutModel> = this.genericApiCall(
    userActions.lockout,
    userSelectors.lockout,
    true,
  );

  getCurrentUser: GenericApiCall<User, void> = this.genericApiCall(
    userActions.getCurrentUser,
    userSelectors.getCurrentUser,
    true,
  );

  getConnectionHistoriesById: GenericApiCall<UserConnectionHistory[], string> =
    this.genericApiCall(
      userActions.getConnectionHistoriesById,
      userSelectors.getConnectionHistoriesById,
      true,
    );
}
