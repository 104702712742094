<form [formGroup]="commentForm" (ngSubmit)="onSubmit()">
  <input name="ParentId" type="hidden" value=""/>
  <div class="row new-comment-container pt-2" [class]="{'bg-light':this.isVisible}" >
    <div class="col">
      <div class="row new-comment-form align-items-center" id="newComment" *ngIf="this.isVisible">
        <div class="col d-flex mt-2">
          <div class="me-3">
            <ng-container *ngIf="this.currentAvatar$ | async as avatar">
              <img src="{{this.avatar.url}}"
                   class="userbox-img btn-rounded"/>
            </ng-container>
          </div>
          <div class="d-flex flex-column flex-even">
            <div class="mb-2">
              <textarea name="message"
                        id="message"
                        formControlName="message"
                        placeholder="{{ 'COMMENT_PLACEHOLDER_MESSAGE' | translate}}"
                        class="form-control form-control-sm">
              </textarea>
            </div>
            <div class="mb-3">
              <button class="btn btn-sm btn-primary" translate>GLOBAL_BTN_SAVE</button>
              <span class="ms-2 btn btn-sm btn-outline-primary" (click)="this.cancelNewComment()" translate>GLOBAL_BTN_CANCEL</span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</form>
