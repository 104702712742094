import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CashJournalRoutingModule } from './cash-journal-routing.module';
import { SharedModule } from '../../../shared/shared.module';
import { CashJournalDetailsComponent } from './cash-journal-details/cash-journal-details.component';
import { CashJournalAddContentComponent } from './cash-journal-add-content/cash-journal-add-content.component';
import { CashControlComponent } from './cash-control/cash-control.component';
import { CashControlFormContentComponent } from './cash-control-form-content/cash-control-form-content.component';

@NgModule({
  declarations: [
    CashJournalDetailsComponent,
    CashJournalAddContentComponent,
    CashControlComponent,
    CashControlFormContentComponent,
  ],
  imports: [CommonModule, CashJournalRoutingModule, SharedModule],
})
export class CashJournalModule {}
