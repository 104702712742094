import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { MessageService } from '../message/message.service';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { NotificationService } from '../store/notification/notification.service';
import {
  NotificationStatus,
  NotificationType,
} from '../store/notification/types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProblemDetails } from './types';
import { FormService } from '../store/form/form.service';

@Injectable()
export class ErrorService {
  constructor(
    private readonly router: Router,
    private readonly notificationService: NotificationService, // private readonly messageService: MessageService
    private readonly modalService: NgbModal,
    private readonly formService: FormService,
  ) {}

  workInProgress = (): void => {
    this.router.navigateByUrl('/work-in-progress', {
      skipLocationChange: true,
    });
  };

  serverErrorLog = (
    response: HttpErrorResponse,
    serviceName: string,
  ): Observable<never> => {
    console.error(serviceName, response);
    return throwError(response);
  };

  serverErrorRedirect = (response: HttpErrorResponse): Observable<never> => {
    if (response) {
      console.log('errorService status ' + response.status);
      // if (!response.status) {
      //   this.internalServerError();
      // } else {

      switch (response.status) {
        case 0:
        case -1:
        case 503:
        case 504:
          // this.notificationService.add({notificationStatus:NotificationStatus.Error, content:'Impossible de contacter le serveur.', timestamp:new Date().getTime(), userCancelled:false, timeout:undefined});
          this.offlineError();
          break;
        case 401:
          this.unauthorized(this.router.url, false);
          break;
        case 403:
          this.notification(
            'NOTIFICATION_MSG_ERROR_403',
            NotificationStatus.Error,
          );
          break;
        case 400:
        case 404:
        case 500:
          // this.notificationService.add({
          //   notificationStatus: NotificationStatus.Error,
          //   content: 'Erreur ' + response.status,
          //   timestamp: new Date().getTime(),
          //   userCancelled: false,
          //   timeout: undefined,
          //   type: NotificationType.Toast,
          // });
          //this.notFound();

          const problemDetails: ProblemDetails = response.error;

          if (problemDetails.detail && problemDetails.status) {
            this.formService.createErrors(problemDetails.detail);
            this.notificationService.add({
              content: '1',
              notificationStatus: NotificationStatus.Info,
              timestamp: new Date().getTime(),
              type: NotificationType.ToastValidation,
              userCancelled: false,
              timeout: 5000,
              customNotification: true,
            });
          }

          break;
        default:
          this.internalServerError(
            response?.error?.message ?? response?.message ?? response,
          );
          break;
        // }
      }
    } else {
      this.internalServerError();
    }

    return throwError(response);
  };

  // serverErrorDialog = (
  //   error: HttpErrorResponse,
  //   serviceName: string
  // ): Promise<any> => {
  //   // Timeout
  //   if (error.statusText === 'abort') {
  //     return Promise.reject(null);
  //   } else if (
  //     error.status === 0 ||
  //     error.status === -1 ||
  //     error.status === 504
  //   ) {
  //     // Offline mode
  //     return this.messageService
  //       .openInformation('MESSAGE_MODAL_SERVICE_OFFLINE')
  //       .then(() => Promise.reject(error));
  //     // Model state validation error
  //   } else if (error.status === 400 && error.error.modelState) {
  //     return this.messageService
  //       .openModelState(error.error)
  //       .then(() => Promise.reject(error));
  //     // Authentication error
  //   } else if (error.status === 401 || error.status === 403) {
  //     return this.messageService
  //       .openError('MESSAGE_MODAL_SERVICE_UNAUTHORIZED', { serviceName })
  //       .then(() => Promise.reject(error));
  //     // Request error
  //   } else if (error.status === 404) {
  //     const message =
  //       (error.error && error.error.message) || error.message || error;
  //     return this.messageService
  //       .openError(message, { serviceName })
  //       .then(() => Promise.reject(error));
  //     // Business Rule error
  //   } else if (error.status === 422) {
  //     const message = error.error
  //       ? error.error.message || error.error
  //       : error.message;
  //     return this.messageService
  //       .openInformation(message)
  //       .then(() => Promise.reject(error));
  //     // Internal server error
  //   } else if (error.status === 500) {
  //     return this.messageService
  //       .openError('MESSAGE_MODAL_SERVICE_ERROR', {
  //         serviceName,
  //       })
  //       .then(() => Promise.reject(error));
  //     // Service down error
  //   } else if (error.status === 503) {
  //     return this.messageService
  //       .openError('MESSAGE_MODAL_SERVICE_UNAVAILABLE', { serviceName })
  //       .then(() => Promise.reject(error));
  //     // Any other error
  //   } else {
  //     const message = error.error
  //       ? error.error.message || error.error
  //       : error.message;
  //     return this.messageService
  //       .openError(message, { serviceName })
  //       .then(() => Promise.reject(error));
  //   }
  // };

  private azureAdInterceptorError = (): void => {
    this.router.navigateByUrl('/redirect-azuread', {
      skipLocationChange: true,
    });
  };

  private notFound = (): void => {
    this.router.navigateByUrl(`/page-not-found`, {
      skipLocationChange: true,
    });
  };

  private offlineError = (): void => {
    this.router.navigateByUrl(`/offline-error`, {
      skipLocationChange: true,
    });
  };

  private internalServerError = (title?: string, message?: string): void => {
    this.router.navigateByUrl(`/internal-server-error/${title}/${message}`, {
      skipLocationChange: true,
    });
  };

  public unauthorized = (
    url: string | null,
    withNotification: boolean = false,
  ): Promise<boolean> | boolean => {
    console.log('unauthorized redirect');
    if (withNotification) {
      this.notification(
        'NOTIFICATION_MSG_ERROR_DISCONNECTED',
        NotificationStatus.Error,
      );
    }

    this.closeAllModals();

    if (this.router.url.includes('/Login')) return true;

    return this.router.navigate(['/Login'], {
      queryParams: { returnUrl: url },
    });
  };

  private notification(
    content: string,
    notificationStatus: NotificationStatus = NotificationStatus.Error,
  ) {
    this.notificationService.add({
      notificationStatus: notificationStatus,
      content: content,
      timestamp: new Date().getTime(),
      userCancelled: false,
      timeout: undefined,
      type: NotificationType.Toast,
      customNotification: false,
    });
  }

  private closeAllModals = () => {
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll();
    }
  };
}
