import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  ContractModel,
  ContractHealthFormModel,
  ContractStatus,
  ContractStatusFlow,
  HealthInsuranceContractDetailModel,
  ContractCarFormModel,
  ContractHouseFormModel,
  CarInsuranceContractModel,
  HouseInsuranceContractModel,
  ContractHealthUpdateModel,
  ContractCarUpdateModel,
  ContractHouseUpdateModel,
  CreateContractEntityModel,
  ContractUpdateBaseModel,
  ContractAddBaseModel,
} from './types';
import { ErrorService } from '../../error';
import { catchError } from 'rxjs/operators';
import { CrudType } from '../../service/message/types';
import { MessageService } from '../../service/message/message.service';
import { Action } from '../view/types';

@Injectable({
  providedIn: 'root',
})
export class ContractApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService,
    private readonly messageService: MessageService
  ) {}

  getById = (id: string): Observable<ContractModel> => {
    let parameters: string[];
    parameters = [id];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<ContractModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getByUniqueId = (id: string): Observable<ContractModel> => {
    let parameters: string[];
    parameters = ['uniqueid', id];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<ContractModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getByLeadId = (id: string): Observable<ContractModel> => {
    let parameters: string[];
    parameters = ['lead', id];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<ContractModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getHealthDetail = (
    id: number
  ): Observable<HealthInsuranceContractDetailModel[]> => {
    let parameters: string[];
    parameters = ['HealthDetail', id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<HealthInsuranceContractDetailModel[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getCarDetail = (id: number): Observable<CarInsuranceContractModel> => {
    let parameters: string[];
    parameters = ['CarDetail', id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<CarInsuranceContractModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getHouseDetail = (id: number): Observable<HouseInsuranceContractModel> => {
    let parameters: string[];
    parameters = ['HouseDetail', id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<HouseInsuranceContractModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getStatusFlow = (
    statusFlow: ContractStatus
  ): Observable<ContractStatusFlow[]> => {
    let parameters: string[];
    parameters = ['statusflow', statusFlow.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<ContractStatusFlow[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getStatusActions = (statusFlow: ContractStatus): Observable<Action[]> => {
    let parameters: string[];
    parameters = ['statusactions', statusFlow.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<Action[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  confirm = (contractId: number): Observable<ContractModel> => {
    let parameters: string[];
    parameters = ['confirm', contractId.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<ContractModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  saveAsDraft = (contractId: number): Observable<ContractModel> => {
    let parameters: string[];
    parameters = ['saveasdraft', contractId.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<ContractModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };
  validate = (contractId: number): Observable<ContractModel> => {
    let parameters: string[];
    parameters = ['validate', contractId.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<ContractModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };
  reject = (contractId: number): Observable<ContractModel> => {
    let parameters: string[];
    parameters = ['reject', contractId.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<ContractModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };
  markAsRegularized = (contractId: number): Observable<ContractModel> => {
    let parameters: string[];
    parameters = ['markasregularized', contractId.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<ContractModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };
  verify = (contractId: number): Observable<ContractModel> => {
    let parameters: string[];
    parameters = ['verify', contractId.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<ContractModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };
  suspendContract = (contractId: number): Observable<ContractModel> => {
    let parameters: string[];
    parameters = ['suspendcontract', contractId.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<ContractModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };
  closeContract = (contractId: number): Observable<ContractModel> => {
    let parameters: string[];
    parameters = ['closecontract', contractId.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<ContractModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  requestValidation = (contractId: number): Observable<ContractModel> => {
    let parameters: string[];
    parameters = ['requestvalidation', contractId.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .get<ContractModel>(url, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  getContractModel = (
    createEntityModel: CreateContractEntityModel
  ): Observable<ContractModel> => {
    let parameters: string[];
    parameters = ['ContractModel'];
    let url = this.formatUrl(parameters);

    let queryParams = new HttpParams();
    if (!!createEntityModel.productCategoryId) {
      queryParams = queryParams.append(
        'productCategoryId',
        createEntityModel.productCategoryId
      );
    }
    if (!!createEntityModel.leadId) {
      queryParams = queryParams.append('leadId', createEntityModel.leadId);
    }
    if (!!createEntityModel.productId) {
      queryParams = queryParams.append(
        'productId',
        createEntityModel.productId
      );
    }

    return this.httpClient
      .get<ContractModel>(url, { params: queryParams })
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };
  getHealthContractModel = (
    leadId: number
  ): Observable<HealthInsuranceContractDetailModel[]> => {
    let parameters: string[];
    parameters = ['HealthContractModel'];
    let url = this.formatUrl(parameters);

    let queryParams = new HttpParams();
    queryParams = queryParams.append('leadId', leadId);

    return this.httpClient
      .get<HealthInsuranceContractDetailModel[]>(url, { params: queryParams })
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };
  getCarContractModel = (
    leadId: number
  ): Observable<CarInsuranceContractModel> => {
    let parameters: string[];
    parameters = ['CarContractModel'];
    let url = this.formatUrl(parameters);

    let queryParams = new HttpParams();
    queryParams = queryParams.append('leadId', leadId);

    return this.httpClient
      .get<CarInsuranceContractModel>(url, { params: queryParams })
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };
  getHouseContractModel = (
    leadId: number
  ): Observable<HouseInsuranceContractModel> => {
    let parameters: string[];
    parameters = ['HouseContractModel'];
    let url = this.formatUrl(parameters);

    let queryParams = new HttpParams();
    queryParams = queryParams.append('leadId', leadId);

    return this.httpClient
      .get<HouseInsuranceContractModel>(url, { params: queryParams })
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  createContract = (contractAddBaseModel: ContractAddBaseModel): any => {
    let parameters: string[];
    parameters = ['Contract'];
    let url = this.formatUrl(parameters);

    return this.httpClient
      .post<ContractModel>(url, contractAddBaseModel, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  createHealthContract = (
    contractHealthFormModel: ContractHealthFormModel
  ): any => {
    let parameters: string[];
    parameters = ['HealthContract'];
    let url = this.formatUrl(parameters);

    return this.httpClient
      .post<ContractModel>(url, contractHealthFormModel, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };
  createCarContract = (contractCarFormModel: ContractCarFormModel): any => {
    let parameters: string[];
    parameters = ['CarContract'];
    let url = this.formatUrl(parameters);

    return this.httpClient
      .post<ContractModel>(url, contractCarFormModel, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };
  createHouseContract = (
    contractHouseFormModel: ContractHouseFormModel
  ): any => {
    let parameters: string[];
    parameters = ['HouseContract'];
    let url = this.formatUrl(parameters);

    return this.httpClient
      .post<ContractModel>(url, contractHouseFormModel, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  updateContract = (contractUpdateBaseModel: ContractUpdateBaseModel): any => {
    let parameters: string[];
    parameters = ['Contract'];
    let url = this.formatUrl(parameters);

    return this.httpClient
      .put<ContractModel>(url, contractUpdateBaseModel, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  updateHealthContract = (
    contractHealthUpdateModel: ContractHealthUpdateModel
  ): any => {
    let parameters: string[];
    parameters = ['HealthContract'];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .put<ContractModel>(url, contractHealthUpdateModel, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };
  updateCarContract = (contractCarUpdateModel: ContractCarUpdateModel): any => {
    let parameters: string[];
    parameters = ['CarContract'];
    let url = this.formatUrl(parameters);

    return this.httpClient
      .put<ContractModel>(url, contractCarUpdateModel, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };
  updateHouseContract = (
    contractHouseUpdateModel: ContractHouseUpdateModel
  ): any => {
    let parameters: string[];
    parameters = ['HouseContract'];
    let url = this.formatUrl(parameters);

    return this.httpClient
      .put<ContractModel>(url, contractHouseUpdateModel, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  formatUrl = (parameters: string[]): string => {
    var url = environment.comparanooapiurl;
    url += environment.features.contracts.url;
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}
