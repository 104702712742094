import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SearchService } from '../../store/search/search.service';
import { SmartAction } from '../../store/view/types';

@Component({
  selector: 'app-smart-actions-lead',
  templateUrl: './smart-actions-lead.component.html',
  styleUrls: ['./smart-actions-lead.component.scss'],
})
export class SmartActionsLeadComponent {
  @Input() smartActions: SmartAction[];

  constructor(
    private readonly searchService: SearchService,
    private readonly router: Router,
  ) {}

  onSmartLinkClick(index: number) {
    this.searchService.addActiveFilter(this.smartActions[index].filter, true);
    this.searchService.resetActiveFilter();

    if (this.smartActions[index].singleId) {
      const url = this.smartActions[index].url.replace(
        'List',
        'Details/' + this.smartActions[index].singleId,
      );
      this.router.navigate([url]);
      return;
    }

    this.router.navigate([this.smartActions[index].url], {
      state: { useTempFilter: true },
    });
  }
}
