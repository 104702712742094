import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import { DocumentApiService } from './document.api.service';
import { DocumentAction, documentActions } from './document.actions';
import { Store } from '@ngrx/store';
import { documentSelectors } from './document.selector';
import { commentActions } from '../comment/comment.action';
import { leadActions } from '../lead/lead.actions';
import { commissionPlanRuleActions } from '../commissions/commission-plan-rule/commission-plan-rule.actions';
import { contractActions } from '../contract/contract.actions';

@Injectable()
export class DocumentEffects {
  constructor(
    private readonly store: Store<any>,
    private readonly documentApiService: DocumentApiService,
    private readonly action$: Actions<DocumentAction>,
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    documentActions.getById,
    this.documentApiService.getById,
  );

  getByEntityId = createApiCallEffects(
    this,
    this.action$,
    documentActions.getByEntityId,
    this.documentApiService.getByEntityId,
    () => {},
    () => {
      return this.store.select(documentSelectors.getByEntityId.paramsSelector);
    },
  );

  getContactDocumentByEntityId = createApiCallEffects(
    this,
    this.action$,
    documentActions.getContactDocumentByEntityId,
    this.documentApiService.getByEntityId,
  );

  getContractDocumentByEntityId = createApiCallEffects(
    this,
    this.action$,
    documentActions.getContractDocumentByEntityId,
    this.documentApiService.getByEntityId,
  );

  getFile = createApiCallEffects(
    this,
    this.action$,
    documentActions.getFile,
    this.documentApiService.getFile,
  );

  add = createApiCallEffects(
    this,
    this.action$,
    documentActions.add,
    this.documentApiService.add,
  );

  update = createApiCallEffects(
    this,
    this.action$,
    documentActions.update,
    this.documentApiService.update,
    (value) => {
      this.store.dispatch(documentActions.getByEntityId.refresh());
    },
  );

  delete = createApiCallEffects(
    this,
    this.action$,
    documentActions.delete,
    this.documentApiService.delete,
    (value) => {
      this.store.dispatch(documentActions.getByEntityId.refresh());
    },
  );

  deleteFile = createApiCallEffects(
    this,
    this.action$,
    documentActions.deleteFile,
    this.documentApiService.deleteFile,
  );

  copyToDocumentRequest = createApiCallEffects(
    this,
    this.action$,
    documentActions.copyToDocumentRequest,
    this.documentApiService.copyToDocumentRequest,
    (value) => {
      this.store.dispatch(contractActions.getByUniqueId.refresh());
    },
  );
}
