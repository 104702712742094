import { createApiCallActions } from '../../../common/store/generic.actions';
import { DocumentModel } from './types';

export const documentActions = {
  getById: createApiCallActions<DocumentModel>('document', 'getById'),
  getByEntityId: createApiCallActions<DocumentModel[]>(
    'document',
    'getByEntityId',
  ),
  getContactDocumentByEntityId: createApiCallActions<DocumentModel[]>(
    'document',
    'getContactDocumentByEntityId',
  ),
  getContractDocumentByEntityId: createApiCallActions<DocumentModel[]>(
    'document',
    'getContractDocumentByEntityId',
  ),
  getFile: createApiCallActions<any>('document', 'getFile'),
  add: createApiCallActions<DocumentModel>('document', 'add'),
  update: createApiCallActions<DocumentModel>('document', 'update'),
  delete: createApiCallActions<boolean>('document', 'delete'),
  deleteFile: createApiCallActions<boolean>('document', 'deleteFile'),
  copyToDocumentRequest: createApiCallActions<DocumentModel>(
    'document',
    'copyToDocumentRequest',
  ),
};

export type DocumentAction = typeof documentActions;
