import { CommissionPlanRuleModel } from './types';
import { createApiCallActions } from '../../../../common/store/generic.actions';

export const commissionPlanRuleActions = {
  getById: createApiCallActions<CommissionPlanRuleModel>(
    'commissionPlanRule',
    'getById',
  ),
  getByProductLineId: createApiCallActions<CommissionPlanRuleModel[]>(
    'commissionPlanRule',
    'getByProductLineId',
  ),
  getSharedBySalesPersonIdAndCommissionType:
    createApiCallActions<CommissionPlanRuleModel>(
      'commissionPlanRule',
      'getSharedBySalesPersonIdAndCommissionType',
    ),
  createInstance: createApiCallActions<CommissionPlanRuleModel>(
    'commissionPlanRule',
    'createInstance',
  ),
  add: createApiCallActions<CommissionPlanRuleModel>(
    'commissionPlanRule',
    'add',
  ),
  update: createApiCallActions<CommissionPlanRuleModel>(
    'commissionPlanRule',
    'update',
  ),
  delete: createApiCallActions<boolean>('commissionPlanRule', 'delete'),
};

export type CommissionPlanRuleAction = typeof commissionPlanRuleActions;
