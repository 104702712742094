import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Entity, View } from 'src/app/shared/store/view/types';
import {
  DataType,
  DropdownStyle,
  SourceEnum,
} from '../../../store/typeahead/types';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { filter, Observable } from 'rxjs';
import { SubHeaderService } from '../../../store/subheader/subheader.service';
import { ViewService } from '../../../store/view/views.service';
import { FormService } from '../../../store/form/form.service';
import { AccountingService } from '../../../store/accounting/accounting.service';
import { ModalService } from '../../../service/modal.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  AccountMoveLineModel,
  AccountMoveModel,
  BindType,
  IssuingAccountMovePayingAccountMoveModel,
} from '../../../store/accounting/types';
import { filterTrue } from '../../../pipe/rxjs/operators';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-account-move-assign-content',
  templateUrl: './account-move-assign-content.component.html',
  styleUrls: ['./account-move-assign-content.component.scss'],
})
export class AccountMoveAssignContentComponent implements OnInit, OnDestroy {
  @Input() modalRandomIdentifier: number;
  @Input() source: SourceEnum = SourceEnum.Component;
  @Input() bindType: BindType;
  @Input() contactId: number;
  @Input() contractId: number;
  @Input() paymentId: number;
  @Input() invoiceId: number;

  view$: Observable<View>;
  assignForm: FormGroup;
  accountMoves$: Observable<AccountMoveModel[]>;

  paymentAmount: number = 0;
  invoiceAmount: number = 0;

  protected readonly DataType = DataType;
  protected readonly DropdownStyle = DropdownStyle;
  protected readonly Entity = Entity;

  constructor(
    private readonly subHeaderService: SubHeaderService,
    private readonly viewService: ViewService,
    private readonly formBuilder: FormBuilder,
    private readonly formService: FormService,
    private readonly accountingService: AccountingService,
    private readonly dynamicService: ModalService,
  ) {}

  ngOnInit(): void {
    this.assignForm = this.formBuilder.group({
      payments: this.formBuilder.array([]),
      invoices: this.formBuilder.array([]),
    });

    if (this.contactId) {
      this.accountMoves$ = this.accountingService.getUnbindedByContactId.value$;
      this.accountingService.getUnbindedByContactId.call({
        id: this.contactId.toString(),
        bindType: 'Payment',
      });
    }
    if (this.contractId) {
      this.accountMoves$ =
        this.accountingService.getUnbindedByContractId.value$;
      this.accountingService.getUnbindedByContractId.call({
        id: this.contractId.toString(),
        bindType: this.bindType,
      });
    }

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'accountMoveAssignCreate') this.onSubmit();
        this.subHeaderService.setTriggerAction('');
      });

    this.accountMoves$
      .pipe(filterTrue(), take(1))
      .subscribe((accountMoves: AccountMoveModel[]) => {
        accountMoves.forEach((accountMove) => {
          if (
            accountMove.accountMoveLines &&
            accountMove.accountMoveLines.length > 0
          ) {
            accountMove.accountMoveLines.forEach((accountMoveLine) => {
              switch (accountMove.moveType) {
                case 'Entry':
                  const accountMoveFormEntry = this.formBuilder.group({
                    id: [accountMove.id],
                    name: [accountMoveLine.name],
                    amount: [accountMoveLine.unitPrice],
                    checked: [false, [Validators.required]],
                  });

                  this.invoices.push(this.formBuilder.group({}));
                  this.payments.push(accountMoveFormEntry);
                  break;
                case 'CustomerInvoice':
                  const accountMoveFormCustomerInvoice = this.formBuilder.group(
                    {
                      id: [accountMove.id],
                      name: [accountMove.name],
                      amount: [accountMoveLine.unitPrice],
                      checked: [false, [Validators.required]],
                    },
                  );
                  this.invoices.push(accountMoveFormCustomerInvoice);
                  this.payments.push(this.formBuilder.group({}));
                  break;
                case 'VendorInvoice':
                default:
                  break;
              }
            });
          } else {
            this.invoices.push(this.formBuilder.group({}));
            this.payments.push(this.formBuilder.group({}));
          }
        });
        if (this.payments.controls && this.paymentId) {
          this.addPayment(this.paymentId);
        }
        if (this.invoices.controls && this.invoiceId) {
          this.addInvoice(this.invoiceId);
        }
      });
  }

  onSubmit() {
    if (this.assignForm.invalid) {
      this.assignForm.markAllAsTouched();
      this.formService.setEntityErrors('ASSIGN');
      this.formService.countErrors(this.assignForm, true);
      return;
    }
    // if (this.paymentAmount < this.invoiceAmount) return;

    let paymentControls = this.payments.controls as FormControl[];

    const payments: number[] = paymentControls
      .filter((x: FormControl) => x.value.checked == true)
      .map((x: FormControl) => x.value.id);

    let invoiceControls = this.invoices.controls as FormControl[];

    const invoices: number[] = invoiceControls
      .filter((x: FormControl) => x.value.checked == true)
      .map((x: FormControl) => x.value.id);

    const issuingAccountMovePayingAccountMoveModel: IssuingAccountMovePayingAccountMoveModel =
      {
        issuingAccountMoves: invoices,
        payingAccountMoves: payments,
        bindType: 'Payment',
      };

    let result$ = this.accountingService.bind.call(
      issuingAccountMovePayingAccountMoveModel,
    );

    result$
      .pipe(
        filter((x) => x !== undefined && x !== null),
        take(1),
      )
      .subscribe((result) => {
        this.formService.clear();
        switch (this.source) {
          case SourceEnum.Component:
            //   this.userService.getCurrentUser.call();
            // this.router.navigate(['/Contacts/Contacts/Details', result.id]);
            break;
          case SourceEnum.Modal:
            this.dynamicService.outputFromDynamicComponent(
              this.modalRandomIdentifier,
              result ? '1' : '-1',
            ); //1 to refresh the data, -1 to cancel
            break;
          default:
            // this.router.navigate(['/Contacts/Contacts/Details', result.id]);
            break;
        }
      });
  }

  ngOnDestroy(): void {
    this.accountingService.getUnbindedByContactId.reset();
    this.accountingService.getUnbindedByContractId.reset();
    this.accountingService.bind.reset();
  }

  get payments() {
    return this.assignForm.controls['payments'] as FormArray;
  }

  get invoices() {
    return this.assignForm.controls['invoices'] as FormArray;
  }

  addPayment(accountMoveId: number) {
    let controls = this.payments.controls as FormControl[];
    const index = controls.findIndex(
      (x: FormControl) => x.value.id == accountMoveId,
    );
    const value = (this.payments.controls[index] as FormGroup).controls[
      'checked'
    ].value;

    (this.payments.controls[index] as FormGroup).controls['checked'].patchValue(
      !value,
    );

    if (value) {
      this.paymentAmount -= (
        this.payments.controls[index] as FormGroup
      ).controls['amount'].value;
    } else {
      this.paymentAmount += (
        this.payments.controls[index] as FormGroup
      ).controls['amount'].value;
    }
  }

  addInvoice(accountMoveId: number) {
    let controls = this.invoices.controls as FormControl[];
    const index = controls.findIndex(
      (x: FormControl) => x.value.id == accountMoveId,
    );
    const value = (this.invoices.controls[index] as FormGroup).controls[
      'checked'
    ].value;

    (this.invoices.controls[index] as FormGroup).controls['checked'].patchValue(
      !value,
    );

    if (value) {
      this.invoiceAmount -= (
        this.invoices.controls[index] as FormGroup
      ).controls['amount'].value;
    } else {
      this.invoiceAmount += (
        this.invoices.controls[index] as FormGroup
      ).controls['amount'].value;
    }
  }

  trackByAccountMoveId(index: any, item: AccountMoveModel) {
    return item.id;
  }
  trackByAccountMoveLineId(index: any, item: AccountMoveLineModel) {
    return item.id;
  }
}
