import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CashControlApiService } from './cash-control.api.service';
import { CashControlEffects } from './cash-control.effects';
import {
  ICashControlState,
  cashControlInitialState,
  cashControlReducer,
} from './cash-control.reducer';
import { CashControlService } from './cash-control.service';
import { cashControlFeatureKey } from './types';

@NgModule({
  imports: [
    StoreModule.forFeature<ICashControlState>(
      cashControlFeatureKey,
      cashControlReducer,
      {
        initialState: cashControlInitialState,
      },
    ),
    EffectsModule.forFeature([CashControlEffects]),
  ],
  providers: [CashControlApiService, CashControlService],
})
export class StoreCashControlModule {}
