import { createApiCallActions } from '../../../common/store/generic.actions';
import { ApplicationFeeRuleModel } from './types';
import { CommissionPlanRuleModel } from '../commissions/commission-plan-rule/types';

export const applicationFeeRuleActions = {
  getById: createApiCallActions<ApplicationFeeRuleModel>(
    'applicationFeeRule',
    'getById',
  ),
  getByProductLineId: createApiCallActions<ApplicationFeeRuleModel[]>(
    'applicationFeeRule',
    'getByProductLineId',
  ),
  getByContractId: createApiCallActions<ApplicationFeeRuleModel>(
    'applicationFeeRule',
    'getByContractId',
  ),
  createInstance: createApiCallActions<ApplicationFeeRuleModel>(
    'applicationFeeRule',
    'createInstance',
  ),
  add: createApiCallActions<ApplicationFeeRuleModel>(
    'applicationFeeRule',
    'add',
  ),
  update: createApiCallActions<ApplicationFeeRuleModel>(
    'applicationFeeRule',
    'update',
  ),
  delete: createApiCallActions<boolean>('applicationFeeRule', 'delete'),
};

export type ApplicationFeeRuleAction = typeof applicationFeeRuleActions;
