<ng-container *ngIf="this.data$ | async as data">
  <div class="card-list p-3">
    <div class="card-col" *ngFor="let item of data.data">
      <div class="card data-card h-100"
           [class]="item['active'] | cardactivepipe"
      >
          <div class="card-body">
            <div class="card-left pe-2">
              <ng-container *ngIf="this.currentAvatar$ | async as avatar">
                <img src="{{this.avatar.url}}?name={{item['contactName']}}&bgColor={{this.avatar.bgColor}}&color=FFFFFF"
                     alt="{{item['contactName']}}"
                     class="card-img"/>
              </ng-container>
            </div>
            <div class="card-content">
              <h5 class="card-title" *ngIf="item['contactName']"> {{ item['contactName'] }} </h5>
              <span class="card-subtitle text-muted mb-1" *ngIf="item['jobName']">
              {{ item['jobName'] }}
                </span>
              <span class="card-subtitle text-muted mb-1" *ngIf="item['workPhone']">
                  <i class="mdi mdi-phone me-1"></i>
                {{ item['workPhone'] }}
                </span>
              <span class="card-subtitle text-muted mb-1" *ngIf="item['workEmail']">
                  <i class="mdi mdi-email me-1"></i>
                {{ item['workEmail'] }}
                </span>
            </div>
          </div>
          <!--        <div class="card-ur-corner-wrapper">-->
          <!--          <div class="dropdown card-menu" *ngIf="view.kanbanView.states.length > 0">-->
          <!--            <button class="btn dropdown-toggle btn-outline-light show" type="button" id="dropdownMenuButton"-->
          <!--                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
          <!--              <i class="mdi mdi-dots-vertical"></i>-->
          <!--            </button>-->
          <!--            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="">-->
          <!--              <a class="dropdown-item" href="#" *ngFor="let state of view.kanbanView.states">{{ state }}</a>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--        </div>-->
          <a (click)="onCardClick(item['id'])" class="stretched-link"></a></div>
      </div>
  </div>
</ng-container>
