import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DocumentTypeService } from '../../../../shared/store/document-type/document-type.service';
import { Observable } from 'rxjs';
import { ListItem, SourceEnum } from '../../../../shared/store/typeahead/types';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { filterTrue } from '../../../../shared/pipe/rxjs/operators';
import { take } from 'rxjs/operators';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { DocumentTypeModel } from '../../../../shared/store/document-type/types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@UntilDestroy()
@Component({
  selector: 'app-select-document-type-edit-content',
  templateUrl: './select-document-type-edit-content.component.html',
  styleUrls: ['./select-document-type-edit-content.component.scss'],
})
export class SelectDocumentTypeEditContentComponent
  implements OnInit, OnDestroy
{
  @Input() source: SourceEnum = SourceEnum.Component;

  documentTypeListItem$: Observable<ListItem[]>;
  form: FormGroup;

  constructor(
    private readonly documentTypeService: DocumentTypeService,
    private readonly formBuilder: FormBuilder,
    private readonly subHeaderService: SubHeaderService,
    protected readonly activeModalService: NgbActiveModal,
  ) {
    this.documentTypeListItem$ = this.documentTypeService.getListItem.value$;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      documentTypes: this.formBuilder.array([]),
    });

    this.documentTypeService.getListItem.call({
      filter: 'recordType=0', //do not include system document
      pageSize: 100,
      orderBy: '',
    });

    this.documentTypeListItem$
      .pipe(filterTrue(), take(1))
      .subscribe((documentTypes) => {
        documentTypes.forEach((documentType) => {
          const documentTypeForm = this.formBuilder.group({
            id: [documentType.id],
            name: [documentType.name],
            checked: [false, [Validators.required]],
          });
          this.documentTypes.push(documentTypeForm);
        });
        this.documentTypeService
          .getDocumentTypes()
          .pipe(filterTrue(), take(1))
          .subscribe((x) => {
            for (let i = 0; i < x.length; i++) {
              this.addDocumentType(x[i].id);
            }
          });
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'documentTypeUpdate') {
          this.onSubmit();
        }
        this.subHeaderService.setTriggerAction('');
      });
  }

  onSubmit() {
    let controls = this.documentTypes.controls as FormControl[];

    const documentTypes: DocumentTypeModel[] = controls
      .filter((x: FormControl) => x.value.checked == true)
      .map((x: FormControl) => ({
        id: x.value.id,
        name: x.value.name,
        description: '',
      }));

    this.documentTypeService.setDocumentTypes(documentTypes);

    this.activeModalService.close();
  }

  ngOnDestroy(): void {
    this.documentTypeService.getListItem.reset();
  }

  get documentTypes() {
    return this.form.controls['documentTypes'] as FormArray;
  }

  addDocumentType(documentTypeId: number) {
    let controls = this.documentTypes.controls as FormControl[];
    const index = controls.findIndex(
      (x: FormControl) => x.value.id == documentTypeId,
    );
    const value = (this.documentTypes.controls[index] as FormGroup).controls[
      'checked'
    ].value;

    (this.documentTypes.controls[index] as FormGroup).controls[
      'checked'
    ].patchValue(!value);
  }
}
