<div
  class="tab-pane fade flex-even active show"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
>
  <div
    class="d-flex flex-column h-100"
    *ngIf="this.contract$ | async as contract"
  >
    <div class="form-group-title my-1" translate>
      CONTRACT_FIELDSET_SHARED_COMMISSION
    </div>
    <div class="row mt-2">
      <div class="col-12 col-md">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_SHAREDSALESPERSON</label
          >
          <div class="col">
            <input
              class="form-control-plaintext form-control-sm"
              type="text"
              readonly
              value="{{ contract.sharedSalesPersonContactName }}"
            />
          </div>
        </div>
        <div
          class="form-group row"
          *ngIf="contract.sharedSalesPersonId != null"
        >
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_SHAREDCOMMISSIONRATE</label
          >
          <div class="col">
            <input
              class="form-control-plaintext form-control-sm"
              type="text"
              readonly
              value="{{ contract.sharedCommissionRate }} %"
            />
          </div>
        </div>
      </div>

      <div class="col"></div>
    </div>
  </div>
</div>
