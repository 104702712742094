import { Injectable } from '@angular/core';
import { BaseServiceFacade, GenericApiCall } from 'src/app/common/store/types';
import { PaymentSplitting } from './type';
import { paymentSplittingActions } from './payment-splitting.actions';
import { paymentSplittingSelectors } from './payment-splitting.selectors';
import { AppState } from '../types';
import { Store } from '@ngrx/store';

@Injectable()
export class PaymentSplittingService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  getById: GenericApiCall<PaymentSplitting, string> = this.genericApiCall(
    paymentSplittingActions.getById,
    paymentSplittingSelectors.getById,
    true
  );

  getByProductLine: GenericApiCall<PaymentSplitting[], string> =
    this.genericApiCall(
      paymentSplittingActions.getByProductLine,
      paymentSplittingSelectors.getByProductLine,
      true
    );

  getByInsuranceCompanyId: GenericApiCall<PaymentSplitting[], number> =
    this.genericApiCall(
      paymentSplittingActions.getByInsuranceCompanyId,
      paymentSplittingSelectors.getByInsuranceCompanyId,
      true
    );
}
