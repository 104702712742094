import { createApiCallActions } from '../../../common/store/generic.actions';
import { CashControlModel } from './types';

export const cashControlActions = {
  getById: createApiCallActions<CashControlModel>('cashControl', 'getById'),
  getBySalesTeamAccountId: createApiCallActions<CashControlModel[]>(
    'cashControl',
    'getBySalesTeamAccountId',
  ),
  add: createApiCallActions<CashControlModel>('cashControl', 'add'),
  update: createApiCallActions<CashControlModel>('cashControl', 'update'),
  delete: createApiCallActions<boolean>('cashControl', 'delete'),
  createInstance: createApiCallActions<CashControlModel>(
    'cashControl',
    'createInstance',
  ),
};

export type CashControlAction = typeof cashControlActions;
