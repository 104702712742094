import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinAllArrayValues',
})
export class JoinAllArrayValuesPipe implements PipeTransform {
  transform(value: string[] | undefined, delimiter: string): string {
    if (!value) return '';

    return value.join(`${delimiter} `);
  }
}
