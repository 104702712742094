import { createApiCallActions } from 'src/app/common/store/generic.actions';
import { PaymentSplitting } from './type';

export const paymentSplittingActions = {
  getById: createApiCallActions<PaymentSplitting>(
    'paymentSplitting',
    'getById'
  ),
  getByProductLine: createApiCallActions<PaymentSplitting[]>(
    'paymentSplitting',
    'getByProductLine'
  ),
  getByInsuranceCompanyId: createApiCallActions<PaymentSplitting[]>(
    'paymentSplitting',
    'getByInsuranceCompanyId'
  ),
};
export type PaymentSplittingAction = typeof paymentSplittingActions;
