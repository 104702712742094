import { AmountType } from '../store/commissions/commission-plan-rule/types';

export const PERCENTAGE: string = 'PAYMENT_PLACEHOLDER_PERCENTAGE';
export const MONETARY: string = 'PAYMENT_PLACEHOLDER_MONETARY';

export function getAmountSign(
  defaultAmountType: AmountType,
  amountType?: AmountType,
): string {
  let defaultAmountSign: string = '';
  switch (defaultAmountType) {
    case 'Percentage':
      defaultAmountSign = PERCENTAGE;
      break;
    case 'Monetary':
      defaultAmountSign = MONETARY;
      break;
  }

  switch (amountType) {
    case 'Percentage':
      return PERCENTAGE;
    case 'Monetary':
      return MONETARY;
    default:
      //Euro is the default
      return defaultAmountSign;
  }
}
