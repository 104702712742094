<div
  class="tab-pane fade flex-even active show"
  id="employees"
  role="tabpanel"
  aria-labelledby="employees-tab"
  formGroupName="publicInformationsFormGroup"
>
  <div class="d-flex flex-column h-100">
    <div class="row mt-2">
      <div class="col-12 col-md">
        <div class="form-group row">
              <label class="col-4 col-form-label-sm" translate
              >EMPLOYEE_LBL_WORKING_ADDRESS</label
              >
              <div class="col">
                <app-typeahead
                  [routeEndpoint]="'Contacts'"
                  [entity]="Entity.Contact"
                  [controlForm]="this.form.controls['publicInformationsFormGroup'].get('publicInformationsWorkingAddressId')"
                  [domain]="'name!=null,isCompany=true'"
                  [orderBy]="'lastname asc'"
                  [limitToList]="true"
                ></app-typeahead>
              </div>
            </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate>EMPLOYEE_LBL_USER</label>
          <div class="col">
            <app-typeahead
              [routeEndpoint]="'Users'"
              [entity]="Entity.User"
              [controlForm]="this.form.controls['publicInformationsFormGroup'].get('publicInformationsUserId')"
              [orderBy]="'contactName asc'"
              [limitToList]="true"
            ></app-typeahead>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate>EMPLOYEE_LBL_JOB</label>
          <div class="col">
            <app-typeahead
              [entity]="Entity.Job"
              [routeEndpoint]="'Jobs'"
              [controlForm]="this.form.controls['publicInformationsFormGroup'].get('publicInformationsJobId')"
              [limitToList]="false"
              [dropdownStyle]="DropdownStyle.DropDownList"
              [quickAdd]="true"
            ></app-typeahead>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate>EMPLOYEE_LBL_MANAGER</label>
          <div class="col">
            <app-typeahead
              [entity]="Entity.Employee"
              [routeEndpoint]="'Employees'"
              [controlForm]="this.form.controls['publicInformationsFormGroup'].get('publicInformationsManagerId')"
              [limitToList]="true"
              [dropdownStyle]="DropdownStyle.DropDown"
              [dbNameOfName]="'ResourceName'"
              [dbNameOfId]="'Id'"
              [domain]="'isManager=true'"
            ></app-typeahead>
<!--            ,id!='+this.form.controls['publicInformationsFormGroup'].get('publicInformationsManagerId')?.value-->
          </div>
        </div>

        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate>EMPLOYEE_LBL_ISMANAGER</label>
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              <input
                type="checkbox"
                name="active"
                formControlName="publicInformationsIsManager"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
