<div
  class="file-upload-wrapper mb-2"
  [class.file-over]="this.fileOver$ | async"
  (drop)="onFileDrop($event)"
  (dragover)="onDragOver($event)"
  (dragleave)="onDragLeave($event)"
  (dragend)="onDragEnd($event)"
>

  <!--  <app-document-add [entityId]="this.entityId" [entityType]="this.entityType" [multiple]="true"-->
  <!--                    (newDocument)="refreshDocument($event)" #newDocument></app-document-add>-->
  <app-document-add #newDocument [entityId]="this.entityId" [entityType]="this.entityType"
                    (newDocument)="refresh($event)" [multiple]="multiple"/>

  <button
    type="button"
    [class]="ButtonType.Primary | buttontypepipe"
    class="btn-sm btn"
    (click)="newDocument.fileupload.nativeElement.click()"
    translate
  >
    {{ button_label }}
  </button>
  <div class="ms-auto me-auto" translate [translateParams]="{ label_button: button_label | translate }">DOCUMENT_LBL_ADD_DESCRIPTION</div>
</div>
