<div
  class="tab-pane fade flex-even active show"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
>
  <!--  *ngIf="contractDetail$ | async as contractDetail"-->
  <div class="d-flex flex-column h-100">
    <div class="row mt-2">
      <div class="col-12 col-md">
        <div class="form-group-title my-1" translate>
          CONTRACT_FIELDSET_DOCUMENTS
        </div>
        <div class="row mt-2">
          <div class="col-12">
            @if (sortedDocuments$ | async; as sortedDocuments) {
              @for (document of sortedDocuments; track document.id) {
                <app-contract-document-request-card
                  [documentModel]="document"
                  (onNewDocumentRefresh)="onNewDocument($event)"
                  (onDocumentDownload)="onDocumentDownload($event)"
                  (onDocumentEdit)="onDocumentEdit($event)"
                  (onDocumentDelete)="onDocumentDelete($event)"
                />
              }
            }
          </div>
        </div>
      </div>
    </div>
        <div class="row mt-2">
          <div class="col-12 col-md">
            <div class="form-group-title my-1" translate>
              CONTRACT_FIELDSET_DOCUMENTS_AVAILABLE
            </div>
            @if (this.allDocuments$ | async; as availableDocuments) {
              <app-generic-grid
                [data]="availableDocuments"
                [tabViewName]="'allDocuments'"
                [dragAndDrop]="true"
                [rowHeight]="25"
                [pageSize]="10"
                (onActionClickedSetRowData)="onSetRowData($event)"
              />
            }

            <div class="row mt-2">
              <div class="col-12">
                <app-document-upload-drag-and-drop
                  [entityId]="this.contractId.toString()"
                  [entityType]="'Contract'"
                  [multiple]="true"
                  [button_label]="'CONTRACT_LBL_DOCUMENTS_ADD'"
                  [isDefaultUse]="false"
                  (onAddedDocumentRefresh)="onRefreshContractDocument($event)"
                />
              </div>
            </div>
          </div>
        </div>
<!--    <div class="row mt-2">-->
<!--      <div class="col-12 col-md">-->
<!--        <div class="form-group-title my-1" translate>-->
<!--          CONTRACT_FIELDSET_DOCUMENTS_AVAILABLE_CONTRACT-->
<!--        </div>-->
<!--        <div class="row mt-2">-->
<!--          <div class="col-12">-->
<!--            <app-document-upload-drag-and-drop-->
<!--              [entityId]="this.contractId.toString()"-->
<!--              [entityType]="'Contract'"-->
<!--              [multiple]="true"-->
<!--              [button_label]="'CONTRACT_LBL_DOCUMENT_ADD'"-->
<!--              [isDefaultUse]="false"-->
<!--              (onAddedDocumentRefresh)="onRefreshContractDocument($event)"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->
<!--        @if (this.contractDocumentsNoRequest$ | async; as availableContractDocuments) {-->
<!--          <app-generic-grid-->
<!--            [data]="availableContractDocuments"-->
<!--            [tabViewName]="'contractDocuments'"-->
<!--            [dragAndDrop]="true"-->
<!--            (onActionClickedSetRowData)="onSetRowData($event)"-->
<!--          />-->
<!--        }-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="row mt-2">-->
<!--      <div class="col-12 col-md">-->
<!--        <div class="form-group-title my-1" translate>-->
<!--          CONTRACT_FIELDSET_DOCUMENTS_AVAILABLE_CONTACT-->
<!--        </div>-->
<!--        <div class="row mt-2">-->
<!--          <div class="col-12">-->
<!--            <app-document-upload-drag-and-drop-->
<!--              [entityId]="this.contactId.toString()"-->
<!--              [entityType]="'Contact'"-->
<!--              [multiple]="true"-->
<!--              [button_label]="'CONTRACT_LBL_DOCUMENT_ADD'"-->
<!--              [isDefaultUse]="false"-->
<!--              (onAddedDocumentRefresh)="onRefreshContactDocument($event)"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->
<!--        @if (this.contactDocuments$ | async; as availableContactDocuments) {-->
<!--          <app-generic-grid-->
<!--            [data]="availableContactDocuments"-->
<!--            [tabViewName]="'contactDocuments'"-->
<!--            [dragAndDrop]="true"-->
<!--            (onActionClickedSetRowData)="onSetRowData($event)"-->
<!--          />-->
<!--        }-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>
