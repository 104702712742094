<app-document-subheader></app-document-subheader>
<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div class="page-container partner-details">
        <div class="card card-light w-100">
          <div class="card-body">
            <div class="container-fluid">
              <form
                [formGroup]="contractForm"
                (ngSubmit)="onSubmit()"
                [hasUnsavedData]="contractForm"
              >
                <button type="submit" hidden="hidden"></button>
                <div class="row mb-2">
                  <div class="col-12 mr-auto">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-6">
                          <label class="form-title-label" translate
                            >CONTRACT_LBL_NAME</label
                          >
                          <input
                            class="form-control form-title"
                            type="text"
                            formControlName="name"
                          />
                        </div>
                        <div class="col-6"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md">
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                        >CONTRACT_LBL_CLIENT</label
                      >
                      <div class="col">
                        <app-typeahead
                          [entity]="Entity.Contact"
                          [routeEndpoint]="'Contacts'"
                          [controlForm]="this.contractForm.controls['clientId']"
                          [orderBy]="'name asc'"
                          [allowCustomActions]="true"
                          [limitToList]="true"
                        ></app-typeahead>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                        >CONTRACT_LBL_SALESTEAM</label
                      >
                      <div class="col">
                        <app-typeahead
                          [entity]="Entity.SalesTeam"
                          [routeEndpoint]="'SalesTeams'"
                          [controlForm]="
                            this.contractForm.controls['salesTeam']
                          "
                          [limitToList]="true"
                        ></app-typeahead>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                        >CONTRACT_LBL_SALESPERSON</label
                      >
                      <div class="col">
                        <app-typeahead
                          [entity]="Entity.SalesTeam"
                          [routeEndpoint]="'SalesTeams/SalesTeamMembers'"
                          [controlForm]="
                            this.contractForm.controls['salesTeamMember']
                          "
                          [limitToList]="true"
                          [referenceFilterParentName]="'SalesTeamId'"
                          [referenceFilterParentValue]="
                            this.contractForm.controls['salesTeam'].value
                          "
                          [dbNameOfName]="'UserContactName'"
                          [dbNameOfId]="'UserId'"
                        ></app-typeahead>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                        >CONTRACT_LBL_MANAGER</label
                      >
                      <div class="col">
                        <app-typeahead
                          [entity]="Entity.SalesTeam"
                          [routeEndpoint]="'SalesTeams/SalesTeamMembers'"
                          [controlForm]="
                            this.contractForm.controls['contractManagerId']
                          "
                          [limitToList]="true"
                          [referenceFilterParentName]="'SalesTeamId'"
                          [referenceFilterParentValue]="
                            this.contractForm.controls['salesTeam'].value
                          "
                          [dbNameOfName]="'UserContactName'"
                          [dbNameOfId]="'UserId'"
                        ></app-typeahead>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                        >CONTRACT_LBL_CATEGORY</label
                      >
                      <div class="col">
                        <product-category-edit
                          [controlForm]="
                            this.contractForm.controls['productCategoryId']
                          "
                          [isReadonly]="true"
                          (categoryChange)="onCategoryChange($event)"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                        >CONTRACT_LBL_COMPANY</label
                      >
                      <div class="col">
                        <app-typeahead
                          [routeEndpoint]="'InsuranceCompanies'"
                          [entity]="Entity.InsuranceCompany"
                          [dbNameOfName]="'contactName'"
                          [controlForm]="
                            this.contractForm.controls['companyId']
                          "
                          [orderBy]="'contactname asc'"
                          [allowCustomActions]="false"
                          [limitToList]="true"
                          [domain]="this.companyDomain"
                        ></app-typeahead>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                        >CONTRACT_LBL_PRODUCTLINE</label
                      >
                      <div class="col">
                        <app-typeahead
                          [routeEndpoint]="'ProductLines'"
                          [entity]="Entity.ProductLine"
                          [controlForm]="
                            this.contractForm.controls['productLineId']
                          "
                          [orderBy]="'name asc'"
                          [allowCustomActions]="false"
                          [limitToList]="true"
                          [domain]="this.productLineDomain"
                        ></app-typeahead>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                        >CONTRACT_LBL_PRODUCT</label
                      >
                      <div class="col">
                        <app-typeahead
                          [routeEndpoint]="'Products'"
                          [entity]="Entity.Product"
                          [controlForm]="
                            this.contractForm.controls['productId']
                          "
                          [orderBy]="'name asc'"
                          [allowCustomActions]="false"
                          [limitToList]="true"
                          [domain]="this.productDomain"
                        ></app-typeahead>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                        >CONTRACT_LBL_EFFECT_DATE</label
                      >
                      <div class="col">
                        <input
                          class="form-control form-control-sm"
                          type="date"
                          formControlName="effectiveDate"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                        >CONTRACT_LBL_SUBSCRIPTION_DATE</label
                      >
                      <div class="col">
                        <input
                          class="form-control form-control-sm"
                          type="date"
                          formControlName="subscriptionDate"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <app-contract-tabs-form
                  [category]="this.category"
                  [form]="this.contractForm"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-comment" *ngIf="this.contractIdNumber">
      <app-comment-panel
        [entityType]="'Contract'"
        [entityId]="this.contractIdNumber.toString()"
      ></app-comment-panel>
<!--      <app-history-panel-->
<!--        [entityType]="'Contract'"-->
<!--        [entityId]="this.contractIdNumber.toString()"-->
<!--      ></app-history-panel>-->
    </div>
  </div>
</div>
