import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  applicationFeeRuleInitialState,
  applicationFeeRuleReducer,
  IApplicationFeeRuleState,
} from './application-fee-rule.reducer';
import { applicationFeeRuleFeatureKey } from './types';
import { ApplicationFeeRuleEffects } from './application-fee-rule.effects';
import { ApplicationFeeRuleApiService } from './application-fee-rule.api.service';
import { ApplicationFeeRuleService } from './application-fee-rule.service';

@NgModule({
  imports: [
    StoreModule.forFeature<IApplicationFeeRuleState>(
      applicationFeeRuleFeatureKey,
      applicationFeeRuleReducer,
      {
        initialState: applicationFeeRuleInitialState,
      },
    ),
    EffectsModule.forFeature([ApplicationFeeRuleEffects]),
  ],
  providers: [ApplicationFeeRuleApiService, ApplicationFeeRuleService],
})
export class StoreApplicationFeeRuleModule {}
