import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Config, Environment } from 'src/app/core/types';
import { OAuthenticationService } from 'src/app/shared/authentication/oauthentication.service';
import { AuthenticationService } from 'src/app/shared/store/authentication/authentication.service';
import { UserConnected } from 'src/app/shared/store/authentication/types';
import { Avatar } from '../../shared/store/user/type';
import { UserService } from '../../shared/store/user/user.service';
import { environment } from '../../../environments/environment';
import { filter, map, take } from 'rxjs/operators';
import { ModalService } from '../../shared/service/modal.service';
import { ModalContentComponent } from '../../shared/components/modal-content/modal-content.component';
import { NavigationService } from '../../shared/store/navigation/navigation.service';
import { Menu } from '../../shared/store/navigation/types';
import { FormService } from '../../shared/store/form/form.service';
import { ActionButton } from '../../shared/components/modal-content/action-button';
import { toFirstLetterLower } from '../../shared/helper/stringhelper';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  config!: Config;
  env!: { name: Environment; version: string };
  user$!: Observable<UserConnected>;
  currentLanguage$!: Observable<string>;
  notCurrentLanguage$!: Observable<string[]>;
  isIpad!: boolean;
  isAuthenticated$!: Observable<boolean>;
  menus$: Observable<Menu[]>;

  currentAvatar$: Observable<Avatar>;

  constructor(
    private readonly navigationService: NavigationService,
    private readonly authService: OAuthenticationService,
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly modalService: NgbModal,
    private readonly modalExchangeService: ModalService,
    private readonly formService: FormService,
  ) {
    this.user$ = this.authenticationService.getUserConnected();
    this.menus$ = this.navigationService.getMenus.value$;
  }

  ngOnInit(): void {
    const bgColor = window
      .getComputedStyle(document.body)
      .getPropertyValue('--comment-avatar-bg')
      .replace('#', '');

    this.currentAvatar$ = this.userService.getCurrentUser.value$.pipe(
      filter((user) => !!user),
      map((user) => {
        let url: string = '';
        if (!user.contactPicturePath) {
          url = `${
            environment.comparanooapiurl +
            environment.features.thumbnail.url +
            environment.features.thumbnail.avatar
          }?name=${user.contactName}&bgColor=${bgColor}&color=FFFFFF`;
        } else {
          url = environment.comparanooapiurl + user.contactPicturePath;
        }

        return new Avatar(user.contactName, url, '');
      }),
    );

    //this.env = this.config.env;
    //this.isIpad = this.config.deviceInfo.device.toLowerCase().includes('ipad');
  }

  ngOnDestroy(): void {}

  logout() {
    this.formService.clear();
    this.authService.logout();
    this.router.navigateByUrl('/Login');
  }

  changePassword() {
    const modalRef = this.modalService.open(ModalContentComponent, {
      size: 'lg',
      backdrop: 'static',
    });
    modalRef.componentInstance.component =
      this.modalExchangeService.getComponentClass(
        'PasswordManageContentComponent',
      );
    modalRef.componentInstance.title = 'ACCOUNT_LBL_TITLE';
    modalRef.componentInstance.cancelButton = true;
    modalRef.componentInstance.buttons = [
      <ActionButton>{
        actionName: 'changePassword',
      },
    ];

    // modalRef.result.then((id) => {}).finally(() => this.formService.clear());
  }
}
