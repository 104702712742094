import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import { AccountAction, accountActions } from './account.actions';
import { AccountApiService } from './account.api.service';

@Injectable()
export class AccountEffects {
  constructor(
    private readonly accountApiService: AccountApiService,
    private readonly action$: Actions<AccountAction>,
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    accountActions.getById,
    this.accountApiService.getById,
  );

  add = createApiCallEffects(
    this,
    this.action$,
    accountActions.add,
    this.accountApiService.add,
  );

  update = createApiCallEffects(
    this,
    this.action$,
    accountActions.update,
    this.accountApiService.update,
  );

  delete = createApiCallEffects(
    this,
    this.action$,
    accountActions.delete,
    this.accountApiService.delete,
  );

  create = createApiCallEffects(
    this,
    this.action$,
    accountActions.createInstance,
    this.accountApiService.createInstance,
  );
}
