@if (message$ | async; as message) {
  @if (needTranslation) {
    <span class="badge badge-{{this.badgeColor}}" translate>
     {{ message }}
    </span>
  } @else {
    <span class="badge badge-{{this.badgeColor}}">
     {{ message }}
    </span>
  }
}
