import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SourceEnum } from '../../../store/typeahead/types';
import { FormService } from '../../../store/form/form.service';
import { SubHeaderService } from '../../../store/subheader/subheader.service';
import { ScraperService } from '../../../store/scraper/scraper.service';
import { OcealizAccountType } from '../../../store/scraper/types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { filterTrue } from '../../../pipe/rxjs/operators';
import { ModalService } from '../../../service/modal.service';

@UntilDestroy()
@Component({
  selector: 'app-update-password-account',
  templateUrl: './update-password-account.component.html',
  styleUrls: ['./update-password-account.component.scss'],
})
export class UpdatePasswordAccountComponent implements OnInit, OnDestroy {
  @Input() modalRandomIdentifier: number;
  @Input() source: SourceEnum = SourceEnum.Component;
  @Input({ required: true }) id: number;
  @Input({ required: true }) ocealizType: OcealizAccountType;

  updatePasswordForm: FormGroup;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly formService: FormService,
    private readonly subHeaderService: SubHeaderService,
    private readonly scraperService: ScraperService,
    private readonly dynamicService: ModalService,
  ) {}

  ngOnInit(): void {
    this.updatePasswordForm = this.formBuilder.group({
      password: ['', [Validators.required]],
    });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'insuranceUpdatePassword' || x == 'counselorUpdatePassword')
          this.onSubmit();
        this.subHeaderService.setTriggerAction('');
      });
  }

  onSubmit() {
    if (this.updatePasswordForm.invalid) {
      this.updatePasswordForm.markAllAsTouched();
      this.formService.setEntityErrors('PASSWORD');
      this.formService.countErrors(this.updatePasswordForm, true);
      return;
    }

    switch (this.ocealizType) {
      case 'Insurance':
        this.onUpdatePasswordInsurance();
        break;
      case 'Counselor':
        this.onUpdatePasswordCounselor();
    }
  }

  onUpdatePasswordInsurance() {
    let resultInsurance$ = this.scraperService.updatePasswordInsurance.call({
      insuranceId: this.id.toString(),
      password: this.updatePasswordForm.value.password!,
    });

    resultInsurance$.pipe(filterTrue(), take(1)).subscribe((result) => {
      this.dynamicService.resetFromDynamicComponent();
      this.scraperService.updatePasswordInsurance.reset();
    });
  }

  onUpdatePasswordCounselor() {
    let resultCounselor$ = this.scraperService.updatePasswordCounselor.call({
      counselorId: this.id.toString(),
      password: this.updatePasswordForm.value.password!,
    });

    resultCounselor$.pipe(filterTrue(), take(1)).subscribe((result) => {
      this.dynamicService.resetFromDynamicComponent();
      this.scraperService.updatePasswordCounselor.reset();
    });
  }

  ngOnDestroy(): void {
    this.scraperService.updatePasswordInsurance.reset();
    this.scraperService.updatePasswordCounselor.reset();
  }
}
