import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommissionPlanRuleSalesPersonRoutingModule } from './commission-plan-rule-sales-person-routing.module';
import { CommissionPlanRuleSalesPersonCreateComponent } from './commission-plan-rule-sales-person-create/commission-plan-rule-sales-person-create.component';
import { CommissionPlanRuleSalesPersonEditComponent } from './commission-plan-rule-sales-person-edit/commission-plan-rule-sales-person-edit.component';
import { SharedModule } from '../../../../shared/shared.module';
import { CommissionPlanRuleSalesPersonFormContentComponent } from './commission-plan-rule-sales-person-form-content/commission-plan-rule-sales-person-form-content.component';

@NgModule({
  declarations: [
    CommissionPlanRuleSalesPersonCreateComponent,
    CommissionPlanRuleSalesPersonEditComponent,
    CommissionPlanRuleSalesPersonFormContentComponent,
  ],
  imports: [
    CommonModule,
    CommissionPlanRuleSalesPersonRoutingModule,
    SharedModule,
  ],
})
export class CommissionPlanRuleSalesPersonModule {}
