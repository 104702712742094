        <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="changePasswordForm">
          <button type="submit" hidden="hidden"></button>

          <div class="row">
            <div class="col-lg-12 col-12">

              <div class="form-group row">
                <label class="col col-form-label-sm" translate>
                  ACCOUNT_LBL_USERNAME
                </label>
                <input
                  class="form-control-plaintext form-control-sm"
                  type="text"
                  readonly
                  [value]="this.userEmail"
                />
              </div>
              <div class="form-group row">
                <label class="col col-form-label-sm" for="current" translate>
                  ACCOUNT_LBL_CURRENT_PASSWORD</label>
                <input
                  class="form-control form-control-sm"
                  id="current"
                  name="current"
                  type="password"
                  value=""
                  formControlName="current"
                  checkFormFieldValidity
                  autocomplete="new-password"
                />

              </div>


              <div class="form-group row">
                <label class="col col-form-label-sm" for="password" translate>
                  ACCOUNT_LBL_NEW_PASSWORD</label>
                <input
                  class="form-control form-control-sm"
                  id="password"
                  name="password"
                  type="password"
                  value=""
                  formControlName="password"
                  checkFormFieldValidity
                />
              </div>
              <div class="row mt-4 mb-4">
                <div class="col">
                  <ul class="no-bullets">
                    <li>Doit avoir au moins de 6 caractères</li>
                    <li>Doit contenir au moins une majuscule et une minuscule (A, z)</li>
                    <li>Doit contenir au moins un nombre</li>
                    <li>Doit contenir au moins un caractère spécial (!, %, &#64;, #, etc.)</li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label-sm" for="passwordConfirm" translate>
                  ACCOUNT_LBL_CONFIRM_PASSWORD</label>
                <input
                  class="form-control form-control-sm"
                  id="passwordConfirm"
                  name="passwordConfirm"
                  type="password"
                  value=""
                  formControlName="passwordConfirm"
                  checkFormFieldValidity
                />
              </div>
            </div>
          </div>

        </form>
