import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { AppState } from '../types';
import { accountActions } from './account.actions';
import { accountSelectors } from './account.selector';
import { AccountModel, AccountType } from '../accounting/types';

@Injectable()
export class AccountService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  getById: GenericApiCall<AccountModel, string> = this.genericApiCall(
    accountActions.getById,
    accountSelectors.getById,
    true,
  );
  add: GenericApiCall<AccountModel, AccountModel> = this.genericApiCall(
    accountActions.add,
    accountSelectors.add,
    true,
  );
  update: GenericApiCall<AccountModel, AccountModel> = this.genericApiCall(
    accountActions.update,
    accountSelectors.update,
    true,
  );
  delete: GenericApiCall<boolean, string> = this.genericApiCall(
    accountActions.delete,
    accountSelectors.delete,
    true,
  );
  createInstance: GenericApiCall<AccountModel, AccountType | undefined> =
    this.genericApiCall(
      accountActions.createInstance,
      accountSelectors.createInstance,
      true,
    );
}
