import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../../../../../shared/store/user/user.service';
import { UserConnectionHistory } from '../../../../../../../shared/store/user/type';

@Component({
  selector: 'app-user-details-connection-history',
  templateUrl: './user-details-connection-history.component.html',
  styleUrl: './user-details-connection-history.component.scss',
})
export class UserDetailsConnectionHistoryComponent
  implements OnInit, OnDestroy
{
  userId: number;
  userConnectionHistories$: Observable<UserConnectionHistory[]>;

  constructor(
    private readonly userService: UserService,
    private readonly route: ActivatedRoute,
  ) {
    this.userConnectionHistories$ =
      this.userService.getConnectionHistoriesById.value$;
  }

  ngOnInit(): void {
    this.userId = this.route.snapshot.params['id'];

    this.userService.getConnectionHistoriesById.call(this.userId.toString());
  }

  ngOnDestroy(): void {
    this.userService.getConnectionHistoriesById.reset();
  }
}
