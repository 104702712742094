import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, takeUntil } from 'rxjs/operators';
import { UploadFileAction, uploadFileActions } from './upload-file.actions';
import { UploadFileApiService } from './upload-file.api.service';
import { HttpEvent, HttpEventType, HttpHeaderResponse, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';

@Injectable()
export class UploadFileEffects {
  constructor(
    private readonly uploadFileApiService: UploadFileApiService,
    private readonly actions$: Actions<UploadFileAction>,
    private readonly store: Store,
  ) {}

  uploadRequestEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadFileActions.UPLOAD_REQUEST),
      concatMap((action) =>
        this.uploadFileApiService
          .uploadFile({
            id: action.id,
            entityType: action.entityType,
            entityId: action.entityId,
            file: action.file,
          })
          .pipe(
            takeUntil(
              this.actions$.pipe(ofType(uploadFileActions.UPLOAD_CANCEL)),
            ),
            map((event) => this.getActionFromHttpEvent(event)),
            catchError((error) => of(uploadFileActions.UPLOAD_FAILURE(error))),
          ),
      ),
    ),
  );

  private getActionFromHttpEvent(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent: {
        return uploadFileActions.UPLOAD_STARTED();
      }
      case HttpEventType.UploadProgress: {
        const httpProgressEvent: HttpProgressEvent = event;
        return uploadFileActions.UPLOAD_PROGRESS({
          progress: Math.round(
            (100 * httpProgressEvent.loaded) / (httpProgressEvent?.total ?? 1),
          ),
        });
      }
      case HttpEventType.ResponseHeader:
      case HttpEventType.Response: {
        if (event.status === 200) {
          if (event instanceof HttpResponse) {
            // this.store.dispatch(
            //   walletActions.get.addedOrUpdated({ value: event.body.paxWallet })
            // );
            if (
              event.body.returnMessageLabel != null &&
              event.body.returnMessageLabel != ''
            ) {
              // this.store.dispatch(
              //   walletActions.setReturnMessageAfterUpload({
              //     value: event.body.returnMessageLabel,
              //   }),
              // );
            }
          }
          return uploadFileActions.UPLOAD_COMPLETED();
        } else {
          const httpResponse: HttpHeaderResponse | HttpResponse<any> = event;
          return uploadFileActions.UPLOAD_FAILURE({
            error: httpResponse.statusText,
          });
        }
      }
      case HttpEventType.DownloadProgress: {
        return uploadFileActions.UPLOAD_COMPLETED();
      }
      default: {
        return uploadFileActions.UPLOAD_FAILURE({
          error: `Unknown Event: ${JSON.stringify(event)}`,
        });
      }
    }
  }

  private handleError(error: any) {}
}
