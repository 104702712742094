import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onApiCall } from '../../../common/store/generic.reducer';
import { GenericState, IState } from '../../../common/store/types';
import { DocumentModel } from './types';
import { documentActions } from './document.actions';

export interface IDocumentState extends IState<DocumentModel> {
  getById: GenericState<DocumentModel>;
  getByEntityId: GenericState<DocumentModel[]>;
  getContactDocumentByEntityId: GenericState<DocumentModel[]>;
  getContractDocumentByEntityId: GenericState<DocumentModel[]>;
  getFile: GenericState<any>;
  add: GenericState<DocumentModel>;
  update: GenericState<DocumentModel>;
  delete: GenericState<boolean>;
  deleteFile: GenericState<boolean>;
  copyToDocumentRequest: GenericState<DocumentModel>;
}

export const documentInitialState: IDocumentState = {
  getById: new GenericState<DocumentModel>(),
  getByEntityId: new GenericState<DocumentModel[]>(),
  getContactDocumentByEntityId: new GenericState<DocumentModel[]>(),
  getContractDocumentByEntityId: new GenericState<DocumentModel[]>(),
  getFile: new GenericState<any>(),
  add: new GenericState<DocumentModel>(),
  update: new GenericState<DocumentModel>(),
  delete: new GenericState<boolean>(),
  deleteFile: new GenericState<boolean>(),
  copyToDocumentRequest: new GenericState<DocumentModel>(),
};

const documentReducers = [
  ...onApiCall<DocumentModel>(documentActions.getById, 'getById'),
  ...onApiCall<DocumentModel[]>(documentActions.getByEntityId, 'getByEntityId'),
  ...onApiCall<DocumentModel[]>(
    documentActions.getContactDocumentByEntityId,
    'getContactDocumentByEntityId',
  ),
  ...onApiCall<DocumentModel[]>(
    documentActions.getContractDocumentByEntityId,
    'getContractDocumentByEntityId',
  ),
  ...onApiCall<any>(documentActions.getFile, 'getFile'),
  ...onApiCall<DocumentModel>(documentActions.add, 'add'),
  ...onApiCall<DocumentModel>(documentActions.update, 'update'),
  ...onApiCall<boolean>(documentActions.delete, 'delete'),
  ...onApiCall<boolean>(documentActions.deleteFile, 'deleteFile'),
  ...onApiCall<DocumentModel>(
    documentActions.copyToDocumentRequest,
    'copyToDocumentRequest',
  ),
];

export const documentReducer = createReducer(
  documentInitialState,
  ...documentReducers,
) as ActionReducer<IDocumentState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return documentReducer(state, action);
}
