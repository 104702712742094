import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, withLatestFrom } from 'rxjs';
import { InputByEntity } from '../../../store/comment/types';
import { DocumentModel } from '../../../store/document/types';
import { DocumentService } from '../../../store/document/document.service';
import { UploadFileService } from '../../../store/upload-file/upload-file.service.facade';
import { map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-document-panel-horizontal',
  templateUrl: './document-panel-horizontal.component.html',
  styleUrls: ['./document-panel-horizontal.component.scss'],
})
export class DocumentPanelHorizontalComponent implements OnInit {
  @Input() entityId: string;
  @Input() entityType: string;

  documentBase$: Observable<DocumentModel[]>;
  inputDocument: InputByEntity;

  uploadCompleted$: Observable<boolean>;

  constructor(
    private readonly documentService: DocumentService,
    private readonly uploadFileService: UploadFileService,
  ) {
    this.documentBase$ = this.documentService.getByEntityId.value$;
  }

  ngOnInit(): void {
    this.inputDocument = {
      entityId: +this.entityId,
      entityType: this.entityType,
    };
    this.documentService.getByEntityId.call(this.inputDocument);

    this.uploadCompleted$ = this.uploadFileService.completed$.pipe(
      withLatestFrom(this.uploadFileService.currentFileId$),
      // filter(([_isInProgress, id]) => this.document.id == id),
      map(([isInProgress, _id]) => isInProgress),
      untilDestroyed(this),
    );
  }

  trackByDocument(index: any, document: DocumentModel) {
    return document.id;
  }

  refresh(newDocument: boolean) {
    this.documentService.getByEntityId.call(this.inputDocument);
  }
}
