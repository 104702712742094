import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from 'src/app/shared/guard/authentication-guard';
import { HeaderMenuType } from '../../../shared/store/view/types';
import { Entity } from 'src/app/shared/store/view/types';
import { JobCreateComponent } from './job-create/job-create.component';
import { JobDetailsComponent } from './job-details/job-details.component';
import { JobEditComponent } from './job-edit/job-edit.component';
import { HasUnsavedChangesGuard } from '../../../shared/guard/has-form-changed.guard';
import { DataListingProviderComponent } from 'src/app/shared/components/data-listing-provider/data-listing-provider.component';

const routes: Routes = [
  {
    path: 'HumanResources/Jobs',
    data: {
      breadcrumb: { label: ' ', alias: 'list' },
    },
    children: [
      {
        path: '',
        redirectTo: 'List',
        pathMatch: 'full',
      },
      {
        path: 'List',
        component: DataListingProviderComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.HumanResources,
          entity: Entity.Job,
          breadcrumb: { skip: true },
        },
      },
      {
        path: 'Details/:id',
        component: JobDetailsComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.HumanResources,
          entity: Entity.Job,
          breadcrumb: { label: ' ', alias: 'jobDetails' },
        },
      },
      {
        path: 'Create',
        component: JobCreateComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.HumanResources,
          entity: Entity.Job,
          breadcrumb: { label: ' ', alias: 'jobCreate' },
        },
      },
      {
        path: 'Edit/:id',
        component: JobEditComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.HumanResources,
          entity: Entity.Job,
          breadcrumb: { label: ' ', alias: 'jobEdit' },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class JobRoutingModule {}
