import { createAction, props } from '@ngrx/store';
import { ErrorsNestedFormGroup } from './types';
import { createApiCallActions } from '../../../common/store/generic.actions';
import { Observable } from 'rxjs';

export const formActions = {
  setIsDirty: createAction(
    '[Form] set IsDirty',
    props<{ formName: string; isDirty: boolean }>(),
  ),
  clear: createAction('[Form] clear'),
  countErrors: createAction(
    '[Form] count Errors',
    props<ErrorsNestedFormGroup>(),
  ),
  setErrorsEntity: createAction(
    '[Form] set Entity',
    props<{ entityType: string }>(),
  ),
  createErrors: createAction(
    '[Form] create Errors',
    props<{ message: string }>(),
  ),
};
export type FormAction = typeof formActions;
