import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DataListingProviderComponent } from 'src/app/shared/components/data-listing-provider/data-listing-provider.component';
import { AuthenticationGuard } from 'src/app/shared/guard/authentication-guard';
import { Entity } from 'src/app/shared/store/view/types';
import { HasUnsavedChangesGuard } from '../../../shared/guard/has-form-changed.guard';
import { ModuleId } from '../../../shared/store/navigation/types';
import { HeaderMenuType } from '../../../shared/store/view/types';
import { ActivityCreateComponent } from './activity-create/activity-create.component';
import { ActivityDetailsComponent } from './activity-details/activity-details.component';
import { ActivityEditComponent } from './activity-edit/activity-edit.component';

const routes: Routes = [
  {
    path: 'Crm/Activities',
    data: {
      breadcrumb: { label: ' ', alias: 'list' },
    },
    children: [
      {
        path: '',
        redirectTo: 'List',
        pathMatch: 'full',
      },
      {
        path: 'List',
        component: DataListingProviderComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          moduleId: ModuleId.crm,
          entity: Entity.Activity,
          breadcrumb: { skip: true },
        },
      },
      {
        path: 'Details/:id',
        component: ActivityDetailsComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          moduleId: ModuleId.crm,
          entity: Entity.Activity,
          breadcrumb: { label: ' ', alias: 'activityDetails' },
        },
      },
      {
        path: 'Create',
        component: ActivityCreateComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          moduleId: ModuleId.crm,
          entity: Entity.Activity,
          breadcrumb: { label: ' ', alias: 'activityCreate' },
        },
      },
      {
        path: 'Edit/:id',
        component: ActivityEditComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          moduleId: ModuleId.crm,
          entity: Entity.Activity,
          breadcrumb: { label: ' ', alias: 'activityEdit' },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ActivityRoutingModule {}
