export const notificationFeatureKey = 'notification';

export enum NotificationStatus {
  Success = 1,
  Info,
  Warning,
  Error,
}

export enum NotificationType {
  Toast = 1,
  ToastValidation,
}

export interface Notification {
  timestamp: number;
  content: string;
  timeout?: number;
  notificationStatus: NotificationStatus;
  userCancelled: boolean;
  type: NotificationType;
  customNotification: boolean;
}
