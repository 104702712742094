import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { OAuthenticationService } from 'src/app/shared/authentication/oauthentication.service';
import { ErrorService } from '../error';

export const AuthenticationGuard = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const authService = inject(OAuthenticationService);
  const router = inject(Router);

  try {
    if (authService.isAuthenticated()) {
      return true;
    } else {
      const isTokenRefreshed = await authService.refreshToken();
      if (isTokenRefreshed) return true;
    }

    return router.createUrlTree(['/Login'], {
      queryParams: { returnUrl: state.url },
    });
  } catch (error) {
    console.error('Error refreshing token on guard', error);
    return router.createUrlTree(['/Login'], {
      queryParams: { returnUrl: state.url },
    });
  } finally {
    //
  }
};
