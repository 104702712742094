import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'horizontalClass',
})
export class HorizontalClassPipe implements PipeTransform {
  transform(isVertical: boolean): unknown {
    return {
      'card-horizontal': isVertical,
      'data-card-horizontal': isVertical,
    };
  }
}
