<div class="comment-content pt-0 h-100">
  <ng-container *ngIf="(this.documentBase$ | async) as documents">
<!--      <ul class="list-group list-group-horizontal">-->
      <ul class="d-flex flex-wrap ps-0">
        <li class="list-group-item border-0 align-self-start mb-2 mt-2 ps-2 pe-2">
          <div class="card data-card h-100">
            <div class="card-body">
              <div class="card-content">
              </div>
              <div class="card-right ps-2 pe-2">
                <button
                  class="btn btn-outline-primary"
                  type="button"
                  [title]="'DOCUMENT_PLACEHOLDER_DOCUMENT_ADD' | translate"
                  (click)="newDocumentUpload.fileupload.nativeElement.click()">
                  <i class="mdi mdi-cloud-upload" *ngIf="!(this.uploadCompleted$ | async)"></i>
                  <i class="mdi mdi-cloud-check" *ngIf="this.uploadCompleted$ | async"></i>
                </button>
              </div>
            </div>
          </div>
        </li>
        <!--          flex-fill-->
        <li class="list-group-item border-0 align-items-start mb-2 mt-2 ps-2 pe-2"
            *ngFor="let document of documents; trackBy: trackByDocument">
          <app-document-card class="d-flex h-100"
                             [isHorizontalUse]="true"
                             [document]="document" />
        </li>
      </ul>
  </ng-container>
</div>
<app-document-add [entityId]="this.entityId" [entityType]="this.entityType" (newDocument)="refresh($event)"
                  #newDocumentUpload/>
