import { Component, Input, OnInit } from '@angular/core';
import {
  AccountMoveLineModel,
  AccountMoveModel,
  AccountMoveStatus,
  BindType,
  MoveType,
} from '../../../../../../shared/store/accounting/types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '../../../../../../shared/service/modal.service';
import { ContractService } from '../../../../../../shared/store/contract/contract.service';
import { AccountingService } from '../../../../../../shared/store/accounting/accounting.service';
import { ActivatedRoute } from '@angular/router';
import { ModalContentComponent } from '../../../../../../shared/components/modal-content/modal-content.component';
import { ActionButton } from '../../../../../../shared/components/modal-content/action-button';
import { toFirstLetterLower } from '../../../../../../shared/helper/stringhelper';
import { SourceEnum } from '../../../../../../shared/store/typeahead/types';
import { filterTrue } from '../../../../../../shared/pipe/rxjs/operators';
import { take } from 'rxjs/operators';
import { ButtonType } from 'src/app/shared/store/subheader/type';

@Component({
  selector: 'app-contact-details-account-moves-table',
  templateUrl: './contact-details-account-moves-table.component.html',
  styleUrl: './contact-details-account-moves-table.component.scss',
})
export class ContactDetailsAccountMovesTableComponent implements OnInit {
  @Input() accountMoves: AccountMoveModel[];
  // @Input() contractId: number;
  @Input() isClient: boolean;
  // @Input() contractInsuranceCompanyContactId: number;

  contractUniqueId: string;

  protected readonly ButtonType = ButtonType;

  constructor(
    private readonly modalService: NgbModal,
    private readonly modalExchangeService: ModalService,
    private readonly contractService: ContractService,
    private readonly accountingService: AccountingService,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.contractUniqueId = this.route.snapshot.params['id'];
  }

  // openModalCreateInvoice(contractId: number) {
  //   const modalRef = this.modalService.open(ModalContentComponent, {
  //     size: 'lg',
  //     backdrop: 'static',
  //   });
  //
  //   modalRef.componentInstance.component =
  //     this.modalExchangeService.getComponentClass(
  //       'AccountMoveAddContentComponent',
  //     );
  //   modalRef.componentInstance.title = 'ACCOUNTMOVE_LBL_ADD';
  //   modalRef.componentInstance.cancelButton = true;
  //   modalRef.componentInstance.buttons = [
  //     <ActionButton>{
  //       actionName: toFirstLetterLower('AccountMove') + 'Create',
  //     },
  //   ];
  //   modalRef.componentInstance.inputParams = {
  //     contractId: contractId,
  //     source: SourceEnum.Modal,
  //     isClient: this.isClient,
  //     contractInsuranceCompanyContactId: this.contractInsuranceCompanyContactId,
  //   };
  //
  //   modalRef.result
  //     .then((id) => {
  //       if (id >= 0) {
  //         this.contractService.getByUniqueId.call(
  //           this.contractUniqueId.toString(),
  //         );
  //       }
  //     })
  //     .finally(() => {
  //       //avoid error on cancel
  //     });
  // }

  openModalBindAccountMoves(
    contractId: number,
    moveType?: MoveType,
    accountMoveId?: number,
  ) {
    const modalRef = this.modalService.open(ModalContentComponent, {
      size: 'xl',
      backdrop: 'static',
    });

    modalRef.componentInstance.component =
      this.modalExchangeService.getComponentClass(
        'AccountMoveAssignContentComponent',
      );
    modalRef.componentInstance.title = 'ACCOUNTMOVE_LBL_ASSIGN_INVOICE';
    modalRef.componentInstance.cancelButton = true;
    modalRef.componentInstance.buttons = [
      <ActionButton>{
        actionName: toFirstLetterLower('accountMoveAssign') + 'Create',
      },
    ];

    let bindType: BindType;
    if (moveType == 'Entry') {
      if (this.isClient) {
        bindType = 'Payment';
      } else {
        bindType = 'Slip';
      }
    } else {
      bindType = 'Repayment';
    }

    modalRef.componentInstance.inputParams = {
      contractId: contractId,
      invoiceId: accountMoveId,
      source: SourceEnum.Modal,
      bindType: bindType,
    };

    modalRef.result
      .then((id) => {
        if (id >= 0) {
          this.contractService.getByUniqueId.call(
            this.contractUniqueId.toString(),
          );
        }
      })
      .finally(() => {
        //avoid error on cancel
      });
  }

  openModalRepaymentBindAccountMoves(
    contractId: number,
    accountMoveId?: number,
  ) {
    const modalRef = this.modalService.open(ModalContentComponent, {
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.component =
      this.modalExchangeService.getComponentClass(
        'AccountMoveAssignRepaymentContentComponent',
      );
    modalRef.componentInstance.title = 'ACCOUNTMOVE_LBL_ASSIGN_REPAYMENT';
    modalRef.componentInstance.cancelButton = true;
    modalRef.componentInstance.buttons = [
      <ActionButton>{
        actionName: toFirstLetterLower('accountMoveRepaymentAssign') + 'Create',
      },
    ];
    modalRef.componentInstance.inputParams = {
      contractId: contractId,
      source: SourceEnum.Modal,
    };

    modalRef.result
      .then((id) => {
        if (id >= 0) {
          this.contractService.getByUniqueId.call(
            this.contractUniqueId.toString(),
          );
        }
      })
      .finally(() => {
        //avoid error on cancel
      });
  }

  openModalBindAccountMovesDetails(invoice: AccountMoveModel, type: BindType) {
    const modalRef = this.modalService.open(ModalContentComponent, {
      size: 'lg',
      backdrop: true,
    });

    modalRef.componentInstance.component =
      this.modalExchangeService.getComponentClass(
        'AccountMoveAssignDetailsContentComponent',
      );
    modalRef.componentInstance.title = 'ACCOUNTMOVE_LBL_PAYMENTS_INVOICES';
    if (type === 'Repayment') {
      modalRef.componentInstance.title = 'ACCOUNTMOVE_LBL_PAYMENTS_REPAYMENT';
    }

    modalRef.componentInstance.closeCrossButton = true;
    modalRef.componentInstance.cancelButton = false;
    let buttons = [
      <ActionButton>{
        label: 'PAYMENT_LBL_UNBIND',
        actionName: 'accountMovesUnbind',
      },
    ];
    if (type === 'Payment') {
      buttons.push(<ActionButton>{
        label: 'ACCOUNTMOVE_LBL_GENERATE_PDF',
        actionName: 'accountMovesPDF',
      });
    }

    modalRef.componentInstance.buttons = buttons;

    switch (type) {
      case 'Repayment':
        modalRef.componentInstance.inputParams = {
          source: SourceEnum.Modal,
          accountMoveId: invoice.id,
          labelPayments: 'ACCOUNTMOVE_LBL_CLIENT',
          labelInvoices: 'ACCOUNTMOVE_LBL_VENDOR',
          isVendorInvoice: true,
          bindType: 'Repayment',
        };
        break;
      case 'Slip':
        modalRef.componentInstance.inputParams = {
          source: SourceEnum.Modal,
          accountMoveId: invoice.id,
          bindType: 'Slip',
        };
        break;
      case 'Payment':
      default:
        modalRef.componentInstance.inputParams = {
          source: SourceEnum.Modal,
          accountMoveId: invoice.id,
          bindType: 'Payment',
        };
        break;
    }

    modalRef.result
      .then((id) => {
        if (id >= 0) {
          this.contractService.getByUniqueId.call(
            this.contractUniqueId.toString(),
          );
        }
      })
      .finally(() => {
        //avoid error on cancel
      });
  }

  onClickChangeAccountMoveStatus(id: number, status: AccountMoveStatus) {
    let result$ = this.accountingService.changeAccountMoveStatus.call({
      id: id.toString(),
      status: status,
    });

    result$.pipe(filterTrue(), take(1)).subscribe((result) => {
      this.contractService.getByUniqueId.call(this.contractUniqueId.toString());
      this.accountingService.changeAccountMoveStatus.reset();
    });
  }

  onClickDeleteAccountMove(id: number) {
    let result$ = this.accountingService.delete.call(id);

    result$.pipe(filterTrue(), take(1)).subscribe((result) => {
      this.contractService.getByUniqueId.call(this.contractUniqueId.toString());
      this.accountingService.delete.reset();
    });
  }

  openModalBindSlipDetails(
    accountMove: AccountMoveModel,
    journalId: number,
    journalName: string,
  ) {
    const modalRef = this.modalService.open(ModalContentComponent, {
      size: 'lg',
      backdrop: true,
    });

    modalRef.componentInstance.component =
      this.modalExchangeService.getComponentClass(
        'AccountMoveAssignSlipDetailsContentComponent',
      );
    modalRef.componentInstance.title = 'ACCOUNTMOVE_LBL_INVOICE_JOURNAL';

    modalRef.componentInstance.closeCrossButton = true;
    modalRef.componentInstance.cancelButton = false;
    modalRef.componentInstance.buttons = [];

    modalRef.componentInstance.inputParams = {
      source: SourceEnum.Modal,
      invoice: accountMove,
      journalId: journalId,
      journalName: journalName,
    };

    modalRef.result
      .then((id) => {
        if (id >= 0) {
          // this.contactService.getById.call(contactId.toString());
        }
      })
      .finally(() => {
        //avoid error on cancel
      });
  }

  triggerAction(actionName: string, accountMoveId: number) {
    let status: AccountMoveStatus = 'Draft';
    switch (actionName) {
      case 'CancelAccountMove':
        status = 'Cancel';
        this.onClickChangeAccountMoveStatus(accountMoveId, status);
        break;
      case 'ValidateAccountMove':
        status = 'Posted';
        this.onClickChangeAccountMoveStatus(accountMoveId, status);
        break;
      case 'DeleteAccountMove':
        this.onClickDeleteAccountMove(accountMoveId);
        break;
    }
  }

  trackByAccountMoveId(index: any, item: AccountMoveModel) {
    return item.id;
  }

  trackByAccountMoveLineId(index: any, item: AccountMoveLineModel) {
    return item.id;
  }
}
