import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DataListingProviderComponent } from 'src/app/shared/components/data-listing-provider/data-listing-provider.component';
import { AuthenticationGuard } from 'src/app/shared/guard/authentication-guard';
import { Entity, HeaderMenuType } from 'src/app/shared/store/view/types';
import { HasUnsavedChangesGuard } from '../../../shared/guard/has-form-changed.guard';
import { ModuleId } from '../../../shared/store/navigation/types';
import { ArticleCreateComponent } from './article-create/article-create.component';
import { ArticleDetailComponent } from './article-detail/article-detail.component';
import { ArticleEditComponent } from './article-edit/article-edit.component';

const routes: Routes = [
  {
    path: 'Cms/Articles',
    data: {
      breadcrumb: { label: ' ', alias: 'list' },
    },
    children: [
      {
        path: '',
        redirectTo: 'List',
        pathMatch: 'full',
      },
      {
        path: 'List',
        component: DataListingProviderComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Content,
          moduleId: ModuleId.cms,
          entity: Entity.Article,
          breadcrumb: { skip: true },
        },
      },
      {
        path: 'Details/:id',
        component: ArticleDetailComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Content,
          moduleId: ModuleId.cms,
          entity: Entity.Article,
          breadcrumb: { label: ' ', alias: 'articleDetails' },
        },
      },
      {
        path: 'Create',
        component: ArticleCreateComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Content,
          moduleId: ModuleId.cms,
          entity: Entity.Article,
          breadcrumb: { label: ' ', alias: 'articleCreate' },
        },
      },
      {
        path: 'Edit/:id',
        component: ArticleEditComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Content,
          moduleId: ModuleId.cms,
          entity: Entity.Article,
          breadcrumb: { label: ' ', alias: 'articleEdit' },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ArticleRoutingModule {}
