import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '../../error';
import { MessageService } from '../../service/message/message.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UploadFileApiService {
  constructor(
    private readonly http: HttpClient,
    private readonly errorService: ErrorService,
    private readonly messageService: MessageService,
  ) {}

  uploadFile = (params: {
    id: number;
    entityType: string;
    entityId: string;
    file: File;
  }): Observable<HttpEvent<boolean>> => {
    let formData = new FormData();
    formData.append('file', params.file);

    let parameters: string[] = ['files'];
    if (params.entityType && params.entityId) {
      parameters = ['files', params.entityType, params.entityId];
    }

    if (params.id > 0) {
      parameters = ['files', params.id.toString()];
    }

    let url = this.formatUrl(parameters);

    return this.http
      .post<boolean>(url, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  formatUrl = (parameters: string[]): string => {
    var url = environment.comparanooapiurl;
    url += environment.features.document.url;
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}
