<app-document-subheader></app-document-subheader>
<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div
        class="page-container"
        *ngIf="this.productLine$ | async as productLine"
      >
        <div class="card card-light w-100">
          <div class="card-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label class="form-title-label" translate
                      >PRODUCTLINE_LBL_NAME</label
                    >
                    <div class="form-title">{{ productLine.name }}</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-lg-6">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >PRODUCTLINE_LBL_INSURANCECOMPANY</label
                    >
                    <div class="col">
                      <span
                        class="form-control-plaintext form-control-sm mb-2 h-100"
                      >
                        <app-link-to [id]="productLine.insuranceCompanyId.toString()"
                                     [label]="productLine.insuranceCompanyContactName"
                                     [url]="'/Insurance/InsuranceCompanies/Details/'" />
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >PRODUCTLINE_LBL_PRODUCTCATEGORY</label
                    >
                    <div class="col">
                      <span
                        class="form-control-plaintext form-control-sm mb-2 h-100"
                      >
                        <app-link-to [id]="productLine.productCategoryId.toString()"
                                     [label]="productLine.productCategoryName"
                                     [url]="'/Insurance/ProductCategories/Details/'" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <app-product-line-tabs-details />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-comment" *ngIf="this.productLine$ | async as productLine">
      <app-comment-panel
        [entityType]="'ProductLine'"
        [entityId]="productLine.id.toString()"
      ></app-comment-panel>
    </div>
  </div>
</div>
