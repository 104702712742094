<app-document-subheader/>
<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div class="page-container" *ngIf="this.commissionPlanRuleModel$ | async as commissionPlanRuleModel">
        <div class="card card-light w-100">

          <div class="card-body">
            <div class="container-fluid">

              <div class="row">
                <div class="col-6 col-lg-6">
                  <div class="form-group">
                    <label class="form-title-label" translate>COMMISSION_PLAN_RULE_LBL_NAME</label>
                    <div class="form-title">{{ commissionPlanRuleModel.name }}</div>
                  </div>
                </div>
                <div class="col"></div>
              </div>

              <div class="row">

                <div class="col-6 col-lg-6">

                  <ng-container *ngIf="commissionType$ | async as commissionType">
                    <div class="form-group row" *ngIf="commissionType === 'CompanyCommission'">
                      <label class="col-4 col-form-label-sm" translate>COMMISSION_PLAN_RULE_LBL_PRODUCTLINE_NAME</label>
                      <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                          <app-link-to
                            *ngIf="!!commissionPlanRuleModel.productLineName && !!commissionPlanRuleModel.productLineId;else elseBlock"
                            [id]="commissionPlanRuleModel.productLineId.toString()"
                            [label]="commissionPlanRuleModel.productLineName"
                            [url]="'/Insurance/ProductLines/Details/'"/>
                        <ng-template #elseBlock>
                          {{ commissionPlanRuleModel.productLineName }}
                        </ng-template>
                      </span>
                      </div>
                    </div>

                    <div class="form-group row" *ngIf="commissionType === 'SalesPersonCommission' || commissionType === 'SharedSalesPersonCommission'">
                      <label class="col-4 col-form-label-sm"
                             translate>COMMISSION_PLAN_RULE_LBL_COMMISSION_PLAN_NAME</label>
                      <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ commissionPlanRuleModel.commissionPlans | commissionPlanExtractName | joinAllArrayValues : ',' }}
                      </span>
                      </div>
                    </div>
                    <div class="form-group row" *ngIf="commissionType === 'SalesPersonCommission'">
                      <label class="col-4 col-form-label-sm" translate>COMMISSION_PLAN_RULE_LBL_SOURCE</label>
                      <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ commissionPlanRuleModel.commissionPlanRuleSourceLoc}}
                      </span>
                      </div>
                    </div>
                  </ng-container>

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>COMMISSION_PLAN_RULE_LBL_VALUE</label>
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ commissionPlanRuleModel.value | showSignValueType: commissionPlanRuleModel.amountType}}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>COMMISSION_PLAN_RULE_LBL_START_DATE</label>
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{
                          !!commissionPlanRuleModel.startDate
                            ? (commissionPlanRuleModel.startDate | date : 'dd/MM/yyyy')
                            : '-'
                        }}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>COMMISSION_PLAN_RULE_LBL_END_DATE</label>
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{
                          !!commissionPlanRuleModel.endDate
                            ? (commissionPlanRuleModel.endDate | date : 'dd/MM/yyyy')
                            : '-'
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-comment" *ngIf="this.commissionPlanRuleModel$ | async as commissionPlanRule">
      <app-comment-panel [entityType]="'CommissionPlanRule'"
                         [entityId]="commissionPlanRule.id.toString()"></app-comment-panel>
    </div>
  </div>
</div>
