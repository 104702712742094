<div
  class="tab-pane fade flex-even active show"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
  formGroupName="generalInformationFormGroup"
>
  <div class="d-flex flex-column h-100">
    <div class="row mt-2">
      <div class="col-12 col-md">
        <div class="form-group-title my-1" translate>
          CONTRACT_FIELDSET_VALIDATION
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_VALIDATION_DATE</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              type="date"
              formControlName="validationDate"
              checkFormFieldValidity
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_APPROVER</label
          >
          <div class="col">
            <app-typeahead
              [entity]="Entity.SalesTeam"
              [routeEndpoint]="'SalesTeams/SalesTeamMembers'"
              [controlForm]="
                this.form.controls['generalInformationFormGroup'].get(
                  'approverId'
                )
              "
              [limitToList]="true"
              [referenceFilterParentName]="'SalesTeamId'"
              [referenceFilterParentValue]="
                this.form.controls['salesTeam'].value
              "
              [dbNameOfName]="'UserContactName'"
              [dbNameOfId]="'UserId'"
            ></app-typeahead>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_DOCUMENTS_RECEIVED_DATE</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              type="date"
              formControlName="documentsReceivedDate"
              checkFormFieldValidity
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_DOCUMENTS_SENT_DATE</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              type="date"
              formControlName="documentsSentDate"
              checkFormFieldValidity
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_REGULARIZATION_DATE</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              type="date"
              formControlName="regularizationDate"
              checkFormFieldValidity
            />
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group-title my-1" translate>
          CONTRACT_FIELDSET_FEES_AND_CHARGES
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_MONTHLY_FEE</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              type="text"
              [placeholder]="'€'"
              formControlName="monthlyFee"
              checkFormFieldValidity
              mask="separator.2"
              [suffix]="'CONTRACT_PLACEHOLDER_MONTHLY_FEE' | translate"
              [showMaskTyped]="false"
              [decimalMarker]="','"
              [allowNegativeNumbers]="false"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_MONTHLY_COST</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              type="text"
              [placeholder]="'CONTRACT_PLACEHOLDER_MONTHLY_COST' | translate"
              formControlName="monthlyCost"
              checkFormFieldValidity
              mask="separator.2"
              [suffix]="'€'"
              [showMaskTyped]="false"
              [decimalMarker]="','"
              [allowNegativeNumbers]="false"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_ANNUAL_FEE</label
          >
          <div class="col">
            <input
              class="form-control-sm form-control-plaintext"
              type="text"
              readonly
              formControlName="annualFee"
              mask="separator.2"
              [suffix]="'€'"
              [showMaskTyped]="false"
              [decimalMarker]="','"
              [allowNegativeNumbers]="false"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_TAX_RATE</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              type="text"
              [placeholder]="'CONTRACT_PLACEHOLDER_TAX_RATE' | translate"
              formControlName="taxRate"
              checkFormFieldValidity
              mask="percent.2"
              [suffix]="'%'"
              [showMaskTyped]="false"
              [decimalMarker]="','"
              [allowNegativeNumbers]="false"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_COMMISSION_RATE</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              type="text"
              [placeholder]="'CONTRACT_PLACEHOLDER_COMMISSION_RATE' | translate"
              formControlName="commissionRate"
              checkFormFieldValidity
              mask="percent.2"
              [suffix]="'%'"
              [showMaskTyped]="false"
              [decimalMarker]="','"
              [allowNegativeNumbers]="false"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="form-group-title my-1" translate>
        CONTRACT_FIELDSET_END_DATE
      </div>
      <div class="col-12 col-md">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_EXPIRY_DATE</label
          >
          <div class="col">
            <input
              class="form-control-plaintext form-control-sm"
              type="text"
              readonly
              value="1er janvier"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_TERMINATION_DATE</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              type="date"
              formControlName="terminationDate"
              checkFormFieldValidity
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_TERMINATION_REASON</label
          >
          <div class="col">
            <app-typeahead
              [routeEndpoint]="'Contracts/TerminationReason'"
              [controlForm]="
                this.form.controls['generalInformationFormGroup'].get(
                  'terminationId'
                )
              "
              [limitToList]="true"
              [orderBy]="'id asc'"
            ></app-typeahead>
          </div>
        </div>
      </div>
      <div class="col"></div>
    </div>
  </div>
</div>
