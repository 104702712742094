import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onApiCall } from '../../../common/store/generic.reducer';
import { GenericState, IState } from '../../../common/store/types';
import { CashControlModel } from './types';
import { cashControlActions } from './cash-control.actions';

export interface ICashControlState extends IState<CashControlModel> {
  getById: GenericState<CashControlModel>;
  getBySalesTeamAccountId: GenericState<CashControlModel[]>;
  add: GenericState<CashControlModel>;
  update: GenericState<CashControlModel>;
  delete: GenericState<boolean>;
  createInstance: GenericState<CashControlModel>;
}

export const cashControlInitialState: ICashControlState = {
  getById: new GenericState<CashControlModel>(),
  getBySalesTeamAccountId: new GenericState<CashControlModel[]>(),
  add: new GenericState<CashControlModel>(),
  update: new GenericState<CashControlModel>(),
  delete: new GenericState<boolean>(),
  createInstance: new GenericState<CashControlModel>(),
};

const cashControlReducers = [
  ...onApiCall<CashControlModel>(cashControlActions.getById, 'getById'),
  ...onApiCall<CashControlModel[]>(
    cashControlActions.getBySalesTeamAccountId,
    'getBySalesTeamAccountId',
  ),
  ...onApiCall<CashControlModel>(cashControlActions.add, 'add'),
  ...onApiCall<CashControlModel>(cashControlActions.update, 'update'),
  ...onApiCall<boolean>(cashControlActions.delete, 'delete'),
  ...onApiCall<CashControlModel>(
    cashControlActions.createInstance,
    'createInstance',
  ),
];

export const cashControlReducer = createReducer(
  cashControlInitialState,
  ...cashControlReducers,
) as ActionReducer<ICashControlState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return cashControlReducer(state, action);
}
