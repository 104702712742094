import { Component } from '@angular/core';

@Component({
  selector: 'app-application-fee-rule-create',
  templateUrl: './application-fee-rule-create.component.html',
  styleUrls: ['./application-fee-rule-create.component.scss']
})
export class ApplicationFeeRuleCreateComponent {

}
