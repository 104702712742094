import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function datesValidator(
  smallestDate: string,
  biggestDate: string,
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const start = control.get(smallestDate);
    const end = control.get(biggestDate);

    if (!start?.value) return null;
    if (!end?.value) {
      start.setErrors(null);
      return null;
    }

    if (start.value > end.value) {
      start.setErrors({ EndDateLessThanStartDate: true });
      end?.setErrors({ EndDateLessThanStartDate: true });
    } else {
      start.setErrors(null);
      end?.setErrors(null);
    }

    return null;
  };
}
