import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'xng-breadcrumb';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, take } from 'rxjs/operators';
import {
  CommissionPlanRuleModel,
  CommissionTypeSubject,
} from '../../../../shared/store/commissions/commission-plan-rule/types';
import { View } from '../../../../shared/store/view/types';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { ViewService } from '../../../../shared/store/view/views.service';
import { CommissionPlanRuleService } from '../../../../shared/store/commissions/commission-plan-rule/commission-plan-rule.service';
import { filterTrue } from '../../../../shared/pipe/rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-commission-plan-rule-details',
  templateUrl: './commission-plan-rule-details.component.html',
  styleUrls: ['./commission-plan-rule-details.component.scss'],
})
export class CommissionPlanRuleDetailsComponent implements OnInit, OnDestroy {
  commissionPlanRuleId: number;
  commissionPlanRuleModel$: Observable<CommissionPlanRuleModel>;
  view$: Observable<View>;

  commissionType$: BehaviorSubject<CommissionTypeSubject> =
    new BehaviorSubject<CommissionTypeSubject>('');
  commissionRouteUrl: string = '';

  constructor(
    private route: ActivatedRoute,
    private readonly router: Router,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly commissionPlanRuleService: CommissionPlanRuleService,
  ) {
    this.commissionPlanRuleModel$ =
      this.commissionPlanRuleService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.commissionPlanRuleId = this.route.snapshot.params['id'];

    this.commissionPlanRuleService.getById.call(
      this.commissionPlanRuleId.toString(),
    );

    this.commissionPlanRuleModel$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@commissionPlanRuleDetails', x.name);
        this.commissionType$.next(x.commissionType);
      });
    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'commissionPlanRuleEdit') {
          this.router.navigate([
            this.commissionRouteUrl,
            'Edit',
            this.commissionPlanRuleId,
          ]);
        }
        if (x == 'commissionPlanRuleDelete') {
          this.onDelete();
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.commissionType$
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((commissionType) => {
        switch (commissionType) {
          case 'SalesPersonCommission':
            this.commissionRouteUrl = 'Finance/CommissionPlanRuleSalesPersons/';
            break;
          case 'CompanyCommission':
            this.commissionRouteUrl = 'Finance/CommissionPlanRuleCompanies/';
            break;
          default:
            this.commissionRouteUrl = '';
        }
      });
  }

  onDelete() {
    let result$ = this.commissionPlanRuleService.delete.call(
      this.commissionPlanRuleId.toString(),
    );

    result$.pipe(filterTrue(), take(1)).subscribe((result) => {
      this.commissionPlanRuleService.delete.reset();
      this.router.navigate([this.commissionRouteUrl, 'List']);
    });
  }

  ngOnDestroy(): void {
    this.commissionPlanRuleService.getById.reset();
    this.commissionPlanRuleService.delete.reset();
    this.breadcrumbService.set('@commissionPlanRuleDetails', ' ');
  }
}
