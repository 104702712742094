import { Action } from '../view/types';

export const cashControlFeatureKey = 'cashControl';

export class CashControlModel {
  id: number;
  salesTeamAccountId: number;
  salesTeamAccountName: string;
  controlDate: Date;
  realAmount: number;
  calculatedAmount: number;
  differenceAmount: number;
  salesPersonId: number;
  salesPersonName: string;
  description: string;
  creationDate: Date;
  actions: Action[];
}
