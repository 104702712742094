import { Pipe, PipeTransform } from '@angular/core';
import { SalesTeamModel } from '../../../../../shared/store/sales-team/types';

@Pipe({
  name: 'salesTeamExtractName',
})
export class SalesTeamExtractNamePipe implements PipeTransform {
  transform(values: SalesTeamModel[] | null | undefined): string[] {
    if (!values || values.length == 0) return [''];

    return values.map((value) => value.name).sort();
  }
}
