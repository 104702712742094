import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ContractCreateComponent } from './contract-create/contract-create.component';
import { ContractDetailsCommissionComponent } from './contract-details/contract-details-tab/contract-details-commission/contract-details-commission.component';
import { ContractDetailsGeneralInformationsComponent } from './contract-details/contract-details-tab/contract-details-general-informations/contract-details-general-informations.component';
import { ContractDetailsInsuredPersonsComponent } from './contract-details/contract-details-tab/contract-details-insured-persons/contract-details-insured-persons.component';
import { ContractDetailsMarketingComponent } from './contract-details/contract-details-tab/contract-details-marketing/contract-details-marketing.component';
import { ContractDetailsPaymentSplittingComponent } from './contract-details/contract-details-tab/contract-details-payment-splitting/contract-details-payment-splitting.component';
import { ContractDetailsComponent } from './contract-details/contract-details.component';
import { ContractTabsDetailsComponent } from './contract-details/contract-tabs-details/contract-tabs-details.component';
import { ContractEditComponent } from './contract-edit/contract-edit.component';
import { ContractFormCarInsuranceComponent } from './contract-form/contract-form-tabs/contract-form-car-insurance/contract-form-car-insurance.component';
import { ContractFormCommissionComponent } from './contract-form/contract-form-tabs/contract-form-commission/contract-form-commission.component';
import { ContractFormDriverComponent } from './contract-form/contract-form-tabs/contract-form-driver/contract-form-driver.component';
import { ContractFormGeneralInformationsComponent } from './contract-form/contract-form-tabs/contract-form-general-informations/contract-form-general-informations.component';
import { ContractFormHealthComponent } from './contract-form/contract-form-tabs/contract-form-health/contract-form-health.component';
import { ContractFormHouseComponent } from './contract-form/contract-form-tabs/contract-form-house/contract-form-house.component';
import { ContractFormMarketingComponent } from './contract-form/contract-form-tabs/contract-form-marketing/contract-form-marketing.component';
import { ContractFormPaymentSplittingComponent } from './contract-form/contract-form-tabs/contract-form-payment-splitting/contract-form-payment-splitting.component';
import { ContractFormVehicleComponent } from './contract-form/contract-form-tabs/contract-form-vehicle/contract-form-vehicle.component';
import { ContractTabsFormComponent } from './contract-form/contract-tabs-form/contract-tabs-form.component';
import { ContractRoutingModule } from './contract-routing.module';
import { ContractDetailsAccountMovesComponent } from './contract-details/contract-details-tab/contract-details-account-moves/contract-details-account-moves.component';
import { ContractDetailsDocumentsComponent } from './contract-details/contract-details-tab/contract-details-documents/contract-details-documents.component';
import { ContractDocumentRequestCardComponent } from './contract-details/contract-details-tab/contract-details-documents/contract-document-request-card/contract-document-request-card.component';

@NgModule({
  declarations: [
    ContractDetailsComponent,
    ContractCreateComponent,
    ContractTabsFormComponent,
    ContractFormMarketingComponent,
    ContractFormHealthComponent,
    ContractFormPaymentSplittingComponent,
    ContractFormGeneralInformationsComponent,
    ContractFormCommissionComponent,
    ContractFormCarInsuranceComponent,
    ContractFormDriverComponent,
    ContractFormHouseComponent,
    ContractFormVehicleComponent,
    ContractTabsDetailsComponent,
    ContractDetailsCommissionComponent,
    ContractDetailsGeneralInformationsComponent,
    ContractDetailsInsuredPersonsComponent,
    ContractDetailsMarketingComponent,
    ContractDetailsPaymentSplittingComponent,
    ContractEditComponent,
    ContractDetailsAccountMovesComponent,
    ContractDetailsDocumentsComponent,
    ContractDocumentRequestCardComponent,
  ],
  imports: [CommonModule, SharedModule, ContractRoutingModule],
})
export class ContractModule {}
