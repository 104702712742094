<div class="d-flex border-red ">
  <div class="me-3">
    <ng-container>
      <i class="mdi mdi-file-hidden icon-img"></i>
    </ng-container>
  </div>
  <div class="d-flex flex-column">
    <div>
      <span class="font-weight-bolder pe-1">{{ document.name }}</span>
      <small>{{ {localeDate: document.localeCreationDate, utcDate: document.creationDate} | friendlyDate }}</small>
      <span>
      <button
        class="btn btn-outline-primary"
        type="button"
        [disabled]="this.uploadCompleted$ | async"
        [style.font-size]="'20px'"
        [title]="'DOCUMENT_PLACEHOLDER_DOCUMENT_ADD' | translate"
        (click)="newDocument.fileupload.nativeElement.click()">
        <i class="mdi mdi-cloud-upload" *ngIf="!(this.uploadCompleted$ | async)"></i>
        <i class="mdi mdi-cloud-check" *ngIf="this.uploadCompleted$ | async"></i>
      </button>
    </span>
    </div>
    <div>{{ document.description }}</div>
  </div>
</div>
<app-document-add #newDocument [documentId]="document.id" />
