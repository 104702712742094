import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, Observable } from 'rxjs';
import { Entity, View } from '../../../../shared/store/view/types';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { FormService } from '../../../../shared/store/form/form.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { ProductLineModel } from 'src/app/shared/store/product-line/types';
import { ProductLineService } from 'src/app/shared/store/product-line/product-line.service';

@UntilDestroy()
@Component({
  selector: 'app-product-line-create',
  templateUrl: './product-line-create.component.html',
  styleUrls: ['./product-line-create.component.scss'],
})
export class ProductLineCreateComponent implements OnInit, OnDestroy {
  protected readonly Entity = Entity;
  view$: Observable<View>;
  productLineForm: FormGroup;
  productLineCreateInstance$: Observable<ProductLineModel>;

  constructor(
    private readonly router: Router,
    private readonly productLineService: ProductLineService,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder,
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.productLineCreateInstance$ =
      this.productLineService.createInstance.value$;
  }

  ngOnInit(): void {
    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'productLineCreatePush') this.onSubmit();
        if (x == 'productLineList') {
          this.formService.clear();
          this.router.navigate(['Insurance/ProductLines/']);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
        this.breadcrumbService.set('@productLineCreate', x.breadcrumbCreate);
      });

    this.productLineForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      productCategoryId: [undefined, [Validators.required]],
      insuranceCompanyId: [undefined, [Validators.required]],
      commissionRate: [''],
    });

    this.productLineService.createInstance.call();
    this.productLineCreateInstance$
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((x) => {
        this.productLineForm?.patchValue({
          name: x.name,
          productCategoryId: !!x.productCategoryId
            ? x.productCategoryId
            : undefined,
          insuranceCompanyId: !!x.insuranceCompanyId
            ? x.insuranceCompanyId
            : undefined,
          commissionRate: x.commissionRate,
        });
      });
  }

  onSubmit() {
    if (this.productLineForm.invalid) {
      this.productLineForm.markAllAsTouched();
      this.formService.countErrors(this.productLineForm, true);
      return;
    }

    this.formService.clear();

    let productLineModel: ProductLineModel = {
      id: 0,
      name: this.productLineForm.value.name!,
      productCategoryId: this.productLineForm.value.productCategoryId!,
      productCategoryName: '',
      insuranceCompanyId: this.productLineForm.value.insuranceCompanyId!,
      commissionRate: this.productLineForm.value.commissionRate!,
      insuranceCompanyContactName: '',
      products: [],
      productCategoryInsuranceDocuments: [],
      notes: '',
    };

    let result$ = this.productLineService.add.call(productLineModel);

    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result) => {
        this.router.navigate(['/Insurance/ProductLines/Details', result.id]);
      });
  }

  ngOnDestroy(): void {
    this.productLineService.add.reset();
  }
}
