import { Action } from '../view/types';

export const documentFeatureKey = 'document';

export type DocumentListItemModel = {
  id: number;
  name: String;
  description: String;
  path: String;
  fileExtension: String;
  creationDate: Date;
  size?: number;
};

export type DocumentModel = {
  id: number;
  name: string;
  description: string;
  entityId: number;
  entityType: string;
  fileExtension: string;
  creationDate: Date;
  contentType: string;
  size?: number;
  localeCreationDate: Date;
  userId?: number;
  userContactName: string;
  contactId?: number;
  contactName: string;
  downloadFinished: boolean;
  requestDate?: Date;
  dateReceived?: Date;
  dateSent?: Date;
  dueDate?: Date;
  documentTypeId?: number;
  documentTypeName: string;
  productCategoryDocumentTypeId?: number;
  productCategoryDocumentTypeDocumentFlow?: DocumentFlow;
  productCategoryDocumentTypeDocumentFlowLoc?: string;
  productCategoryDocumentTypeMandatory?: boolean;
  actions: Action[];
};

export type DocumentFlow = 'ToSend' | 'ToReceive';
