<div class="modal-header">
  <h5 class="modal-title" translate>{{ title }}</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()">
<!--    <span aria-hidden="true">&times;</span>-->
  </button>
</div>
<div class="modal-body" translate>
  {{ message }}
</div>
<div class="modal-footer">
  <button
    [class]="ButtonType.Light | buttontypepipe"
    class="btn-sm btn"
    (click)="decline()"
    translate
  >
    GLOBAL_BTN_NO
  </button>
  <button
    [class]="ButtonType.Primary | buttontypepipe"
    class="btn-sm btn"
    translate
    (click)="accept()"
    ngbAutofocus
  >
    GLOBAL_BTN_YES
  </button>
</div>
