import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProductDetailsCoverageComponent } from '../product-details-tab/product-details-coverage/product-details-coverage.component';
import { ProductDetailsNotesComponent } from '../product-details-tab/product-details-notes/product-details-notes.component';
import { TabsContentProduct } from '../../../../../shared/models/tabs-content-product';

@Component({
  selector: 'app-product-tabs-details',
  templateUrl: './product-tabs-details.component.html',
  styleUrls: ['./product-tabs-details.component.scss'],
})
export class ProductTabsDetailsComponent
  implements OnInit, OnChanges, OnDestroy
{
  active = 1;
  currentActive: number = 1;

  tabsContent$: BehaviorSubject<TabsContentProduct[]> = new BehaviorSubject<
    TabsContentProduct[]
  >([]);
  tabsInit: TabsContentProduct[] = [
    {
      title: 'PRODUCT_TABS_COVERAGE',
      index: 1,
      name: 'productCoverage',
      component: ProductDetailsCoverageComponent,
    },
    // {
    //   title: 'PRODUCT_TABS_NOTES',
    //   index: 2,
    //   name: 'notes',
    //   component: ProductDetailsNotesComponent,
    // },
  ];

  constructor() {}

  ngOnInit(): void {
    this.tabsContent$.next(this.tabsInit);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.currentActive) {
      this.active = this.currentActive;
    } else {
      this.active = 1;
    }
    this.tabsContent$.next(this.tabsInit);
  }

  trackByTabsContent(index: any, item: TabsContentProduct) {
    return item.index;
  }

  onActiveIdChangeSaveCurrent(active: number) {
    this.currentActive = active;
  }

  ngOnDestroy(): void {
    this.currentActive = 0;
  }
}
