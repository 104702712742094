import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { accountFeatureKey } from './types';
import { IAccountState } from './account.reducer';
import { AccountType } from '../accounting/types';

const accountFeatureState =
  createFeatureSelector<IAccountState>(accountFeatureKey);

export const accountSelectors = {
  getById: createApiCallSelectors<number, IAccountState>(
    accountFeatureState,
    'getById',
  ),
  add: createApiCallSelectors<AccountType, IAccountState>(
    accountFeatureState,
    'add',
  ),
  update: createApiCallSelectors<AccountType, IAccountState>(
    accountFeatureState,
    'update',
  ),
  delete: createApiCallSelectors<number, IAccountState>(
    accountFeatureState,
    'delete',
  ),
  createInstance: createApiCallSelectors<
    AccountType | undefined,
    IAccountState
  >(accountFeatureState, 'createInstance'),
};
