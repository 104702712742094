import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SourceEnum } from '../../../../shared/store/typeahead/types';
import { distinctUntilChanged, filter, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GenericApiCall } from '../../../../common/store/types';
import {
  dateAndTimeToDate,
  formatDate,
} from '../../../../shared/helper/datehelper';
import { setDate } from '../../../../shared/helper/timehelper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CashControlService } from '../../../../shared/store/cash-control/cash-control.service';
import { ActivatedRoute } from '@angular/router';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { FormService } from '../../../../shared/store/form/form.service';
import { CashControlModel } from '../../../../shared/store/cash-control/types';
import { take } from 'rxjs/operators';
import { ModalService } from '../../../../shared/service/modal.service';

@UntilDestroy()
@Component({
  selector: 'app-cash-journal-control-add-content',
  templateUrl: './cash-control-form-content.component.html',
  styleUrl: './cash-control-form-content.component.scss',
})
export class CashControlFormContentComponent implements OnInit, OnDestroy {
  @Input() modalRandomIdentifier: number;
  @Input() source: SourceEnum = SourceEnum.Component;
  @Input() cashControlId: number;
  @Input() salesTeamAccountId: number;

  createCashControlInstance$: Observable<CashControlModel>;
  cashControlModel$: Observable<CashControlModel>;
  calculatedAmount: number;

  cashControlForm: FormGroup;
  isEditMode: boolean = false;

  apiCall: GenericApiCall<any, any>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly dynamicService: ModalService,
    private readonly subHeaderService: SubHeaderService,
    private readonly formBuilder: FormBuilder,
    private readonly formService: FormService,
    private readonly cashControlService: CashControlService,
  ) {
    this.createCashControlInstance$ =
      this.cashControlService.createInstance.value$;
    this.cashControlModel$ = this.cashControlService.getById.value$;
  }

  ngOnInit(): void {
    //getting the salesTeamAccount
    if (!this.salesTeamAccountId) {
      this.salesTeamAccountId = this.route.snapshot.params['id'];
    }

    //Déclaration du formulaire
    this.cashControlForm = this.formBuilder.group({
      id: [0, [Validators.required]],
      salesTeamAccountId: [this.salesTeamAccountId, [Validators.required]],
      salesPersonId: [undefined, [Validators.required]],
      realAmount: [undefined, [Validators.required]],
      calculatedAmount: [undefined, [Validators.required]],
      differenceAmount: [0],
      controlDate: [undefined, [Validators.required]],
      controlTime: [undefined, [Validators.required]],
      description: ['', [Validators.required]],
      creationDate: [''],
    });

    //Déterminer si mode Edit (true) ou Create (false)
    this.isEditMode = !!this.cashControlId;

    //On appelle les API en fonction du mode
    if (this.isEditMode) {
      this.apiCall = this.cashControlService.getById;
      this.cashControlService.getById.call(this.cashControlId.toString());
    } else {
      this.apiCall = this.cashControlService.createInstance;
      this.cashControlService.createInstance.call(
        this.salesTeamAccountId.toString(),
      );
    }

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'addCashControl' || x == 'updateCashControl') this.onSubmit();
        this.subHeaderService.setTriggerAction('');
      });

    this.createCashControlInstance$
      .pipe(
        filter((x) => !this.isEditMode),
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((x) => {
        this.calculatedAmount = x.calculatedAmount;
        this.cashControlForm.patchValue({
          salesPersonId: x.salesPersonId,
          salesTeamAccountId: x.salesTeamAccountId,
          calculatedAmount: x.calculatedAmount,
          description: x.description,
          controlDate: formatDate(x.controlDate, 'ToInverseSqlDate'),
          controlTime: setDate(x.controlDate),
          creationDate: x.creationDate,
        });
      });

    this.cashControlModel$
      .pipe(
        filter((x) => this.isEditMode),
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.calculatedAmount = x.calculatedAmount;
        this.cashControlForm.patchValue({
          id: x.id,
          salesTeamAccountId: x.salesTeamAccountId,
          salesPersonId: x.salesPersonId,
          realAmount: x.realAmount,
          calculatedAmount: x.calculatedAmount,
          differenceAmount: x.differenceAmount,
          description: x.description,
          controlDate: formatDate(x.controlDate, 'ToInverseSqlDate'),
          controlTime: setDate(x.controlDate),
          creationDate: x.creationDate,
        });
      });

    //When real amount is changing, we calculate the difference
    this.cashControlForm
      .get('realAmount')
      ?.valueChanges?.pipe(distinctUntilChanged(), untilDestroyed(this))
      ?.subscribe((realAmount) => {
        if (!!realAmount) {
          this.cashControlForm.patchValue({
            differenceAmount: +realAmount - this.calculatedAmount,
          });
        }
      });
  }

  onSubmit() {
    if (this.cashControlForm.invalid) {
      this.cashControlForm.markAllAsTouched();
      this.formService.setEntityErrors('CASH_CONTROL');
      this.formService.countErrors(this.cashControlForm, true);
      return;
    }

    const controlDate = dateAndTimeToDate(
      this.cashControlForm.value.controlDate,
      this.cashControlForm.value.controlTime,
    );

    const cashControlModel: CashControlModel = {
      id: this.cashControlForm.value.id!,
      controlDate: controlDate,
      realAmount: this.cashControlForm.value.realAmount,
      calculatedAmount: this.cashControlForm.value.calculatedAmount,
      salesTeamAccountId: this.cashControlForm.value.salesTeamAccountId,
      salesPersonId: this.cashControlForm.value.salesPersonId,
      description: this.cashControlForm.value.description!,
      creationDate: this.cashControlForm.value.creationDate!,

      //Others
      actions: [],
      salesPersonName: '',
      differenceAmount: this.cashControlForm.value.differenceAmount,
      salesTeamAccountName: '',
    };

    console.log(cashControlModel);

    let result$: Observable<CashControlModel>;
    if (this.isEditMode) {
      result$ = this.cashControlService.update.call(cashControlModel);
    } else {
      result$ = this.cashControlService.add.call(cashControlModel);
    }

    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result) => {
        this.formService.clear();
        switch (this.source) {
          case SourceEnum.Component:
            break;
          case SourceEnum.Modal:
            this.dynamicService.outputFromDynamicComponent(
              this.modalRandomIdentifier,
              result.id.toString(),
            );
            break;
          default:
            break;
        }
      });
  }

  ngOnDestroy(): void {
    this.cashControlService.add.reset();
    this.cashControlService.update.reset();

    if (this.isEditMode) {
      this.cashControlService.getById.reset();
    } else {
      this.cashControlService.createInstance.reset();
    }
  }
}
