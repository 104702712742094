import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from 'src/app/common/store/generic.selectors';
import { PageResult } from '../../types';
import { User, UserConnectionHistory, userFeatureKey } from './type';
import { IUserState } from './user.reducer';

const userFeatureState = createFeatureSelector<IUserState>(userFeatureKey);

export const userSelectors = {
  get: createApiCallSelectors<User[], IUserState>(userFeatureState, 'get'),
  getById: createApiCallSelectors<User, IUserState>(
    userFeatureState,
    'getById',
  ),
  add: createApiCallSelectors<User, IUserState>(userFeatureState, 'add'),
  update: createApiCallSelectors<User, IUserState>(userFeatureState, 'update'),
  lockout: createApiCallSelectors<boolean, IUserState>(
    userFeatureState,
    'lockout',
  ),

  getPaged: createApiCallSelectors<PageResult<User>, IUserState>(
    userFeatureState,
    'getPaged',
  ),
  getCurrentUser: createApiCallSelectors<User, IUserState>(
    userFeatureState,
    'getCurrentUser',
  ),
  getConnectionHistoriesById: createApiCallSelectors<
    UserConnectionHistory[],
    IUserState
  >(userFeatureState, 'getConnectionHistoriesById'),
};
