<app-document-subheader/>
<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div class="page-container" *ngIf="this.applicationFeeRuleModel$ | async as applicationFeeRuleModel">
        <div class="card card-light w-100">

          <div class="card-body">
            <div class="container-fluid">

              <div class="row">

                <div class="col-6 col-lg-6">

                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate>APPLICATION_FEE_RULE_LBL_PRODUCTLINE_NAME</label>
                      <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                          <app-link-to
                            *ngIf="!!applicationFeeRuleModel.productLineName && !!applicationFeeRuleModel.productLineId;else elseBlock"
                            [id]="applicationFeeRuleModel.productLineId.toString()"
                            [label]="applicationFeeRuleModel.productLineName"
                            [url]="'/Insurance/ProductLines/Details/'"/>
                        <ng-template #elseBlock>
                          {{ applicationFeeRuleModel.productLineName }}
                        </ng-template>
                      </span>
                      </div>
                    </div>

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>APPLICATION_FEE_RULE_LBL_TYPE</label>
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ applicationFeeRuleModel.applicationFeeTypeLoc }}
                      </span>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>APPLICATION_FEE_RULE_LBL_MINIMUM_VALUE</label>
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ applicationFeeRuleModel.minimumValue | showSignValueType: applicationFeeRuleModel.amountType }}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>APPLICATION_FEE_RULE_LBL_MAXIMUM_VALUE</label>
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ applicationFeeRuleModel.maximumValue | showSignValueType: applicationFeeRuleModel.amountType }}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>APPLICATION_FEE_RULE_LBL_DEFAULT_VALUE</label>
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ applicationFeeRuleModel.defaultValue | showSignValueType: applicationFeeRuleModel.amountType }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>COMMISSION_PLAN_RULE_LBL_START_DATE</label>
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{
                          !!applicationFeeRuleModel.startDate
                            ? (applicationFeeRuleModel.startDate | date : 'dd/MM/yyyy')
                            : '-'
                        }}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>COMMISSION_PLAN_RULE_LBL_END_DATE</label>
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{
                          !!applicationFeeRuleModel.endDate
                            ? (applicationFeeRuleModel.endDate | date : 'dd/MM/yyyy')
                            : '-'
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-comment" *ngIf="this.applicationFeeRuleModel$ | async as applicationFeeRule">
      <app-comment-panel [entityType]="'ApplicationFeeRule'"
                         [entityId]="applicationFeeRule.id.toString()"></app-comment-panel>
    </div>
  </div>
</div>
