<div
  class="tab-pane fade flex-even active show"
  id="employees"
  role="tabpanel"
  aria-labelledby="employees-tab"
  formGroupName="parametersFormGroup"
>
  <div class="d-flex flex-column h-100">
    <div class="row mt-2">
      <div class="col-12 col-md">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" for="parametersVehicle" translate
          >EMPLOYEE_LBL_COMPANY_VEHICLE</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="parametersVehicle"
              type="text"
              formControlName="parametersVehicle"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" for="parameterHomeWorkDistance" translate
          >EMPLOYEE_LBL_HOME_DISTANCE</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="parameterHomeWorkDistance"
              type="text"
              formControlName="parameterHomeWorkDistance"
              mask="separator.2"
              [showMaskTyped]="false"
              [decimalMarker]="','"
              [allowNegativeNumbers]="false"
            />
<!--            [suffix]="' km'"-->
          </div>
        </div>
      </div>
      <div class="col"></div>
    </div>
  </div>
</div>
