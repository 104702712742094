import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommissionPlanRuleCompanyRoutingModule } from './commission-plan-rule-company-routing.module';
import { CommissionPlanRuleCompanyCreateComponent } from './commission-plan-rule-company-create/commission-plan-rule-company-create.component';
import { SharedModule } from '../../../../shared/shared.module';
import { CommissionPlanRuleCompanyFormContentComponent } from './commission-plan-rule-company-form-content/commission-plan-rule-company-form-content.component';
import { CommissionPlanRuleCompanyEditComponent } from './commission-plan-rule-company-edit/commission-plan-rule-company-edit.component';
import { CommissionPlanRuleCompanyTabsDetailsComponent } from './commission-plan-rule-company-tabs-details/commission-plan-rule-company-tabs-details.component';

@NgModule({
  declarations: [
    CommissionPlanRuleCompanyCreateComponent,
    CommissionPlanRuleCompanyFormContentComponent,
    CommissionPlanRuleCompanyEditComponent,
    CommissionPlanRuleCompanyTabsDetailsComponent,
  ],
  imports: [CommonModule, CommissionPlanRuleCompanyRoutingModule, SharedModule],
  exports: [],
})
export class CommissionPlanRuleCompanyModule {}
