import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationFeeRuleService } from '../../store/application-fee-rule/application-fee-rule.service';
import { ApplicationFeeRuleModel } from '../../store/application-fee-rule/types';

@Component({
  selector: 'app-application-fee-rule-details-application-fee',
  templateUrl: './application-fee-rule-details-application-fee.component.html',
  styleUrls: ['./application-fee-rule-details-application-fee.component.scss'],
})
export class ApplicationFeeRuleDetailsApplicationFeeComponent
  implements OnInit, OnDestroy
{
  applicationFeeRuleModels$: Observable<ApplicationFeeRuleModel[]>;
  productLineId: number;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly applicationFeeRuleService: ApplicationFeeRuleService,
  ) {
    this.applicationFeeRuleModels$ =
      this.applicationFeeRuleService.getByProductLineId.value$;
  }

  ngOnInit(): void {
    this.productLineId = this.route.snapshot.params['id'];
  }

  ngOnDestroy(): void {}

  trackByApplicationFeeRuleId(index: any, item: ApplicationFeeRuleModel) {
    return item.id;
  }

  onClickLinkTo(applicationFeeRuleId: number, url: string) {
    this.router.navigate([
      'Finance/ApplicationFeeRules/Details/',
      applicationFeeRuleId,
    ]);
  }
}
