<app-document-subheader></app-document-subheader>
<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div class="page-container">
        <div class="card card-light w-100">
          <div class="card-body">
            <div class="container-fluid">
              <form [formGroup]="employeeForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="employeeForm">
                <button type="submit" hidden="hidden"></button>

                <div class="row mb-2">
                  <div class="col-6 mr-auto">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-6">
                          <label
                            class="form-title-label"
                            for="lastname"
                            translate
                          >EMPLOYEE_LBL_LASTNAME</label
                          >
                          <input
                            class="form-control form-title"
                            id="lastname"
                            name="lastname"
                            type="text"
                            formControlName="lastname"
                            oninput="this.value = this.value.toUpperCase()"
                            checkFormFieldValidity
                          />
                        </div>
                        <div class="col-6">
                          <label
                            class="form-title-label"
                            for="firstname"
                            translate
                          >EMPLOYEE_LBL_FIRSTNAME</label
                          >
                          <input
                            class="form-control form-title"
                            id="firstname"
                            name="firstname"
                            type="text"
                            formControlName="firstname"
                            checkFormFieldValidity
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md">
                    <input
                      type="text"
                      [hidden]="true"
                      formControlName="resourceId"
                    />
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" for="workPhone" translate
                      >EMPLOYEE_LBL_PHONE_NUMBER</label
                      >
                      <div class="col">
                        <input
                          class="form-control form-control-sm"
                          id="workPhone"
                          type="text"
                          [placeholder]="'EMPLOYEE_PLACEHOLDER_PHONE_NUMBER' |translate"
                          formControlName="workPhone"
                          mask="00 00 00 00 00"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" for="workEmail" translate
                      >EMPLOYEE_LBL_EMAIL</label
                      >
                      <div class="col">
                        <input
                          class="form-control form-control-sm"
                          id="workEmail"
                          type="text"
                          formControlName="workEmail"
                          [placeholder]="'EMPLOYEE_PLACEHOLDER_EMAIL' | translate"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col">
                  </div>
                </div>
                <app-employee-tabs-form
                  [form]="this.employeeForm"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-comment" *ngIf="this.employeeId" [hidden]="false">
      <app-comment-panel [entityType]="'Employee'" [entityId]="this.employeeId.toString()"></app-comment-panel>
    </div>
  </div>
</div>
