import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { LeadService } from 'src/app/shared/store/lead/lead.service';
import {
  PushLeadModel,
  LeadDetailModel,
  InsuranceCategory,
} from 'src/app/shared/store/lead/types';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { filter } from 'rxjs/operators';
import { SmartAction, View } from '../../../../shared/store/view/types';
import { ViewService } from '../../../../shared/store/view/views.service';
import { CategoryChoiceComponent } from 'src/app/shared/components/category-choice/category-choice.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SalesType } from '../../../../shared/enum/sales-type';
import { SearchService } from 'src/app/shared/store/search/search.service';

@UntilDestroy()
@Component({
  selector: 'app-lead-details',
  templateUrl: './lead-details.component.html',
  styleUrls: ['./lead-details.component.scss'],
})
export class LeadDetailsComponent implements OnInit, OnDestroy {
  leadId: string;
  view$: Observable<View>;
  leadDetail$: Observable<LeadDetailModel>;
  active = '';
  category: number;
  fragment: string;
  smartActions: SmartAction[];

  constructor(
    private readonly leadService: LeadService,
    private route: ActivatedRoute,
    private readonly router: Router,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly searchService: SearchService,
    private readonly modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.view$ = this.viewService.getViews.value$;
    this.leadDetail$ = this.leadService.getByUniqueId.value$;
    this.leadId = this.route.snapshot.params['id'];
    this.leadService.getByUniqueId.call(this.leadId);
    this.fragment = this.route.snapshot.fragment!;

    this.smartActions = [
      {
        id: 'contracts',
        label: 'Contracts',
        url: '/Insurance/Contracts/List',
        count: 0,
        filter: {
          domains: [
            {
              field: 'LeadId',
              operator: {
                id: 'EqualTo',
                label: '',
                numberOfValues: 1,
              },
              connector: 'Or',
              fieldLabel: '',
              text: '',
              isActive: true,
              text2: '',
              value: '',
              value2: null,
            },
          ],
          name: '',
          tag: 'SmartActionFilter',
          text: '',
        },
        icon: 'file-check-outline',
        actif: true,
        singleId: '',
      },
      {
        id: 'similarLeads',
        label: 'Leads similaires',
        url: '/Crm/Leads/List',
        count: 0,
        filter: {
          domains: [
            {
              field: 'FirstName',
              operator: {
                id: 'EqualTo',
                label: '',
                numberOfValues: 1,
              },
              connector: 'And',
              fieldLabel: '',
              text: '',
              isActive: true,
              text2: '',
              value: '',
              value2: null,
            },
            {
              field: 'LastName',
              operator: {
                id: 'EqualTo',
                label: '',
                numberOfValues: 1,
              },
              connector: 'And',
              fieldLabel: '',
              text: '',
              isActive: true,
              text2: '',
              value: '',
              value2: null,
            },
            {
              field: 'PhoneNumber',
              operator: {
                id: 'EqualTo',
                label: '',
                numberOfValues: 1,
              },
              connector: 'And',
              fieldLabel: '',
              text: '',
              isActive: true,
              text2: '',
              value: '',
              value2: null,
            },
            {
              field: 'Email',
              operator: {
                id: 'EqualTo',
                label: '',
                numberOfValues: 1,
              },
              connector: 'And',
              fieldLabel: '',
              text: '',
              isActive: true,
              text2: '',
              value: '',
              value2: null,
            },
          ],
          name: '',
          tag: 'SmartActionFilter',
          text: '',
        },
        icon: 'set-center',
        actif: true,
        singleId: '',
      },
    ];

    this.leadDetail$
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@leadDetails', x.name);
        switch (x.productCategoryProductCategoryEnumKey) {
          case InsuranceCategory.Health:
            this.leadService.getHealthDetail.call(x.id.toString());
            break;
          case InsuranceCategory.Car:
            this.leadService.getCarDetail.call(x.id.toString());
            break;
          case InsuranceCategory.House:
            this.leadService.getHouseDetail.call(x.id.toString());
            break;
          default:
            break;
        }

        if (x.contracts) {
          this.smartActions[0].filter.domains[0].value = x.id.toString();
          this.smartActions[0].filter.name = x.name;
          this.smartActions[0].filter.text = x.name;
          this.smartActions[0].count = x.contracts.length;
          this.smartActions[0].label =
            x.contracts.length > 1
              ? 'SMARTACTIONS_LBL_CONTRACTS'
              : 'SMARTACTIONS_LBL_CONTRACT';
          this.smartActions[0].singleId =
            x.contracts.length === 1 ? x.contracts[0].uniqueId : '';
        }
        this.smartActions[1].count = x.similarLeadsCount;
        this.smartActions[1].filter.name = x.name;
        this.smartActions[1].filter.text = x.name;
        this.smartActions[1].filter.domains[0].value = x.firstName;
        this.smartActions[1].filter.domains[1].value = x.lastName;
        this.smartActions[1].filter.domains[2].value = x.phoneNumber;
        this.smartActions[1].filter.domains[3].value = x.email;
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        let postLeadModel: PushLeadModel = {
          id: this.leadId,
          oggo: false,
          smartbroker: false,
        };
        switch (x) {
          case 'leadSendOggo':
            postLeadModel.oggo = true;
            this.leadService.sendLead.call(postLeadModel);
            break;
          case 'leadSendSmartbroker':
            postLeadModel.smartbroker = true;
            this.leadService.sendLead.call(postLeadModel);
            break;
          case 'leadEdit':
            this.router.navigate(['/Crm/Leads/Edit', this.leadId]);
            break;
          case 'leadCreate':
            this.createLead();
            break;
          default:
            break;
        }
        this.subHeaderService.setTriggerAction('');
      });
  }

  ngOnDestroy() {
    this.leadService.getByUniqueId.reset();
    this.breadcrumbService.set('@leadDetails', ' ');
  }

  createLead() {
    const modalRef = this.modalService.open(CategoryChoiceComponent);
    modalRef.componentInstance.salesType = SalesType.LEAD;
  }
}
