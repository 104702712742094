<div
  class="tab-pane fade flex-even active show"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
  formGroupName="commissionFormGroup"
>
  <div class="d-flex flex-column h-100">
    <div class="row mt-2">
      <div class="col-12 col-md">
        <div class="form-group-title my-1" translate>
          CONTRACT_FIELDSET_SHARED_COMMISSION
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_SHAREDSALESPERSON</label
          >
          <div class="col">
            <app-typeahead
              [entity]="Entity.SalesTeam"
              [routeEndpoint]="'SalesTeams/SalesTeamMembers'"
              [controlForm]="
                this.form.controls['commissionFormGroup'].get(
                  'sharedSalesPersonId'
                )
              "
              [limitToList]="true"
              [referenceFilterParentName]="'SalesTeamId'"
              [referenceFilterParentValue]="
                this.form.controls['salesTeam'].value
              "
              [dbNameOfName]="'UserContactName'"
              [dbNameOfId]="'UserId'"
            ></app-typeahead>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_SHAREDCOMMISSIONRATE</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              type="text"
              [readOnly]="!this.isRateVisible"
              [placeholder]="'CONTRACT_PLACEHOLDER_COMMISSION_RATE' | translate"
              formControlName="sharedCommissionRate"
              checkFormFieldValidity
              [placeholder]="this.amountPlaceholder | translate"
              mask="percent.2"
              [suffix]="this.amountSuffix | translate"
              [showMaskTyped]="false"
              [decimalMarker]="','"
              [allowNegativeNumbers]="false"
            />
          </div>
        </div>
      </div>
      <div class="col"></div>
    </div>
  </div>
</div>
