import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorService } from '../../error';
import { MessageService } from '../../service/message/message.service';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CrudType } from '../../service/message/types';
import {
  AccountMoveModel,
  AccountMoveInstanceParams,
  IssuingAccountMovePayingAccountMoveModel,
  AccountMovePeriodModel,
  SlipModel,
  PaymentStatus,
  AccountMoveStatus,
  SlipResponseModel,
  JournalAssignRecord,
  BindType,
  AccountModel,
  CashJournalEntry,
  CashJournalModel,
} from './types';
import { isNumber } from 'chart.js/helpers';

@Injectable({
  providedIn: 'root',
})
export class AccountingApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService,
    private readonly messageService: MessageService
  ) {}

  getById = (id: number): Observable<AccountMoveModel> => {
    let parameters: string[];
    parameters = [id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<AccountMoveModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getByContactId = (id: number): Observable<AccountMoveModel[]> => {
    let parameters: string[];
    parameters = ['contact', id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<AccountMoveModel[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getByJournaltId = (id: number): Observable<AccountMoveModel[]> => {
    let parameters: string[];
    parameters = ['journal', id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<AccountMoveModel[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getUnbindedByContactId = ({
    id,
    bindType,
  }: {
    id: string;
    bindType: BindType;
  }): Observable<AccountMoveModel[]> => {
    let parameters: string[];
    parameters = ['unbinded', 'contact', id.toString(), bindType];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<AccountMoveModel[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getUnbindedByContractId = ({
    id,
    bindType,
  }: {
    id: string;
    bindType: BindType;
  }): Observable<AccountMoveModel[]> => {
    let parameters: string[];
    parameters = ['unbinded', 'contract', id.toString(), bindType];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<AccountMoveModel[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };
  getUnbindedRepayment = (id: number): Observable<AccountMoveModel[]> => {
    let parameters: string[];
    parameters = ['repayment', id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<AccountMoveModel[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };
  getUnbindedSlip = (id: number): Observable<JournalAssignRecord[]> => {
    let parameters: string[];
    parameters = ['unbinded', 'slip', 'contact', id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<JournalAssignRecord[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  createInstance = (
    createInstanceParams: AccountMoveInstanceParams
  ): Observable<AccountMoveModel> => {
    let parameters: string[];
    parameters = ['create'];

    let queryParams = new HttpParams();
    queryParams = queryParams.append(
      'moveType',
      createInstanceParams.moveType!
    );
    queryParams = queryParams.append(
      'contractId',
      createInstanceParams.contractId!
    );

    let url = this.formatUrl(parameters);
    return this.httpClient
      .get<AccountMoveModel>(url, { params: queryParams })
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  add = (accountMoveModel: AccountMoveModel): Observable<AccountMoveModel> => {
    let parameters: string[];
    parameters = [];
    let url = this.formatUrl(parameters);

    //add formData (with all the properties) if file //generic method
    let formData = new FormData();
    for (const property in accountMoveModel) {
      if (!!accountMoveModel[property]) {
        if (Array.isArray(accountMoveModel[property])) {
          for (let i = 0; i < accountMoveModel[property].length; i++) {
            for (const propertySub in accountMoveModel[property][i]) {
              if (isNumber(accountMoveModel[property][i][propertySub])) {
                //Replace the . by , for float/decimal/double number in formData.
                //In formData, the controller does not accept . for decimal.
                let val = accountMoveModel[property][i][propertySub];
                val = val.toString().replace('.', ',');
                formData.append(
                  property + '[' + i + '][' + propertySub + ']',
                  val
                );
              } else {
                formData.append(
                  property + '[' + i + '][' + propertySub + ']',
                  accountMoveModel[property][i][propertySub]
                );
              }
            }
          }
        } else {
          formData.append(property, accountMoveModel[property]);
        }
      }
    }

    return this.httpClient
      .post<AccountMoveModel>(url, formData, { observe: 'response' })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  bind = (
    issuingAccountMovePayingAccountMoveModel: IssuingAccountMovePayingAccountMoveModel
  ): Observable<boolean> => {
    let parameters: string[];
    parameters = ['bind'];
    let url = this.formatUrl(parameters);

    return this.httpClient
      .post<boolean>(url, issuingAccountMovePayingAccountMoveModel, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  getPeriods = (contractId: number): Observable<AccountMovePeriodModel[]> => {
    let parameters: string[];
    parameters = ['periods'];

    let queryParams = new HttpParams();
    queryParams = queryParams.append('contractId', contractId);

    let url = this.formatUrl(parameters);
    return this.httpClient
      .get<AccountMovePeriodModel[]>(url, { params: queryParams })
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  importSlip = (slipModel: SlipModel): Observable<SlipResponseModel[]> => {
    let parameters: string[];
    parameters = ['slip'];
    let url = this.formatUrl(parameters);

    //add formData (with all the properties) if file //generic method
    let formData = new FormData();
    for (const property in slipModel) {
      if (!!slipModel[property]) {
        if (Array.isArray(slipModel[property])) {
          for (let i = 0; i < slipModel[property].length; i++) {
            formData.append(property + '[]', slipModel[property][i]);
          }
        } else {
          formData.append(property, slipModel[property]);
        }
      }
    }

    return this.httpClient
      .post<SlipResponseModel[]>(url, formData, { observe: 'response' })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.import),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  changeAccountMoveStatus = ({
    id,
    status,
  }: {
    id: string;
    status: AccountMoveStatus;
  }): Observable<AccountMoveModel> => {
    let parameters: string[];
    parameters = [id, 'status'];
    let url = this.formatUrl(parameters);

    let formData = new FormData();
    formData.append('status', status);

    return this.httpClient
      .put<AccountMoveModel>(url, formData, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  uploadProof = (params: { id: number; file: File }): Observable<boolean> => {
    let parameters: string[];
    parameters = ['accountMoveLines', 'files', params.id.toString()];
    let url = this.formatUrl(parameters);

    let formData = new FormData();
    formData.append('file', params.file);

    return this.httpClient.post<boolean>(url, formData).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getAccountMovePdf = (accountMoveId: number): Observable<any> => {
    let parameters: string[];
    parameters = ['pdf', accountMoveId.toString()];
    let url = this.formatUrl(parameters);

    return this.httpClient.get(url, { responseType: 'blob' }).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  delete = (accountMoveId: number): Observable<boolean> => {
    let parameters: string[];
    parameters = [accountMoveId.toString()];
    let url = this.formatUrl(parameters);

    return this.httpClient.delete<boolean>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  unbind = ({
    accountMoveId,
    bindType,
  }: {
    accountMoveId: number;
    bindType: BindType;
  }): Observable<boolean> => {
    let parameters: string[];
    parameters = [accountMoveId.toString(), 'binded', bindType.toString()];
    let url = this.formatUrl(parameters);

    return this.httpClient.delete<boolean>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getBindedAccountMoveByAccountMoveId = ({
    accountMoveId,
    bindType,
  }: {
    accountMoveId: number;
    bindType: BindType;
  }): Observable<AccountMoveModel> => {
    let parameters: string[];
    parameters = ['bindedAccountMoves', accountMoveId.toString(), bindType];
    let url = this.formatUrl(parameters);

    return this.httpClient.get<AccountMoveModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getAccountByContactId = (id: number): Observable<AccountModel> => {
    const url =
      environment.comparanooapiurl +
      environment.features.account.url +
      '/contact/' +
      id.toString();

    return this.httpClient.get<AccountModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };
  getAccountById = (id: number): Observable<AccountModel> => {
    const url =
      environment.comparanooapiurl +
      environment.features.account.url +
      '/' +
      id.toString();

    return this.httpClient.get<AccountModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getCashJournal = (accountId: number): Observable<CashJournalModel> => {
    let parameters: string[];
    parameters = ['cashjournal', accountId.toString()];
    let url = this.formatUrl(parameters);

    return this.httpClient.get<CashJournalModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  formatUrl = (parameters: string[]): string => {
    let url = environment.comparanooapiurl;
    url += environment.features.accounting.url;
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}
