<ng-select [items]="dataSource$ | async"
           #select
           [bindValue]="this.valueMember"
           [bindLabel]="this.displayMember"
           [placeholder]="this.placeholder"
           [formControl]="this.controlForm"
           [searchable]="this.dropdownStyle === DropdownStyle.DropDownList"
           [multiple]="true"
           [clearAllText]="'TYPEAHEAD_LBL_CLEAR_ALL_TEXT' | translate"
           [typeahead]="searchInput$"
           [addTagText]="this.addTagText | translate"
           [addTag]="quickAdd"
           (add)="onAdd($event)"
           (clear)="onClear()"
           [hideSelected]="true"
           [compareWith]="selectedFn"
           [readonly]="this.readonly"
           class="form-control form-control-sm"
           checkFormFieldValidity
>
<!--  (change)="onValueMemberChange($event)"-->
  <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
    <div [ngOptionHighlight]="search">{{ item.name }}</div>
  </ng-template>
</ng-select>
