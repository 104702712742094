import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ErrorService } from '../../error';
import { DocumentModel } from './types';
import { catchError } from 'rxjs/operators';
import { InputByEntity } from '../comment/types';
import { DocumentTypeModel } from '../document-type/types';
import { MessageService } from '../../service/message/message.service';
import { CrudType } from '../../service/message/types';

@Injectable({
  providedIn: 'root',
})
export class DocumentApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService,
    private readonly messageService: MessageService,
  ) {}

  getById = (id: number): Observable<DocumentModel> => {
    let parameters: string[];
    parameters = [id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<DocumentModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  getByEntityId = (
    inputDocument: InputByEntity,
  ): Observable<DocumentModel[]> => {
    let parameters: string[];
    parameters = [inputDocument.entityType, inputDocument.entityId.toString()];

    let params = new HttpParams().append('orderBy', 'CreationDate desc');

    let url = this.formatUrl(parameters);
    return this.httpClient.get<DocumentModel[]>(url, { params: params }).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  getFile = (id: number): Observable<any> => {
    let parameters: string[];
    parameters = ['files', id.toString()];
    let url = this.formatUrl(parameters);

    return this.httpClient.get(url, { responseType: 'blob' }).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  add = (documentModel: DocumentModel): Observable<DocumentModel> => {
    let parameters: string[];
    parameters = [];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .post<DocumentModel>(url, documentModel, { observe: 'response' })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  update = (documentModel: DocumentModel): Observable<DocumentModel> => {
    let parameters: string[];
    parameters = [documentModel.id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .put<DocumentModel>(url, documentModel, { observe: 'response' })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  delete = (id: number): Observable<boolean> => {
    let parameters: string[];
    parameters = [id.toString()];
    let url = this.formatUrl(parameters);

    return this.httpClient.delete<boolean>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  deleteFile = (id: number): Observable<boolean> => {
    let parameters: string[];
    parameters = ['files', id.toString()];
    let url = this.formatUrl(parameters);

    return this.httpClient.delete<boolean>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  copyToDocumentRequest = ({
    documentId,
    documentRequestId,
  }: {
    documentId: number;
    documentRequestId: number;
  }): Observable<DocumentModel> => {
    let parameters: string[];
    parameters = [documentId.toString(), 'copy', documentRequestId.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .post<DocumentModel>(url, '', { observe: 'response' })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  formatUrl = (parameters: string[]): string => {
    var url = environment.comparanooapiurl;
    url += environment.features.document.url;
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}
