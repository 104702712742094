import { Component, input, OnInit, output } from '@angular/core';
import { DocumentModel } from '../../../../../../../shared/store/document/types';
import { BehaviorSubject, Observable, withLatestFrom } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DocumentService } from '../../../../../../../shared/store/document/document.service';
import { UploadFileService } from '../../../../../../../shared/store/upload-file/upload-file.service.facade';
import { NotificationService } from '../../../../../../../shared/store/notification/notification.service';
import { ConfigurationService } from '../../../../../../../shared/service/configuration.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { InputByEntity } from '../../../../../../../shared/store/comment/types';
import {
  NotificationStatus,
  NotificationType,
} from '../../../../../../../shared/store/notification/types';

@UntilDestroy()
@Component({
  selector: 'app-contract-document-request-card',
  templateUrl: './contract-document-request-card.component.html',
  styleUrl: './contract-document-request-card.component.scss',
})
export class ContractDocumentRequestCardComponent implements OnInit {
  // @Input({ required: true }) documentModel: DocumentModel;
  documentModel = input.required<DocumentModel>();
  isEditMode = input<boolean>(true);

  //Refresh event
  onNewDocumentRefresh = output<boolean>();

  //Actions event
  onDocumentDownload = output<DocumentModel>();
  onDocumentEdit = output<DocumentModel>();
  onDocumentDelete = output<DocumentModel>();

  fileOver$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  inputDocument: InputByEntity;
  uploadCompleted$: Observable<boolean>;
  acceptFiles: string;
  protected readonly document = document;

  constructor(
    private documentService: DocumentService,
    private readonly uploadFileService: UploadFileService,
    private readonly notificationService: NotificationService,
    private readonly configurationService: ConfigurationService,
    private readonly translateService: TranslateService,
    private readonly store: Store,
  ) {}

  ngOnInit(): void {
    //Quand l'upload est fini, on refresh la liste
    this.uploadCompleted$ = this.uploadFileService.completed$.pipe(
      withLatestFrom(this.uploadFileService.currentFileId$),
      filter(([_isInProgress, id]) => this.documentModel().id == id),
      map(([isInProgress, _id]) => isInProgress),
      untilDestroyed(this),
    );

    this.uploadCompleted$.subscribe((x) => {
      this.onNewDocument(x);
    });

    this.acceptFiles = this.configurationService
      .getAutorizedFileExtensions()
      .join(',');
  }

  onNewDocument(refresh: boolean) {
    this.onNewDocumentRefresh.emit(refresh);
  }

  triggerAction(actionName: string) {
    switch (actionName) {
      case 'documentDownload':
        this.onDocumentDownload.emit(this.documentModel());
        break;
      case 'documentEdit':
        this.onDocumentEdit.emit(this.documentModel());
        break;
      case 'documentDelete':
        this.onDocumentDelete.emit(this.documentModel());
        break;
    }
  }

  // Handler for file drop
  onFileDrop(event: DragEvent): void {
    event.preventDefault();

    if (!this.isEditMode()) return;

    const files = event.dataTransfer?.files;

    if (!files) return;

    if (files.length > parseInt(this.configurationService.getMaxFiles())) {
      this.notificationService.add({
        timestamp: new Date().getTime(),
        content: this.translateService.instant('DOCUMENT_MSG_ERROR_MAX_FILES', {
          max: this.configurationService.getMaxFiles(),
        }),
        notificationStatus: NotificationStatus.Error,
        userCancelled: false,
        type: NotificationType.Toast,
        customNotification: false,
      });
      this.reset();
    }

    for (let i = 0; i < files.length; i++) {
      let file: File = files[i];

      if (file) {
        if (file.size == 0) {
          this.notificationService.add({
            timestamp: new Date().getTime(),
            content: this.translateService.instant(
              'DOCUMENT_MSG_ERROR_EMPTY_FILE',
              {
                fileName: file.name,
              },
            ),
            notificationStatus: NotificationStatus.Error,
            userCancelled: false,
            type: NotificationType.Toast,
            customNotification: false,
          });
          this.reset();
        } else if (
          file.size >
          parseInt(this.configurationService.getMaxSize()) * 1024 * 1024
        ) {
          this.notificationService.add({
            timestamp: new Date().getTime(),
            content: this.translateService.instant(
              'DOCUMENT_MSG_ERROR_MAX_SIZE',
              {
                fileName: file.name,
                max: this.configurationService.getMaxSize(),
              },
            ),
            notificationStatus: NotificationStatus.Error,
            userCancelled: false,
            type: NotificationType.Toast,
            customNotification: false,
          });
          this.reset();
        } else {
          this.uploadFileService.uploadFile(
            this.documentModel().id,
            this.documentModel().entityType,
            this.documentModel().entityId,
            file,
          );
        }
      }
    }
    this.fileOver$.next(false);
  }

  // Prevent default dragover behavior
  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    if (!this.isEditMode()) return;

    this.fileOver$.next(true);
  }

  onDragLeave($event: DragEvent) {
    if (!this.isEditMode()) return;
    this.fileOver$.next(false);
  }

  onDragEnd($event: DragEvent) {
    if (!this.isEditMode()) return;
    this.fileOver$.next(false);
  }

  reset = () => {
    // this.fileupload.nativeElement.value = '';
    this.uploadFileService.resetUpload();
  };
}
