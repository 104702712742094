import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import { CashControlAction, cashControlActions } from './cash-control.actions';
import { CashControlApiService } from './cash-control.api.service';

@Injectable()
export class CashControlEffects {
  constructor(
    private readonly cashControlApiService: CashControlApiService,
    private readonly action$: Actions<CashControlAction>,
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    cashControlActions.getById,
    this.cashControlApiService.getById,
  );

  getBySalesTeamAccountId = createApiCallEffects(
    this,
    this.action$,
    cashControlActions.getBySalesTeamAccountId,
    this.cashControlApiService.getBySalesTeamAccountId,
  );

  add = createApiCallEffects(
    this,
    this.action$,
    cashControlActions.add,
    this.cashControlApiService.add,
  );

  update = createApiCallEffects(
    this,
    this.action$,
    cashControlActions.update,
    this.cashControlApiService.update,
  );

  delete = createApiCallEffects(
    this,
    this.action$,
    cashControlActions.delete,
    this.cashControlApiService.delete,
  );

  create = createApiCallEffects(
    this,
    this.action$,
    cashControlActions.createInstance,
    this.cashControlApiService.createInstance,
  );
}
