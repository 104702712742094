import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cardactivepipe',
})
export class CardActivePipe implements PipeTransform {
  transform(value: boolean): unknown {
    return {
      'card-active': value,
      'card-non-active': !value,
    };
  }
}
