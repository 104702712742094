import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { CashControlModel, cashControlFeatureKey } from './types';
import { ICashControlState } from './cash-control.reducer';

const cashControlFeatureState = createFeatureSelector<ICashControlState>(
  cashControlFeatureKey,
);

export const cashControlSelectors = {
  getById: createApiCallSelectors<number, ICashControlState>(
    cashControlFeatureState,
    'getById',
  ),
  getBySalesTeamAccountId: createApiCallSelectors<number, ICashControlState>(
    cashControlFeatureState,
    'getBySalesTeamAccountId',
  ),
  add: createApiCallSelectors<CashControlModel, ICashControlState>(
    cashControlFeatureState,
    'add',
  ),
  update: createApiCallSelectors<CashControlModel, ICashControlState>(
    cashControlFeatureState,
    'update',
  ),
  delete: createApiCallSelectors<number, ICashControlState>(
    cashControlFeatureState,
    'delete',
  ),
  createInstance: createApiCallSelectors<number, ICashControlState>(
    cashControlFeatureState,
    'createInstance',
  ),
};
