<div class="card-header">
  <div>
    <div class="smart-actions">
      <ng-container *ngFor="let smartAction of this.smartActions">
        <ng-container *ngIf="smartAction.id == 'similarLeads'; else elseBlock">
          <a
            *ngIf="smartAction.count != 1"
            class="smart-link"
            (click)="onSmartLinkClick(smartActions.indexOf(smartAction))"
          >
            <i class="mdi mdi-{{ smartAction.icon }}"></i>
            <div class="ms-1">
              <div class="smart-value">
                {{ smartAction.count }}
              </div>
              <div class="smart-unit">
                {{ smartAction.label | translate }}
              </div>
            </div>
          </a>
        </ng-container>
        <ng-template #elseBlock>
          <a
            *ngIf="smartAction.count != 0"
            class="smart-link"
            (click)="onSmartLinkClick(smartActions.indexOf(smartAction))"
          >
            <i class="mdi mdi-{{ smartAction.icon }}"></i>
            <div class="ms-1">
              <div class="smart-value">
                {{ smartAction.count }}
              </div>
              <div class="smart-unit">
                {{ smartAction.label | translate }}
              </div>
            </div>
          </a>
        </ng-template>
      </ng-container>
    </div>
  </div>
</div>
