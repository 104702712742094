import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommissionPlanRuleSharedSalesPersonRoutingModule } from './commission-plan-rule-shared-sales-person-routing.module';
import { CommissionPlanRuleSharedSalesPersonCreateComponent } from './commission-plan-rule-shared-sales-person-create/commission-plan-rule-shared-sales-person-create.component';
import { CommissionPlanRuleSharedSalesPersonEditComponent } from './commission-plan-rule-shared-sales-person-edit/commission-plan-rule-shared-sales-person-edit.component';
import { SharedModule } from '../../../../shared/shared.module';
import { CommissionPlanRuleModule } from '../commission-plan-rule.module';
import { CommissionPlanRuleSharedSalesPersonFormContentComponent } from './commission-plan-rule-shared-sales-person-form-content/commission-plan-rule-shared-sales-person-form-content.component';

@NgModule({
  declarations: [
    CommissionPlanRuleSharedSalesPersonCreateComponent,
    CommissionPlanRuleSharedSalesPersonEditComponent,
    CommissionPlanRuleSharedSalesPersonFormContentComponent,
  ],
  imports: [
    CommonModule,
    CommissionPlanRuleSharedSalesPersonRoutingModule,
    SharedModule,
  ],
})
export class CommissionPlanRuleSharedSalesPersonModule {}
