import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-colored-amount',
  templateUrl: './colored-amount.component.html',
  styleUrl: './colored-amount.component.scss',
})
export class ColoredAmountComponent implements ICellRendererAngularComp {
  amount: number = 0;

  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.amount = params.value;
  }

  refresh(params: ICellRendererParams) {
    this.amount = params.value;
    return true;
  }
}
