import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ApplicationFeeRuleRoutingModule } from './application-fee-rule-routing.module';
import { ApplicationFeeRuleDetailsComponent } from './application-fee-rule-details/application-fee-rule-details.component';
import { ApplicationFeeRuleCreateComponent } from './application-fee-rule-create/application-fee-rule-create.component';
import { ApplicationFeeRuleEditComponent } from './application-fee-rule-edit/application-fee-rule-edit.component';
import { ApplicationFeeRuleFormContentComponent } from './application-fee-rule-form-content/application-fee-rule-form-content.component';
import { ApplicationFeeRuleTabsDetailsComponent } from './application-fee-rule-tabs-details/application-fee-rule-tabs-details.component';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  declarations: [
    ApplicationFeeRuleDetailsComponent,
    ApplicationFeeRuleCreateComponent,
    ApplicationFeeRuleEditComponent,
    ApplicationFeeRuleFormContentComponent,
    ApplicationFeeRuleTabsDetailsComponent,
  ],
  imports: [CommonModule, ApplicationFeeRuleRoutingModule, SharedModule],
})
export class ApplicationFeeRuleModule {}
