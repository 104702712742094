import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DataListingProviderComponent } from '../../../../shared/components/data-listing-provider/data-listing-provider.component';
import { AuthenticationGuard } from '../../../../shared/guard/authentication-guard';
import { HasUnsavedChangesGuard } from '../../../../shared/guard/has-form-changed.guard';
import { Entity, HeaderMenuType } from '../../../../shared/store/view/types';
import { ModuleId } from '../../../../shared/store/navigation/types';
import { CommissionPlanRuleSalesPersonCreateComponent } from './commission-plan-rule-sales-person-create/commission-plan-rule-sales-person-create.component';
import { CommissionPlanRuleSalesPersonEditComponent } from './commission-plan-rule-sales-person-edit/commission-plan-rule-sales-person-edit.component';
import { CommissionPlanRuleDetailsComponent } from '../commission-plan-rule-details/commission-plan-rule-details.component';

const routes: Routes = [
  {
    path: 'Finance/CommissionPlanRuleSalesPersons',
    data: {
      breadcrumb: { label: ' ', alias: 'list' },
    },
    children: [
      {
        path: '',
        redirectTo: 'List',
        pathMatch: 'full',
      },
      {
        path: 'List',
        component: DataListingProviderComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Finance,
          moduleId: ModuleId.finance,
          entity: Entity.CommissionPlanRuleSalesPerson,
          breadcrumb: { skip: true },
        },
      },
      {
        path: 'Details/:id',
        component: CommissionPlanRuleDetailsComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Finance,
          moduleId: ModuleId.finance,
          entity: Entity.CommissionPlanRuleSalesPerson,
          breadcrumb: {
            label: ' ',
            alias: 'commissionPlanRuleDetails',
          },
        },
      },
      {
        path: 'Create',
        component: CommissionPlanRuleSalesPersonCreateComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Finance,
          moduleId: ModuleId.finance,
          entity: Entity.CommissionPlanRuleSalesPerson,
          breadcrumb: {
            label: ' ',
            alias: 'commissionPlanRuleSalesPersonCreate',
          },
        },
      },
      {
        path: 'Edit/:id',
        component: CommissionPlanRuleSalesPersonEditComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Finance,
          moduleId: ModuleId.finance,
          entity: Entity.CommissionPlanRuleSalesPerson,
          breadcrumb: {
            label: ' ',
            alias: 'commissionPlanRuleSalesPersonEdit',
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CommissionPlanRuleSalesPersonRoutingModule {}
