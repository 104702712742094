import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DataListingProviderComponent } from 'src/app/shared/components/data-listing-provider/data-listing-provider.component';
import { AuthenticationGuard } from 'src/app/shared/guard/authentication-guard';
import { Entity } from 'src/app/shared/store/view/types';
import { HasUnsavedChangesGuard } from '../../../shared/guard/has-form-changed.guard';
import { ModuleId } from '../../../shared/store/navigation/types';
import { HeaderMenuType } from '../../../shared/store/view/types';
import { ActivityTypeCreateComponent } from './activity-type-create/activity-type-create.component';
import { ActivityTypeDetailsComponent } from './activity-type-details/activity-type-details.component';
import { ActivityTypeEditComponent } from './activity-type-edit/activity-type-edit.component';

const routes: Routes = [
  {
    path: 'Crm/ActivityTypes',
    data: {
      breadcrumb: { label: ' ', alias: 'list' },
    },
    children: [
      {
        path: '',
        redirectTo: 'List',
        pathMatch: 'full',
      },
      {
        path: 'List',
        component: DataListingProviderComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          moduleId: ModuleId.crm,
          entity: Entity.ActivityType,
          breadcrumb: { skip: true },
        },
      },
      {
        path: 'Details/:id',
        component: ActivityTypeDetailsComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          moduleId: ModuleId.crm,
          entity: Entity.ActivityType,
          breadcrumb: { label: ' ', alias: 'activityTypeDetails' },
        },
      },
      {
        path: 'Create',
        component: ActivityTypeCreateComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          moduleId: ModuleId.crm,
          entity: Entity.ActivityType,
          breadcrumb: { label: ' ', alias: 'activityTypeCreate' },
        },
      },
      {
        path: 'Edit/:id',
        component: ActivityTypeEditComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          moduleId: ModuleId.crm,
          entity: Entity.ActivityType,
          breadcrumb: { label: ' ', alias: 'activityTypeEdit' },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ActivityTypeRoutingModule {}
