<form [formGroup]="accountForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="accountForm">
  <button type="submit" hidden="hidden"></button>


  <div class="row">
    <div [ngClass]="{'col-6': source == SourceEnum.Component, 'col': source == SourceEnum.Modal}">
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" for="accountId" translate>ACCOUNT_LBL_ACCOUNT</label>
        <div class="col">
          <app-typeahead
            id="accountId"
            [routeEndpoint]="'Accounts'"
            [controlForm]="this.accountForm.get('accountId')"
            [displayMember]="'name'"
            [valueMember]="'id'"
            [orderBy]="'name asc'"
            [limitToList]="true"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div [ngClass]="{'col-6': source == SourceEnum.Component, 'col': source == SourceEnum.Modal}">
      <div class="form-group">
        <label class="form-title-label" for="name" translate>ACCOUNT_LBL_NAME</label>
        <input
          id="name"
          class="form-control form-title"
          type="text"
          formControlName="name"
          checkFormFieldValidity
        />
        <!--            [maxLength]="100"-->
      </div>
    </div>
  </div>


  <div class="row">

    <div [ngClass]="{'col-6': source == SourceEnum.Component, 'col': source == SourceEnum.Modal}">
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" for="accountType" translate>ACCOUNT_LBL_TYPE</label>
        <div class="col">
          <app-typeahead
            id="accountType"
            [limitToList]="true"
            [dataType]="DataType.Enum"
            [valueMember]="'value'"
            [displayMember]="'name'"
            [referenceEndpoint]="'Views/enum/values'"
            [referenceField]="'Comparanoo.Core.Enums.Accounting.AccountType'"
            [dropdownStyle]="DropdownStyle.DropDown"
            [controlForm]="this.accountForm.get('accountType')"
          ></app-typeahead>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" for="contactId" translate>ACCOUNT_LBL_CONTACT</label>
        <div class="col">
          <app-typeahead
            id="contactId"
            [routeEndpoint]="'Contacts'"
            [controlForm]="this.accountForm.get('contactId')"
            [displayMember]="'name'"
            [valueMember]="'id'"
            [orderBy]="'name asc'"
            [limitToList]="true"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
        >ACCOUNT_LBL_CAN_HANDLE_CASH</label
        >
        <div class="col">
            <span class="form-control-plaintext form-control-sm">
              <input
                type="checkbox"
                name="canHandleCash"
                formControlName="canHandleCash"
              />
            </span>
        </div>
      </div>

    </div>
  </div>
</form>
