import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, filter, Observable } from 'rxjs';
import { View } from '../../../../shared/store/view/types';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { FormService } from '../../../../shared/store/form/form.service';
import { take } from 'rxjs/operators';
import { CarReferenceExactModelModel } from '../../../../shared/store/car-reference-exact-model/types';
import { CarReferenceExactModelService } from '../../../../shared/store/car-reference-exact-model/car-reference-exact-model.service';
import { filterTrue } from '../../../../shared/pipe/rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-car-reference-exact-model-form-content',
  templateUrl: './car-reference-exact-model-form-content.component.html',
  styleUrls: ['./car-reference-exact-model-form-content.component.scss'],
})
export class CarReferenceExactModelFormContentComponent
  implements OnInit, OnDestroy
{
  view$: Observable<View>;
  carReferenceExactModelModel$: Observable<CarReferenceExactModelModel>;
  createCarReferenceExactModelInstance$: Observable<CarReferenceExactModelModel>;

  carReferenceExactModelForm: FormGroup;

  isEditMode: boolean = false;
  carReferenceExactModelId: string;

  carReferenceBrandId: string;
  modelDomain: string = 'carReferenceBrandId=0';

  constructor(
    private readonly carReferenceExactModelService: CarReferenceExactModelService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder,
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.carReferenceExactModelModel$ =
      this.carReferenceExactModelService.getById.value$;
    this.createCarReferenceExactModelInstance$ =
      this.carReferenceExactModelService.createInstance.value$;
  }

  ngOnInit(): void {
    //Déclaration du formulaire
    this.carReferenceExactModelForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      carReferenceModelId: [undefined, [Validators.required]],
      carReferenceBrandId: [undefined, [Validators.required]],
    });

    //Récupération de l'id via la route pour déterminer si mode Edit (true) ou Create (false)
    this.carReferenceExactModelId = this.route.snapshot.params['id'];
    this.isEditMode = !!this.carReferenceExactModelId;

    //On appelle les API en fonction du mode
    if (this.isEditMode) {
      this.carReferenceExactModelService.getById.call(
        this.carReferenceExactModelId,
      );
    } else {
      // this.carReferenceExactModelService.createInstance.call();
    }

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (
          x == 'carReferenceExactModelCreate' ||
          x == 'carReferenceExactModelUpdate'
        )
          this.onSubmit();
        if (x == 'carReferenceExactModelList') {
          this.formService.clear();
          this.router.navigate(['Crm/CarReferenceExactModels/']);
        }
        if (x == 'carReferenceExactModelDetails') {
          this.formService.clear();
          this.router.navigate([
            '/Crm/CarReferenceExactModels/Details',
            this.carReferenceExactModelId,
          ]);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);

        if (!this.isEditMode)
          this.breadcrumbService.set(
            '@carReferenceExactModelCreate',
            x.breadcrumbCreate,
          );
      });

    if (this.isEditMode) {
      this.carReferenceExactModelModel$
        .pipe(
          filter((x) => !!x),
          take(1),
        )
        .subscribe((x) => {
          this.breadcrumbService.set('@carReferenceExactModelEdit', x.name);
          this.carReferenceExactModelForm.patchValue({
            name: x.name,
            carReferenceModelId: x.carReferenceModelId,
            carReferenceBrandId: x.carReferenceModelCarReferenceBrandId,
          });
        });
    }

    if (!this.isEditMode) {
      // this.createCarReferenceExactModelInstance$
      //   .pipe(
      //     filter((x) => !!x),
      //     take(1),
      //   )
      //   .subscribe((x) => {
      //     this.carReferenceExactModelForm?.patchValue({
      //       name: x.name,
      //     });
      //   });
    }

    this.carReferenceExactModelForm
      .get('carReferenceBrandId')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value) {
          this.modelDomain = `carReferenceBrandId=${value}`;
        } else {
          this.modelDomain = 'carReferenceBrandId=0';
          this.carReferenceExactModelForm.patchValue({
            carReferenceBrandId: undefined,
          });
        }
      });
  }

  onSubmit() {
    if (this.carReferenceExactModelForm.invalid) {
      this.carReferenceExactModelForm.markAllAsTouched();
      this.formService.setEntityErrors('CAR_REFERENCE_EXACT_MODEL');
      this.formService.countErrors(this.carReferenceExactModelForm, true);
      return;
    }

    const carReferenceExactModelModel: CarReferenceExactModelModel = {
      id: this.carReferenceExactModelId ? +this.carReferenceExactModelId : 0,
      name: this.carReferenceExactModelForm.value.name!,
      carReferenceModelId:
        this.carReferenceExactModelForm.value.carReferenceModelId!,
      carReferenceModelCarReferenceBrandId:
        this.carReferenceExactModelForm.value.carReferenceBrandId!,
      carReferenceModelName: '',
      carReferenceModelCarReferenceBrandName: '',
    };

    let result$: Observable<CarReferenceExactModelModel>;
    if (this.isEditMode)
      result$ = this.carReferenceExactModelService.update.call(
        carReferenceExactModelModel,
      );
    else
      result$ = this.carReferenceExactModelService.add.call(
        carReferenceExactModelModel,
      );

    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result) => {
        this.formService.clear();
        this.router.navigate([
          '/Crm/CarReferenceExactModels/Details',
          result.id,
        ]);
      });
  }

  ngOnDestroy(): void {
    this.carReferenceExactModelService.add.reset();
    this.carReferenceExactModelService.update.reset();

    // if (this.isEditMode) {
    //   this.activityService.getById.reset();
    // } else {
    //   this.activityService.createInstance.reset();
    // }

    this.breadcrumbService.set('@carReferenceExactModelEdit', ' ');
    this.breadcrumbService.set('@carReferenceExactModelCreate', ' ');
  }
}
