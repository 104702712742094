import { createAction, props } from '@ngrx/store';
import { createApiCallActions } from 'src/app/common/store/generic.actions';
import { Domain, Filter, OperationModel, SelectedRows } from './types';
import { SearchResult } from '../../types';

export const searchActions = {
  search: createApiCallActions<SearchResult<any>>('search', 'search'),
  operations: createApiCallActions<OperationModel[]>('search', 'operations'),
  setActiveFilters: createAction(
    '[Search] Set active filters',
    props<{ activeFilters: Filter[] }>(),
  ),
  setCustomFilters: createAction(
    '[Search] Set custom filters',
    props<{ customFilters: Filter[] }>(),
  ),
  removeActiveFilterByIndex: createAction(
    '[Search] Remove action filter by index',
    props<{ index: number }>(),
  ),
  addActiveFilter: createAction(
    '[Search] add active filters',
    props<{ filter: Filter; isContextFilterFlag: boolean }>(),
  ),
  setContextFilters: createAction(
    '[Search] Set context filters',
    props<{ contextFilters: Filter[]; isContextFilterFlag: boolean }>(),
  ),
  resetActiveFilter: createAction('[Search] reset active filters'),
  setContextFilterFlag: createAction(
    '[Search] set context filter flag',
    props<{ flag: boolean }>(),
  ),
  addTextFilter: createAction(
    '[Search] add text filters',
    props<{ domain: Domain }>(),
  ),
  toggleBuiltInFilter: createAction(
    '[Search] toggle built in filter',
    props<{ builtInFilter: Filter }>(),
  ),
  applyCustomFilter: createAction(
    '[Search] apply custom filter',
    props<{ domains: Domain[] }>(),
  ),
  changeDomainState: createAction(
    '[Search] change domain state',
    props<{ filterIndex: number; domainIndex: number }>(),
  ),
  addDefaultActiveFilter: createAction(
    '[Search] add default active filters',
    props<{ filter: Filter; isContextFilterFlag: boolean }>(),
  ),
  setSelectedRows: createAction(
    '[Search] Set selected rows',
    props<{ selectedRows: SelectedRows }>(),
  ),
};
export type SearchAction = typeof searchActions;
