<form [formGroup]="commissionPlanRuleForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="commissionPlanRuleForm">
  <button type="submit" hidden="hidden"></button>

  <div class="row">
    <div class="col-6 col-lg-6">
      <div class="form-group">
        <label class="form-title-label" for="name" translate>COMMISSION_PLAN_RULE_LBL_NAME</label>
        <input
          id="name"
          class="form-control form-title"
          type="text"
          formControlName="name"
          checkFormFieldValidity
          [maxLength]="150"
        />
      </div>
    </div>
    <div class="col"></div>
  </div>

  <div class="row">

    <div class="col-6 col-lg-6">

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" for="commissionPlanId" translate>COMMISSION_PLAN_RULE_LBL_COMMISSION_PLAN_NAME</label>
        <div class="col">
          <app-multi-select-dropdown
            [routeEndpoint]="'CommissionPlans'"
            [quickAddRouteEndpoint]="'SalesPerson'"
            [dropdownStyle]="DropdownStyle.DropDownList"
            [controlForm]="this.commissionPlanRuleForm.controls['commissionPlanIds']"
            [valueMember]="'id'"
            [displayMember]="'name'"
            [orderBy]="'name asc'"
            [limitToList]="false"
            [pageSize]="100"
            [domain]="'CommissionPlanType=0'"
          ></app-multi-select-dropdown>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" for="commissionPlanRuleSource" translate>COMMISSION_PLAN_RULE_LBL_SOURCE</label>
        <div class="col">
          <app-typeahead
            id="commissionPlanRuleSource"
            [limitToList]="true"
            [dataType]="DataType.Enum"
            [valueMember]="'value'"
            [displayMember]="'name'"
            [referenceEndpoint]="'Views/enum/values'"
            [referenceField]="'Comparanoo.Core.Enums.Commissions.CommissionPlanRuleSource'"
            [dropdownStyle]="DropdownStyle.DropDown"
            [controlForm]="this.commissionPlanRuleForm.get('commissionPlanRuleSource')"
          ></app-typeahead>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" for="value" translate>COMMISSION_PLAN_RULE_LBL_VALUE</label>
        <div class="col">
          <input
            id="value"
            name="value"
            class="form-control form-control-sm"
            type="text"
            formControlName="value"
            checkFormFieldValidity
            [placeholder]="this.amountPlaceholder | translate"
            mask="percent.2"
            [suffix]="this.amountSuffix | translate"
            [showMaskTyped]="false"
            [decimalMarker]="','"
            [allowNegativeNumbers]="true"
          />
        </div>
      </div>
    </div>

    <div class="col">
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" for="startDate" translate>COMMISSION_PLAN_RULE_LBL_START_DATE</label>
        <div class="col">
          <input
            class="form-control form-control-sm"
            id="startDate"
            type="date"
            formControlName="startDate"
            checkFormFieldValidity
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" for="endDate" translate>COMMISSION_PLAN_RULE_LBL_END_DATE</label>
        <div class="col">
          <input
            class="form-control form-control-sm"
            id="endDate"
            type="date"
            formControlName="endDate"
            checkFormFieldValidity
          />
        </div>
      </div>
    </div>
  </div>
</form>
