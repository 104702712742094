import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SmartAction, View } from '../../../../shared/store/view/types';
import { ActivatedRoute, Router } from '@angular/router';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { InsuranceCompanyModel } from 'src/app/shared/store/insurance-company/types';
import { InsuranceCompanyService } from 'src/app/shared/store/insurance-company/insurance-company.service';

@UntilDestroy()
@Component({
  selector: 'app-insurance-company-details',
  templateUrl: './insurance-company-details.component.html',
  styleUrls: ['./insurance-company-details.component.scss'],
})
export class InsuranceCompanyDetailsComponent implements OnInit {
  view$: Observable<View>;
  insuranceCompanyModel$: Observable<InsuranceCompanyModel>;
  insuranceCompanyId: number;
  smartActions: SmartAction[];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly subHeaderService: SubHeaderService,
    private readonly viewService: ViewService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly insuranceCompanyService: InsuranceCompanyService,
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.insuranceCompanyModel$ = this.insuranceCompanyService.getById.value$;
  }

  ngOnInit(): void {
    this.insuranceCompanyId = this.route.snapshot.params['id'];

    this.smartActions = [
      {
        id: 'documents',
        label: 'SMARTACTIONS_LBL_CONTACT',
        url: '/Contacts/Contacts/List',
        count: 0,
        filter: {
          domains: [
            {
              field: 'Id',
              operator: {
                id: 'EqualTo',
                label: '',
                numberOfValues: 1,
              },
              connector: 'Or',
              fieldLabel: '',
              text: '',
              isActive: true,
              text2: '',
              value: '',
              value2: null,
            },
          ],
          name: '',
          tag: 'SmartActionFilter',
          text: '',
        },
        icon: 'file-outline',
        actif: true,
        singleId: '',
      },
    ];

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'insuranceCompanyEdit') {
          this.router.navigate([
            'Insurance/InsuranceCompanies/Edit/',
            this.insuranceCompanyId,
          ]);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.insuranceCompanyModel$
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((x) => {
        if (x.contact) {
          this.smartActions[0].count = 1;
          this.smartActions[0].filter.domains[0].value = x.contact.id;
          this.smartActions[0].filter.name = x.contact.name;
          this.smartActions[0].filter.text = x.contact.name;
          this.smartActions[0].singleId = x.contact[0].id.toString();
        }
      });
  }
}
