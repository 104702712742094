import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DataListingProviderComponent } from '../../../shared/components/data-listing-provider/data-listing-provider.component';
import { AuthenticationGuard } from '../../../shared/guard/authentication-guard';
import { HasUnsavedChangesGuard } from '../../../shared/guard/has-form-changed.guard';
import { Entity, HeaderMenuType } from '../../../shared/store/view/types';
import { ModuleId } from '../../../shared/store/navigation/types';
import { CarReferenceModelDetailsComponent } from './car-reference-model-details/car-reference-model-details.component';
import { CarReferenceModelCreateComponent } from './car-reference-model-create/car-reference-model-create.component';
import { CarReferenceModelEditComponent } from './car-reference-model-edit/car-reference-model-edit.component';

const routes: Routes = [
  {
    path: 'Crm/CarReferenceModels',
    data: {
      breadcrumb: { label: ' ', alias: 'list' },
    },
    children: [
      {
        path: '',
        redirectTo: 'List',
        pathMatch: 'full',
      },
      {
        path: 'List',
        component: DataListingProviderComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          moduleId: ModuleId.crm,
          entity: Entity.CarReferenceModel,
          breadcrumb: { skip: true },
        },
      },
      {
        path: 'Details/:id',
        component: CarReferenceModelDetailsComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          moduleId: ModuleId.crm,
          entity: Entity.CarReferenceModel,
          breadcrumb: { label: ' ', alias: 'carReferenceModelDetails' },
        },
      },
      {
        path: 'Create',
        component: CarReferenceModelCreateComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          moduleId: ModuleId.crm,
          entity: Entity.CarReferenceModel,
          breadcrumb: { label: ' ', alias: 'carReferenceModelCreate' },
        },
      },
      {
        path: 'Edit/:id',
        component: CarReferenceModelEditComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          moduleId: ModuleId.crm,
          entity: Entity.CarReferenceModel,
          breadcrumb: { label: ' ', alias: 'carReferenceModelEdit' },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CarReferenceModelRoutingModule {}
