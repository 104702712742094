import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import {
  ApplicationFeeRuleModel,
  applicationFeeRuleFeatureKey,
  ApplicationFeeType,
} from './types';
import { IApplicationFeeRuleState } from './application-fee-rule.reducer';

const applicationFeeRuleFeatureState =
  createFeatureSelector<IApplicationFeeRuleState>(applicationFeeRuleFeatureKey);

export const applicationFeeRuleSelectors = {
  getById: createApiCallSelectors<number, IApplicationFeeRuleState>(
    applicationFeeRuleFeatureState,
    'getById',
  ),

  getByProductLineId: createApiCallSelectors<number, IApplicationFeeRuleState>(
    applicationFeeRuleFeatureState,
    'getByProductLineId',
  ),
  getByContractId: createApiCallSelectors<
    { id: number; type: ApplicationFeeType },
    IApplicationFeeRuleState
  >(applicationFeeRuleFeatureState, 'getByContractId'),
  createInstance: createApiCallSelectors<void, IApplicationFeeRuleState>(
    applicationFeeRuleFeatureState,
    'createInstance',
  ),
  add: createApiCallSelectors<
    ApplicationFeeRuleModel,
    IApplicationFeeRuleState
  >(applicationFeeRuleFeatureState, 'add'),
  update: createApiCallSelectors<
    ApplicationFeeRuleModel,
    IApplicationFeeRuleState
  >(applicationFeeRuleFeatureState, 'update'),
  delete: createApiCallSelectors<boolean, IApplicationFeeRuleState>(
    applicationFeeRuleFeatureState,
    'delete',
  ),
};
